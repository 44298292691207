import {SupportedLanguages} from "../models/Languages";
import {useFetch} from "./useFetch";

export function useLanguageApi() {
    const baseURL = process.env.REACT_APP_API_URL as string;
    const languages_url = `${baseURL}/languages`;
    const requester = useFetch();

    const getSupportedLanguages = () => {
        return requester.get(SupportedLanguages, languages_url) as Promise<SupportedLanguages>;
    }

    return {
        getSupportedLanguages
    }
}
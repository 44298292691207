import {ContentTable} from "../common/ContentStandards";
import {useAppSelector} from "../../state/hooks";
import {selectUser} from "../../state/userSlice";
import LatestClientCases from "./LatestClientCases";
import TasksOverview from "./TasksOverview";
import {isUser} from "../utils/Enums";
import {InactiveCheck} from "../common/ErrorMessage";
import NewsList from "./NewsList";

export default function Overview() {
    const user = useAppSelector(selectUser);

    return (
        <ContentTable>
            <LatestClientCases />
            <>
                {isUser(user.accessLevel) && (
                    <InactiveCheck fit={true}>
                        <TasksOverview />
                    </InactiveCheck>
                )}
            </>
            <NewsList />
        </ContentTable>
    );
}
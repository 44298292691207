import {useEffect, useState} from "react";

export class ScreenSpec {
    screenWidth: number;
    screenHasMenu: boolean;

    constructor(width: number, hasMenu: boolean = false) {
        this.screenWidth = width;
        this.screenHasMenu = hasMenu
    }
}

export const useScreenSize = (): ScreenSpec | undefined => {
    const [screenSpec, setScreenSpec ] = useState<ScreenSpec>();

    useEffect(() => {
        function getScreenSize(): ScreenSpec {
            const width = window.innerWidth;
            const hasMenu = width > 915;
            return new ScreenSpec(width, hasMenu);
        }

        function handleResize() {
            setScreenSpec(getScreenSize);
            // console.log("resize");
        }

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);

    }, []);

    return screenSpec;
}
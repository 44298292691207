import requireTranslation from "../../lang/locales/config";
import React, {useState} from "react";
import {useStandardSetup} from "../../utils/customHooks";
import {useSubscriptionApi} from "../../API/useSubscriptionApi";
import {IntegratedSpinLoader, OverlaySpinLoader} from "../common/SpinLoader";
import {InformationRow} from "../common/TextCommons";
import {SubscriptionChangeOutcome, SubscriptionStatus} from "../../models/user/subscription";
import {ConfirmationDialog, useDialog} from "../common/ConfirmationDialog";
import {useNavigate} from "react-router-dom";
import {SubscriptionEnum, SubscriptionUpdateOption} from "./Enums";
import {BasicSubscription, ExtendedSubscription} from "./SelectSubscription";
import {ExpandableContent} from "../common/ContentStandards";


interface OutcomeOfChangeDisplayProps {
    subscriptionChangeOutcome?: SubscriptionChangeOutcome
}

function OutcomeOfChangeDisplay({ subscriptionChangeOutcome }: OutcomeOfChangeDisplayProps) {
    const t = requireTranslation();

    return (
        <>
            {subscriptionChangeOutcome ?
                <div className="content-inner-frame-container scrollable color-sec-background ">
                    <div className="content-inner-form">
                        {subscriptionChangeOutcome.calculation.map((calculationItem, index) => (
                                <InformationRow
                                    key={index}
                                    title={calculationItem.type === "credit" ?
                                        t.subscriptionAdministrate.credit :
                                        t.subscriptionAdministrate.cost}
                                    text={`${calculationItem.amount / 100} ${subscriptionChangeOutcome.currency.toUpperCase()}`}/>
                        ))}
                        <hr/>
                        <InformationRow
                            title={t.subscriptionAdministrate.owed}
                            text={`${subscriptionChangeOutcome.owed / 100} ` +
                                `${subscriptionChangeOutcome.currency.toUpperCase()}`} />
                        <InformationRow
                            title={t.subscription}
                            text={`${subscriptionChangeOutcome.subscriptionPrice / 100} ` +
                                `${subscriptionChangeOutcome.currency.toUpperCase()}`}/>
                        <hr/>
                        <InformationRow
                            title={t.subscriptionAdministrate.subtotal}
                            text={`${subscriptionChangeOutcome.subtotal / 100} ` +
                                `${subscriptionChangeOutcome.currency.toUpperCase()}`}/>
                        <InformationRow
                            title={t.subscriptionAdministrate.total}
                            text={`${subscriptionChangeOutcome.total / 100} ` +
                                `${subscriptionChangeOutcome.currency.toUpperCase()}`}/>
                        <p/>
                        <p className="single-line italic">{t.subscriptionAdministrate.unusedTimeCredit}</p>
                        <p className="single-line italic">{t.subscriptionAdministrate.costTimeSpendNew}</p>
                    </div>
                </div> :
                <></>
            }
        </>
    )
}


interface AdministrateSubscriptionProps {
    handleUpdateUser: () => void
}


export function AdministrateSubscription({ handleUpdateUser }: AdministrateSubscriptionProps) {
    const t = requireTranslation();
    const [
        loading, setLoading,
        error, setError,
        subscriptionStatus, setSubscriptionStatus
    ] = useStandardSetup<SubscriptionStatus>();
    const [ overlayLoader, setOverlayLoader ] = useState(false);
    const [ changeOutcome, setChangeOutcome ] = useState<SubscriptionChangeOutcome>();
    const [ editError, setEditError] = useState("");
    const [ confirmAction, setConfirmAction ] = useState(SubscriptionUpdateOption.NONE);
    const { isOpen, message: dialogMessage, setDialogMessage, toggle: toggleDialog } = useDialog();
    const { getSubscriptionStatus, getSubscriptionChangeOutcome, updateSubscription } = useSubscriptionApi();
    const navigate = useNavigate();

    const handleGetSubscriptionStatus = (forceUpdate: boolean = false) => {
        if ((!subscriptionStatus && !error) || forceUpdate) {
            setError("");
            setLoading(true);
            getSubscriptionStatus()
                .then(setSubscriptionStatus)
                .catch((e) => {
                    if (e instanceof Error) {
                        setError(e.message);
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    const handleShowConfirmationDialog = (
        confirmText: string,
        confirmAction: SubscriptionUpdateOption
    ) => {
        if (confirmAction === SubscriptionUpdateOption.UPGRADE ||
            confirmAction === SubscriptionUpdateOption.DOWNGRADE) {
            setOverlayLoader(true);
            getSubscriptionChangeOutcome(confirmAction)
                .then((outcome) => {
                    setChangeOutcome(outcome);
                    setConfirmAction(confirmAction);
                    setDialogMessage(confirmText);
                    toggleDialog();
                })
                .catch((e) => {
                    if (e instanceof Error) {
                        if (e.message === t.responseMessage.errorMaxExceeded) {
                            setError(t.subscriptionAdministrate.errorChangeSubscriptionMax)
                        } else {
                            setError(e.message);
                        }
                    }
                })
                .finally(() => {
                    setOverlayLoader(false);
                });
        } else {
            setChangeOutcome(undefined);
            setConfirmAction(confirmAction);
            setDialogMessage(confirmText);
            toggleDialog();
        }
    };

    const handleConfirmAction = () => {
        setEditError("");

        switch (confirmAction) {
            case SubscriptionUpdateOption.REACTIVATE: {
                handleReActivateSubscription();
                break;
            }
            case SubscriptionUpdateOption.CANCEL: {
                handleCancelSubscription();
                break;
            }
            case SubscriptionUpdateOption.UPGRADE: {
                handleUpgradeSubscription();
                break;
            }
            case SubscriptionUpdateOption.DOWNGRADE: {
                handleDowngradeSubscription();
                break;
            }
            default: {
                break;
            }
        }
    };

    const handleBeginNewSubscription = () => {
        if (subscriptionStatus) {
            navigate(
                `/checkout/${subscriptionStatus.subscriptionChoice}/` +
                `${subscriptionStatus.customerEmail}/${subscriptionStatus.customerId}`
            )
        }
    };
    
    const handleReActivateSubscription = () => {
        setLoading(true);
        updateSubscription(SubscriptionUpdateOption.REACTIVATE)
            .then(() => handleGetSubscriptionStatus(true))
            .catch((e) => {
                if (e instanceof Error) {
                    setEditError(e.message);
                }
            })
            .finally(() => setLoading(false));
    };
    
    const handleCancelSubscription = () => {
        setLoading(true);
        updateSubscription(SubscriptionUpdateOption.CANCEL)
            .then(() => handleGetSubscriptionStatus(true))
            .catch((e) => {
                if (e instanceof Error) {
                    setEditError(e.message);
                }
            })
            .finally(() => setLoading(false));
    };
    
    const handleUpgradeSubscription = () => {
        setLoading(true);
        updateSubscription(SubscriptionUpdateOption.UPGRADE)
            .then(() => {
                handleUpdateUser();
                handleGetSubscriptionStatus(true);
            })
            .catch((e) => {
                if (e instanceof Error) {
                    setEditError(e.message);
                }
            })
            .finally(() => setLoading(false));
    };
    
    const handleDowngradeSubscription = () => {
        setLoading(true);
        updateSubscription(SubscriptionUpdateOption.DOWNGRADE)
            .then(() => {
                handleUpdateUser();
                handleGetSubscriptionStatus(true);
            })
            .catch((e) => {
                if (e instanceof Error) {
                    setEditError(e.message);
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <>
            {isOpen &&
                <ConfirmationDialog
                    toggle={toggleDialog}
                    message={dialogMessage}
                    onConfirm={handleConfirmAction}>
                    <OutcomeOfChangeDisplay subscriptionChangeOutcome={changeOutcome} />
                </ConfirmationDialog>
            }
            <OverlaySpinLoader loading={overlayLoader} loadMessage={""} />

            <ExpandableContent title={t.subscriptionAdministrate.title} onExpand={handleGetSubscriptionStatus} >
                <div className="content invisible color-sec-background fold-out">
                    <IntegratedSpinLoader loading={loading} loadMessage={""} />
                    {!loading && !error && subscriptionStatus &&
                        <div className="content-inner-form extra-space">
                            <InformationRow
                                title={t.subscriptionAdministrate.status}
                                text={t.subscriptionAdministrate.statusTrans[subscriptionStatus.status]} />
                            {subscriptionStatus.status === 'canceled' && subscriptionStatus.endPayment !== null &&
                                // subscription is canceled
                                <>
                                    <InformationRow
                                        title={t.subscriptionAdministrate.endPayment}
                                        text={subscriptionStatus.endPayment} />
                                    <hr/>
                                    <div className="row">
                                        <p className="clickable text-button"
                                           onClick={handleBeginNewSubscription}>
                                            {t.subscriptionAdministrate.begin}
                                        </p>
                                    </div>
                                </>
                            }
                            {subscriptionStatus.status === 'active' && subscriptionStatus.endPayment !== null &&
                                // subscription is active but set to end.
                                <>
                                    <InformationRow
                                        title={t.subscriptionAdministrate.endingPayment}
                                        text={subscriptionStatus.endPayment}/>

                                    <hr/>
                                    <div className="row">
                                        <p className="clickable text-button"
                                           onClick={() => {
                                               handleShowConfirmationDialog(
                                                   t.subscriptionAdministrate.confirmReactivate,
                                                   SubscriptionUpdateOption.REACTIVATE
                                               );
                                           }}>
                                            {t.subscriptionAdministrate.activate}
                                        </p>
                                    </div>
                                </>
                            }
                            {subscriptionStatus.status === 'active' && subscriptionStatus.nextPayment !== null &&
                                // subscription is active and awaiting next payment
                                <>
                                    <InformationRow
                                        title={t.subscriptionAdministrate.nextPayment}
                                        text={subscriptionStatus.nextPayment}/>
                                        {subscriptionStatus.subscriptionChoice === SubscriptionEnum.Basic &&
                                            <>
                                                <div className="input-container">
                                                    <div className="row toColumn">
                                                        <p className="title-line fill-half align-start italic">
                                                            {t.subscriptionAdministrate.currentSubscription}
                                                        </p>
                                                        <BasicSubscription isSelected={true}/>
                                                    </div>
                                                </div>
                                                <div className="input-container">
                                                    <div className="row toColumn">
                                                        <p className="title-line fill-half align-start italic">
                                                            {t.subscriptionAdministrate.canUpgrade}
                                                        </p>
                                                        <ExtendedSubscription isSelected={false}/>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        {subscriptionStatus.subscriptionChoice === SubscriptionEnum.Extended &&
                                            <>
                                                <div className="input-container">
                                                    <div className="row toColumn">
                                                        <p className="title-line fill-half align-start italic">
                                                            {t.subscriptionAdministrate.currentSubscription}
                                                        </p>
                                                        <ExtendedSubscription isSelected={true}/>
                                                    </div>
                                                </div>
                                                <div className="input-container">
                                                    <div className="row toColumn">
                                                        <p className="title-line fill-half align-start italic">
                                                            {t.subscriptionAdministrate.canDowngrade}
                                                        </p>
                                                        <BasicSubscription isSelected={false}/>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    <hr/>
                                    <div className="row toColumn">
                                        {subscriptionStatus.subscriptionChoice === SubscriptionEnum.Basic &&
                                            <p className="clickable text-button"
                                                onClick={() => {
                                                    handleShowConfirmationDialog(
                                                        t.subscriptionAdministrate.confirmUpgrade,
                                                        SubscriptionUpdateOption.UPGRADE
                                                    )
                                                }}>
                                                {t.subscriptionAdministrate.upgrade}
                                            </p>
                                        }
                                        {subscriptionStatus.subscriptionChoice === SubscriptionEnum.Extended &&
                                            <p className="clickable text-button"
                                               onClick={() => {
                                                   handleShowConfirmationDialog(
                                                       t.subscriptionAdministrate.confirmDowngrade,
                                                       SubscriptionUpdateOption.DOWNGRADE
                                                   )
                                               }}>
                                                {t.subscriptionAdministrate.downgrade}
                                            </p>
                                        }
                                        <p className="clickable text-button"
                                           onClick={() => {
                                               handleShowConfirmationDialog(
                                                   t.subscriptionAdministrate.confirmCancel,
                                                   SubscriptionUpdateOption.CANCEL
                                               )
                                           }}>
                                            {t.subscriptionAdministrate.cancel}
                                        </p>
                                    </div>
                                    <p className="support-linebreak italic">{t.subscriptionAdministrate.disclaimer}</p>
                                </>
                            }
                        </div>
                    }
                    {editError &&
                        <div className="column margin">
                            <p className="error message no-border" role="alert">{editError}</p>
                        </div>
                    }
                    {error && !loading &&
                        <div className="column margin">
                            <p className="error message no-border" role="alert">{error}</p>
                            <p
                                className="clickable box-radius input-redirect-link"
                                onClick={() => handleGetSubscriptionStatus(true)}
                            >{t.tryAgain}</p>
                        </div>
                    }
                </div>
            </ExpandableContent>
        </>
    )
}
interface InstallationModelInit {
    id: string | null;
    installation: string;
    installationId: string;
    placement: string;
    control: string;
    preheatingSurface: string;
    filterType: string;
    clientId: string | null;
}

export class InstallationModel implements InstallationModelInit {
    id: string | null = null;
    installation: string = "";
    installationId: string = "";
    placement: string = "";
    control: string = "";
    preheatingSurface: string = "";
    filterType: string = "";
    clientId: string | null = null;

    constructor(init?: InstallationModelInit) {
        if (!init) return

        this.id = init.id;
        this.installation = init.installation;
        this.installationId = init.installationId;
        this.placement = init.placement;
        this.control = init.control;
        this.preheatingSurface = init.preheatingSurface;
        this.filterType = init.filterType;
        this.clientId = init.clientId;
    }
}
import requireTranslation from "../../lang/locales/config";
import React, {useEffect, useState} from "react";
import {MenuButton, OverlayMenu, StandardMenu, useMenu, useOverlayMenu} from "../common/OverlayMenu";
import ClientCaseList from "./ClientCaseList";
import CaseForm from "./CaseForm";
import {useLocation} from "react-router-dom";
import {InactiveCheck} from "../common/ErrorMessage";

interface StateValueObject {
    statusMessage?: string
}

export default function CasesPage() {
    const t = requireTranslation();
    const location = useLocation();
    const {statusMessage}: StateValueObject = location.state ? location.state : { statusMessage: undefined };
    const [ shownContent, setShownContent ] = useState<JSX.Element>();
    const [ highlightList, setHighlightIndex ] = useMenu(2);
    const [ isFloatingMenuShow, toggleMenu ] = useOverlayMenu();
    const [ toastMessage, setToastMessage ] = useState(statusMessage);

    useEffect(() => {
        if (!shownContent) {
            handleToCasesList();
        }
    }, [shownContent]);

    useEffect(() => {
        setTimeout(() => { setToastMessage(undefined); }, 3000);
    }, [toastMessage]);

    const handleToCasesList = () => {
        setHighlightIndex(0);
        setShownContent(<ClientCaseList />);
    }

    const handleMenuButtonClick = (index: number, content: JSX.Element) => {
        setHighlightIndex(index);
        setShownContent(content);
    };

    const menuButtons = () => (
        <>
            <MenuButton
                text={t.cases}
                onClick={() => {handleMenuButtonClick(0,
                        <ClientCaseList />
                    )}}
                isHighlight={highlightList[0]} />
            <MenuButton
                text={t.new}
                onClick={() => {handleMenuButtonClick(1,
                        <InactiveCheck>
                            <CaseForm />
                        </InactiveCheck>
                    )}}
                isHighlight={highlightList[1]} />
        </>
    )


    return (
        <div className="page-menu-with-content-container center-content">
            {toastMessage && (
                <div className="snackbar">{toastMessage}</div>
            )}

            <div className="content-control-menu-show-button" onClick={toggleMenu}>
                <img src={"miniMenu.svg"} className="icon" alt="menu" />
            </div>

            <OverlayMenu
                isVisible={isFloatingMenuShow}
                toggle={toggleMenu}
                ButtonList={menuButtons} />

            <StandardMenu MenuButtons={menuButtons} />

            <div className="content-container">
                {shownContent}
            </div>

        </div>
    )
}
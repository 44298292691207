export interface CheckVATModel {
    vatNumber: string;
    countryCode: string;
}


interface CheckVATResultInit {
    valid: boolean;
    vatNumber: string;
    countryCode: string;
    name: string | null;
    address: string | null;
    zipCode: string | null;
    city: string | null;
    reason: string | null;
}

export class CheckVATResult {
    valid: boolean;
    vatNumber: string;
    countryCode: string;
    name: string | null;
    address: string | null;
    zipCode: string | null;
    city: string | null;
    reason: string | null;

    constructor(init: CheckVATResultInit) {
        this.valid = init.valid;
        this.vatNumber = init.vatNumber;
        this.countryCode = init.countryCode;
        this.name = init.name;
        this.address = init.address;
        this.zipCode = init.zipCode;
        this.city = init.city;
        this.reason = init.reason;
    }
}

export interface VatInformation {
    vatNumber: string;
    countryCode: string;
    name: string;
    address: string;
    zipCode: string | null;
    city: string | null;
}

export class VatInformationModel {
    vatNumber: string;
    countryCode: string;
    name: string;
    address: string;
    zipCode: string | null;
    city: string | null;

    constructor(init: VatInformation) {
        this.vatNumber = init.vatNumber;
        this.countryCode = init.countryCode;
        this.name = init.name;
        this.address = init.address;
        this.zipCode = init.zipCode;
        this.city = init.city;
    }
}
import {NumberInputWithLabel, PhoneInput, SelectFieldWithLabel, TextInputWithLabel} from "../common/FormCommons";
import {Content} from "../common/ContentStandards";
import {OrgUserModel} from "../../models/user/user";
import {ChangeEvent, useState} from "react";
import {Subscription} from "./SubscriptionModel";
import useAdminApi from "../../API/useAdminApi";
import {IntegratedSpinLoader} from "../common/SpinLoader";
import ErrorMessage from "../common/ErrorMessage";
import {useUserApi} from "../../API/useUserApi";
import {requireCurrentLanguage} from "../../lang/locales/config";
import {usePhoneNumberInput} from "../../utils/customHooks";
import {useDialog, ConfirmationDialog} from "../common/ConfirmationDialog";

interface AdminUserEditFormProps {
    initialUser: OrgUserModel
}

export default function AdminUserEditForm({ initialUser }: AdminUserEditFormProps) {
    const [ user, setUser ] = useState(initialUser);
    const [ loading, setLoading ] = useState(false);
    const [ message, setMessage ] = useState("");
    const [ subscription, setSubscription ] = useState(new Subscription(user.subscription));
    const [ passphrase, setPassphrase ] = useState<string>();
    const [ emailLang, setEmailLang ] = useState(requireCurrentLanguage());
    const [ prefEmail, setPrefEmail ] = useState(user.privateEmail);
    const {
        handlePhoneNumberChange,
        inputFieldValue,
        countryCode,
        setCountryCode
    } = usePhoneNumberInput(OrgUserModel, setUser, user.phone);
    const {
        isOpen,
        message: dialogMessage,
        setDialogMessage,
        toggle: toggleDialog
    } = useDialog();
    const { putUser, deleteUser } = useAdminApi();
    const { resetPassword } = useUserApi();

    const handleUserChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        let change = {};

        if (name === "subscription") {
            const indicator = subscription.getIndicator(value)
            setSubscription(new Subscription(indicator));
            change = {[name]: indicator}
        } else {
            change = {[name]: value};
        }

        setUser((prevState) => {
            return { ...prevState, ...change};
        })
    };

    const handleEditUser = () => {
        setMessage("");
        setLoading(true);
        putUser(user)
            .then(() => {
                setMessage("User edit was successful.");
            })
            .catch((e) => {
                if (e instanceof Error) {
                    setMessage(e.message);
                }
            })
            .finally(() => setLoading(false));
    };

    const handleResetPassword = () => {
        setMessage("");
        setLoading(true);
        resetPassword(prefEmail)
            .then(() => {
                setMessage("Reset password email has been sent.");
            })
            .catch((e) => {
                if (e instanceof Error) {
                    setMessage(e.message);
                }
            })
            .finally(() => setLoading(false));
    };

    const handleDelete = () => {
        setMessage("");
        setLoading(true);
        deleteUser({id: user.id, passphrase: passphrase})
            .then(() => {
                setMessage("User deleted! Reload page/new search...");
            })
            .catch((e) => {
                if (e instanceof Error) {
                    setMessage(e.message);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleDeleteDialog = () => {
        setDialogMessage("Are you sure you want to perform this action!? It cannot be undone!\nEnter your unique pass to verify!");
        toggleDialog();
    };

    return (
        <Content invisible={true}>

            <>
                {isOpen &&
                    <ConfirmationDialog
                        toggle={toggleDialog}
                        message={dialogMessage}
                        onConfirm={handleDelete} >
                        <TextInputWithLabel
                            labelText={"Pass"}
                            initialValue={passphrase}
                            isPassword={true}
                            onChange={(e) => setPassphrase(e.target.value)} />
                    </ConfirmationDialog>
                }
            </>
            <div className="content-inner-form">
                <TextInputWithLabel
                    labelText={"Name"}
                    name="name"
                    initialValue={user.name}
                    onChange={handleUserChange} />
                <TextInputWithLabel
                    labelText={"Username"}
                    name="username"
                    initialValue={user.username}
                    onChange={handleUserChange} />
                <TextInputWithLabel
                    labelText={"Firm Name"}
                    name="firmName"
                    initialValue={user.firmName}
                    onChange={handleUserChange} />
                <TextInputWithLabel
                    labelText={"Firm Email"}
                    name="firmEmail"
                    initialValue={user.firmEmail}
                    onChange={handleUserChange} />
                <TextInputWithLabel
                    labelText={"Private Email"}
                    name="privateEmail"
                    initialValue={user.privateEmail}
                    onChange={handleUserChange} />
                <PhoneInput
                    initialValue={inputFieldValue}
                    countryCode={countryCode}
                    setCountryCode={setCountryCode}
                    onChange={handlePhoneNumberChange} />
                <SelectFieldWithLabel
                    labelText={"Subscription"}
                    name="subscription"
                    selection={subscription.getSelection()}
                    initialValue={subscription.textRepresentation}
                    onChange={handleUserChange} />

                <div className="row reverse">
                    <button
                        className="filled"
                        onClick={handleEditUser}>
                        {"Edit"}
                    </button>
                    <button
                        className="outline"
                        onClick={handleResetPassword}>
                        {"Reset PW"}
                    </button>
                    <input
                        value={emailLang}
                        onChange={(event) => {
                            setEmailLang(event.target.value);
                        }}/>
                    <select
                        defaultValue={prefEmail}
                        onChange={(event) => {
                            setPrefEmail(event.target.value);
                        }}>
                        <option value={user.privateEmail}>{`Private Email: ${user.firmEmail}`}</option>
                        <option value={user.firmEmail}>{`Firm Email: ${user.firmEmail}`}</option>
                    </select>
                </div>
                <div className="row reverse">
                    <p className="clickable text-button" onClick={handleDeleteDialog}>
                        Delete
                    </p>

                </div>
            </div>

            <IntegratedSpinLoader
                loading={loading}
                loadMessage={""}/>

            <ErrorMessage error={message}/>
        </Content>
    )
}
import requireTranslation from "../../lang/locales/config";
import {useStandardSetup} from "../../utils/customHooks";
import {EmployeeModel, FormerEmployeeModel} from "../../models/Employee";
import {useEmployeeApi} from "../../API/useEmployeeApi";
import {useEffect} from "react";
import {SpinLoader} from "../common/SpinLoader";
import ErrorMessage from "../common/ErrorMessage";
import {InformationRow} from "../common/TextCommons";


interface ClientCaseEmployeeProps {
    employeeId: string | null;
    formerEmployeeId: string | null;
    onEdit: () => void;
}

export default function ClientCaseEmployee({ employeeId, formerEmployeeId, onEdit }: ClientCaseEmployeeProps) {
    const t = requireTranslation();
    const [
        loading, setLoading,
        error, setError,
        employee, setEmployee,
    ] = useStandardSetup<EmployeeModel | FormerEmployeeModel>();
    const { getEmployee, getFormerEmployee } = useEmployeeApi();

    useEffect(() => {
        if (employeeId) {
            setLoading(true);
            getEmployee(employeeId)
                .then(setEmployee)
                .catch((e) => {
                    if (e instanceof Error) setError(e.message);
                })
                .finally(() => setLoading(false));
        } else if (formerEmployeeId) {
            setLoading(true);
            getFormerEmployee(formerEmployeeId)
                .then(setEmployee)
                .catch((e) => {
                    if (e instanceof Error) setError(e.message);
                })
                .finally(() => setLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employeeId, formerEmployeeId])

    return (
        <>
            <SpinLoader
                containerClassName="content color-main-background"
                loading={loading}
                loadMessage={`${t.loading} ${t.employee.toLowerCase()}...`} />

            <ErrorMessage
                error={error}
                containerClassName="content color-main-background" />

            {!loading && !error && employee && (
                <div className="content fold-out color-main-background">
                    <h3 className="card-header">{`${t.employeeRelated}`}</h3>
                    <InformationRow title={`${t.name}:`} text={employee.name} />
                    <div className="row margin reverse">
                        <button
                            className="filled"
                            onClick={() => onEdit()
                            }
                        >{t.edit}</button>
                    </div>
                </div>
            )}
            {!loading && !error && !employee && (
                <div className="content fold-out color-main-background">
                    <h3 className="card-header">{`${t.employeeRelated}`}</h3>
                    <InformationRow title={`${t.name}:`} text={t.yourself} />
                    <div className="row margin reverse">
                        <button
                            className="filled"
                            onClick={() => onEdit()
                            }
                        >{t.edit}</button>
                    </div>
                </div>
            )}
        </>
    )
}
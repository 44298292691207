import requireTranslation from "../../lang/locales/config";
import {useStandardSetup} from "../../utils/customHooks";
import {SyntheticEvent, useEffect, useState} from "react";
import {SelectFieldWithLabel, TextInputWithLabel} from "../common/FormCommons";
import {useLoggerNoteFileApi, useLoggerSupportedDevices} from "../../API/useClientCaseApi";
import LoggerDeviceModel from "../../models/case/LoggerDevice";
import {isInputErrorsEmpty, validateRequired} from "../../utils/inputValidation";
import {SpinLoader} from "../common/SpinLoader";
import ErrorMessage from "../common/ErrorMessage";
import SupportedLoggersModel from "../../models/case/supportedLoggers";

interface LoggerNoteUploadFileProps {
    clientId: string;
    afterUploadSuccess: () => void;
}

interface InputErrorsInter {
    file: string;
    deviceName: string;
    deviceCompany: string;
}

export default function LoggerNoteUploadFile({ clientId, afterUploadSuccess }: LoggerNoteUploadFileProps) {
    const t = requireTranslation();
    const newDeviceInputErrors: InputErrorsInter = {
        "file": "",
        "deviceName": "",
        "deviceCompany": "",
    }
    const [
        loading, setLoading,
        error, setError,
        loggerNoteFile, setLoggerNoteFile,
    ] = useStandardSetup<File>();
    const [ inputErrors, setInputErrors ] = useState(newDeviceInputErrors);
    const [ newDevice, setNewDevice ] = useState(new LoggerDeviceModel());
    const [ showDeviceForm, setShowDeviceForm ] = useState(false);
    const [ supportedDeviceChoice, setSupportedDeviceChoice ] = useState<string>();
    const [ loadingMessage, setLoadingMessage ] = useState('');
    const [ supportedLoggers, setSupportedLoggers ] = useState<SupportedLoggersModel>();
    const { getSupportedLoggers } = useLoggerSupportedDevices();
    const { postLoggerNoteFile, postUnsupportedLoggerNoteFile } = useLoggerNoteFileApi();

    useEffect(() => {
        if (supportedLoggers === undefined) {
            setLoading(true);
            setLoadingMessage(`${t.loading} ${t.supportedLoggers.toLowerCase()}...`);
            getSupportedLoggers()
                .then((list) => {
                    list.supportedLoggers.push(t.notSupported)
                    setSupportedLoggers(list)
                    setSupportedDeviceChoice(list.supportedLoggers[0])
                })
                .catch((e) => {
                    if (e instanceof Error) setError(e.message)
                })
                .finally(() => setLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supportedLoggers])

    const uploadSupportedLoggerFile = async (formData: FormData, supDevice: string) => {
        setLoading(true);
        setLoadingMessage(`${t.uploading} ${t.loggerNoteFile.toLowerCase()}...`);
        await postLoggerNoteFile(clientId, formData, supDevice)
            .then(() => {
                afterUploadSuccess();
            })
            .catch((e) => {
                if (e instanceof Error) setError(e.message)
            })
            .finally(() => setLoading(false));
    };

    const uploadUnsupportedLoggerFile = async (formData: FormData) => {
        setLoading(true);
        setLoadingMessage(`${t.uploading} ${t.loggerNoteFile.toLowerCase()}...`);
        await postUnsupportedLoggerNoteFile(clientId, formData)
            .then(() => {
                afterUploadSuccess();
            })
            .catch((e) => {
                if (e instanceof Error) setError(e.message)
            })
            .finally(() => setLoading(false));
    }

    const handleFileSelect = (e: any) => {
        setLoggerNoteFile(e.target.files[0])
    }

    const handleSupportedDeviceSelect = (e: any) => {
        const { value } = e.target;

        if (value === t.notSupported) {
            setShowDeviceForm(true);
            setSupportedDeviceChoice('');
        } else {
            setShowDeviceForm(false);
            setSupportedDeviceChoice(value);
        }
    }

    const handleDeviceFormChange = (e: any) => {
        const { name, value } = e.target;
        const change = { [name]: value };

        setNewDevice((previous) =>
            new LoggerDeviceModel({ ...previous, ...change}));
    }

    const validateDeviceInput = (): boolean => {
        const err = { ...inputErrors };

        err.file = validateRequired(loggerNoteFile?.name);

        if (showDeviceForm) {
            err.deviceName = validateRequired(newDevice.deviceName);
            err.deviceCompany = validateRequired(newDevice.deviceCompany);
        }

        setInputErrors(err);
        return isInputErrorsEmpty(err);
    }

    const handleUpload = async () => {
        const formData = new FormData();
        let supDevice = null;

        if (validateDeviceInput()) {
            if (showDeviceForm) {
                formData.append("loggerNoteFile", loggerNoteFile!!, loggerNoteFile!!.name);
                formData.append("deviceName", newDevice.deviceName);
                formData.append("deviceCompany", newDevice.deviceCompany);
                await uploadUnsupportedLoggerFile(formData);
            } else {
                formData.append("loggerNoteFile", loggerNoteFile!!, loggerNoteFile!!.name);
                supDevice = supportedDeviceChoice;
                await uploadSupportedLoggerFile(formData, supDevice!!);
            }
        }
    }

    return (
        <>
            <SpinLoader
                containerClassName="content color-main-background"
                loading={loading}
                loadMessage={loadingMessage}/>
            <ErrorMessage
                error={error}
                containerClassName="content color-main-background"/>

            {!loading && !error && supportedLoggers && (
                <div className="content color-main-background fold-out">
                    <div className="content-inner-form">
                        <form
                            aria-label="logger note upload form"
                            name="logger note upload form"
                            onSubmit={(e: SyntheticEvent) => e.preventDefault()}>
                            <div className="input-row">
                                <h5>{t.loggerNoteNoLoggerFileText}</h5>
                            </div>
                            <div className="column">
                                <div className="row margin toColumn">
                                    <label
                                        className="input-label"
                                        htmlFor="logger note upload">
                                        Upload
                                    </label>
                                    <input
                                        className="input"
                                        aria-label="logger note file upload input"
                                        type="file"
                                        name="logger note upload"
                                        onChange={(e) => handleFileSelect(e)}/>
                                </div>
                                <p className="input-error fixed" role="alert">{inputErrors.file}</p>
                            </div>
                            <SelectFieldWithLabel
                                labelText="Supported Device"
                                selection={supportedLoggers.supportedLoggers}
                                initialValue={supportedLoggers.supportedLoggers[0]}
                                ariaLabel="logger supportedDevice"
                                name="supportedDevice"
                                onChange={(e) => handleSupportedDeviceSelect(e)}/>
                            {showDeviceForm && (
                                <div className="row margin toColumn fold-out">
                                    <h4 className="input-label stay-top">{t.addDevice}</h4>
                                    <div className="input select">
                                        <TextInputWithLabel
                                            labelText={t.deviceName}
                                            name="deviceName"
                                            ariaLabel="logger deviceName"
                                            initialValue={newDevice.deviceName}
                                            placeholder={t.deviceName}
                                            error={inputErrors.deviceName}
                                            onChange={handleDeviceFormChange}/>
                                        <TextInputWithLabel
                                            labelText={t.company}
                                            name="deviceCompany"
                                            ariaLabel="logger deviceCompany"
                                            initialValue={newDevice.deviceCompany}
                                            placeholder={`${t.enter} ${t.company.toLowerCase()}`}
                                            error={inputErrors.deviceCompany}
                                            onChange={handleDeviceFormChange}/>
                                    </div>
                                </div>
                            )}
                            <div className="row margin reverse">
                                <button className="filled" onClick={handleUpload}>Upload</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
}
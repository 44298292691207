import {Content, ContentForm, PageWithContent} from "../common/ContentStandards";
import requireTranslation from "../../lang/locales/config";
import {TextInputWithLabel} from "../common/FormCommons";
import {ChangeEvent, useState} from "react";
import {useStandardSetup} from "../../utils/customHooks";
import {isInputErrorsEmpty, validateEmail, validateUsername} from "../../utils/inputValidation";
import {IntegratedSpinLoader} from "../common/SpinLoader";
import ErrorMessage from "../common/ErrorMessage";
import {ForgotLoginValues} from "../../models/Login";
import {useLoginApi} from "../../API/useLoginApi";
import {Link} from "react-router-dom";




export default function ForgotLoginPage() {
    const t = requireTranslation();
    const initialInputErrors: ForgotLoginValues = {
        username: "",
        email: ""
    }
    const [
        loading, setLoading,
        error, setError,
        formValues, setFormValues
    ] = useStandardSetup<ForgotLoginValues>(initialInputErrors);
    const [ successMessage, setSuccessMessage ] = useState("");
    const [ inputErrors, setInputErrors ] = useState<ForgotLoginValues>(initialInputErrors);
    const { forgotLogin } = useLoginApi();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const change = {[name]: value};

        if (error) {
            setError("");
        }

        setFormValues((prevState) => {
            if (prevState) {
                return {...prevState, ...change};
            }
        });
    };

    const validateInput = (): boolean => {
        const err = initialInputErrors;

        if (formValues) {
            err.username = validateUsername(formValues.username);
            err.email = validateEmail(formValues.email);
        } else {
            err.username = t.errors.required;
            err.email = t.errors.required;
        }

        setInputErrors(err);

        return isInputErrorsEmpty<ForgotLoginValues>(err);
    };

    const submitInput = () => {
        if (error) {
            setError("");
        }

        if (validateInput() && formValues) {
            setLoading(true);
            forgotLogin(formValues)
                .then(() => setSuccessMessage(t.informationTexts.emailUsernameValidated))
                .catch((e) => {
                    if (e instanceof Error) {
                        setError(e.message);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    return (
        <PageWithContent>
            <>
                {successMessage &&
                    <Content
                        headline={t.emailSend + "!"}
                        centerHeadline={true}>
                        <p className="support-linebreak center-text">
                            {successMessage}
                        </p>
                        <Link className="content invisible center-page" to={"/login"}>
                            {t.login}
                        </Link>
                    </Content>
                }

                {!successMessage &&
                    <Content
                        headline={t.forgotLogin}
                        centerHeadline={true}>
                        <p className="support-linebreak center-text">
                            {t.informationTexts.forgotLoginPageInformation}
                        </p>
                        <ContentForm
                            ariaLabel="forgotLoginForm">
                            <TextInputWithLabel
                                labelText={t.username}
                                name="username"
                                ariaLabel="forgotLoginUsername"
                                initialValue={formValues!!.username}
                                placeholder={`${t.enter} ${t.username.toLowerCase()}`}
                                error={inputErrors.username}
                                onChange={handleChange}/>
                            <TextInputWithLabel
                                labelText={t.email}
                                name="email"
                                ariaLabel="forgotLoginEmail"
                                initialValue={formValues!!.email}
                                placeholder={`${t.enter} ${t.email.toLowerCase()}`}
                                error={inputErrors.email}
                                onChange={handleChange}/>
                            <div className="row reverse margin">
                                <button
                                    className="filled"
                                    onClick={submitInput}>
                                    {t.ok}
                                </button>
                            </div>
                        </ContentForm>

                        <IntegratedSpinLoader
                            loading={loading}
                            loadMessage={""} />

                        <ErrorMessage
                            error={error}
                            containerClassName={"content invisible"} />
                    </Content>
                }
            </>
        </PageWithContent>
    );
};
interface CaseStatusModelInit {
    installation: boolean;
    armatures: boolean;
    measurements: boolean;
    stepReadings: number;
    wattAndComments: boolean;
    totals: boolean;
    loggerNoteFile: boolean;
    loggerNotes: boolean;
}

export default class CaseStatusModel implements CaseStatusModelInit {
    installation: boolean;
    armatures: boolean;
    measurements: boolean;
    stepReadings: number;
    wattAndComments: boolean;
    totals: boolean;
    loggerNoteFile: boolean;
    loggerNotes: boolean;


    constructor(init: CaseStatusModelInit) {
        this.installation = init.installation;
        this.armatures = init.armatures;
        this.measurements = init.measurements;
        this.stepReadings = init.stepReadings;
        this.wattAndComments = init.wattAndComments;
        this.totals = init.totals;
        this.loggerNoteFile = init.loggerNoteFile;
        this.loggerNotes = init.loggerNotes;
    }


}
import {useAppDispatch, useAppSelector} from "../state/hooks";
import {selectAuth, setNoAuth} from "../state/authSlice";
import {ResponseInterface, statusMessage} from "./StatusError";
import {setNoUser} from "../state/userSlice";
import {requireCurrentLanguage} from "../lang/locales/config";

class BodyNotAllowed extends Error{
    constructor(message: string = "a body cannot be included in a GET request!") {
        super(message);
    }
}

export class NoAuth extends Error {}


export class ToManyLoginTries extends Error {
    timeLeft: number;
    constructor(description: string) {
        super();
        this.timeLeft = description as unknown as number;
    }
}


export class SuccessNoContent {
    data: null;
    constructor(data: any) {
        this.data = data;
    }
}

interface ResponseBody401 {
    status_code: number,
    error: string,
    description: string
}


/**
 * For simulation of network delay.
 *
 * @param ms Milliseconds the Response should be delayed.
 */
export function delay<T>(ms: number) {
    return function(x: T): Promise<T> {
        return new Promise((resolve) => setTimeout(() => resolve(x), ms));
    };
}


/**
 * Convert Json body (data) into multiple objects (class models).
 *
 * @param data The received body from a request. Should be an array containing the objects that fits the model
 * @param model The class model the body should be compatible with.
 */
function convertJsonArrayToModel<T>(data: any[], model: new (data: any, header?: Headers) => T,): T[] {
    if (data.length === 0) return [];

    return data.map((innerData) => convertJsonToModel(innerData, model));
}

/**
 * Convert Json body (data) into an object (class model).
 *
 * @param data The received body from a request.
 * @param model The class model the body should be compatible with.
 */
function convertJsonToModel<T>(data: any, model: new (data: any, header?: Headers) => T,): T {
    return new model(data);
}

export function useFetch() {
    const auth = useAppSelector(selectAuth);
    const dispatch = useAppDispatch();
    const apiUrl = process.env.REACT_APP_API_URL as string;

    const request = (method: string) => {
        return async <T>(
            returnModel: new (data: any, header?: Headers) => T,
            url: string,
            body?: any | FormData,
            replacer?: ((this: any, key: string, value: any) => any),
            blobAndHeader: boolean = false,
            resetAuth: boolean = false,
            acceptLanguageHeader: boolean | string = false,
        ) => {
            if (resetAuth) {
                dispatch(setNoAuth);
                dispatch(setNoUser);
            }

            const header = authHeader(url);

            if (acceptLanguageHeader === true) {
                header["Accept-Language"] = [requireCurrentLanguage(),];
            } else if (typeof acceptLanguageHeader === "string") {
                header["Accept-Language"] = [acceptLanguageHeader,];
            }

            if (body) {
                if (method === "GET") throw new BodyNotAllowed();

                if (!(body instanceof FormData)) {
                    header['Content-Type'] = "application/json";
                    body = JSON.stringify(body, replacer);
                }
            }

            const requestOptions = {
                method: method,
                headers: header,
                mode: "cors" as RequestMode,
                body: body
            }
            const response = await fetch(url, requestOptions);
            return await handleResponse(response, returnModel, blobAndHeader);
        };
    };

    const authHeader = (url: string): any => {
        const token = auth.token;
        const isLoggedIn = !!token;
        const isApiUrl = url.startsWith(apiUrl)

        if (isLoggedIn && isApiUrl) {
            return { Authorization: `JWT ${token}`};
        }
        return {}
    }

    const handleResponse = async <T>(
        response: Response,
        returnModel: new (data: any, header?: Headers) => T,
        blobAndHeader: boolean = false,
    ): Promise<T[] | T | SuccessNoContent> => {
        if (response.ok) {
            if (![204].includes(response.status)) {
                if (blobAndHeader) {
                    return await handleBlobWithHeader(response, returnModel);
                } else {
                    return await handleJsonResponse(response, returnModel);
                }

            } else {
                return convertJsonToModel(null, SuccessNoContent);
            }
        }

        const text = await response.text();

        if (response.status === 401 && !auth.token) {
            let data: ResponseBody401 | undefined

            try {
                data = (text && JSON.parse(text)) as ResponseBody401;
            } catch (e) {
                data = undefined
            }

            if (data !== undefined && data.error === "errorLoginTry") {
                throw new ToManyLoginTries(data.description);
            }
        }

        if ([401, 403].includes(response.status) && !!auth.token) {
            dispatch(setNoUser());
            dispatch(setNoAuth());
            throw new NoAuth();
        }

        if (404 === response.status) {
            throw new Error("Url not Found!");
        }

        // this is only needed during dev, when production. proxy server and this will be one.
        if (response.status === 500 && text.startsWith("Proxy error")) {
            const errorMessage = statusMessage({
                regarding: "",
                description: "serverDown",
                standard: true,
            });
            throw new Error(errorMessage);
        }

        throw handleErrorResponse(text);
    }

    const handleJsonResponse = async <T>(
        response: Response,
        returnModel: new (data: any, header?: Headers) => T,
    ): Promise<T[] | T> => {
        const text = await response.text();
        let data: [] | object | string;

        try {
            data = text && JSON.parse(text);
        } catch (e) {
            data = text;
        }

        return (data instanceof Array) ?
            convertJsonArrayToModel(data, returnModel) :
            convertJsonToModel(data, returnModel);
    };

    const handleErrorResponse = (text: string): Error => {
        const data = text && JSON.parse(text);
        const errorMessage_1 = statusMessage(data as ResponseInterface);
        return new Error(errorMessage_1);
    };

    /**
     * Used for file transfer from server. Here the returnModel is assumed
     * to the initiated with blob and header 'new (data: Blob, header: Header)'.
     *
     * @param response The Response object.
     * @param returnModel The return model object to instantiate with blob and header.
     */
    const handleBlobWithHeader = async <T>(
        response: Response,
        returnModel: new (data: any, header?: Headers) => T,
    ): Promise<T[] | T> => {
        return response.blob()
            .then((blob) => {
                return new returnModel(blob, response.headers);
            });
    };

    return {
        get: request("GET"),
        post: request("POST"),
        put: request("PUT"),
        delete: request("DELETE")
    };
}
import requireTranslation from "../../lang/locales/config";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useStandardSetup} from "../../utils/customHooks";
import {SessionModel} from "../../models/registration/session";
import {useRegistrationApi} from "../../API/useRegistrationApi";
import {Content, PageWithContent} from "../common/ContentStandards";
import {IntegratedSpinLoader} from "../common/SpinLoader";
import {SubscriptionEnum} from "../utils/Enums";
import {BasicSubscription, ExtendedSubscription} from "../utils/SelectSubscription";
import {setNoUser, setUser} from "../../state/userSlice";
import {useUserApi} from "../../API/useUserApi";
import {useAppDispatch} from "../../state/hooks";
import {setNoAuth} from "../../state/authSlice";

interface CheckoutReturnPageParams {
    [index: string]: string | undefined;
    session_id: string;
    subscription_choice: string;
    existing: string | undefined;
}

export default function CheckoutReturnPage() {
    const t = requireTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const params = useParams<CheckoutReturnPageParams>();
    const [ userUpdated, setUserUpdated ] = useState(false);
    const [
        loading, setLoading,
        error, setError,
        session, setSession
    ] = useStandardSetup<SessionModel>()
    const { getSessionStatus } = useRegistrationApi();
    const { getUser } = useUserApi();

    useEffect(() => {
        if (!session) {
            if (params.session_id) {
                setLoading(true);
                getSessionStatus(params.session_id)
                    .then((session) => {
                        setSession(session);
                        // if we are serving an existing user - update user...
                        if (params.existing) {
                            getUser()
                                .then((user) => {
                                    dispatch(setUser({...user}));
                                    setUserUpdated(true);
                                })
                                .catch(() => {
                                    // if user could not be updated, we log out and ask to log again.
                                    setUserUpdated(false);
                                    dispatch(setNoAuth());
                                    dispatch(setNoUser());
                                })
                        }
                    })
                    .catch((e) => {
                        if (e instanceof Error) {
                            setError(e.message);
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    })
            } else {
                // console.log("no sessionId...")
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session]);

    return (
        <PageWithContent>
            <Content>
                <IntegratedSpinLoader
                    loading={loading}
                    loadMessage={t.loadingMessages.checkoutSessionStatus} />

                <>
                    {session && session.status === 'open' &&
                        navigate(`/checkout/${params.subscription_choice}/${session.customerEmail}`)
                    }
                    {session && session.status === 'complete' &&
                        <div>
                            <h2 className="content-headline center-page">
                                {t.subscriptionSuccess.title}
                            </h2>
                            <h3 className="content-headline center-page">
                                {t.subscriptionSuccess.subtitle}
                            </h3>
                            <div className="content-inner-list-column">
                                <div className="row toColumn center-page">
                                    {params.subscription_choice === SubscriptionEnum.Basic.toString() &&
                                        <BasicSubscription isSelected={true} />
                                    }
                                    {params.subscription_choice === SubscriptionEnum.Extended.toString() &&
                                        <ExtendedSubscription isSelected={true} />
                                    }
                                </div>
                            </div>
                            <h3 className="support-linebreak center">
                                {params.existing === 'true' ?
                                    t.subscriptionSuccess.bodyExisting :
                                    t.subscriptionSuccess.body}
                            </h3>
                            {!userUpdated && params.existing === 'true' &&
                                <p className="message">{t.subscriptionSuccess.failedToReloadUser}</p>
                            }
                        </div>
                    }
                    {!session && error &&
                        <div className="row margin">
                            <p className="error message">{t.errors.checkoutSessionStatus}</p>
                        </div>
                    }
                </>
            </Content>
        </PageWithContent>
    )
}
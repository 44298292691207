import requireTranslation from "../../lang/locales/config";
import {useStandardSetup} from "../../utils/customHooks";
import {OverlaySpinLoader} from "../common/SpinLoader";
import {SyntheticEvent, useState} from "react";
import {NumberInputWithLabel} from "../common/FormCommons";
import {MAX_DUPLICATES, MAX_DUPLICATES_LENGTH, MIN_DUPLICATES} from "../../constants/requirement";
import {validateMaxValue} from "../../utils/inputValidation";
import {useClientApi} from "../../API/useClientCaseApi";
import {useNavigate} from "react-router-dom";


interface DuplicationFormProps {
    clientId: string;
}


export default function DuplicationForm({ clientId }: DuplicationFormProps) {
    const t = requireTranslation();
    const [
        loading, setLoading,
        inputError, setInputError,
        duplicates, setDuplicates
    ] = useStandardSetup<number>(0);
    const [ error, setError ] = useState("");
    const { duplicateClient } = useClientApi();
    const navigate = useNavigate();

    const handleChange = (event: any) => {
        const { value } = event.target;

        setDuplicates(value);
        setInputError(validateMaxValue(value, MAX_DUPLICATES));
    };

    const handleDuplicate = () => {
        if (!inputError && duplicates && duplicates >= MIN_DUPLICATES) {
            setLoading(true);
            duplicateClient(clientId, duplicates)
                .then(() => {
                    navigate("/cases", {state: {
                        statusMessage: t.messages.successDuplicate
                    }});
                })
                .catch((e) => {
                    if (e instanceof Error) setError(e.message);
                })
                .finally(() => setLoading(false));
        }
    }

    return (
        <>
            <OverlaySpinLoader
                loading={loading}
                loadMessage={t.loadingMessages.duplicating} />

            <div className="content fold-out color-main-background">
                <h3 className="content-headline">{t.duplicate}</h3>
                <div className="content-inner-form">
                    <form
                        aria-label="formDuplication"
                        name="formDuplication"
                        onSubmit={(e: SyntheticEvent) => e.preventDefault()}>
                        <NumberInputWithLabel
                            labelText={`${t.duplicate} ${t.case}`}
                            name="duplicates"
                            ariaLabel="caseDuplicates"
                            placeholder={`${t.enter} ${t.number} ${t.duplicateRange}`}
                            initialValue={duplicates}
                            maxLength={MAX_DUPLICATES_LENGTH}
                            error={inputError}
                            onChange={handleChange}
                        />
                        <div className="row margin reverse">
                            <button
                                className="filled"
                                onClick={handleDuplicate}>
                                {t.duplicate}
                            </button>
                        </div>
                        {error && (
                            <div className="row margin">
                                <p className="error message">{error}</p>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </>
    )
}
import {OrgUserModel} from "../../models/user/user";
import {useState} from "react";
import {Content} from "../common/ContentStandards";
import AdminUserEditForm from "./AdminUserEditForm";
import AdminUserEmployeesList from "./AdminUserEmployeesList";
import {InformationRow} from "../common/TextCommons";
import {localeTimeFromTimestamp, localeTimeFromUtcTimestamp} from "../../utils/timestamp";

interface UserDetailsAndEditInit {
    initialUser: OrgUserModel
}

export default function UserDetailsAndEdit({ initialUser }: UserDetailsAndEditInit) {
    const [ shownContent, setShownContent ] = useState(<AdminUserEditForm initialUser={initialUser} />);
    const [ highlight, setHighlight ] = useState(0);

    return (
        <Content
            headline={`${initialUser.name} (${initialUser.username}) - ${initialUser.firmName}`}
            centerHeadline={true}
            invisible={true}>
            <div className="content-box">
                <InformationRow
                    title={"Created (Locale)"}
                    text={localeTimeFromUtcTimestamp(initialUser.created)} />
                <InformationRow
                    title={"Created (UTC)"}
                    text={localeTimeFromTimestamp(initialUser.created)} />
                <InformationRow
                    title={"Last Login (Locale)"}
                    text={localeTimeFromUtcTimestamp(initialUser.lastLogin)} />
                <InformationRow
                    title={"Last Login (UTC)"}
                    text={localeTimeFromTimestamp(initialUser.lastLogin)} />
            </div>

            <div className="tabs-container no-box-shadow">
                <div className="tabs-bar">
                    <button
                        className={highlight === 0 ?
                            "tabs-bar-button selected" :
                            "tabs-bar-button"}
                        onClick={() => {
                            setHighlight(0);
                            setShownContent(<AdminUserEditForm initialUser={initialUser} />);
                        }}>
                        {"Edit User"}
                    </button>
                    <button
                        className={highlight === 1 ?
                            "tabs-bar-button selected" :
                            "tabs-bar-button"}
                        onClick={() => {
                            setHighlight(1);
                            setShownContent(<AdminUserEmployeesList userId={initialUser.id} />);
                        }}>
                        {"Employees"}
                    </button>
                    {/*<button*/}
                    {/*    className={highlight === 2 ?*/}
                    {/*        "tabs-bar-button selected" :*/}
                    {/*        "tabs-bar-button"}*/}
                    {/*    onClick={() => {*/}
                    {/*        setHighlight(2)*/}
                    {/*    }}>*/}
                    {/*    {"Cases"}*/}
                    {/*</button>*/}
                </div>
                {shownContent}
            </div>
        </Content>
    );
}
import React, {ChangeEvent, useEffect, useState} from "react";
import {Content, ContentForm, PageWithContent} from "../common/ContentStandards";
import requireTranslation from "../../lang/locales/config";
import {PhoneInput, TextInputWithLabel} from "../common/FormCommons";
import {useFormSetup, usePhoneNumberInput, useStandardHandleChange} from "../../utils/customHooks";
import {RegisterUserModel, RegistrationWithVatModel} from "../../models/user/user";
import {
    isInputErrorsEmpty,
    validateEmail,
    validateName,
    validatePassword, validatePhoneNumber,
    validateUsername
} from "../../utils/inputValidation";
import {useNavigate} from "react-router-dom";
import {SelectSubscription, useSelectSubscription} from "../utils/SelectSubscription";
import {useRegistrationApi} from "../../API/useRegistrationApi";
import {OverlaySpinLoader} from "../common/SpinLoader";
import {TermsAndConditions} from "../../models/registration/termsAndConditions";
import CheckVAT from "./CheckVAT";
import {CheckVATResult} from "../../models/registration/checkVAT";
import {
    InformationDialog,
    useDialog,
    useVerificationDialog,
    VerificationDialog
} from "../common/ConfirmationDialog";


interface inputErrors {
    name: string;
    username: string;
    password: string;
    confirmPassword: string;
    firmName: string;
    firmEmail: string;
    privateEmail: string;
    phone: string;
    terms: string;
}


function RegisterPage() {
    const t = requireTranslation();
    const initialInputErrors: inputErrors = {
        name: "",
        username: "",
        password: "",
        confirmPassword: "",
        firmName: "",
        firmEmail: "",
        privateEmail: "",
        phone: "",
        terms: ""
    };
    const [
        awaiting, setAwaiting,
        inputErrors, setInputErrors,
        errorMessage, setErrorMessage,
        registerUser, setRegisterUser
    ] = useFormSetup(new RegisterUserModel(), initialInputErrors);
    const [ handleChange ] = useStandardHandleChange(RegisterUserModel, setRegisterUser);
    const [ isPasswordConfirmed, setIsPasswordConfirmed ] = useState(false);
    const [ confirmPassword, setConfirmPassword ] = useState("");
    const [ checkVATResult, setCheckVATResult ] = useState<CheckVATResult>();
    const [ confirmsVAT, setConfirmsVAT ] = useState(false);
    const [ selectedSubscription, setSelectedSubscription, handleSubscriptionChange ]
        = useSelectSubscription(RegisterUserModel, setRegisterUser);
    const [ termAndConditions, setTermAndConditions ] = useState(false);
    const [ termsModel, setTermsModel ] = useState<TermsAndConditions>();
    const [ verificationCode, setVerificationCode ] = useState("");
    const navigate = useNavigate();
    const {
        handlePhoneNumberChange,
        inputFieldValue,
        setCountryCode
    } = usePhoneNumberInput(RegisterUserModel, setRegisterUser);
    const {
        isOpen: isForgotCheckVATOpen,
        toggle: toggleForgotCheckVAT,
        setDialogMessage: setForgotCheckVATMessage,
        message: forgotCheckVATMessage
    } = useDialog();
    const {
        isOpen,
        toggle,
        dialogError,
        dialogAwaitingResponse,
        dialogAwaitingSendEmail
    } = useVerificationDialog();
    const { postRegister, getTermsAndConditions, postVerifyEmail } = useRegistrationApi();

    useEffect(() => {
        if (!termsModel) {
            setAwaiting(true);
            getTermsAndConditions()
                .then(setTermsModel)
                .catch((e) => {
                    if (e instanceof Error) {
                        setErrorMessage(e.message);
                    }
                })
                .finally(() => setAwaiting(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [termsModel]);

    useEffect(() => {
        const change = {
            firmName: confirmsVAT && checkVATResult ? checkVATResult.name!! : ""
        }
        setRegisterUser((prevState) => {
            return new RegisterUserModel({ ...prevState, ...change })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmsVAT]);

    const handleConfirmPassword = (event: ChangeEvent<HTMLInputElement>) => {
        setIsPasswordConfirmed(event.target.value === registerUser.password);
        setConfirmPassword(event.target.value);
    };

    const handleChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
        setIsPasswordConfirmed(event.target.value === confirmPassword);
        handleChange(event);
    };

    const handleChangeTermsAndConditions = (checked: boolean) => {
        setTermAndConditions(checked);
    };

    const handleRegister = () => {
        dialogAwaitingResponse(true);
        dialogError("");
        // console.log("Register user...", registerUser);
        const registration = new RegistrationWithVatModel(verificationCode, registerUser, checkVATResult);
        postRegister(registration)
            .then(() => {
                navigate(`/checkout/${registration.subscription}/${registerUser.firmEmail}`)
            })
            .catch((e) => {
                if (e instanceof Error) {
                    if (e.message === t.responseMessage.errorVerifyCode) {
                        dialogError(e.message);
                    } else if (e.message === t.responseMessage.errorNewCode) {
                        handleSendVerificationEmail();
                    } else {
                        handleRegisterError(e.message);
                        toggle();
                    }
                }
            })
            .finally(() => dialogAwaitingResponse(false));
    };

    const handleIsVatValidAndChecked = () => {
        if (checkVATResult && checkVATResult.valid && !confirmsVAT) {
            setForgotCheckVATMessage(t.informationTexts.vatIsValidButNotConfirmed)
            toggleForgotCheckVAT();
        }
        else if (checkVATResult && checkVATResult.valid && confirmsVAT) {
            handleSendVerificationEmail();
        }
        else {
            setForgotCheckVATMessage(t.informationTexts.vatForgotten)
            toggleForgotCheckVAT();
        }
    };

    const handleSendVerificationEmail = () => {
        if (validateInput()) {
            toggle();
            dialogAwaitingSendEmail(true);
            dialogError("");
            setErrorMessage("");
            postVerifyEmail(registerUser.firmEmail)
                .then()
                .catch((e) => {
                    if (e instanceof Error) {
                        if (e.message !== t.responseMessage.errorVerifyEmail) {
                            handleRegisterError(e.message);
                            toggle();
                        }
                    }
                })
                .finally(() => dialogAwaitingSendEmail(false));
        }
    };

    const handleRegisterError = (errorMessage: string) => {
        const err = initialInputErrors;

        switch (errorMessage) {
            case t.responseMessage.errorUnique: {
                err.username = errorMessage;
                break;
            }
            case t.responseMessage.errorEmailExist: {
                if (registerUser.firmEmail === registerUser.privateEmail) {
                    err.firmEmail = errorMessage;
                    err.privateEmail = errorMessage;
                } else {
                    setErrorMessage(errorMessage);
                }
                break;
            }
            case t.responseMessage.errorPhoneExist: {
                err.phone = errorMessage;
                break;
            }
            default:
                setErrorMessage(errorMessage);
                break;
        }
        setInputErrors(err)
    }

    const validateInput = () => {
        const err = initialInputErrors;

        err.name = validateName(registerUser.name);
        err.username = validateUsername(registerUser.username);
        err.password = validatePassword(registerUser.password);
        err.confirmPassword = isPasswordConfirmed ? "" : t.errors.passwordConfirm;
        err.firmName = validateName(registerUser.firmName);
        err.firmEmail = validateEmail(registerUser.firmEmail);
        err.privateEmail = validateEmail(registerUser.privateEmail);
        err.phone = validatePhoneNumber(registerUser.phone);

        if (termAndConditions)
            err.terms = ""
        else
            err.terms = t.errors.termsAndConditions;

        setInputErrors(err);

        return isInputErrorsEmpty(err);
    };


    return (
        <PageWithContent smaller={true}>

            <>
                {isOpen &&
                    <VerificationDialog
                        setCode={setVerificationCode}
                        toggle={toggle}
                        onVerify={handleRegister}
                        error={dialogError}
                        awaitingResponse={dialogAwaitingResponse}
                        awaitingEmailSend={dialogAwaitingSendEmail} />
                }
                {isForgotCheckVATOpen &&
                    <InformationDialog
                        toggle={toggleForgotCheckVAT}
                        message={forgotCheckVATMessage} />
                }
            </>

            <CheckVAT
                checkVATResult={checkVATResult}
                setCheckVATResult={setCheckVATResult}
                setConfirmsVAT={setConfirmsVAT} />

            <OverlaySpinLoader
                loading={awaiting}
                loadMessage={`${t.awaiting}...`} />

            <Content
                headline={t.register}
                infoIconText={t.informationTexts.registrationInformation}>
                <ContentForm
                    ariaLabel="registerUserForm"
                    name="registerUserForm">
                    <TextInputWithLabel
                        labelText={t.name}
                        ariaLabel="RegisterUserFormName"
                        name="name"
                        placeholder={`${t.enter} ${t.name.toLowerCase()}`}
                        initialValue={registerUser.name}
                        onChange={handleChange}
                        error={inputErrors.name}
                    />
                    <TextInputWithLabel
                        labelText={t.username}
                        ariaLabel="RegisterUserFormUsername"
                        name="username"
                        placeholder={`${t.enter} ${t.username.toLowerCase()}`}
                        initialValue={registerUser.username}
                        onChange={handleChange}
                        error={inputErrors.username}
                    />
                    <TextInputWithLabel
                        labelText={t.password}
                        ariaLabel="RegisterUserFormPassword"
                        name="password"
                        placeholder={`${t.enter} ${t.password.toLowerCase()}`}
                        initialValue={registerUser.password}
                        onChange={handleChangePassword}
                        isPassword={true}
                        error={inputErrors.password}
                    />
                    <TextInputWithLabel
                        labelText={`${t.confirm} ${t.password.toLowerCase()}`}
                        ariaLabel="RegisterUserFormConfirmPassword"
                        name="confirmPassword"
                        placeholder={`${t.reenter} ${t.password.toLowerCase()}`}
                        initialValue={confirmPassword}
                        onChange={handleConfirmPassword}
                        isPassword={true}
                        rightIcon={isPasswordConfirmed && confirmPassword ? "done.svg" : "error.svg"}
                        rightIconColorControl={isPasswordConfirmed}
                        error={inputErrors.confirmPassword}
                    />
                    <TextInputWithLabel
                        labelText={t.firmName}
                        ariaLabel="RegisterUserFormFirmName"
                        name="firmName"
                        placeholder={`${t.enter} ${t.firmName.toLowerCase()}`}
                        initialValue={registerUser.firmName}
                        onChange={handleChange}
                        error={inputErrors.firmName}
                    />
                    <TextInputWithLabel
                        labelText={t.firmEmail}
                        ariaLabel="RegisterUserFormFirmEmail"
                        name="firmEmail"
                        placeholder={`${t.enter} ${t.firmEmail.toLowerCase()}`}
                        initialValue={registerUser.firmEmail}
                        onChange={handleChange}
                        error={inputErrors.firmEmail}
                    />
                    <TextInputWithLabel
                        labelText={t.privateEmail}
                        ariaLabel="RegisterUserFormPrivateEmail"
                        name="privateEmail"
                        placeholder={`${t.enter} ${t.privateEmail.toLowerCase()}`}
                        initialValue={registerUser.privateEmail}
                        onChange={handleChange}
                        error={inputErrors.privateEmail}
                    />
                    <PhoneInput
                        ariaLabel="RegisterUserFormPhone"
                        initialValue={inputFieldValue}
                        setCountryCode={setCountryCode}
                        error={inputErrors.phone}
                        onChange={handlePhoneNumberChange}
                    />
                    <SelectSubscription
                        selectedSubscription={selectedSubscription}
                        setSelectedSubscription={setSelectedSubscription}
                        handleChange={handleSubscriptionChange}/>
                    <div className="row margin">
                        <p className="title-line italic">{t.informationTexts.siteFrontPart4PreNote}</p>
                    </div>

                    <div className="column">
                        <div className="row margin">
                            <p className="multiline">
                                {t.readAndAccept}
                                <a
                                    href={termsModel?.url}
                                    rel="noreferrer"
                                    target="_blank">
                                    {t.termsAndConditions}
                                </a>
                            </p>
                            <label className="input-checkbox-container">
                                <input
                                    name="terms"
                                    type="checkbox"
                                    disabled={!termsModel}
                                    onClick={(event) => handleChangeTermsAndConditions(
                                        event.currentTarget.checked
                                    )}/>
                                <span className="input-checkmark"></span>
                            </label>
                        </div>
                        <p className="input-error fixed" role="alert">{inputErrors.terms}</p>
                    </div>
                    <div className="row reverse margin">
                        <button
                            className="filled"
                            onClick={handleIsVatValidAndChecked}>{t.register}</button>
                    </div>
                    <>
                        {errorMessage && (
                            <div className="row margin">
                                <p className="error message">{errorMessage}</p>
                            </div>
                        )}
                    </>
                </ContentForm>
            </Content>
        </PageWithContent>
    );
}

export default RegisterPage;
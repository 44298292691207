import {useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import requireTranslation from "../../lang/locales/config";
import CaseStatusModel from "../../models/case/caseStatus";
import {useCaseStatusApi} from "../../API/useClientCaseApi";
import CaseMenu from "./CaseMenu";
import {OverlaySpinLoader} from "../common/SpinLoader";

interface stateValueObject {
    clientId: string;
    clientName: string;
    statusMessage?: string;
}

export default function ClientCaseDetailsPage() {
    const t = requireTranslation();
    const { clientId, clientName, statusMessage }: stateValueObject = useLocation().state;
    const [ caseName, setCaseName ] = useState(clientName); // if the client name is modified.
    const [ caseStatus, setCaseStatus ] = useState<CaseStatusModel>();
    const [ initialLoad, setInitialLoad ] = useState(true);
    const [ refreshStatus, setRefreshStatus ] = useState(true);
    const [ toastMessage, setToastMessage ] = useState(statusMessage);
    const [ shownContent, setShownContent ] = useState<JSX.Element>();
    const { getCaseStatus } = useCaseStatusApi();

    useEffect(() => {
        setTimeout(() => { setToastMessage(undefined); }, 3000)
    }, [toastMessage]);

    useEffect(() => {
        // console.log("Shown content updated!");
    }, [shownContent])

    useEffect(() => {
        if (refreshStatus) {
            // console.log("Fecthing case status!")
            getCaseStatus(clientId)
                .then((statusData) => {
                    // console.log(statusData);
                    setCaseStatus(statusData);
                    if (initialLoad) {
                        setInitialLoad(false);
                    }
                    setRefreshStatus(false);
                })
            // TODO: Error handling?
        }
    }, [refreshStatus]);

    const handleChangeShownContent = (
        formElement: JSX.Element,
        statusMessage?: string,
        newClientName?: string,
        shouldRefreshStatus?: boolean
    ) => {
        setShownContent(formElement);

        if (statusMessage) setToastMessage(statusMessage);
        if (newClientName) setCaseName(newClientName);
        if (shouldRefreshStatus) handleRefreshStatus();
    };

    const handleMenuButtonClick = (content: JSX.Element) => {
        setShownContent(content);
    };

    const handleRefreshStatus = () => {
        setRefreshStatus(true);
    }

    return (
        <>
            <div className="page-menu-with-content-container">
                <OverlaySpinLoader
                    loading={initialLoad}
                    loadMessage={t.loadingMessages.caseStatus}/>

                {toastMessage && (
                    <div className="snackbar">{toastMessage}</div>)}
                {!initialLoad &&
                    <>
                        <CaseMenu
                            currentCaseStatus={caseStatus!!}
                            clientId={clientId}
                            handleMenuButtonClick={handleMenuButtonClick}
                            handleChangeShownContent={handleChangeShownContent}/>

                        <div className="content-container">
                            <h2 className="content-container-headline">{`${t.case}: ${caseName}`}</h2>
                            {shownContent}
                        </div>
                    </>}
            </div>
        </>
    );
}
import {NoAuth, SuccessNoContent, ToManyLoginTries, useFetch} from "./useFetch";
import requireTranslation from "../lang/locales/config";
import {ForgotLoginValues, Login} from "../models/Login";
import {Token} from "../models/Token";
import {useAppDispatch} from "../state/hooks";
import {setAuth, setNoAuth} from "../state/authSlice";
import {setNoUser} from "../state/userSlice";

export function useLoginApi() {
    const t = requireTranslation();
    const baseUrl = process.env.REACT_APP_API_URL as string;
    const url = `${baseUrl}/auth`;
    const forgotLoginUrl = `${baseUrl}/forgot-login`;
    const requester = useFetch();
    const dispatch = useAppDispatch();

    const login = async (login: Login) => {
        return requester.post(Token, url, login)
            .then((token) => {
                dispatch(setAuth({
                    token: (token as Token).access_token
                }));
            })
            .catch((e) => {
                if (e instanceof ToManyLoginTries) {
                    throw e
                }
                if (e instanceof Error) {
                    throw new Error(e.message);
                }
                if (e instanceof NoAuth) {
                    throw new Error(t.responseMessage.errorLogin);
                }
            });
    };

    const logout = () => {
        dispatch(setNoAuth());
        dispatch(setNoUser());
    };

    const forgotLogin = (forgotLoginValues: ForgotLoginValues) => {
        return requester.post(
            SuccessNoContent,
            forgotLoginUrl,
            forgotLoginValues,
            undefined,
            false,
            false,
            true

        ) as Promise<SuccessNoContent>;
    };

    return {
        login,
        logout,
        forgotLogin,
    };
}
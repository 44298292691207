import {InstallationModel} from "../../models/case/installation";
import {OverlaySpinLoader} from "../common/SpinLoader";
import requireTranslation from "../../lang/locales/config";
import {useInstallationApi} from "../../API/useClientCaseApi";
import {useFormSetup} from "../../utils/customHooks";
import {SyntheticEvent} from "react";
import {TextInputWithLabel} from "../common/FormCommons";
import InstallationDetails from "./InstallationDetails";
import {isInputErrorsEmpty, validateRequired} from "../../utils/inputValidation";

interface InstallationFormProps {
    initialInstallation?: InstallationModel;
    onSave: (formElement: JSX.Element,
             statusMessage?: string,
             newClientName?: string,
             shouldRefreshStatus?: boolean) => void;
    clientId?: string;
    haveBackButton?: boolean;
}

interface InputErrorsInter {
    installation: string;
    installationId: string;
    placement: string;
    control: string;
    preheatingSurface: string;
    filterType: string;
}

export default function InstallationForm(
    { initialInstallation, onSave, clientId, haveBackButton = false }: InstallationFormProps
) {
    if (!initialInstallation && !clientId) {
        throw Error("If initialMeasurement is undefined, then clientId must be defined!")
    }

    let newInstallation = null;
    if (!initialInstallation) {
        newInstallation = new InstallationModel();
        newInstallation.clientId = clientId!!;
    }

    const t = requireTranslation();
    const { putInstallation, postInstallation } = useInstallationApi();
    const initialInputErrors: InputErrorsInter = {
        "installation": "",
        "installationId": "",
        "placement": "",
        "control": "",
        "preheatingSurface": "",
        "filterType": ""
    };
    const [
        awaiting, setAwaiting,
        inputErrors, setInputErrors,
        error, setError,
        installation, setInstallation,
    ] = useFormSetup<InstallationModel, InputErrorsInter>(
        initialInstallation ? initialInstallation : newInstallation!!,
        initialInputErrors);

    /**
     * Handles when the back button (if any) is clicked.
     */
    const handleBackClick = () => {
        onSave(
            <InstallationDetails clientId={installation.clientId!!} onEdit={onSave} />
        )
    }

    /**
     * Handles a change in an input field.
     * Updates the InstallationModel to match the change.
     */
    const handleChange = (event: any) => {
        const { name, value } = event.target;
        const change = { [name]: value };

        setInstallation((installation) =>
            new InstallationModel({ ...installation, ...change }));
    };

    /**
     * Handles when the edit button is clicked.
     * First validates input, if valid, a PUT request is sent to the server.
     */
    const handleSave = async () => {
        if (validateInput()) {
            if (initialInstallation) {
                setAwaiting(true);
                await putInstallation(installation)
                    .then(() => {
                        onSave(
                            <InstallationDetails clientId={installation.clientId!!} onEdit={onSave}/>,
                            t.messages.successEdit,
                        );
                    })
                    .catch((e) => {
                        if (e instanceof Error) setError(e.message);
                    })
                    .finally(() => setAwaiting(false));
            } else {
                setAwaiting(true);
                await postInstallation(installation)
                    .then(() => {
                        onSave(
                            <InstallationDetails
                                clientId={installation.clientId!!}
                                onEdit={onSave}/>,
                            t.messages.successAdd,
                            undefined,
                            true
                        );
                    })
                    .catch((e) => {
                        if (e instanceof Error) setError(e.message);
                    })
                    .finally(() => setAwaiting(false));
            }
        }
    };

    /**
     * Validates the input fields. Sets error text if any is invalid.
     * Returns True if all tests passed.
     */
    const validateInput = () => {
        const err = { ...inputErrors };

        err.installation = validateRequired(installation.installation);
        err.installationId = validateRequired(installation.installationId);
        err.placement = validateRequired(installation.placement);
        err.control = validateRequired(installation.control);
        err.preheatingSurface = validateRequired(installation.preheatingSurface);
        err.filterType = validateRequired(installation.filterType);

        setInputErrors(err);
        return isInputErrorsEmpty<InputErrorsInter>(err);
    };

    return (
        <>
            <OverlaySpinLoader
                loading={awaiting}
                loadMessage={`${t.awaiting}...`}/>

            <div className="content fold-out color-main-background">
                <div className="content-inner-form">
                    <div className="row">
                        {haveBackButton &&
                            <img
                                className="icon-button"
                                src={"back_arrow.svg"}
                                alt={t.back}
                                onClick={handleBackClick}/>
                        }
                        {initialInstallation && (
                            <h3 className="form-header">{t.edit}</h3>
                        )}
                        {!initialInstallation && (
                            <h3 className="form-header">{t.add} {t.installationInformation}</h3>
                        )}
                    </div>
                    <form
                        aria-label="installation form"
                        name="installation form"
                        onSubmit={(e: SyntheticEvent) => e.preventDefault()}>
                        <TextInputWithLabel
                            labelText={t.installation}
                            name="installation"
                            ariaLabel="installation installation"
                            placeholder={`${t.enter} ${t.installation.toLowerCase()}`}
                            initialValue={installation.installation}
                            error={inputErrors.installation}
                            onChange={handleChange}/>
                        <TextInputWithLabel
                            labelText={t.installationId}
                            name="installationId"
                            ariaLabel="installation installationId"
                            placeholder={`${t.enter} ${t.installationId.toLowerCase()}`}
                            initialValue={installation.installationId}
                            error={inputErrors.installationId}
                            onChange={handleChange}/>
                        <TextInputWithLabel
                            labelText={t.placement}
                            name="placement"
                            ariaLabel="installation placement"
                            autocompleteList={t.placementRoomsList}
                            placeholder={`${t.enter} ${t.placement.toLowerCase()}`}
                            initialValue={installation.placement}
                            error={inputErrors.placement}
                            onChange={handleChange}/>
                        <TextInputWithLabel
                            labelText={t.control}
                            name="control"
                            ariaLabel="installation control"
                            placeholder={`${t.enter} ${t.control.toLowerCase()}`}
                            initialValue={installation.control}
                            error={inputErrors.control}
                            onChange={handleChange}/>
                        <TextInputWithLabel
                            labelText={t.preheatingSurface}
                            name="preheatingSurface"
                            ariaLabel="installation preheatingSurface"
                            placeholder={`${t.enter} ${t.preheatingSurface.toLowerCase()}`}
                            initialValue={installation.preheatingSurface}
                            error={inputErrors.preheatingSurface}
                            onChange={handleChange}/>
                        <TextInputWithLabel
                            labelText={t.filterType}
                            name="filterType"
                            ariaLabel="installation filterType"
                            placeholder={`${t.enter} ${t.filterType.toLowerCase()}`}
                            initialValue={installation.filterType}
                            error={inputErrors.filterType}
                            onChange={handleChange}/>
                        <div className="row margin reverse">
                            <button
                                className="filled"
                                onClick={handleSave}>{initialInstallation ? t.edit : t.add}</button>
                        </div>
                        {error && (
                            <div className="row margin">
                                <p className="error message">{error}</p>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </>
    )
}
import React, {useState} from "react";



export function useOverlayMenu(): [boolean, () => void] {
    const [isVisible, setIsVisible] = useState(false);

    const toggle = () => {
        setIsVisible((current) => !current);
    };

    return [
        isVisible,
        toggle,
    ];
}

export function useMenu(numberOfElements: number):
    [boolean[], (index: number) => void, () => number | null ] {

    const [ highlightList, setHighlightList ] = useState(() => {
        const arr = new Array<boolean>(numberOfElements)
        for (let i = 0; i < numberOfElements; i++) {
            arr[i] = false;
        }
        return arr;
    });

    const resetHighlights = () => {
        const arr = new Array<boolean>(numberOfElements);
        for (let i = 0; i < numberOfElements; i++) {
            arr[i] = false;
        }
        setHighlightList(arr);
    };

    const setHighlightIndex = (index: number) => {
        const arr = new Array<boolean>(numberOfElements);
        for (let i = 0; i < numberOfElements; i++) {
            arr[i] = i === index;
        }
        resetHighlights();
        setHighlightList(arr);
    };

    const currentIndex = () => {
        const arr = new Array<boolean>(numberOfElements);
        for (let i = 0; i < numberOfElements; i++) {
            if (arr[i]) {
                return i;
            }
        }
        return null;
    }

    return [highlightList, setHighlightIndex, currentIndex];
}


interface MenuButtonProps {
    text: string;
    onClick: () => void;
    isHighlight: boolean;
    initialClassName?: string;
    highlightClassName?: string;
}

export function MenuButton(
    {
        text,
        onClick,
        isHighlight,
        initialClassName = "content-control-menu-button fixed-height",
        highlightClassName = "content-control-menu-button fixed-height color-high-emphasis",
    }: MenuButtonProps) {
    return (
        <button
            className={isHighlight ? highlightClassName : initialClassName}
            onClick={onClick}>
            {text}
        </button>
    );
}

interface StandardMenuProps {
    children: JSX.Element | JSX.Element[]
}

export function StandardMenu({ children }: StandardMenuProps) {
    if (children instanceof Array) {
        for (let child of children) {
            if (child !instanceof MenuButton) {
                throw Error("StandardMenu only MenuButton as child/children!");
            }
        }
    }
    else if (children !instanceof MenuButton)
        throw Error("StandardMenu only MenuButton as child/children!");

    const [ isOverlayShowing, toggleOverlayMenu ] = useOverlayMenu();

    return (
        <>
            <OverlayMenu isVisible={isOverlayShowing} toggle={toggleOverlayMenu}>
                {children}
            </OverlayMenu>

            <div
                className="content-control-menu-show-button"
                onClick={toggleOverlayMenu}>
                <img src={"miniMenu.svg"} className="icon" alt="menu"/>
            </div>

            <div className="content-control-menu no-margin color-main-background">
                {children}
            </div>
        </>
    );
}


interface OverlayMenuProps {
    isVisible: boolean;
    toggle: () => void;
    children: JSX.Element | JSX.Element[];
    visibleOnLargeDevices?: boolean;
}

/**
 * An overlay menu. Use instead of normal menu, when site is accessed on mobile device.
 * OverlayMenu should be used in combination with useOverlayMenu hook.
 *
 * @param isVisible A state variable boolean. When true the menu will show.
 * @param toggle Function that toggles the isVisible on and off.
 * @param children Collection of MenuButtons.
 * @param visibleOnLargeDevices Default false. Set to true if overlay menu should be shown up to
 *                              1330px screen width.
 */
export function OverlayMenu({ isVisible, toggle, children, visibleOnLargeDevices = false }: OverlayMenuProps) {
    if (children instanceof Array) {
        for (let child of children) {
            if (child !instanceof MenuButton) {
                throw Error("OverlayMenu only MenuButton as child/children!");
            }
        }
    }
    else if (children !instanceof MenuButton)
        throw Error("OverlayMenu only MenuButton as child/children!");

    return (
        <>
            {isVisible && (
                <div className="dialog-overlay" onClick={toggle}>
                    <div className={visibleOnLargeDevices ? "dialog-overlay-menu" : "content-control-menu-floating"}>
                        {children}
                    </div>
                </div>
            )}
        </>
    );
}
import {Content, ContentForm, PageWithContent} from "../common/ContentStandards";
import requireTranslation from "../../lang/locales/config";
import {ChangeEvent, useEffect, useState} from "react";
import {useUserApi} from "../../API/useUserApi";
import {useAppSelector} from "../../state/hooks";
import {selectUser} from "../../state/userSlice";
import {OverlaySpinLoader, useSpinLoader} from "../common/SpinLoader";
import FirmHeaderFileModel from "../../models/user/firmHeader";
import ErrorMessage from "../common/ErrorMessage";
import {FileInputWithLabel, FormSubHeadline} from "../common/FormCommons";
import {useNavigate} from "react-router-dom";
import {ConfirmationDialog, useDialog} from "../common/ConfirmationDialog";

export default function UploadFirmHeaderPage() {
    const t = requireTranslation();
    const user = useAppSelector(selectUser);
    const navigate = useNavigate();
    const [ inputError, setInputError ] = useState('');
    const [ errorMessage, setErrorMessage ] = useState('');
    const [ errorCurrentHeader, setErrorCurrentHeader ] = useState(false);
    const [
        awaiting, setAwaiting,
        awaitingMessage, setAwaitingMessage
    ] = useSpinLoader();
    const {
        isOpen,
        message: dialogMessage,
        setDialogMessage,
        toggle: toggleDialog
    } = useDialog();
    const [ firmHeader, setFirmHeader ] = useState<File>();
    const [ currentUploadFirmHeader, setCurrentUploadFirmHeader ] = useState<FirmHeaderFileModel>();
    const [ previewImage, setPreviewImage ] = useState<string>("");
    const [ uploadFileText, setUploadFileText ] = useState<string>(t.informationTexts.noFileSelected);
    const {
        getFirmHeader,
        postFirmHeader,
        putFirmHeader,
        deleteFirmHeader,
        updateUserModel
    } = useUserApi();


    useEffect(() => {
        if (user.hasFirmHeader) {
            setAwaiting(true);
            setAwaitingMessage(t.loadingMessages.fetchInformation);
            getFirmHeader()
                .then(setCurrentUploadFirmHeader)
                .catch((e) => { if (e instanceof Error)  setErrorCurrentHeader(true)})
                .finally(() => setAwaiting(false))
        }
    }, [user.hasFirmHeader]);

    const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
        if (previewImage) {
            URL.revokeObjectURL(previewImage);
            setPreviewImage("");
        }

        if (inputError) {
            setInputError("");
        }

        if (event.target.files) {
            const file = event.target.files[0];
            // console.log(file);

            setFirmHeader(file)
            setUploadFileText(file.name);
            setPreviewImage(URL.createObjectURL(file))
        }
    };

    const handlePostFirmHeader = (formData: FormData) => {
        postFirmHeader(formData)
            .then(() => {
                updateUserModel()
                    .then(() => navigate(
                        "/overview",
                        {state: {statusMessage: t.messages.successUpload}}
                    ));
            })
            .catch((e) => {
                if (e instanceof Error) {
                    if (e.message === t.responseMessage.errorPost) {
                        handlePutFirmHeader(formData);
                    } else {
                        setErrorMessage(e.message);
                    }
                }
            })
            .finally(() => setAwaiting(false));
    }

    const handlePutFirmHeader = (formData: FormData) => {
        putFirmHeader(formData)
            .then(() => {
                updateUserModel()
                    .then(() => navigate(
                        "/overview",
                        {state: {statusMessage: t.messages.successEdit}}
                    ));
            })
            .catch((e) => {
                if (e instanceof Error) {
                    if (e.message === t.responseMessage.errorPut) {
                        handlePostFirmHeader(formData);
                    } else {
                        setErrorMessage(e.message);
                    }
                }
            })
            .finally(() => setAwaiting(false));
    }

    const handleUploadFirmHeader = () => {
        URL.revokeObjectURL(previewImage);

        const formData = new FormData();

        formData.append("firmHeader", firmHeader!!, firmHeader!!.name);

        setAwaiting(true);
        setAwaitingMessage(`${t.uploading} ${firmHeader?.name}`);
        !user.hasFirmHeader ?
            handlePostFirmHeader(formData) :
            handlePutFirmHeader(formData);
    };

    const handleDelete = () => {
        setAwaiting(true);
        setAwaitingMessage(t.loadingMessages.deletingCQM.replace("?", t.firmHeader.toLowerCase()));
        deleteFirmHeader()
            .then(() => {
                updateUserModel()
                    .then(() => {
                        navigate(
                            "/overview",
                            {state: {statusMessage: t.messages.successDelete}}
                        )
                    })
            })
            .catch((e) => {
                if (e instanceof Error) {
                    setErrorMessage(e.message);
                }
            })
            .finally(() => setAwaiting(false));
    }

    const handleOpenDeleteDialog = () => {
        setDialogMessage(t.messages.confirmDelete + t.firmHeader.toLowerCase() + "?");
        toggleDialog();
    };

    return (
        <PageWithContent>

            <OverlaySpinLoader
                loading={awaiting}
                loadMessage={awaitingMessage} />

            <ErrorMessage
                error={errorMessage} />

            <>
                {isOpen &&
                    <ConfirmationDialog
                        toggle={toggleDialog}
                        message={dialogMessage}
                        onConfirm={handleDelete} />
                }
            </>

            <>
                {!errorMessage &&
                    <Content
                        headline={t.firmHeader}
                        subtitle={t.informationTexts.firmHeader}
                        centerHeadline={true}
                        infoIconText={t.informationTexts.uploadFirmHeaderInformation}>
                        <ContentForm
                            ariaLabel="uploadFirmHeaderForm">
                            <>
                                {user.hasFirmHeader && (
                                    <>
                                        <div className="row toColumn">
                                            <label className="input-label">
                                                {t.currentFirmHeader}
                                            </label>
                                            <div className="autocomplete">
                                                <div className="input-container">
                                                    {currentUploadFirmHeader && <img
                                                        className="preview-image"
                                                        width="1070"
                                                        height="200"
                                                        src={currentUploadFirmHeader.url}
                                                        alt={t.currentFirmHeader}/>}
                                                    {errorCurrentHeader && !currentUploadFirmHeader && (
                                                        <p className="single-line">
                                                            {t.informationTexts.problemCurrentFirmHeaderFetch}
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row reverse margin">
                                            <button
                                                className="outline"
                                                onClick={handleOpenDeleteDialog}>
                                                {t.delete}
                                            </button>
                                        </div>
                                        <hr className="in-content-form"/>
                                    </>
                                )}
                                {user.hasFirmHeader && (
                                    <FormSubHeadline
                                        headline={t.replaceCQM.replace("?", t.firmHeader)}
                                        centerHeadline={true}
                                    />
                                )}
                                <FileInputWithLabel
                                    labelText={t.firmHeader}
                                    buttonText={t.chooseFile}
                                    uploadFileText={uploadFileText}
                                    accepted={".jpg, .jpeg, .png"}
                                    name="firmHeader"
                                    ariaLabel="uploadFirmHeaderFile"
                                    error={inputError}
                                    onChange={handleFileSelect} />

                                <div className="row toColumn">
                                    <label
                                        className="input-label">
                                        {t.preview}
                                    </label>
                                    <div className="autocomplete">
                                        <div className="input-container">
                                        {previewImage ? (
                                            <img
                                                className="preview-image"
                                                width="1070"
                                                height="200"
                                                src={previewImage}
                                                alt="preview"/>) : (
                                            <p className="preview-image">choose a file to see preview...</p>
                                        )}
                                        </div>
                                    </div>
                                </div>
                                <div className="row reverse margin">
                                    <button
                                        className="filled"
                                        onClick={firmHeader ? handleUploadFirmHeader : () => setInputError(t.errors.selectFile)}>
                                        Upload
                                    </button>
                                </div>
                            </>
                        </ContentForm>
                    </Content>
                }
            </>
        </PageWithContent>
    );
}
import {Translation} from "./Translation";

class AvailableLanguages {
    private langList = new Map<string, Translation>();

    constructor() {
        this.langList.set("en", require("./en/translations.json"));
        this.langList.set("da", require("./da/translations.json"));
    };

    find = (lang: string) => {
        return this.langList.get(lang)!!;
    };

    hasLang = (lang: string) => {
        return this.langList.has(lang);
    };
}

function setup(): [Translation, string] {
    const prefLangs = window.navigator.languages;
    const prefLang = window.navigator.language;
    const defaultLang = "en";
    const availableLanguages = new AvailableLanguages();

    const indexOf = prefLang.search("-")

    // console.log("pref Lang (single): " + window.navigator.language)
    // console.log("pref lang: " + prefLang);
    const usingLang = ((): [Translation, string] => {
            // First we check the single pref lang if it is supported.
            if (indexOf !== -1) {
                const slicedLang = prefLang.slice(0, indexOf)
                if (availableLanguages.hasLang(slicedLang)) {
                    return [availableLanguages.find(slicedLang), slicedLang];
                }
            } else {
                if (availableLanguages.hasLang(prefLang)) {
                    return [availableLanguages.find(prefLang), prefLang];
                }
            }

            // else we run through the language list to find the first supported
            // language. (else default)
            // eslint-disable-next-line array-callback-return
            const filtered = prefLangs.filter((lang) => {
                if (availableLanguages.hasLang(lang)) {
                    return lang;
                }
            });
            filtered.push(defaultLang);
            return [availableLanguages.find(filtered[0]), filtered[0]];
        }
    )
    // console.log(usingLang())

    return usingLang();
}

const [translation, current_lang] = setup()

export function requireCurrentLanguage(): string {
    return current_lang
}

export default function requireTranslation(): Translation {
    return translation;
}
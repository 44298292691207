import requireTranslation from "../lang/locales/config";
import {ParseError, parsePhoneNumber} from "libphonenumber-js";


/**
 * Checks is the errors object is empty.
 *
 * If all values is empty strings - return true.
 *
 * If any value contains some message - return false.
 *
 * @param errors An standard input errors object, fx. - { "name": "", "address": "some error" }
 */
function isInputErrorsEmpty<T> (errors: T): boolean {
    for (let k in errors) {
        if (errors[k]) {
            return false;
        }
    }
    return true;
}


/**
 * If password requirements are not met, return a descriptive error string.
 * Else returns an empty string.
 *
 * @param password The password that needs to be validated.
 */
function validatePassword(password: string | null) {
    const t = requireTranslation();
    const specialChar = /[!-\/:-@[-`{-~]/;
    const numbers = /[0-9]/;
    const capital = /[A-Z]/;

    if (!password)
        return t.errors.passwordShort;

    if (password.length < 8) {
        return t.errors.passwordShort;
    }
    if (!numbers.test(password)) {
        return t.errors.passwordNumber;
    }
    if (!capital.test(password)) {
        return t.errors.passwordCapital
    }
    if (!specialChar.test(password)) {
        return t.errors.passwordSpecial;
    }

    return "";
}

function validatePhoneNumber(phone: string) {
    const t = requireTranslation();

    if (!phone)
        return t.errors.phoneBothFields;

    try {
        const phoneNumber = parsePhoneNumber(phone);

        if (!phoneNumber.isValid()) {
            return t.errors.phoneNotValid;
        }

    } catch (e) {
        if (e instanceof ParseError) {
            if (e.message === "TOO_SHORT") {
                return t.errors.phoneTooShort;
            } else if (e.message === "TOO_LONG") {
                return t.errors.phoneTooLong;
            } else {
                return t.errors.phoneNotValid;
            }
        }
    }

    return "";
}

/**
 * Returns a descriptive error string if the username requirements are not met.
 * Else an empty string is returned.
 *
 * @param username The username string that should be validated.
 */
function validateUsername(username: string | null) {
    const t = requireTranslation();
    const punctuation = /[.]/g;
    const notAllowed = /[^a-zA-Z0-9.]/g;

    if (!username)
        return t.errors.usernameRange;

    if (username.length < 6 || 30 < username.length) {
        return t.errors.usernameRange;
    }
    if ((username.match(punctuation) || []).length > 1) {
        return t.errors.usernameOnePunctuation;
    }
    if (username.startsWith(".")) {
        return t.errors.usernameStartPunctuation;
    }
    if (username.endsWith(".")) {
        return t.errors.usernameEndPunctuation;
    }
    if (notAllowed.test(username)) {
        return t.errors.usernameCanContain;
    }

    return "";
}

/**
 * Validates a text area only contains the allowed special characters.
 *
 * Can Contain: letters, numbers, .,!?%@+()`'"_=-
 *
 * @param text The text to validate.
 * @param required Set true if the text should not be an empty string. Default to false.
 */
function validateTextArea(text: string, required = false) {
    const t = requireTranslation();
    const notAllowed = /[^\n\ra-zA-Z0-9øæåÆØÅ .,!?%@+()`'"_=-]/g;

    if (required && text.replaceAll(" ", "").length === 0) {
        return t.errors.required;
    }

    if (notAllowed.test(text)) {
        return t.errors.textAreaCanContain;
    }

    return "";
}

function validateName(name: string): string {
    const t = requireTranslation();
    const notAllowed = /[^a-zA-ZøæåÆØÅ ]/g;
    const nameWithoutWhitespace = name.split(" ").join();

    if (nameWithoutWhitespace.length < 3) {
        return t.errors.nameRange;
    }

    if (notAllowed.test(name)) {
        return t.errors.nameOnlyLetters;
    }

    return "";
}

/**
 * Checks whether an address is valid. If Valid returns an empty string.
 *
 * Should not be empty.
 *
 * Can Contain: letters numbers , . ' ` - _
 *
 * @param address The address in question.
 */
function validateAddress(address: string): string {
    const t = requireTranslation();
    const notAllowed = /[^\wøæåÆØÅ ,.-]/g

    if (address.length === 0) return t.errors.required;
    if (notAllowed.test(address)) return t.errors.addressContain;
    return "";
}

/**
 * Simply checks whether input is empty or not.
 * Returns required-error if empty. Else an empty string.
 *
 * @param input The input to validate.
 */
function validateRequired(input: string | number | null | undefined): string {
    const t = requireTranslation();

    if (!input || input.toString().replaceAll(" ", "").length === 0)
        return t.errors.required;

    return ""
}

/**
 * Simply checks if the number exceeds the max given.
 * Return max-exceeded-error if value is too high. Else an empty string.
 *
 * @param inputNumber The number to check.
 * @param maxAccepted The maximum value.
 */
function validateMaxValue(inputNumber: number, maxAccepted: number): string {
    const t = requireTranslation();

    if (inputNumber > maxAccepted) {
        return t.errors.exceedMax.replace("?", `${maxAccepted}`);
    }

    return "";
}

/**
 * Check if an email meets the requirements
 */
function validateEmail(inputEmail: string): string {
    const t = requireTranslation();
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    if (!regex.test(inputEmail)) {
        return t.errors.emailNotValid;
    }

    return ""
}



export {
    isInputErrorsEmpty,
    validatePassword,
    validatePhoneNumber,
    validateUsername,
    validateTextArea,
    validateName,
    validateAddress,
    validateRequired,
    validateMaxValue,
    validateEmail
}
import requireTranslation from "../../lang/locales/config";
import {useStandardSetup} from "../../utils/customHooks";
import {TotalsModel} from "../../models/case/totals";
import {useTotalsApi} from "../../API/useClientCaseApi";
import {useEffect} from "react";
import {SpinLoader} from "../common/SpinLoader";
import ErrorMessage from "../common/ErrorMessage";
import {InformationRow} from "../common/TextCommons";

interface TotalsDetailsProps {
    clientId: string;
}

export default function TotalsDetails({ clientId }: TotalsDetailsProps) {
    const t = requireTranslation();
    const [
        loading, setLoading,
        error, setError,
        totals, setTotals
    ] = useStandardSetup<TotalsModel>();
    const { getTotals } = useTotalsApi();

    useEffect(() => {
        setLoading(true);
        getTotals(clientId)
            .then(setTotals)
            .catch((e) => {
                if (e instanceof Error) setError(e.message);
            })
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientId]);

    return (
        <>
            <SpinLoader
                containerClassName="content color-main-background"
                loading={loading}
                loadMessage={`${t.loading} ${t.totals.toLowerCase()}...`} />
            <ErrorMessage
                error={error}
                containerClassName="content color-main-background" />

            {!loading && !error && totals && (
                <div className="content fold-out color-main-background">
                    <h3 className="card-header">{`${t.totals}`}</h3>
                    <h4 className="card-header no-margin top-padding">{t.out}</h4>
                    <hr/>
                    <InformationRow title={`${t.projected}:`} text={`${totals.outgoingProjected}`} />
                    <InformationRow title={`${t.measured}:`} text={`${totals.outgoingMeasured}`} />
                    <InformationRow title={`${t.difference}:`} text={`${totals.outgoingDiff}`} />
                    <InformationRow title={`${t.difference} (${t.percent}):`} text={`${totals.outgoingPercentDiff}`} />

                    <h4 className="card-header no-margin top-padding extra">{t.in}</h4>
                    <hr/>
                    <InformationRow title={`${t.projected}:`} text={`${totals.ingoingProjected}`} />
                    <InformationRow title={`${t.measured}:`} text={`${totals.ingoingMeasured}`} />
                    <InformationRow title={`${t.difference}:`} text={`${totals.ingoingDiff}`} />
                    <InformationRow title={`${t.difference} (${t.percent}):`} text={`${totals.ingoingPercentDiff}`} />

                    <h4 className="card-header no-margin top-padding extra">{t.selValue}</h4>
                    <hr/>
                    <InformationRow title={`${t.value}:`} text={`${totals.selValue}`} />
                </div>
            )}
        </>
    )
}
import {SuccessNoContent, useFetch} from "./useFetch";
import {SimplifiedClientCase} from "../models/ClientCase";
import {MeasurementModel} from "../models/case/measurement";
import {ResponseModel} from "./StatusError";
import {ArmatureModel} from "../models/case/armature";
import {ClientModel} from "../models/case/client";
import {StepReadingModel} from "../models/case/stepReading";
import {InstallationModel} from "../models/case/installation";
import {WattAndCommentsModel} from "../models/case/wattAndComments";
import {TotalsModel} from "../models/case/totals";
import {ReportFileModel} from "../models/case/report";
import LoggerNoteFileModel, {LoggerNoteFileUploadResponse} from "../models/case/loggerNoteFile";
import CaseStatusModel from "../models/case/caseStatus";
import LoggerNoteModel from "../models/case/loggerNote";
import SupportedLoggersModel from "../models/case/supportedLoggers";
import LoggerNoteCheckModel from "../models/case/LoggerNoteCheck";

const baseUrl = process.env.REACT_APP_API_URL as string;

export const enum CaseStatusParams {
    INSTALLATION = "installation",
    ARMATURES = "armatures",
    MEASUREMENTS = "measurements",
    STEP_READINGS = "stepReadings",
    WATT_AND_COMMENTS = "wattAndComments",
    TOTALS = "totals",
    LOGGER_NOTE_FILE = "loggerNoteFile",
    LOGGER_NOTES = "loggerNotes"
}

export function useCaseStatusApi() {
    const url = `${baseUrl}/case/status`;
    const requester = useFetch();


    const getCaseStatus = (clientId: string, statusFor: CaseStatusParams[] | null = null) => {
        let param: string
        statusFor !== undefined ?
            param = `?clientId=${clientId}` :
            param = `?clientId=${clientId}&statusFor=${statusFor}`;
        return requester.get(CaseStatusModel, url + param) as Promise<CaseStatusModel>;
    }

    return {
        getCaseStatus,
    }

}

export function useClientApi() {
    const url = `${baseUrl}/case/client`;
    const plural = `${baseUrl}/case/clients`;
    const requester = useFetch();


    const deleteClient = (clientId: string) =>
        requester.delete(SuccessNoContent, url, {
            clientId: clientId
        }) as Promise<SuccessNoContent>;

    const duplicateClient = (clientId: string, duplicates: number) => {
        const param = `?duplicates=${duplicates}`;
        return requester.post(
            SuccessNoContent,
            plural + param,
            { clientId: clientId }) as Promise<SuccessNoContent>;
    }

    const createClientTemplate = (clientId: string, employeeId: string | null, date: number) => {
        const param = `?createClientTemplate=${true}`;
        return requester.post(
            SimplifiedClientCase,
            plural + param,
            {
                clientId: clientId,
                employeeId: employeeId,
                date: date
            },
            undefined,
            false,
            false,
            true
        ) as Promise<SimplifiedClientCase>;
    };

    const getClient = (clientId: string) => {
        const param = `?clientId=${clientId}`;
        return requester.get(ClientModel, url + param) as Promise<ClientModel>;
    };

    const getClientsByLimit = (offset: number, limit: number, simple: boolean) => {
        const params = `?offset=${offset}&limit=${limit}&simple=${simple}`;
        return requester.get(SimplifiedClientCase, plural + params) as Promise<SimplifiedClientCase[]>;
    };

    const getClientsBySearchSimple = (search: string, limit: number, offset: number) => {
        const params = `?search=${search}&simple=${true}&limit=${limit}&offset=${offset}`;
        return requester.get(SimplifiedClientCase, plural + params) as Promise<SimplifiedClientCase[]>;
    };

    const getClientsBySearch = (search: string, limit: number, offset: number, suggestOldCases: boolean = false) => {
        const params = `?search=${search}&simple=${false}&limit=${limit}&offset=${offset}&suggestOldCases=${suggestOldCases}`;
        return requester.get(ClientModel, plural + params) as Promise<ClientModel[]>;
    }

    const getClientsByFilterDate = (
        startDate: number,
        stopDate: number,
        limit: number,
        offset: number,
        simple: boolean = true,
    ) => {
        const params = `?startDate=${startDate}&stopDate=${stopDate}&simple${simple}&limit=${limit}&offset=${offset}`;
        return requester.get(SimplifiedClientCase, plural + params) as Promise<SimplifiedClientCase[]>;
    }

    const postClient = (client: ClientModel) =>
        requester.post(
            ClientModel,
            url,
            client,
            undefined,
            false,
            false,
            true
        ) as Promise<ClientModel>;

    const putClient = (client: ClientModel) =>
        requester.put(ResponseModel, url, client) as Promise<ResponseModel>;

    const putClientEmployee = (clientId: string, employeeId: string | null) => {
        const body = {
            "clientId": clientId,
            "employeeId": employeeId
        };
        const param = `?updateEmployee=${true}`;
        return requester.put(SuccessNoContent, url + param, body);
    }

    return {
        deleteClient,
        duplicateClient,
        createClientTemplate,
        getClient,
        getClientsByLimit,
        getClientsBySearch,
        getClientsBySearchSimple,
        getClientsByFilterDate,
        postClient,
        putClient,
        putClientEmployee,
    };
}

export function useInstallationApi() {
    const url = `${baseUrl}/case/installation`
    const requester = useFetch();

    const getInstallation = (clientId: string) => {
        const param = `?clientId=${clientId}`;
        return requester.get(InstallationModel, url + param) as Promise<InstallationModel>;
    };

    const postInstallation = (installation: InstallationModel) =>
        requester.post(ResponseModel, url, installation) as Promise<ResponseModel>

    const putInstallation = (installation: InstallationModel) =>
        requester.put(ResponseModel, url, installation) as Promise<ResponseModel>

    return {
        getInstallation,
        postInstallation,
        putInstallation
    };
}

export function useMeasurementApi() {
    const pluralUrl = `${baseUrl}/case/measurements`;
    const url = `${baseUrl}/case/measurement`;
    const requester = useFetch();

    const getMeasurements = (clientId: string, extended = true) => {
        const param = `?clientId=${clientId}&extended=${extended}`;
        return requester.get(MeasurementModel, pluralUrl + param) as Promise<MeasurementModel[]>;
    };

    const postMeasurement = (measurement: MeasurementModel) =>
        requester.post(ResponseModel, url, measurement, (key, value) => {
            if (key === "armatureType" || key === "armatureModel") return undefined;
            else return value;
        }) as Promise<ResponseModel>;

    const putMeasurement = (measurement: MeasurementModel) => requester
        .put(ResponseModel, url, measurement, (key, value) => {
            if (key === "armatureType" || key === "armatureModel") return undefined;
            else return value;
        }) as Promise<ResponseModel>;

    const deleteMeasurement = (measurement: MeasurementModel) =>
        requester.delete(ResponseModel, url, measurement, (key, value) => {
            if (key === "armatureType" || key === "armatureModel") return undefined;
            else return value;
        }) as Promise<ResponseModel>;

    return {
        getMeasurements,
        postMeasurement,
        putMeasurement,
        deleteMeasurement
    };
}

export function useArmatureApi() {
    const pluralUrl = `${baseUrl}/case/armatures`;
    const url = `${baseUrl}/case/armature`;
    const requester = useFetch();

    const getArmatures = (clientId: string) => {
        const param = `?clientId=${clientId}`;
        return requester.get(ArmatureModel,pluralUrl + param) as Promise<ArmatureModel[]>;
    };

    const postArmature = (armature: ArmatureModel) =>
        requester.post(ResponseModel, url, armature) as Promise<ResponseModel>;

    const putArmature = (armature: ArmatureModel) =>
        requester.put(ResponseModel, url, armature) as Promise<ResponseModel>;

    const deleteArmature = (armature: ArmatureModel) =>
        requester.delete(ResponseModel, url, armature) as Promise<ResponseModel>;


    return {
        getArmatures,
        postArmature,
        putArmature,
        deleteArmature
    };
}

export function useTotalsApi() {
    const url = `${baseUrl}/case/totals`;
    const requester = useFetch();

    const getTotals = (clientId: string) => {
        const param = `?clientId=${clientId}`;
        return requester.get(TotalsModel, url + param) as Promise<TotalsModel>;
    };

    return {
        getTotals,
    };
}

export function useStepReadingApi() {
    const pluralUrl = `${baseUrl}/case/steps`;
    const url = `${baseUrl}/case/step`;
    const requester = useFetch();

    const getStepReadings = (clientId: string) => {
        const param = `?clientId=${clientId}`;
        return requester.get(StepReadingModel, pluralUrl + param) as Promise<StepReadingModel[]>;
    };

    const postStepReading = (stepReading: StepReadingModel) =>
        requester.post(ResponseModel, url, stepReading) as Promise<SuccessNoContent>;

    const putStepReading = (stepReading: StepReadingModel) => {
        return requester.put(ResponseModel, url, stepReading) as Promise<ResponseModel>;
    };

    const deleteStepReading = (stepReading: StepReadingModel) =>
        requester.delete(ResponseModel, url, stepReading) as Promise<SuccessNoContent>;


    return {
        getStepReadings,
        postStepReading,
        putStepReading,
        deleteStepReading
    };
}

export function useWattAndCommentsApi() {
    const url = `${baseUrl}/case/watt`;
    const requester = useFetch();

    const getWattAndComments = (clientId: string) => {
        const param = `?clientId=${clientId}`;
        return requester.get(WattAndCommentsModel, url + param) as Promise<WattAndCommentsModel>;
    };

    const postWattAndComments = (wattAndComments: WattAndCommentsModel) =>
        requester.post(ResponseModel, url, wattAndComments) as Promise<ResponseModel>;

    const putWattAndComments = (wattAndComments: WattAndCommentsModel) => {
        return requester.put(ResponseModel, url, wattAndComments) as Promise<ResponseModel>;
    }

    return {
        getWattAndComments,
        postWattAndComments,
        putWattAndComments,
    };
}

export function useClientCaseReportApi() {
    const url = `${baseUrl}/case/report`
    const requester = useFetch();

    const getReport = (clientId: string, language: string, option?: string) => {
        let params = `?clientId=${clientId}&lang=${language.valueOf()}`;
        if (option) {
            params += `&option=${option}`;
        }
        return requester.get(
            ReportFileModel,
            url + params,
            undefined,
            undefined,
            true) as Promise<ReportFileModel>
    };

    return {
        getReport,
    };
}

export function useLoggerSupportedDevices() {
    const url = `${baseUrl}/case/supportedDevices`;
    const requester = useFetch();

    const getSupportedLoggers = () =>
        requester.get(SupportedLoggersModel, url) as Promise<SupportedLoggersModel>;

    return {
        getSupportedLoggers,
    };
}

export function useLoggerNoteFileApi() {
    const url = `${baseUrl}/case/loggerNoteFile`;
    const support_url = `${baseUrl}/support/loggerNoteFile`;
    const requester= useFetch();

    const getLoggerNoteFile = (clientId: string) => {
        const param = `?clientId=${clientId}`;

        return requester.get(
            LoggerNoteFileModel,
            url + param,
            undefined,
            undefined,
            true
        ) as Promise<LoggerNoteFileModel>;
    };

    const postLoggerNoteFile = (
        clientId: string,
        loggerNoteFile: FormData,
        supportedLogger: string | null = null
    ) => {
        const param = supportedLogger == null ?
            `?clientId=${clientId}` :
            `?clientId=${clientId}&supportedLogger=${supportedLogger}`;

        return requester.post(
            LoggerNoteFileUploadResponse,
            url + param,
            loggerNoteFile
        ) as Promise<LoggerNoteFileUploadResponse | SuccessNoContent>;
    };

    const postUnsupportedLoggerNoteFile = (
        clientId: string,
        loggerNoteFile: FormData,
    ) => {
        const param = `?clientId=${clientId}`;
        return requester.post(
            SuccessNoContent,
            support_url + param,
            loggerNoteFile
        ) as Promise<SuccessNoContent>;
    };

    const deleteLoggerNoteFile = (clientId: string) => {
        const param = `?clientId=${clientId}`;
        return requester.delete(ResponseModel, url + param) as Promise<SuccessNoContent>;
    };

    const checkMeasurementsContraLoggerNotes = (clientId: string) => {
        const param = `?clientId=${clientId}`;
        return requester.get(LoggerNoteCheckModel, url + "/check" + param) as Promise<LoggerNoteCheckModel>;
    };

    const putMeasurementsLoggerNotesResults = (clientId: string, results: LoggerNoteCheckModel) => {
        const param = `?clientId=${clientId}`;
        return requester.put(SuccessNoContent, url + "/check" + param, results) as Promise<SuccessNoContent>;
    };

    return {
        getLoggerNoteFile,
        postLoggerNoteFile,
        postUnsupportedLoggerNoteFile,
        deleteLoggerNoteFile,
        checkMeasurementsContraLoggerNotes,
        putMeasurementsLoggerNotesResults
    };
}

export function useLoggerNoteApi() {
    const url = baseUrl + '/case/loggerNote';
    const requester = useFetch();

    const getLoggerNotes = (clientId: string) => {
        const param = `?clientId=${clientId}`;
        return requester.get(LoggerNoteModel, url + param) as Promise<LoggerNoteModel[]>;
    }

    const deleteLoggerNotes = (clientId: string) => {
        const param = `?clientId=${clientId}`;
        return requester.delete(ResponseModel, url + param) as Promise<SuccessNoContent>;
    }

    return {
        getLoggerNotes,
        deleteLoggerNotes,
    }
}
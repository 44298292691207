import requireTranslation from "../../lang/locales/config";
import {useStandardSetup} from "../../utils/customHooks";
import LoggerNoteCheckModel from "../../models/case/LoggerNoteCheck";
import {useLoggerNoteFileApi} from "../../API/useClientCaseApi";
import ErrorMessage, {SuccessMessage} from "../common/ErrorMessage";
import {Content} from "../common/ContentStandards";
import {OverlaySpinLoader} from "../common/SpinLoader";
import {useState} from "react";

interface LoggerNoteCheckProps {
    clientId: string;
}

export default function LoggerNoteCheck({ clientId }: LoggerNoteCheckProps) {
    const t = requireTranslation();
    const [
        loading, setLoading,
        error, setError,
        checkResult, setCheckResult
    ] = useStandardSetup<LoggerNoteCheckModel>();
    const [ loadingMessage, setLoadingMessage ] = useState(t.loadingMessages.performingCheck);
    const { checkMeasurementsContraLoggerNotes, putMeasurementsLoggerNotesResults } = useLoggerNoteFileApi();
    const [ message, setMessage ] = useState("");

    const handlePerformCheck = () => {
        setLoading(true);
        checkMeasurementsContraLoggerNotes(clientId)
            .then(setCheckResult)
            .catch((e) => { if (e instanceof Error) setError(e.message);})
            .finally(() => setLoading(false));
    };

    const handlePerformRepair = () => {
        setLoading(true);
        setLoadingMessage(t.loadingMessages.repairMeasurements);
        putMeasurementsLoggerNotesResults(clientId, checkResult!!)
            .then(() => { setMessage(t.informationTexts.loggerNoteCheckRepairSuccess)})
            .catch((e) => {
                if (e instanceof Error)
                    setError(e.message);
            })
            .finally(() => setLoading(false));
    }

    return (
        <>
            <ErrorMessage error={error} />

            <SuccessMessage message={message} />

            <OverlaySpinLoader
                loading={loading}
                loadMessage={loadingMessage} />

            {!error && !message &&
                <Content
                    headline={t.loggerNoteCheck}
                    centerHeadline={true}
                    infoIconText={t.informationTexts.loggerNoteCheckInformation}>
                    <div className="table-container">
                        {checkResult &&
                            <table className="table-with-content">
                                <thead>
                                    <tr>
                                        <th className="table-header-row">{t.loggerNote}</th>
                                        <th className="table-header-row">{t.measured}</th>
                                        <th className="table-header-row">{t.measuredLogger}</th>
                                        <th className="table-header-row">{t.result}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {checkResult.results.map((value) => (
                                        <tr className="table-row" key={value.loggerNumber}>
                                            <td className="table-value">{value.loggerNumber}</td>
                                            <td className="table-value">{value.measurementValue}</td>
                                            <td className="table-value">{value.loggerValue}</td>
                                            <td className="table-value">
                                                <img
                                                    className={value.result ?
                                                        "table-icon color-high-emphasis filter" :
                                                        "table-icon color-warn filter"}
                                                    src={value.result ? "done.svg" : "error.svg"}
                                                    alt={t.result}/>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        }
                    </div>
                    <div className="row reverse">
                        {checkResult ? <>
                            {!checkResult.overallResult &&
                                <button
                                    className="filled"
                                    onClick={handlePerformRepair}>
                                    {t.repair}
                                </button>
                            } </> :
                            <button
                                className="filled"
                                onClick={handlePerformCheck}>
                                {t.check}
                            </button>
                        }
                    </div>
                </Content>
            }
        </>
    )
}
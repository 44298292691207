import {UserUpdateModel} from "./user/user";

interface EmployeeModelInitializer {
    id: string | null,
    name: string,
    description: string,
    username: string | null,
    password: string | null,
    email: string,
    phone: string,
}

export class EmployeeModel {
    id: string | null = null;
    name: string = "";
    description: string = "";
    username: string | null = null;
    password: string | null = null;
    email: string = "";
    phone: string = "";

    constructor(initializer?: EmployeeModelInitializer) {
        if (!initializer) return;
        if (initializer.id) this.id = initializer.id;

        this.name = initializer.name;
        this.description = initializer.description;
        this.username = initializer.username;
        if (initializer.password)
            this.password = initializer.password;
        this.email = initializer.email;
        this.phone = initializer.phone;
    }
}


interface EmployeeUpdateModelInit {
    name?: string;
    privateEmail?: string;
    phone?: string;
    verificationCode?: string;
}

export class EmployeeUpdateModel {
    name: string | null = null;
    email: string | null = null;
    phone: string | null = null;
    verificationCode: string | null = null;

    constructor(init: EmployeeUpdateModelInit) {
        if (init.name) {
            this.name = init.name;
        }
        if (init.privateEmail) {
            this.email = init.privateEmail;
        }
        if (init.phone) {
            this.phone = init.phone;
        }
        if (init.verificationCode) {
            this.verificationCode = init.verificationCode;
        }
    }
}

interface FormerEmployee {
    id: string,
    name: string,
    lastKnownEmail: string,
}

export class FormerEmployeeModel implements FormerEmployee {
    id: string;
    name: string;
    lastKnownEmail: string;

    constructor(init: FormerEmployee) {
        this.id = init.id;
        this.name = init.name;
        this.lastKnownEmail = init.lastKnownEmail;
    }
}
export class Login {
    username: string = "";
    password: string = "";

    constructor(initializer?: any) {
        if (!initializer) return;
        if (initializer.username) this.username = initializer.username;
        if (initializer.password) this.password = initializer.password;
    }
}

export interface ForgotLoginValues {
    username: string;
    email: string;
}
import {MeasurementModel} from "../../models/case/measurement";
import {SyntheticEvent, useEffect, useState} from "react";
import requireTranslation from "../../lang/locales/config";
import {ArmatureModel} from "../../models/case/armature";
import {NumberInputWithLabel, TextInputWithLabel} from "../common/FormCommons";
import {isInputErrorsEmpty, validateRequired} from "../../utils/inputValidation";
import {OverlaySpinLoader, SpinLoader} from "../common/SpinLoader";
import ErrorMessage from "../common/ErrorMessage";
import {useArmatureApi, useMeasurementApi} from "../../API/useClientCaseApi";
import {useFormSetup} from "../../utils/customHooks";
import MeasurementList from "./MeasurementList";
import {ConfirmationDialog, useDialog} from "../common/ConfirmationDialog";

interface MeasurementFormProps {
    initialMeasurement?: MeasurementModel;
    onSave: (formElement: JSX.Element,
             statusMessage?: string,
             newClientName?: string,
             shouldRefreshStatus?: boolean) => void;
    clientId?: string;
    haveBackButton?: boolean;
}

interface InputErrorInter {
    room: string;
    projected: string;
    armature: string;
}

function MeasurementForm(
    { initialMeasurement, onSave, clientId, haveBackButton = false }: MeasurementFormProps
) {
    if (!initialMeasurement && !clientId) {
        throw Error("If initialMeasurement is undefined, then clientId must be defined!")
    }

    let newMeasurement = null;
    if (!initialMeasurement) {
        newMeasurement = new MeasurementModel();
        newMeasurement.clientId = clientId!!;
    }

    const t = requireTranslation();
    const { getArmatures } = useArmatureApi();
    const { putMeasurement, postMeasurement, deleteMeasurement } = useMeasurementApi();
    const { isOpen, message: dialogMessage, setDialogMessage, toggle: toggleDialog} = useDialog();
    const initialInputErrors: InputErrorInter = {
        room: "",
        projected: "",
        armature: ""
    }
    const [
        awaiting, setAwaiting,
        inputErrors, setInputErrors,
        error, setError,
        measurement, setMeasurement,
    ] = useFormSetup<MeasurementModel, InputErrorInter>(
        initialMeasurement ? initialMeasurement : newMeasurement!!,
        initialInputErrors
    );
    const [ armatures, setArmatures ] = useState<ArmatureModel[] | []>([]);
    const [ loading, setLoading ] = useState(false);
    const [ loadError, setLoadError ] = useState("");


    useEffect(() => {
        setLoading(true);
        getArmatures(measurement.clientId)
            .then((data) => {
                data.sort((a: ArmatureModel, b: ArmatureModel) => {
                    if (a.id === measurement.armatureId) return -1;
                    if (b.id === measurement.armatureId) return 1;
                    return 0;
                })
                setArmatures(data)
            })
            .catch((e) => {
                if (e instanceof Error) setLoadError(e.message);
            })
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [measurement.clientId]);

    /**
     * Handles when the back button (if any) is clicked.
     */
    const handleBackClick = () => {
        onSave(
            <MeasurementList clientId={measurement.clientId!!} onEdit={onSave}/>
        )
    }

    /**
     * Handles the change in input fields projected and measured, since
     * these affect the diff and percentDiff numbers.
     */
    const handleChangeProjectedAndMeasured = (event: any) => {
        const { name, value } = event.target;
        const format = /[^0-9]/g
        let change: { [x: string]: number } = {
            [name]: value,
            "diff": 0,
            "percentDiff": 0
        };

        // console.log(!value)

        if (value.length > 4) return

        if (format.test(value)) return

        if ((measurement.projected && name === "measured" && value) ||
            (measurement.measured && name === "projected" && value)
        ) {
            const projected = (name === "projected") ? value : measurement.projected;
            const measured = (name === "measured") ? value : measurement.measured;

            change = {
                [name]: value,
                "diff": projected - measured,
                "percentDiff": (measured.toString().length === 0 || projected.toString().length === 0) ?
                    0 : Math.round(measured / projected * 100)
            };
        } else if (name === "measured" && !value && measurement.projected) {
            change = {
                [name]: value,
                "diff": 0,
                "percentDiff": 0
            }
        } else if (name === "projected" && !value && measurement.measured) {
            change = {
                [name]: value,
                "diff": 0,
                "percentDiff": 0
            }
        }

        setMeasurement((measurement) => {
            return new MeasurementModel({ ...measurement, ...change });
        });
    }

    /**
     * Handles a simple change in input field where any change is allowed.
     */
    const handleChange = (event: any, alt_value = false) => {
        const { name, value } = event.target;
        const change = { [name]: alt_value ? null : value };
        setError("");
        setMeasurement((measurement) => {
            return new MeasurementModel({ ...measurement, ...change });
        });
    };

    /**
     * Handles when the save button is clicked. Validates input and
     * sends a PUT request to the server.
     */
    const handleSave = async () => {
        if (validateInput()) {
            if (!initialMeasurement) {
                setAwaiting(true);
                await postMeasurement(measurement)
                    .then(() => {
                        onSave(
                            <MeasurementList
                                clientId={measurement.clientId}
                                onEdit={onSave}/>,
                            t.messages.successAdd,
                            undefined,
                            true
                        );
                    })
                    .catch((e) => {
                        if (e instanceof Error) setError(e.message);
                    })
                    .finally(() => setAwaiting(false));
            } else {
                setAwaiting(true);
                await putMeasurement(measurement)
                    .then(() => {
                        onSave(
                            <MeasurementList
                                clientId={measurement.clientId}
                                onEdit={onSave}/>,
                            t.messages.successEdit,
                        );
                    })
                    .catch((e) => {
                        if (e instanceof Error) setError(e.message);
                    })
                    .finally(() => setAwaiting(false));
            }
        }
    };

    /**
     * Toggles a confirmation dialog when delete button is clicked.
     */
    const handleDelete = () => {
        setDialogMessage(t.messages.confirmDelete + measurement.room + "?");
        toggleDialog();
    }

    /**
     * When confirmation dialog delete button is clicked, a DELETE request is send to the
     * server, if successful, switch content view to MeasurementList.
     */
    const handleConfirmDelete = async () => {
        // console.log("Delete button clicked...")
        toggleDialog();
        setAwaiting(true);
        deleteMeasurement(initialMeasurement!!)
            .then(() => {
                onSave(
                    <MeasurementList
                        clientId={measurement.clientId}
                        onEdit={onSave}/>,
                    t.messages.successDelete,
                    undefined,
                    true
                );
            })
            .catch((e) => {
                if (e instanceof Error) setError(e.message);
            })
            .finally(() => setAwaiting(false));
    }

    /**
     * Validates the input fields. Returns true if all input is valid.
     */
    const validateInput = (): boolean => {
        const err = { ...inputErrors };

        err.room = validateRequired(measurement.room);
        err.projected = validateRequired(measurement.projected);
        err.armature = validateRequired(measurement.armatureId);

        setInputErrors(err);
        return isInputErrorsEmpty<InputErrorInter>(err);
    }

    return (
        <>
            <OverlaySpinLoader
                loading={awaiting}
                loadMessage={`${t.awaiting}...`} />

            {isOpen && (
                <ConfirmationDialog
                    toggle={toggleDialog}
                    message={dialogMessage}
                    onConfirm={handleConfirmDelete} />
            )}
            <ErrorMessage
                error={loadError}
                containerClassName="content color-main-background" />

            <SpinLoader
                containerClassName="content color-main-background"
                loading={loading}
                loadMessage={t.loading + " " + t.armatures} />

            {!loading && !loadError && (
                <div className="content color-main-background fold-out">
                    <div className="content-inner-form">
                        <div className="row">
                            {haveBackButton &&
                                <img
                                    className="icon-button"
                                    src={"back_arrow.svg"}
                                    alt={t.back}
                                    onClick={handleBackClick}/>
                            }
                            {initialMeasurement && (
                                <h3 className="form-header">{t.edit} - {initialMeasurement.room}</h3>
                            )}
                            {!initialMeasurement && (
                                <h3 className="form-header">{t.add} {t.measurement}</h3>
                            )}
                        </div>
                        <form
                            aria-label="measurement form"
                            name="measurement form"
                            onSubmit={(e: SyntheticEvent) => e.preventDefault()}>
                            <TextInputWithLabel
                                labelText={t.room}
                                name="room"
                                ariaLabel="measurement room"
                                autocompleteList={t.measurementRoomsList}
                                initialValue={measurement.room}
                                error={inputErrors.room}
                                onChange={handleChange}/>
                            <div className="row margin">
                                <NumberInputWithLabel
                                    labelText={`${t.room} ${t.number}`}
                                    name="roomNumber"
                                    ariaLabel="measurement room number"
                                    initialValue={measurement.roomNumber}
                                    placeholder={`${t.enter} ${t.room.toLowerCase()} ${t.number}`}
                                    rowOfTwo={true}
                                    maxLength={4}
                                    onChange={handleChange}/>
                                <NumberInputWithLabel
                                    labelText={t.loggerNote}
                                    name="loggerNote"
                                    ariaLabel="measurement logger note"
                                    initialValue={measurement.loggerNote}
                                    placeholder={`${t.enter} ${t.loggerNote.toLowerCase()}`}
                                    rowOfTwo={true}
                                    maxLength={2}
                                    onChange={handleChange}/>
                            </div>
                            <div className="row margin">
                                <NumberInputWithLabel
                                    labelText={`${t.projected}`}
                                    name="projected"
                                    ariaLabel="measurement projected"
                                    initialValue={measurement.projected}
                                    placeholder={`${t.enter} ${t.projected.toLowerCase()}`}
                                    error={inputErrors.projected}
                                    rowOfTwo={true}
                                    strict={false}
                                    onChange={handleChangeProjectedAndMeasured}/>
                                <NumberInputWithLabel
                                    labelText={`${t.measured}`}
                                    name="measured"
                                    ariaLabel="measurement measured"
                                    initialValue={measurement.measured}
                                    placeholder={`${t.enter} ${t.measured.toLowerCase()}`}
                                    rowOfTwo={true}
                                    strict={false}
                                    onChange={handleChangeProjectedAndMeasured}/>
                            </div>
                            <div className="row margin">
                                <NumberInputWithLabel
                                    labelText={`${t.difference}`}
                                    name="diff"
                                    ariaLabel="measurement diff"
                                    initialValue={measurement.diff}
                                    rowOfTwo={true}
                                    isReadonly={true}/>
                                <NumberInputWithLabel
                                    labelText={`${t.difference} (%)`}
                                    name="percentDiff"
                                    ariaLabel="measurement percentDiff"
                                    initialValue={measurement.percentDiff}
                                    rowOfTwo={true}
                                    isReadonly={true}/>
                            </div>
                            <div className="column">
                                <div className="row margin toColumn">
                                    <label
                                        className="input-label"
                                        htmlFor="armature">
                                        Armature
                                    </label>
                                    <div className="autocomplete">
                                        <div className="input-container">
                                            <select
                                                className="input fit-container select"
                                                aria-label="measurement armature"
                                                name="armatureId"
                                                onChange={handleChange}>
                                                {!measurement.armatureId && <option value=""></option>}
                                                {armatures.map((armature) => (
                                                    <option
                                                        key={armature.id!!}
                                                        value={armature.id!!}>
                                                        {`${t.airflowDirectionList[armature.airflowType!!]} ${armature.model}`}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                </div>
                                <p className="input-error fixed" role="alert">{inputErrors.armature}</p>
                            </div>
                            <div className="row margin reverse">
                                <button
                                    className="filled"
                                    onClick={handleSave}>{initialMeasurement ? t.edit : t.add}</button>
                                {initialMeasurement && (
                                    <button
                                        className="outline"
                                        onClick={handleDelete}>{t.delete}</button>
                                )}
                            </div>
                            {error && (
                                <div className="row margin">
                                    <p className="error message">{error}</p>
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            )}
        </>
    );
}

export { MeasurementForm }
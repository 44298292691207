import {useStandardSetup} from "../../utils/customHooks";
import {EmployeeModel} from "../../models/Employee";
import useAdminApi from "../../API/useAdminApi";
import {useEffect, useState} from "react";
import {IntegratedSpinLoader} from "../common/SpinLoader";
import ErrorMessage from "../common/ErrorMessage";
import {useUserApi} from "../../API/useUserApi";
import {ConfirmationDialog, useDialog} from "../common/ConfirmationDialog";
import {requireCurrentLanguage} from "../../lang/locales/config";
import AdminEditEmployee from "./AdminEditEmployee";


enum Confirm {
    PASS,
    VAL
}

interface AdminUserEmployeesListProps {
    userId: string;
}

export default function AdminUserEmployeesList({ userId }: AdminUserEmployeesListProps) {
    const [
        loading, setLoading,
        error, setError,
        employees, setEmployees
    ] = useStandardSetup<EmployeeModel[]>();
    const [ selectedEmployee, setSelectedEmployee ] = useState<EmployeeModel>();
    const {
        isOpen,
        message: dialogMessage,
        setDialogMessage,
        toggle
    } = useDialog();
    const [ emailLang, setEmailLang ] = useState(requireCurrentLanguage());
    const [ confirmWhat, setConfirmWhat ] = useState(Confirm.PASS);
    const { getUserEmployees, putUserEmployee, resendRegisterEmployee } = useAdminApi();
    const { resetPassword } = useUserApi();

    useEffect(() => {
        if (!employees) {
            setLoading(true);
            getUserEmployees(userId)
                .then(setEmployees)
                .catch((e) => {
                    if (e instanceof Error) {
                        setError(e.message);
                    }
                })
                .finally(() => setLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employees]);

    const handleGetEmployee = (employee: EmployeeModel) => {
        setSelectedEmployee(employee);
    };

    const handleEditEmployee = (employee: EmployeeModel) => {
        setError("");
        setLoading(true);
        putUserEmployee(employee)
            .then(() => {
                setSelectedEmployee(undefined);
                setEmployees(undefined);
            })
            .catch((e) => {
                if (e instanceof Error) {
                    setError(e.message);
                }
            })
            .finally(() => setLoading(false));
    };

    const handleConfirmDialog = () => {
        if (confirmWhat === Confirm.PASS) {
            handleResetPassword();
        } else {
            handleResendValidationEmail();
        }
    };

    const gotoList = (reset=true) => {
        setSelectedEmployee(undefined);
        if (reset)
            setEmployees(undefined);
    }

    const onResetClick = () => {
        setDialogMessage(`Confirm reset password for employee - 
                          ${selectedEmployee!!.name} (${selectedEmployee!!.username})!`);
        setConfirmWhat(Confirm.PASS);
        toggle();
    };

    const onResendValClick = () => {
        setDialogMessage(`Confirm resend validation email to employee - 
                          ${selectedEmployee!!.name}!`);
        setConfirmWhat(Confirm.VAL);
        toggle();
    };

    const handleResetPassword = () => {
        if (selectedEmployee) {
            setLoading(true);
            resetPassword(selectedEmployee.email, emailLang)
                .then(() => {
                    gotoList();
                })
                .catch((e) => {
                    if (e instanceof Error) {
                        setError(e.message);
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    const handleResendValidationEmail = () => {
        if (selectedEmployee) {
            setLoading(true);
            resendRegisterEmployee(selectedEmployee, emailLang)
                .then(() => {
                    gotoList();
                })
                .catch((e) => {
                    if (e instanceof Error) {
                        setError(e.message);
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    return (
        <>
            {isOpen &&
                <ConfirmationDialog
                    toggle={toggle}
                    message={dialogMessage}
                    onConfirm={handleConfirmDialog} />
            }

            {!loading && !error && employees && !selectedEmployee &&
                <div className="table-container margin-padding">
                    <table className="table-with-content">
                        <thead>
                            <tr>
                                <th className="table-header-row">{"name"}</th>
                                <th className="table-header-row">{"description"}</th>
                                <th className="table-header-row">{"username"}</th>
                                <th className="table-header-row">{"email"}</th>
                                <th className="table-header-row">{"Phone"}</th>
                            </tr>
                        </thead>
                        <tbody>
                        {employees.map((employee) => (
                            <tr
                                className="table-row clickable"
                                key={employee.id}
                                onClick={() => handleGetEmployee(employee)}>
                                <td className="table-value">{employee.name}</td>
                                <td className="table-value">{employee.description}</td>
                                <td className="table-value">{employee.username}</td>
                                <td className="table-value">{employee.email}</td>
                                <td className="table-value">{employee.phone}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            }
            {selectedEmployee &&
                <AdminEditEmployee
                    initialEmployee={selectedEmployee}
                    onEditClick={handleEditEmployee}
                    onBack={() => gotoList(false)}
                    onResendVALClick={onResendValClick}
                    onResendPWClick={onResetClick}
                    emailLang={emailLang}
                    onChangeEmailLang={setEmailLang} />
            }

            <IntegratedSpinLoader
                loading={loading}
                loadMessage={""} />

            <ErrorMessage error={error} />
        </>
    );
}
import React, {useCallback, useRef, useState} from "react";

type UseTimerReturn = {
    count: number,
    setCount: React.Dispatch<React.SetStateAction<number>>
    startTimer: () => void;
    endTimer: () => void;
}

export default function useCountdown(): UseTimerReturn {
    const [count, setCount] = useState(0);
    const timerReference = useRef<NodeJS.Timer>();

    const startTimer = useCallback(() => {
        timerReference.current = setInterval(() => {
            setCount((prevCount) => prevCount - 1);
        }, 1000);
    }, []);

    const endTimer = useCallback(() => {
        clearInterval(timerReference.current);
        setCount(0);
    }, [])


    return { count, setCount, startTimer, endTimer };
}
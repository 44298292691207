interface TokenInit {
    access_token: string
}


export class Token {
    access_token: string = "";

    constructor(initializer?: TokenInit) {
        if (!initializer) return;
        if (initializer.access_token) {
            this.access_token = initializer.access_token;
        }
    }
}
interface SupportedLoggersModelInit {
    supportedLoggers: Array<string>;
}

export default class SupportedLoggersModel implements SupportedLoggersModelInit {
    supportedLoggers: Array<string>;

    constructor(init: SupportedLoggersModelInit) {
        this.supportedLoggers = init.supportedLoggers;
    }
}


interface SupportedLoggerAdminModel {
    name: string;
    filename: string;
}

interface SupportedLoggersAdminModelInit {
    supportedLoggers: Array<SupportedLoggerAdminModel>;
}

export class SupportedLoggersAdminModel {
    supportedLoggers: Array<SupportedLoggerAdminModel>;

    constructor(init: SupportedLoggersAdminModelInit) {
        this.supportedLoggers = init.supportedLoggers;
    }
}
import React from "react";
import {Link} from "react-router-dom";
import {SimplifiedClientCase} from "../../models/ClientCase";
import {localeDateFromTimestamp} from "../../utils/timestamp";


interface ClientCaseCardProps {
    clientCase: SimplifiedClientCase;
}

function ClientCaseCard({ clientCase }: ClientCaseCardProps) {
    return (
        <Link
            to="/detail"
            state={{ clientId: clientCase.id, clientName: clientCase.name }}
            className="unlink">
            <div className="card color-sec-background clickable">
                <h3 className="card-header">{clientCase.name}</h3>
                <p className="single-line">{clientCase.address}</p>
                <p className="single-line">{localeDateFromTimestamp(clientCase.date)}</p>
            </div>
        </Link>
    );
}

export default ClientCaseCard;
import requireTranslation from "../lang/locales/config";


export interface ResponseInterface {
    regarding: string,
    description: string,
    standard: boolean,
}

export class ResponseModel implements ResponseInterface {
    public regarding: string;
    public description: string;
    public standard: boolean;
    constructor(init: ResponseInterface) {
        this.regarding = init.regarding;
        this.description = init.description;
        this.standard = init.standard;
    }
}


export function statusMessage({regarding, description, standard,}: ResponseInterface): string {
    const t = requireTranslation();
    if (!standard) {
        if (t.responseMessage[description].includes("?"))
            return t.responseMessage[description].replace("?", (t[regarding] as string).toLowerCase());
        else
            return t.responseMessage[description]
    } else {
        return t.responseMessage[description]
    }
}
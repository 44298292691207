import requireTranslation from "../../lang/locales/config";
import {useStandardSetup} from "../../utils/customHooks";
import {WattAndCommentsModel} from "../../models/case/wattAndComments";
import {useWattAndCommentsApi} from "../../API/useClientCaseApi";
import {useEffect} from "react";
import {SpinLoader} from "../common/SpinLoader";
import ErrorMessage, {InactiveCheck} from "../common/ErrorMessage";
import {InformationRow} from "../common/TextCommons";
import WattAndCommentsForm from "./WattAndCommentsForm";

interface WattAndCommentDetailsProps {
    clientId: string;
    onEdit: (formElement: JSX.Element, statusMessage?: string) => void;
}


export default function WattAndCommentsDetails({ clientId, onEdit }: WattAndCommentDetailsProps) {
    const t = requireTranslation();
    const [
        loading, setLoading,
        error, setError,
        wattAndComments, setWattAndComments
    ] = useStandardSetup<WattAndCommentsModel>();
    const { getWattAndComments } = useWattAndCommentsApi();

    useEffect(() => {
        setLoading(true);
        getWattAndComments(clientId)
            .then(setWattAndComments)
            .catch((e) => {
                if (e instanceof Error) setError(e.message);
            })
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientId])

    return (
        <>
            <SpinLoader
                containerClassName="content color-main-background"
                loading={loading}
                loadMessage={`${t.loading} ${t.wattAndComments.toLowerCase()}...`} />
            <ErrorMessage
                error={error}
                containerClassName="content color-main-background" />

            {!loading && !error && wattAndComments && (
                <div className="content fold-out color-main-background">
                    <h3 className="card-header">{`${t.wattAndComments}`}</h3>
                    <InformationRow title={`${t.watts}:`} text={wattAndComments.watts!!} />
                    <div className="row margin vertical-expandable toColumn">
                        <p className="title-line fill-half italic">{`${t.comments}:`}</p>
                        <p className="multiline-box text-justify">{wattAndComments.comments}</p>
                    </div>
                    <div className="row margin reverse">
                        <button
                            className="filled"
                            onClick={() => onEdit(
                                <InactiveCheck>
                                    <WattAndCommentsForm
                                        initialWattAndComment={wattAndComments}
                                        onSave={onEdit}
                                        haveBackButton={true} />
                                </InactiveCheck>
                            )}>
                            {t.edit}
                        </button>
                    </div>
                </div>
            )}
        </>
    )
}
import {NewsCollectionGet} from "../../models/News";

interface AdminNewsListProps {
    newsList: NewsCollectionGet[];
    onNewsClick: (news: NewsCollectionGet) => void;
}

export default function AdminNewsList(
    {
        newsList,
        onNewsClick
    }: AdminNewsListProps) {
    return (
        <>
            {newsList &&
                <div className="table-container margin-padding">
                    <table className="table-with-content">
                        <thead>
                        <tr>
                            <th className="table-header-row">{"title"}</th>
                            <th className="table-header-row">{"created"}</th>
                            <th className="table-header-row">{"last edit"}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {newsList.map((news) => (
                            <tr
                                className="table-row clickable"
                                key={news.id}
                                onClick={() => onNewsClick(news)}>
                                <td className="table-value">{news.defaultTitle}</td>
                                <td className="table-value">{news.defaultCreated}</td>
                                <td className="table-value">{news.defaultLastEdit}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            }
        </>
    )
}
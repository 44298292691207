interface SupportedLanguagesInit {
    supportedLanguages: string[];
}

export class SupportedLanguages {
    supportedLanguages: string[];

    constructor(init: SupportedLanguagesInit) {
        this.supportedLanguages = init.supportedLanguages;
    }
}
import {Content, ContentForm} from "../common/ContentStandards";
import {useStandardSetup} from "../../utils/customHooks";
import {UnsupportedLoggerFile} from "../../models/case/loggerNoteFile";
import {useEffect, useState} from "react";
import useAdminApi from "../../API/useAdminApi";
import {IntegratedSpinLoader} from "../common/SpinLoader";
import ErrorMessage from "../common/ErrorMessage";
import {InformationRow} from "../common/TextCommons";
import AdminUnsupportedLoggerSupportConstruct from "./AdminUnsupportedLoggerSupportConstruct";


interface LoggerFileDetailsProps {
    loggerId: string;
    onBack: () => void;
}

export default function AdminUnsupportedLoggerFileDetails(
    { loggerId, onBack }: LoggerFileDetailsProps
) {
    const [
        loading, setLoading,
        error, setError,
        loggerFile, setLoggerFile
    ] = useStandardSetup<UnsupportedLoggerFile>();
    const { getUnsupportedLoggerFile } = useAdminApi();

    useEffect(() => {
        setLoading(true);
        getUnsupportedLoggerFile(loggerId)
            .then(setLoggerFile)
            .catch((e) => {
                if (e instanceof Error) {
                    setError(e.message);
                }
            })
            .finally(() => setLoading(false));
    }, [loggerId]);

    return (
        <Content invisible={true} flexGrow={true}>
            <>
                {loggerFile &&
                    <>
                        <div className="row">
                            <img
                                className="icon-button"
                                src={"back_arrow.svg"}
                                alt={"Back"}
                                onClick={() => onBack()}/>
                            <h3 className="content-headline">
                                {loggerFile.deviceName}
                            </h3>
                        </div>

                        <ContentForm name="AdminLoggerFile">
                            <InformationRow
                                title={"Device Name"}
                                text={loggerFile.deviceName} />
                            <InformationRow
                                title={"Device Company"}
                                text={loggerFile.deviceCompany} />
                            <InformationRow
                                title={"Filename"}
                                text={loggerFile.filename} />
                            <InformationRow
                                title={"Mime Type"}
                                text={loggerFile.mimeType} />
                            <InformationRow
                                title={"Encoding"}
                                text={loggerFile.encoding} />
                            <div className="row reverse margin">
                                <a
                                    className="title-line"
                                    href={loggerFile.url}
                                    download={`${loggerFile.filename}`}>Download File</a>
                            </div>
                            <hr/>
                            <AdminUnsupportedLoggerSupportConstruct
                                loggerFile={loggerFile}
                                onBack={onBack} />
                        </ContentForm>
                    </>
                }
            </>

            <IntegratedSpinLoader
                loading={loading}
                loadMessage={""} />

            <ErrorMessage error={error} />
        </Content>
    )
}
import requireTranslation from "../../lang/locales/config";
import {EmployeeModel} from "../../models/Employee";
import EmployeeList from "./EmployeeList";
import React, {useEffect, useState} from "react";
import {MenuButton, OverlayMenu, StandardMenu, useMenu, useOverlayMenu} from "../common/OverlayMenu";
import EmployeeUserForm from "./EmployeeUserForm";
import {useAppSelector} from "../../state/hooks";
import {selectUser} from "../../state/userSlice";

export default function EmployeesPage() {
    const t = requireTranslation();
    const user = useAppSelector(selectUser);
    const [ shownContent, setShownContent ] = useState<JSX.Element>();
    const [ highlightList, setHighlightIndex ] = useMenu(2);
    const [ isFloatingMenuShow, toggleMenu ] = useOverlayMenu();
    const [ toastMessage, setToastMessage ] = useState<string | undefined>();
    const [ addAllowed, setAddAllowed ] = useState(false);

    useEffect(() => {
        if (!shownContent) {
            handleToEmployeeList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shownContent])

    useEffect(() => {
        setTimeout(() => { setToastMessage(undefined); }, 3000)
    }, [toastMessage]);

    const currentEmployeeCount = (count: number) => {
        setAddAllowed(user.maxEmployees > count);
    }

    const handleToEmployeeForm = (employee: EmployeeModel) => {
        setShownContent(<EmployeeUserForm
            initialEmployee={employee}
            onDone={handleToEmployeeList}/>);
    }

    const handleToEmployeeList = (statusMessage?: string) => {
        setHighlightIndex(0);
        setShownContent(<EmployeeList
            onCardClick={handleToEmployeeForm}
            currentEmployeeCount={currentEmployeeCount} />);

        if (statusMessage) setToastMessage(statusMessage);
    };

    const handleMenuButtonClick = (index: number, content: JSX.Element) => {
        setHighlightIndex(index);
        setShownContent(content);
    };


    const menuButtons = () => (
      <>
          <MenuButton
              text={t.employees}
              onClick={() => {handleMenuButtonClick(0,
                  <EmployeeList
                      onCardClick={handleToEmployeeForm}
                      currentEmployeeCount={currentEmployeeCount}/>)}}
              isHighlight={highlightList[0]} />
          {addAllowed &&
              <MenuButton
                  text={t.add}
                  onClick={() => {
                      handleMenuButtonClick(1,
                          <EmployeeUserForm
                              onDone={handleToEmployeeList}/>)
                  }}
                  isHighlight={highlightList[1]}/>
          }
      </>
    );

    return (
        <div className="page-menu-with-content-container center-content">
            {toastMessage && (
                <div className="snackbar">{toastMessage}</div>
            )}

            <div className="content-control-menu-show-button" onClick={toggleMenu}>
                <img src={"miniMenu.svg"} className="icon" alt="menu"/>
            </div>

            <OverlayMenu
                isVisible={isFloatingMenuShow}
                toggle={toggleMenu}
                ButtonList={menuButtons} />

            <StandardMenu MenuButtons={menuButtons} />

            <div className="content-container">
                {shownContent}
            </div>
        </div>
    )
}
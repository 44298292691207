import {localeTimeFromUtcTimestamp} from "../utils/timestamp";

interface NewsModelInit {
    id: string | null;
    title: string;
    text: string;
    language: string;
    created: number;
    lastEdit: number;
    createdBy: string;
    collectionId: string | null;
}

export class NewsModel implements NewsModelInit {
    id: string | null = null;
    title: string = "";
    text: string = "";
    language: string = "";
    created: number = Date.now() / 1000;
    lastEdit: number = Date.now() / 1000;
    createdBy: string = "";
    collectionId: string | null = null;

    constructor(init?: NewsModelInit) {
        if (!init) return;

        this.id = init.id;
        this.title = init.title;
        this.text = init.text;
        this.language = init.language;
        this.created = init.created;
        this.lastEdit = init.lastEdit;
        this.createdBy = init.createdBy;
        this.collectionId = init.collectionId;
    }
}

interface NewsCollectionInit {
    id: string | null;
    newsCollection: NewsModel[];
}

export class NewsCollectionModel {
    id: string | null = null;
    newsCollection: NewsModel[] = [];

    constructor(init?: NewsCollectionInit) {
        if (!init) return;

        this.id = init.id;
        this.newsCollection = init.newsCollection;
    }
}

interface NewsCollectionGetInit {
    id: string;
    newsIds: string[];
    defaultTitle: string;
    defaultLastEdit: number;
    defaultCreated: number;
    supportedLanguages: string[];
}

export class NewsCollectionGet {
    id: string;
    newsIds: string[];
    defaultTitle: string;
    defaultLastEdit: string;
    defaultCreated: string;
    supportedLanguages: string[];

    constructor(init: NewsCollectionGetInit) {
        this.id = init.id;
        this.newsIds = init.newsIds;
        this.defaultTitle = init.defaultTitle;
        this.defaultLastEdit = localeTimeFromUtcTimestamp(init.defaultLastEdit);
        this.defaultCreated = localeTimeFromUtcTimestamp(init.defaultCreated);
        this.supportedLanguages = init.supportedLanguages;
    }
}

export interface NewsDeleteModel {
    collectionId: string;
    passphrase: string;
}
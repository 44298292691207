import {SpinLoader} from "../common/SpinLoader";
import requireTranslation from "../../lang/locales/config";
import {useStandardSetup} from "../../utils/customHooks";
import {InstallationModel} from "../../models/case/installation";
import {useInstallationApi} from "../../API/useClientCaseApi";
import {useEffect} from "react";
import ErrorMessage, {InactiveCheck} from "../common/ErrorMessage";
import {InformationRow} from "../common/TextCommons";
import InstallationForm from "./InstallationForm";

interface InstallationDetailsProps {
    clientId: string;
    onEdit: (formElement: JSX.Element,
             statusMessage?: string,
             newClientName?: string,
             shouldRefreshStatus?: boolean) => void;
}

export default function InstallationDetails({ clientId, onEdit }: InstallationDetailsProps) {
    const t = requireTranslation();
    const [
        loading, setLoading,
        error, setError,
        installation, setInstallation
    ] = useStandardSetup<InstallationModel>();
    const { getInstallation } = useInstallationApi();

    useEffect(() => {
        setLoading(true);
        getInstallation(clientId)
            .then((data) => setInstallation(data))
            .catch((e) => {
                if (e instanceof Error) setError(e.message);
            })
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientId]);

    return (
        <>
            <SpinLoader
                containerClassName="content color-main-background"
                loading={loading}
                loadMessage={`${t.loading} `} />
            <ErrorMessage
                error={error}
                containerClassName={"content color-main-background"} />
            {!loading && !error && installation && (
                <div className="content fold-out color-main-background">
                    <h3 className="card-header">{t.installationInformation}</h3>
                    <InformationRow title={`${t.installation}:`} text={installation.installation} />
                    <InformationRow title={`${t.installationId}:`} text={installation.installationId} />
                    <InformationRow title={`${t.placement}:`} text={installation.placement} />
                    <InformationRow title={`${t.control}`} text={installation.control} />
                    <InformationRow title={`${t.preheatingSurface}:`} text={installation.preheatingSurface} />
                    <InformationRow title={`${t.filterType}`} text={installation.filterType} />
                    <div className="row margin reverse">
                        <button
                            className="filled"
                            onClick={() => {onEdit(
                                <InactiveCheck>
                                    <InstallationForm
                                        initialInstallation={installation}
                                        onSave={onEdit}
                                        haveBackButton={true} />
                                </InactiveCheck>
                            )}}>
                            {t.edit}</button>
                    </div>
                </div>
            )}
        </>
    )
}
interface CheckResult {
    loggerNumber: number;
    measurementValue: number;
    loggerValue: number
    result: boolean;
}


interface LoggerNoteCheckInit {
    loggerNotesCount: number;
    results: Array<CheckResult>;
    overallResult: boolean;
}

export default class LoggerNoteCheckModel implements LoggerNoteCheckInit {
    loggerNotesCount: number;
    results: Array<CheckResult>;
    overallResult: boolean;

    constructor(init: LoggerNoteCheckInit) {
        this.loggerNotesCount = init.loggerNotesCount;
        this.results = init.results;
        this.overallResult = init.overallResult;
    }
}
export class ReportFileModel {
    mimeType: string;
    filename: string;
    currentOption: string;
    editOptions: string[];
    url: string;
    blob: Blob;

    constructor(blob: Blob, header: Headers | undefined) {
        if (!header) {
            throw Error("ReportFileModel -- setup wrong. This model should get the header! ");
        }
        const mimeType = header.get("content-type");
        const filename = header.get("x-filename");
        const editOptions = header.get("x-edit-options");
        const currentOption = header.get("x-current-option");

        if (!mimeType || !filename || !editOptions || !currentOption) {
            throw Error("ReportFileModel -- header does not include one of the following: " +
                "content-type, x-filename!");
        }

        this.mimeType = mimeType;
        this.filename = filename;
        this.currentOption = currentOption;
        this.editOptions = editOptions.split(",");
        this.url = URL.createObjectURL(blob);
        this.blob = blob
    }
}
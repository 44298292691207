import {Content, ContentForm, PageWithContent} from "../common/ContentStandards";
import requireTranslation from "../../lang/locales/config";
import {ChangeEvent, useEffect, useState} from "react";
import {useScreenSize} from "../utils/Screen";
import {FileInputWithLabel, TextAreaWithLabel, TextInputWithLabel} from "../common/FormCommons";
import {isInputErrorsEmpty, validateRequired, validateTextArea} from "../../utils/inputValidation";
import {useUserApi} from "../../API/useUserApi";
import {OverlaySpinLoader, useSpinLoader} from "../common/SpinLoader";
import {useAppSelector} from "../../state/hooks";
import {selectUser} from "../../state/userSlice";
import {useNavigate} from "react-router-dom";
import {BalancingFrontpageModel, FileModel} from "../../models/user/balancingFrontpage";
import ErrorMessage from "../common/ErrorMessage";
import {ConfirmationDialog, useDialog} from "../common/ConfirmationDialog";

interface InputErrorInter {
    headline: string;
    greeting: string;
    body: string;
    farewell: string;
}

export default function UploadFrontPage() {
    const t = requireTranslation();
    const user = useAppSelector(selectUser);
    const screenSize = useScreenSize();
    const navigate = useNavigate();
    const [
        awaiting, setAwaiting,
        awaitingMessage, setAwaitingMessage
    ] = useSpinLoader();
    const {
        isOpen,
        message,
        setDialogMessage,
        toggle
    } = useDialog();
    const initialInputErrors: InputErrorInter = {
        headline: "",
        greeting: "",
        body: "",
        farewell: ""
    };
    const [ inputErrors, setInputErrors ] = useState<InputErrorInter>(initialInputErrors);
    const [ previewOverlay, setPreviewOverlay ] = useState(false);
    const [ showPreviewDialog, setShowPreviewDialog ] = useState(false);
    const [ pdfInput, setPdfInput ] = useState(new BalancingFrontpageModel())
    const [ errorMessage, setErrorMessage ] = useState("");
    const {
        getBalancingFrontpage,
        getBalancingFrontpageLogo,
        getBalancingFrontpageHeader,
        postBalancingFrontpage,
        putBalancingFrontpage,
        deleteBalancingFrontpage,
        updateUserModel
    } = useUserApi();

    useEffect(() => {
        if (screenSize) {
            if (screenSize.screenWidth <= 1330) {
                setPreviewOverlay(true);
            } else {
                setPreviewOverlay(false);
            }
        }
    }, [screenSize]);

    useEffect(() => {
        if (user.hasBalancingFrontpage) {
            setAwaiting(true);
            setAwaitingMessage(t.loadingMessages.fetchInformation);
            getBalancingFrontpage()
                .then((value) => {
                    setPdfInput(value);
                    if (value.hasLogo) {
                        getBalancingFrontpageLogo()
                            .then((logoData) => {
                                setPdfInput((prevState) =>
                                    new BalancingFrontpageModel(prevState, logoData, prevState.header));
                            })
                            .catch((e) => {
                                if (e instanceof Error) setErrorMessage(e.message)
                            });
                    }
                    if (value.hasHeader) {
                        getBalancingFrontpageHeader()
                            .then((headerData) => {
                                setPdfInput((prevState) =>
                                    new BalancingFrontpageModel(prevState, prevState.logo, headerData));
                            })
                            .catch((e) => { if (e instanceof Error) setErrorMessage(e.message)});
                    }
            })
                .catch((e) => { if (e instanceof Error) setErrorMessage(e.message)})
                .finally(() => setAwaiting(false));
        }
    }, [user.hasBalancingFrontpage])

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const change = { [name]: value };

        setPdfInput((prevState) => {
            return new BalancingFrontpageModel(
                { ...prevState, ...change },
                prevState.logo,
                prevState.header
            );
        });
    };

    const handleLogoChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (pdfInput.logo.url) {
            URL.revokeObjectURL(pdfInput.logo.url);
            setPdfInput((prevState) => {
                return new BalancingFrontpageModel(
                    prevState,
                    new FileModel(),
                    prevState.header
                );
            });
        }

        if (event.target.files) {
            const file = event.target.files[0];

            setPdfInput((prevState) => {
                return new BalancingFrontpageModel(
                    prevState,
                    new FileModel(undefined, undefined, file),
                    prevState.header
                );
            });
        }
    };

    const handleHeaderChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (pdfInput.header.url) {
            URL.revokeObjectURL(pdfInput.header.url);
            setPdfInput((prevState) =>
                new BalancingFrontpageModel(prevState, prevState.logo, new FileModel())
            );
        }

        if (event.target.files) {
            const file = event.target.files[0];
            setPdfInput((prevState) => {
                return new BalancingFrontpageModel(
                    prevState,
                    prevState.logo,
                    new FileModel(undefined, undefined, file)
                );
            });
        }
    };

    const handlePreviewPdf = () => {
        setShowPreviewDialog(true);
    };

    const validateInput = (): boolean => {
        const err = initialInputErrors;

        err.headline = validateRequired(pdfInput.headline);
        err.greeting = validateRequired(pdfInput.greeting);
        err.body = validateTextArea(pdfInput.body, true);
        err.farewell = validateRequired(pdfInput.farewell);

        setInputErrors(err);

        return isInputErrorsEmpty(err);
    }

    const handleUpload = () => {
        if (validateInput()) {
            const frontpageData = pdfInput.getAsFormData();

            setAwaiting(true);
            setAwaitingMessage(t.uploading);
            postBalancingFrontpage(frontpageData)
                .then(() => {
                    updateUserModel()
                        .then(() => navigate(
                            "/overview",
                            {state: {statusMessage: t.messages.successUpload}}
                        ));
                })
                .catch((e) => {
                    if (e instanceof Error) {
                        if (e.message === t.responseMessage.errorPost) {
                            handleEdit();
                        } else {
                            setErrorMessage(e.message);
                        }
                    }
                })
                .finally(() => setAwaiting(false))
        }
    };

    const handleEdit = () => {
        if (validateInput()) {
            const frontpageData = pdfInput.getAsFormData();

            setAwaiting(true);
            setAwaitingMessage(t.uploading);
            putBalancingFrontpage(frontpageData)
                .then(() => {
                    updateUserModel()
                        .then(() => navigate(
                            "/overview",
                            {state: {statusMessage: t.messages.successEdit}}
                        ));
                })
                .catch((e) => {
                    if (e instanceof Error) {
                        if (e.message === t.responseMessage.errorPut) {
                            handleUpload();
                        } else {
                            setErrorMessage(e.message);
                        }
                    }
                })
                .finally(() => setAwaiting(false))
        }
    }

    const handleDelete = () => {
        setAwaiting(true);
        setAwaitingMessage(t.loadingMessages.deletingCQM.replace("?", t.firmFrontPage.toLowerCase()))
        deleteBalancingFrontpage()
            .then(() => {
                updateUserModel()
                    .then(() => navigate(
                        "/overview",
                        {state: {statusMessage: t.messages.successDelete}}
                    ));
            })
            .catch((e) => {
                if (e instanceof Error) {
                    setErrorMessage(e.message);
                }
            })
            .finally(() => setAwaiting(false))
    };

    const handleOpenDeleteDialog = () => {
        setDialogMessage(t.messages.confirmDelete + t.balancingFrontpage.toLowerCase() + "?");
        toggle();
    };

    const handleRemoveFileChoice = (name: string) => {
        if (name === "headerURL") {
            URL.revokeObjectURL(pdfInput.header.url);
            setPdfInput((prevState) =>
                new BalancingFrontpageModel(prevState, prevState.logo, undefined));
        } else {
            URL.revokeObjectURL(pdfInput.logo.url);
            setPdfInput((prevState) =>
                new BalancingFrontpageModel(prevState, undefined, prevState.header));
        }
    };


    return (
        <PageWithContent>
            <OverlaySpinLoader
                loading={awaiting}
                loadMessage={awaitingMessage} />

            <ErrorMessage error={errorMessage} />

            <>
                {isOpen &&
                    <ConfirmationDialog
                        toggle={toggle}
                        message={message}
                        onConfirm={handleDelete}/>
                }
            </>

            <>
                {previewOverlay && showPreviewDialog &&
                    <div className="dialog-overlay" onClick={() => setShowPreviewDialog(false)}>
                        <div className="preview-pdf">
                            {pdfInput.header.url && (
                                <img className="preview-pdf-header" src={pdfInput.header.url} alt="header" />
                            )}
                            {pdfInput.logo.url ?
                                <div className="row">
                                    <img className="preview-logo handheld" src={pdfInput.logo.url} alt="logo" />
                                    <div>
                                        <h1
                                            className="content-headline available top-padding">
                                            {pdfInput.headline}
                                        </h1>
                                        <h2 className="content-headline centered">{pdfInput.subtitle}</h2>
                                        <h3 className="content-headline centered">{`{%${t.installation}%}`}</h3>
                                        <h3 className="content-headline centered">{`{%${t.address}%}`}</h3>
                                    </div>
                                </div> :
                                <>
                                    <h1 className="content-headline centered top-padding">{pdfInput.headline}</h1>
                                    <h2 className="content-headline centered">{pdfInput.subtitle}</h2>
                                    <h3 className="content-headline centered">{`{%${t.installation}%}`}</h3>
                                    <h3 className="content-headline centered">{`{%${t.address}%}`}</h3>
                                </>
                            }
                            <h4/>
                            <h4 className="content-headline">{t.informationTexts.frontpageEmployeeAndDate}</h4>

                            <p className="content-headline top-padding">{`${pdfInput.greeting} {%${t.client}%},`}</p>
                            <p className="support-linebreak adjusted-to-pdf">{pdfInput.body}</p>
                            <p className="support-linebreak adjusted-to-pdf">{`${pdfInput.farewell}\n{%${t.owner}%}\n${pdfInput.ownerTitle}`}</p>
                        </div>
                    </div>
                }
            </>
            <>{!errorMessage &&
                <Content>
                    <div className="content-list">
                        <Content
                            headline={t.frontPageInformation}
                            invisible={true}
                            flexGrow={true}
                            infoIconText={t.informationTexts.uploadFrontpageInformation}>
                            <ContentForm>
                                <TextInputWithLabel
                                    labelText={`${t.headline}*`}
                                    name="headline"
                                    ariaLabel="uploadFrontpageHeadline"
                                    initialValue={pdfInput.headline}
                                    error={inputErrors.headline}
                                    onChange={handleChange}/>
                                <TextInputWithLabel
                                    labelText={t.subtitle}
                                    name="subtitle"
                                    ariaLabel="uploadFrontpageSubtitle"
                                    initialValue={pdfInput.subtitle}
                                    onChange={handleChange}/>
                                <TextInputWithLabel
                                    labelText={`${t.greeting}*`}
                                    name="greeting"
                                    ariaLabel="uploadFrontpageGreeting"
                                    initialValue={pdfInput.greeting}
                                    error={inputErrors.greeting}
                                    onChange={handleChange}/>
                                <TextAreaWithLabel
                                    labelText={`${t.body}*`}
                                    name="body"
                                    ariaLabel="uploadFrontpageBody"
                                    initialValue={pdfInput.body}
                                    error={inputErrors.body}
                                    onChange={handleChange}/>
                                <TextInputWithLabel
                                    labelText={`${t.farewell}*`}
                                    name="farewell"
                                    ariaLabel="uploadFrontpageFarewell"
                                    initialValue={pdfInput.farewell}
                                    error={inputErrors.farewell}
                                    onChange={handleChange}/>
                                <TextInputWithLabel
                                    labelText={t.ownerTitle}
                                    name="ownerTitle"
                                    ariaLabel="uploadFrontpageOwnerTitle"
                                    initialValue={pdfInput.ownerTitle}
                                    onChange={handleChange}/>
                                <FileInputWithLabel
                                    ariaLabel="UploadFrontpageFormLogo"
                                    accepted=".png, .jpeg, .jpg"
                                    name="logo"
                                    labelText={t.logo}
                                    buttonText={t.chooseFile}
                                    uploadFileText={pdfInput.logo.filename}
                                    onChange={handleLogoChange}
                                    onRemove={pdfInput.logo.url ?
                                        () => handleRemoveFileChoice("logoURL") :
                                        undefined}/>
                                <FileInputWithLabel
                                    ariaLabel="UploadFrontpageFormHeader"
                                    accepted=".png, .jpeg, .jpg"
                                    name="header"
                                    labelText={t.header}
                                    buttonText={t.chooseFile}
                                    uploadFileText={pdfInput.header.filename}
                                    onChange={handleHeaderChange}
                                    onRemove={pdfInput.header.url ?
                                        () => handleRemoveFileChoice("headerURL") :
                                        undefined}/>
                                <div className="row margin reverse">
                                    <button
                                        className="filled border-solid"
                                        onClick={user.hasBalancingFrontpage ? handleEdit : handleUpload}>
                                        {user.hasBalancingFrontpage ? t.edit : t.upload}
                                    </button>
                                    {previewOverlay &&
                                        <button
                                            className="outline"
                                            onClick={handlePreviewPdf}>
                                            {t.viewPdf}
                                        </button>
                                    }
                                    {user.hasBalancingFrontpage && !previewOverlay &&
                                        <button
                                            className="outline"
                                            onClick={handleOpenDeleteDialog}>
                                            {t.delete}
                                        </button>
                                    }
                                </div>
                                <div className="row reverse">
                                    {user.hasBalancingFrontpage && previewOverlay &&
                                        <button
                                            className="outline"
                                            onClick={handleOpenDeleteDialog}>
                                            {t.delete}
                                        </button>
                                    }
                                </div>
                            </ContentForm>
                        </Content>
                        {!previewOverlay &&
                            <div className="preview-pdf-container">
                                <h3 className="title-line no-margin">{t.preview}</h3>
                                <div className="preview-pdf">
                                    {pdfInput.header.url && (
                                        <img className="preview-pdf-header" src={pdfInput.header.url} alt="header" />
                                    )}
                                    {pdfInput.logo.url ?
                                        <div className="row">
                                            <img className="preview-logo" src={pdfInput.logo.url} alt="logo" />
                                            <div>
                                                <h1
                                                    className="content-headline available top-padding">
                                                    {pdfInput.headline}
                                                </h1>
                                                <h2 className="content-headline centered">{pdfInput.subtitle}</h2>
                                                <h3 className="content-headline centered">{`{%${t.installation}%}`}</h3>
                                                <h3 className="content-headline centered">{`{%${t.address}%}`}</h3>
                                            </div>
                                        </div> :
                                        <>
                                            <h1 className="content-headline centered top-padding">{pdfInput.headline}</h1>
                                            <h2 className="content-headline centered">{pdfInput.subtitle}</h2>
                                            <h3 className="content-headline centered">{`{%${t.installation}%}`}</h3>
                                            <h3 className="content-headline centered">{`{%${t.address}%}`}</h3>
                                        </>
                                    }
                                    <h4/>
                                    <h4 className="content-headline">{t.informationTexts.frontpageEmployeeAndDate}</h4>

                                    <p className="content-headline top-padding">{`${pdfInput.greeting} {%${t.client}%},`}</p>
                                    <p className="support-linebreak adjusted-to-pdf">{pdfInput.body}</p>
                                    <p className="support-linebreak adjusted-to-pdf">{`${pdfInput.farewell}\n{%${t.owner}%}\n${pdfInput.ownerTitle}`}</p>
                                </div>
                            </div>
                        }
                    </div>
                </Content>
            }</>
        </PageWithContent>
    )
}
import {SupportedDeviceTemplate, UnsupportedLoggerFile} from "../../models/case/loggerNoteFile";
import {SelectFieldWithLabel, TextInputWithLabel} from "../common/FormCommons";
import useAdminApi from "../../API/useAdminApi";
import {useStandardSetup} from "../../utils/customHooks";
import {SupportedLoggersAdminModel} from "../../models/case/supportedLoggers";
import React, {ChangeEvent, useEffect, useState} from "react";
import {IntegratedSpinLoader} from "../common/SpinLoader";
import LoggerNoteModel from "../../models/case/loggerNote";
import AdminLoggerConstructTestResults, {AdminLoggerConstructUploadResults} from "./AdminLoggerConstructTestResults";
import {ConfirmationDialog, useDialog} from "../common/ConfirmationDialog";
import {LoggerDeviceAddResults} from "../../models/case/LoggerDevice";
import ErrorMessage from "../common/ErrorMessage";

interface ConstructProps {
    loggerFile: UnsupportedLoggerFile;
    onBack: (updateList?: boolean) => void;
}

/**
 * This is the under section of AdminUnsupportedLoggerFileDetails, where it is
 * possible to construct a new logger support.
 *
 * @param loggerFile - the unsupported logger file from details
 * @param onBack - Function that returns the admin to the UnsupportedLoggerList.
 */
export default function AdminUnsupportedLoggerSupportConstruct(
    { loggerFile, onBack }: ConstructProps
) {
    const [
        loading, setLoading,
        error, setError,
        supportedDevices, setSupportedDevices
    ] = useStandardSetup<SupportedLoggersAdminModel>();
    const [ template, setTemplate ] = useState<SupportedDeviceTemplate>();
    const [ initialTemplate, setInitialTemplate ] = useState<SupportedDeviceTemplate>();
    const [ templateFilename, setTemplateFilename ] = useState("");
    const [ testResults, setTestResults ] = useState<LoggerNoteModel[]>();
    const [ uploadResults, setUploadResults ] = useState<LoggerDeviceAddResults>();
    const [ isDefaultTemplate, setIsDefaultTemplate ] = useState(true);
    const [ initialChosenName, setInitialChosenName ] = useState("");
    const [ chosenName, setChosenName ] = useState(loggerFile.deviceName.replace(" ", "") + ".json");
    const [ deviceToAdd, setDeviceToAdd ] = useState(loggerFile.deviceName);
    const [ testMessage, setTestMessage ] = useState("");
    const [ dialogCallback, setDialogCallback ] = useState<() => void>();
    const {
        isOpen,
        message: dialogMessage,
        setDialogMessage,
        toggle: toggleDialog} = useDialog();
    const {
        getSupportedDevices,
        getSupportedDeviceTemplate,
        postSupportedDeviceTest,
        postAddDeviceToExisting,
        postSupportedDevice
    } = useAdminApi();

    useEffect(() => {
        if (!supportedDevices) {
            setLoading(true);
            getSupportedDevices()
                .then(setSupportedDevices)
                .catch((e) => {
                    if (e instanceof Error) {
                        setError(e.message);
                    }
                })
                .finally(() => setLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supportedDevices]);

    useEffect(() => {
        if (!template || templateFilename) {
            setLoading(true);
            getSupportedDeviceTemplate(templateFilename)
                .then((loadedTemplate) => {
                    setTemplate(loadedTemplate);
                    setInitialTemplate(loadedTemplate);
                })
                .catch((e) => {
                    if (e instanceof Error) {
                        setError(e.message);
                    }
                })
                .finally(() => {
                    setTemplateFilename("");
                    setLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template, templateFilename]);

    const handleSupportedDeviceChange = (event: any) => {
        const { value } = event.target;

        // eslint-disable-next-line array-callback-return
        const object = supportedDevices!!.supportedLoggers.find((model) => {
            if (model.name === value) {
                return model;
            }
        });

        const filename = object ? object.filename : "template.json"

        setTemplateFilename(filename);
        setInitialChosenName(filename === "template.json" ? "" : filename);
        setChosenName(filename === "template.json" ?
            loggerFile.deviceName.replace(" ", "") + ".json" : filename);
        setIsDefaultTemplate(filename === "template.json");
    }

    const handleChangeTemplate = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const { value, name } = event.target;

        setTemplate((prevState) => {
            return new SupportedDeviceTemplate({ ...prevState!!, ...{[name]: value} })
        })
    };

    const handleTestTemplate = () => {
        setLoading(true);
        setTestMessage("");
        postSupportedDeviceTest(template!!.jsonString, loggerFile.id)
            .then(setTestResults)
            .catch((e) => {
                if (e instanceof Error) {
                    setTestMessage(e.message);
                }
            })
            .finally(() => setLoading(false));
    }

    const handleOpenDialog = (message: string, callback: () => void) => {
        setDialogMessage(message);
        setDialogCallback(callback);
    };

    const handleUploadNewTemplate = () => {
        setLoading(true);
        postSupportedDevice(template!!.jsonString, chosenName)
            .then(setUploadResults)
            .catch((e) => {
                if (e instanceof Error) {
                    setTestMessage(e.message);
                }
            })
            .finally(() => setLoading(false));
    };

    const handleAddDeviceToExisting = () => {
        setLoading(true);
        postAddDeviceToExisting(deviceToAdd, chosenName)
            .then(setUploadResults)
            .catch((e) => {
                if (e instanceof Error) {
                    setTestMessage(e.message);
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <div className="content invisible auto-fit">
            {isOpen && dialogCallback &&
                <ConfirmationDialog
                    toggle={toggleDialog}
                    message={dialogMessage}
                    onConfirm={dialogCallback} />
            }
            {supportedDevices && !error && (
                <SelectFieldWithLabel
                    labelText={"Browse Existing Extractor Files"}
                    selection={supportedDevices.supportedLoggers.map((model) => {
                        return model.name;
                    }).concat("")}
                    initialValue={templateFilename}
                    onChange={handleSupportedDeviceChange}/>
            )}
            {template && !error && (
                <textarea
                    className="input text-area adjust-to-content"
                    name="jsonString"
                    value={template.jsonString}
                    onChange={handleChangeTemplate}/>
            )}
            {!error && (
                <>
                    <TextInputWithLabel
                        labelText={"Choose filename"}
                        initialValue={chosenName}
                        onChange={(e) => {
                            setChosenName(e.target.value);
                        }}/>
                    {!isDefaultTemplate &&
                        <TextInputWithLabel
                            labelText={"Device To Add"}
                            initialValue={deviceToAdd}
                            onChange={(e) => {
                                setDeviceToAdd(e.target.value);
                            }}/>
                    }

                    <div className="row reverse margin">
                        <button
                            className="filled"
                            onClick={handleTestTemplate}>
                            Run Test
                        </button>
                        {testResults && initialChosenName !== chosenName && initialTemplate?.jsonString !== template?.jsonString &&
                            <button
                                className="outline"
                                onClick={() => handleOpenDialog(
                                    "Have you changed filename accordingly and changed template device names with the actual device name?",
                                    handleUploadNewTemplate
                                )}>
                                Upload
                            </button>
                        }
                        {!isDefaultTemplate && deviceToAdd && initialChosenName === chosenName && testResults &&
                            <button
                                className="outline"
                                onClick={() => handleOpenDialog(
                                    "",
                                    handleAddDeviceToExisting
                                )}>
                                Add Device
                            </button>
                        }
                    </div>
                </>
            )}
            {!error && uploadResults && (
                <AdminLoggerConstructUploadResults
                    uploadResults={uploadResults}
                    onBack={onBack} />
            )}
            {!error && !uploadResults && (
                <AdminLoggerConstructTestResults
                    results={testResults}
                    testMessage={testMessage} />
            )}

            <IntegratedSpinLoader loading={loading} loadMessage={""} />
            <ErrorMessage error={error} />
        </div>
    )
}
import requireTranslation from "../../lang/locales/config";
import {useDebouncedEffect, useFormSetup} from "../../utils/customHooks";
import {ClientModel} from "../../models/case/client";
import {
    validateAddress,
    isInputErrorsEmpty,
    validateName,
    validateRequired
} from "../../utils/inputValidation";
import {useClientApi} from "../../API/useClientCaseApi";
import {OverlaySpinLoader} from "../common/SpinLoader";
import React, {ChangeEvent, SyntheticEvent, useState} from "react";
import {DatePickerWithLabel, NumberInputWithLabel, TextInputWithLabel} from "../common/FormCommons";
import ClientDetails from "./ClientDetails";
import {
    timestampFromDateString,
    toDateInputFromTimeStamp
} from "../../utils/timestamp";
import "../../styles/suggestioncard.css"
import {OptionButton, OptionsDialog, useDialog} from "../common/ConfirmationDialog";
import {useNavigate} from "react-router-dom";


/**
 * @param initialClient The existing client, or if new a 'blank' ClientModel.
 * @param onSave Add if form should act as an editing form. Either onSave or onAdd must be declared,
 *               onSave weights highest if both are declared.
 * @param onAdd Add if form should act as a create form. Either onSave or onAdd must be declared.
 * @param borderless Set true if border: none, box-shadow: none;
 */
interface ClientFormProps {
    initialClient: ClientModel;
    onSave?: (formElement: JSX.Element, statusMessage?: string, newClientName?: string) => void;
    onAdd?: (client: ClientModel) => void;
    onOptionCreate?: (optionChoice: ClientModel, date: number) => void;
    borderless?: boolean;
    haveBackButton?: boolean;
}

interface InputErrorsInter extends Object {
    name: string;
    address: string;
    serviceArea: string;
    instrumentType: string;
    instrumentNumber: string;
    date: string;
}

export default function ClientForm(
    { initialClient, onSave, onAdd, onOptionCreate, borderless = false, haveBackButton = false }: ClientFormProps
) {
    const t = requireTranslation();
    const initialInputErrors: InputErrorsInter = {
        "name": "",
        "address": "",
        "serviceArea": "",
        "instrumentType": "",
        "instrumentNumber": "",
        "date": ""
    }
    const [
        awaiting, setAwaiting,
        inputErrors, setInputErrors,
        error, setError,
        client, setClient,
    ] = useFormSetup<ClientModel, InputErrorsInter>(initialClient, initialInputErrors);
    const [ searchQuery, setSearchQuery ] = useState("");
    const [ searchResponse, setSearchResponse] = useState<ClientModel[]>();
    const { getClientsBySearch, putClient } = useClientApi();
    const { isOpen, message, setDialogMessage, toggle } = useDialog();
    const [ optionChoice, setOptionChoice ] = useState<ClientModel>();

    useDebouncedEffect(() => {
        if (onAdd) { // only enable search on new case...
            if (!searchQuery) {
                return setSearchResponse(undefined);
            }

            getClientsBySearch(searchQuery, 5, 0, true)
                .then(setSearchResponse)
                .catch(() => setSearchResponse(undefined))
        }
    }, 300, [searchQuery, onAdd])

    /**
     * When a suggestion is clicked, a dialog will be displayed with some options of
     * how to proceed.
     *
     * @param client The ClientModel of the suggestion.
     */
    const handleSuggestionClick = (client: ClientModel) => {
        setOptionChoice(client);
        setDialogMessage(
            t.informationTexts.clientOptionSelectCQM
                .replace("?1", client.name)
                .replace("?2", client.address)
        );
        toggle();
    };

    /**
     * The option to create a new case from the suggestion has been chosen.
     * Api call to preform this will be made. On success navigate to new 'template case'.
     */
    const handleOptionCreate = () => {
        if (optionChoice && onOptionCreate) {
            onOptionCreate(
                optionChoice,
                client.date
            )
        }
    }

    /**
     * The option to fill the current form from the suggestion has been chosen.
     * Simply fills the client form (except the date).
     */
    const handleOptionFill = () => {
        setClient((prevState) => {
            if (optionChoice) {
                const newState = {
                    id: "",
                    name: optionChoice.name,
                    address: optionChoice.address,
                    serviceArea: optionChoice.serviceArea,
                    instrumentType: optionChoice.instrumentType,
                    instrumentNumber: optionChoice.instrumentNumber,
                    date: prevState.date,
                    employeeId: prevState.employeeId,
                    formerEmployeeId: prevState.formerEmployeeId
                }

                return new ClientModel(newState)
            } else {
                return prevState
            }

        })
    }

    /**
     * Handles when the back button (if any) is clicked.
     */
    const handleBackClick = () => {
        onSave!!(
            <ClientDetails clientId={client.id} onEdit={onSave!!} />
        )
    }

    const handleChangeWithSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
        handleChange(event);
    }

    /**
     * Handles a change in an input field.
     * Updates the ClientModel to match the change.
     */
    const handleChange = (event: any, alt_value: boolean = false) => {
        const { name, value } = event.target;
        const change = { [name]: alt_value ? null : value };

        setClient((client) =>
            new ClientModel({ ...client, ...change }));
    };

    const handleDateChange = (event: any) => {
        const { name, value } = event.target;
        const timestamp = timestampFromDateString(value);
        const change = { [name]: timestamp }

        setClient((client) =>
            new ClientModel({ ...client, ...change }));
    }

    /**
     * Handles when the add button is clicked.
     * First validates input, if valid, a POST request is sent to the server.
     */
    const handleAdd = async () => {
        if (validateInput()) {
            onAdd!!(client);
        }
    }

    /**
     * Handles when the edit button is clicked.
     * First validates input, if valid, a PUT request is sent to the server.
     */
    const handleEdit = async () => {
        if (validateInput()) {
            setAwaiting(true);
            await putClient(client)
                .then(() => {
                    onSave!!(
                        <ClientDetails clientId={client.id} onEdit={onSave!!} />,
                        t.messages.successEdit,
                        client.name !== initialClient.name ? client.name : undefined
                    );
                })
                .catch((e) => { if (e instanceof Error) setError(e.message); })
                .finally(() => setAwaiting(false));
        }
    };

    /**
     * Validates the input fields. Sets error text if any is invalid.
     * Returns True if all tests passed.
     */
    const validateInput = () => {
        const err = { ...inputErrors };

        err.name = validateName(client.name);
        err.address = validateAddress(client.address);
        err.serviceArea = validateRequired(client.serviceArea);
        err.instrumentType = validateRequired(client.instrumentType);
        err.instrumentNumber = validateRequired(client.instrumentNumber);
        err.date = validateRequired(client.date);

        setInputErrors(err);
        return isInputErrorsEmpty<InputErrorsInter>(err);
    };

    return (
        <>
            {isOpen &&
                <OptionsDialog
                    toggle={toggle}
                    message={message}>
                    <DatePickerWithLabel
                        labelText={t.date}
                        ariaLabel="OptionDateField"
                        name="date"
                        initialValue={toDateInputFromTimeStamp(client.date)}
                        onChange={handleDateChange} />
                    <OptionButton
                        text={t.create}
                        onClick={handleOptionCreate}
                        toggle={toggle}/>
                    <OptionButton
                        text={t.fill}
                        onClick={handleOptionFill}
                        toggle={toggle}/>
                </OptionsDialog>
            }

            <div className={borderless ? "content invisible" : "content fold-out color-main-background"}>
                <OverlaySpinLoader
                    loading={awaiting}
                    loadMessage={`${t.awaiting}...`}/>
                <div className="content-inner-form">
                    <div className="row">
                        {haveBackButton &&
                            <img
                                className="icon-button"
                                src={"back_arrow.svg"}
                                alt={t.back}
                                onClick={handleBackClick}/>}
                        {onAdd && (
                            <h3 className="form-header">{t.new} {t.case}</h3>
                        )}
                        {!onAdd && (
                            <h3 className="form-header">{t.edit}</h3>
                        )}
                    </div>
                    <form
                        aria-label="client form"
                        name="client form"
                        onSubmit={(e: SyntheticEvent) => e.preventDefault()}>
                        <div id="suggestion-box">
                            <TextInputWithLabel
                                labelText={t.name}
                                name="name"
                                ariaLabel="client name"
                                placeholder={`${t.enter} ${t.name.toLowerCase()}`}
                                initialValue={client.name}
                                autocompleteList={onAdd ? [] : undefined}
                                error={inputErrors.name}
                                onChange={handleChangeWithSearch}>
                                {searchResponse &&
                                    <div className="suggestion-container">
                                        {searchResponse.map((client) => (
                                            <div
                                                key={client.id}
                                                className="suggestion-card"
                                                onClick={() => handleSuggestionClick(client)}>
                                                <p>{client.name}</p>
                                                <p>{client.address}</p>
                                            </div>
                                        ))}
                                    </div>}
                            </TextInputWithLabel>
                        </div>
                        <div id="suggestion-box">
                            <TextInputWithLabel
                                labelText={t.address}
                                name="address"
                                ariaLabel="client address"
                                placeholder={`${t.enter} ${t.address.toLowerCase()}`}
                                initialValue={client.address}
                                autocompleteList={onAdd ? [] : undefined}
                                error={inputErrors.address}
                                onChange={handleChangeWithSearch}>
                                {searchResponse &&
                                    <div className="suggestion-container">
                                        {searchResponse.map((client) => (
                                            <div
                                                key={client.id}
                                                className="suggestion-card"
                                                onClick={() => handleSuggestionClick(client)}>
                                                <p>{client.name}</p>
                                                <p>{client.address}</p>
                                            </div>
                                        ))}
                                    </div>}
                            </TextInputWithLabel>
                        </div>
                        <TextInputWithLabel
                            labelText={t.serviceArea}
                            name="serviceArea"
                            ariaLabel="client serviceArea"
                            placeholder={`${t.enter} ${t.serviceArea.toLowerCase()}`}
                            initialValue={client.serviceArea}
                            error={inputErrors.serviceArea}
                            onChange={handleChange}/>
                        <TextInputWithLabel
                            labelText={t.instrumentType}
                            name="instrumentType"
                            ariaLabel="client instrumentType"
                            autocompleteList={t.instrumentTypesList}
                            placeholder={`${t.enter} ${t.instrumentType.toLowerCase()}`}
                            initialValue={client.instrumentType}
                            error={inputErrors.instrumentType}
                            onChange={handleChange}/>
                        <NumberInputWithLabel
                            labelText={t.instrumentNumber}
                            name="instrumentNumber"
                            ariaLabel="client instrumentNumber"
                            placeholder={`${t.enter} ${t.instrumentNumber.toLowerCase()}`}
                            initialValue={client.instrumentNumber}
                            error={inputErrors.instrumentNumber}
                            onChange={handleChange}/>
                        <DatePickerWithLabel
                            labelText={t.date}
                            name="date"
                            ariaLabel="client date"
                            initialValue={toDateInputFromTimeStamp(client.date)}
                            error={inputErrors.date}
                            onChange={handleDateChange}/>
                        <div className="row margin reverse">
                            <button
                                className="filled"
                                onClick={onSave ? handleEdit : handleAdd}>
                                {onSave ? t.edit : t.add}
                            </button>
                        </div>
                        {error && (
                            <div className="row margin">
                                <p className="error message">{error}</p>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </>
    );
}
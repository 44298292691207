import {useFetch} from "./useFetch";
import {NewsModel} from "../models/News";

export default function useNewsApi() {
    const baseUrl = process.env.REACT_APP_API_URL as string;
    const url = `${baseUrl}/news`;
    const requester = useFetch();

    const getNews = (limit: number = 5, offset: number = 0) => {
        const params = `?limit=${limit}&offset=${offset}`;
        return requester.get(
            NewsModel,
            url + params,
            undefined,
            undefined,
            false,
            false,
            true
        ) as Promise<NewsModel[]>;
    };

    const getNewsBySearch = (search: string) => {
        const param = `?search=${search}`;
        return requester.get(
            NewsModel,
            url + param,
            undefined,
            undefined,
            false,
            false,
            true
        ) as Promise<NewsModel[]>;
    };

    const getNewsById = (newsId: string) => {
        const param = `?newsId=${newsId}`;
        return requester.get(NewsModel, url + param) as Promise<NewsModel>;
    };

    return {
        getNews,
        getNewsBySearch,
        getNewsById
    };
};
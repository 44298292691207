import {useLocation} from "react-router-dom";
import {EmployeeModel} from "../../models/Employee";

interface stateValueObject {
    isNew: boolean;
    employee: EmployeeModel;
}

export default function EmployeePage() {
    const { isNew, employee }: stateValueObject = useLocation().state;

    return (
        <div className="page-menu-with-content-container">
            {/*<EmployeeForm initialEmployee={employee} isNewEmployee={isNew} />*/}
        </div>
    );
}
import {IntegratedSpinLoader} from "./SpinLoader";
import requireTranslation from "../../lang/locales/config";
import {NavLink} from "react-router-dom";
import {selectUser} from "../../state/userSlice";
import {useAppSelector} from "../../state/hooks";
import {isActive, isUser} from "../utils/Enums";
import {Content} from "./ContentStandards";

interface ErrorMessageProps {
    // The error to be displayed.
    error: string;
    // className of the outer <div> container.
    containerClassName?: string;
    withLoader?: boolean;
    noBorder?: boolean;
}


export default function ErrorMessage(
    {
        error,
        containerClassName = "content color-main-background",
        withLoader = false,
        noBorder = false
    }: ErrorMessageProps
) {
    return (
        <>
            {error && (
                <div className={containerClassName}>
                    <div className="center-page">
                        <p className={`error message ${noBorder ? "no-border" : ""} support-linebreak centered`}>{error}</p>
                    </div>
                    {withLoader &&
                        <IntegratedSpinLoader loading={withLoader} loadMessage={""} />
                    }
                </div>
            )}
        </>
    )
}

interface SuccessMessageProps {
    message: string;
    containerClassName?: string;
    withLoader?: boolean;
}


export function SuccessMessage(
    {
        message,
        containerClassName = "content color-main-background",
        withLoader = false
    }: SuccessMessageProps
) {
    return (
        <>
            {message && (
                <div className={containerClassName}>
                    <div className="center-page">
                        <p className="success message support-linebreak centered">{message}</p>
                    </div>
                    {withLoader &&
                        <IntegratedSpinLoader loading={withLoader} loadMessage={""} />
                    }
                </div>
            )}
        </>
    )
}

interface InactiveCheckProps {
    children: JSX.Element
    fit?: boolean
}

export function InactiveCheck({ children, fit = false }: InactiveCheckProps) {
    const t = requireTranslation();
    const user = useAppSelector(selectUser);

    return (
        <>
            {isActive(user.subscription) ?
                children :
                <Content
                    headline={t.needSubscription}
                    centerHeadline={!fit}
                    flexGrow={fit}>
                    <p className="support-linebreak available centered">
                        {isUser(user.accessLevel) ?
                            t.informationTexts.inactiveSubscription :
                            t.informationTexts.inactiveSubscriptionEmployee
                        }
                        {isUser(user.accessLevel) &&
                            <NavLink to={'/profile'}>{t.manageProfile}</NavLink>
                        }
                    </p>
                </Content>
            }
        </>
    );
}

interface FullPageErrorProps {
    errorMessage: string;
    errorHeadline: string;
}

export function FullPageError({ errorMessage, errorHeadline }: FullPageErrorProps) {
    return (
        <>
            {(errorMessage || errorHeadline) &&
                <div className="content color-main-background fold-out">
                    {errorHeadline && (
                        <h3 className="content-headline center-page">{errorHeadline}</h3>
                    )}
                    {errorMessage && (
                        <p className="support-linebreak center-page">
                            {errorMessage}
                        </p>
                    )}
                </div>
            }
        </>
    );
}
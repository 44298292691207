import requireTranslation from "../../lang/locales/config";

interface BalancingFrontpageInit {
    headline: string;
    subtitle: string;
    greeting: string;
    body: string;
    farewell: string;
    ownerTitle: string;
    hasLogo: boolean;
    hasHeader: boolean;
}

export class BalancingFrontpageModel implements BalancingFrontpageInit {
    headline: string = "";
    subtitle: string = "";
    greeting: string = "";
    body: string = "";
    farewell: string = "";
    ownerTitle: string = "";
    hasLogo: boolean = false;
    hasHeader: boolean = false;
    logo: FileModel = new FileModel();
    header: FileModel = new FileModel();


    constructor(init?: BalancingFrontpageInit, logo?: FileModel, header?: FileModel) {
        if (!init) return;

        this.headline = init.headline;
        this.subtitle = init.subtitle;
        this.greeting = init.greeting;
        this.body = init.body;
        this.farewell = init.farewell;
        this.ownerTitle = init.ownerTitle;
        this.hasLogo = init.hasLogo;
        this.hasHeader = init.hasHeader;
        if (logo)
            this.logo = logo;
        if (header)
            this.header = header;
    }

    getAsFormData = () => {
        const formData = new FormData();

        formData.append("headline", this.headline);
        formData.append("subtitle", this.subtitle);
        formData.append("greeting", this.greeting);
        formData.append("body", this.body);
        formData.append("farewell", this.farewell);
        formData.append("ownerTitle", this.ownerTitle);

        if (this.logo.file) {
            formData.append("logo", this.logo.file, this.logo.filename);
        }

        if (this.header.file) {
            formData.append("header", this.header.file, this.header.filename);
        }

        return formData;
    }
}

export class FileModel {
    filename: string = requireTranslation().informationTexts.noFileSelected;
    url: string = "";
    file: File | undefined = undefined;

    constructor(blob?: Blob, header?: Headers | undefined, file?: File) {
        if (!blob && !header && !file) return;

        if (blob !== undefined && header === undefined) {
            throw Error("FileModel -- Setup wrong. This model should get a header if a blob is present!");
        }

        if (header !== undefined && blob === undefined) {
            throw Error("FileModel -- Setup wrong. This model should get a blob if a header is present!");
        }

        if (blob && header) {
            this.filename = header.get("x-filename")!!;
            this.url = URL.createObjectURL(blob);
            this.file = new File([blob], this.filename);
        } else if (file !== undefined) {
            this.filename = file.name;
            this.url = URL.createObjectURL(file);
            this.file = file;
        } else {
            throw Error("FileModel -- Faulty instantiation. Either give (both blob and header) or file!");
        }
    }
}
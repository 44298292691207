interface WattAndCommentsModelInit {
    id: string | null;
    watts: number | null;
    comments: string;
    clientId: string;
}


export class WattAndCommentsModel implements WattAndCommentsModelInit{
    id: string | null = null;
    watts: number | null = null;
    comments: string = "";
    clientId: string = "";

    constructor(init?: WattAndCommentsModelInit) {
        if (!init) return;
        if (init) {
            this.id = init.id;
            this.watts = init.watts;
            this.comments = init.comments;
            this.clientId = init.clientId;
        }
    }
}
import requireTranslation from "../../lang/locales/config";
import {useStepReadingApi} from "../../API/useClientCaseApi";
import {useFormSetup} from "../../utils/customHooks";
import {StepReadingModel} from "../../models/case/stepReading";
import StepReadingsList from "./StepReadingsList";
import {isInputErrorsEmpty, validateRequired} from "../../utils/inputValidation";
import {OverlaySpinLoader} from "../common/SpinLoader";
import {SyntheticEvent} from "react";
import {DoubleButton, NumberInputWithLabel} from "../common/FormCommons";


interface StepReadingFormProps {
    initialStepReading?: StepReadingModel;
    onSave: (formElement: JSX.Element,
             statusMessage?: string,
             newClientName?: string,
             shouldRefreshStatus?: boolean) => void;
    clientId?: string;
    stepReadingCount: number;
    haveBackButton?: boolean;
}

interface InputErrorInter {
    inM3h: string;
    inPercent: string;
    outM3h: string;
    outPercent: string;
}

export default function StepReadingForm(
    { initialStepReading, onSave, clientId, stepReadingCount, haveBackButton = false }: StepReadingFormProps
) {
    if (!initialStepReading && !clientId) {
        throw Error("If initialStepReading is undefined, then clientId must be defined!")
    }

    let newStepReading = null;
    if (!initialStepReading) {
        newStepReading = new StepReadingModel();
        newStepReading.step = stepReadingCount!! + 1;
        newStepReading.clientId = clientId!!;
    }

    const t = requireTranslation();
    const { putStepReading, postStepReading, deleteStepReading } = useStepReadingApi();
    const initialInputErrors: InputErrorInter = {
        inM3h: "",
        inPercent: "",
        outM3h: "",
        outPercent: ""
    };
    const [
        awaiting, setAwaiting,
        inputErrors, setInputErrors,
        error, setError,
        stepReading, setStepReading
    ] = useFormSetup<StepReadingModel, InputErrorInter>(
        initialStepReading ? initialStepReading : newStepReading!!,
        initialInputErrors
    );

    /**
     * Handles when the back button (if any) is clicked.
     */
    const handleBackClick = () => {
        onSave(
            <StepReadingsList clientId={stepReading.clientId!!} onEdit={onSave} />
        )
    }

    /**
     * Handles a simple change in input field where any change is allowed.
     */
    const handleChange = (event: any, alt_value = false) => {
        const { name, value } = event.target;
        const change = { [name]: alt_value ? null : value };
        setError("");
        setStepReading((stepReading) => {
            return new StepReadingModel({ ...stepReading, ...change });
        });
    };

    /**
     * Handles when the save button is clicked. Validates input and
     * sends a PUT request to the server.
     */
    const handleSave = async () => {
        if (validateInput()) {
            if (initialStepReading) {
                setAwaiting(true);
                await putStepReading(stepReading)
                    .then(() => {
                        onSave(
                            <StepReadingsList
                                clientId={stepReading.clientId}
                                onEdit={onSave}/>,
                            t.messages.successEdit,
                        );
                    })
                    .catch((e) => {
                        if (e instanceof Error) setError(e.message);
                    })
                    .finally(() => setAwaiting(false));
            } else {
                setAwaiting(true);
                await postStepReading(stepReading)
                    .then(() => {
                        onSave(
                            <StepReadingsList
                                clientId={stepReading.clientId}
                                onEdit={onSave}/>,
                            t.messages.successEdit,
                            undefined,
                            true
                        );
                    })
                    .catch((e) => {
                        if (e instanceof Error) setError(e.message);
                    })
                    .finally(() => setAwaiting(false));
            }
        }
    };

    const handleDelete = async () => {
        setAwaiting(true);
        await deleteStepReading(initialStepReading!!)
            .then(() => {
                onSave(
                    <StepReadingsList
                        clientId={stepReading.clientId}
                        onEdit={onSave} />,
                    t.messages.successDelete,
                    undefined,
                    true
                );
            })
            .catch((e) => {
                if (e instanceof Error) setError(e.message);
            })
            .finally(() => setAwaiting(false));
    }

    /**
     * Validates the input fields. Sets error text in inputErrors if input is invalid.
     * Returns true if all input is valid.
     */
    const validateInput = (): boolean => {
        const err = { ...inputErrors };

        err.inM3h = validateRequired(stepReading.inM3h);
        err.inPercent = validateRequired(stepReading.inPercent);
        err.outM3h = validateRequired(stepReading.outM3h);
        err.outPercent = validateRequired(stepReading.outPercent);

        setInputErrors(err);
        return isInputErrorsEmpty<InputErrorInter>(err);
    }

    return (
        <>
            <OverlaySpinLoader
                loading={awaiting}
                loadMessage={`${t.awaiting}...`}/>

            <div className="content color-main-background fold-out">
                <div className="content-inner-form">
                    <div className="row">
                        {haveBackButton &&
                            <img
                                className="icon-button"
                                src={"back_arrow.svg"}
                                alt={t.back}
                                onClick={handleBackClick}/>
                        }
                        {initialStepReading && (
                            <h3 className="form-header">{t.edit} {t.stepReading} {initialStepReading.step}</h3>
                        )}
                        {!initialStepReading && (
                            <h3 className="form-header">{t.add} {t.stepReading} {stepReading.step}</h3>
                        )}
                    </div>
                    <form
                        aria-label="stepReading form"
                        name="stepReading form"
                        onSubmit={(e: SyntheticEvent) => e.preventDefault()}>
                        <div className="row margin">
                            <NumberInputWithLabel
                                labelText={t.inM3h + ":"}
                                name="inM3h"
                                ariaLabel="stepReading inM3h"
                                initialValue={stepReading.inM3h}
                                placeholder={`${t.enter} ${t.inM3h.toLowerCase()}`}
                                rowOfTwo={true}
                                error={inputErrors.inM3h}
                                onChange={handleChange}/>
                            <NumberInputWithLabel
                                labelText={t.inPercent + ":"}
                                name="inPercent"
                                ariaLabel="stepReading inPercent"
                                initialValue={stepReading.inPercent}
                                placeholder={`${t.enter} ${t.inPercent.toLowerCase()}`}
                                rowOfTwo={true}
                                error={inputErrors.inPercent}
                                onChange={handleChange}/>
                        </div>
                        <div className="row margin">
                            <NumberInputWithLabel
                                labelText={t.outM3h + ":"}
                                name="outM3h"
                                ariaLabel="stepReading outM3h"
                                initialValue={stepReading.outM3h}
                                placeholder={`${t.enter} ${t.outM3h.toLowerCase()}`}
                                rowOfTwo={true}
                                error={inputErrors.outM3h}
                                onChange={handleChange}/>
                            <NumberInputWithLabel
                                labelText={t.outPercent + ":"}
                                name="outPercent"
                                ariaLabel="stepReading outPercent"
                                initialValue={stepReading.outPercent}
                                placeholder={`${t.enter} ${t.outPercent.toLowerCase()}`}
                                rowOfTwo={true}
                                error={inputErrors.outPercent}
                                onChange={handleChange}/>
                        </div>
                        <div className="row margin reverse">
                            {initialStepReading && stepReadingCount === initialStepReading.step ? (
                                <DoubleButton
                                    outlinedButtonText={t.delete}
                                    filledButtonText={t.edit}
                                    onOutlinedButton={handleDelete}
                                    onFilledButton={handleSave} />
                            ) : (
                                <button
                                    className="filled"
                                    onClick={handleSave}>{initialStepReading ? t.edit : t.add}</button>
                            )}

                        </div>
                        {error && (
                            <div className="row margin">
                                <p className="error message">{error}</p>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </>
    )
}
import requireTranslation, {requireCurrentLanguage} from "../../lang/locales/config";
import {useStandardSetup} from "../../utils/customHooks";
import {ReportFileModel} from "../../models/case/report";
import {useClientCaseReportApi} from "../../API/useClientCaseApi";
import {ChangeEvent, useEffect, useState} from "react";
import {SpinLoader} from "../common/SpinLoader";
import ErrorMessage from "../common/ErrorMessage";
import {getDocument, GlobalWorkerOptions} from 'pdfjs-dist';
import {GetViewportParameters} from "pdfjs-dist/types/src/display/api";
import {ScreenSpec, useScreenSize} from "../utils/Screen";

interface GetDocumentPagesOptions {
    url: string;
    screenSpec?: ScreenSpec;
}

async function getDocumentPages({  url, screenSpec }: GetDocumentPagesOptions) {
    GlobalWorkerOptions.workerSrc = "/data/worker"
    const loadingTask = getDocument(url);
    const pdf = await loadingTask.promise;
    const numPages = pdf.numPages;
    const canvasUrls: string[] = [];

    for (let i = 0; i < numPages; i++) {
        const page = await pdf.getPage(i + 1);

        let viewport = page.getViewport({scale: 1} as GetViewportParameters);
        let { width, height } = viewport;

        if (screenSpec) { // Adjusting to smaller screens...
            if ((!screenSpec.screenHasMenu && screenSpec.screenWidth < width) ||
                (screenSpec.screenHasMenu && (screenSpec.screenWidth * 0.9 * 0.7) < width)) {
                let scale: number = (screenSpec.screenWidth / width) * 0.9;

                if (screenSpec.screenHasMenu)
                    scale = scale * 0.7;

                viewport = page.getViewport({scale: scale} as GetViewportParameters);
                width = viewport.width;
                height = viewport.height;
                // console.log("width, height", width, height);
            }
        }

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        canvas.className = 'page';
        const canvasContext = canvas.getContext('2d');
        if (canvasContext === null) {
            throw Error("No canvas context!");
        }
        const renderTask = page.render({
            canvasContext: canvasContext,
            viewport: viewport
        });
        await renderTask.promise

        canvasUrls.push(canvas.toDataURL());
    }

    return canvasUrls;
}

interface EditOptionObject {
    translated: string;
    original: string;
}

interface ReportViewerProps {
    clientId: string;
}


export default function ReportViewer({ clientId }: ReportViewerProps) {
    const t = requireTranslation();
    const [
        loading, setLoading,
        error, setError,
        report, setReport
    ] = useStandardSetup<ReportFileModel>();
    const [ pages, setPages ] = useState<string[]>();
    const [ editOptions, setEditOptions ] = useState<EditOptionObject[]>();
    const [ currentOption, setCurrentOption ] = useState('');
    const { getReport } = useClientCaseReportApi();
    const screenSpec = useScreenSize();

    const fetchReport = async (option?: string) => {
        setLoading(true);
        getReport(clientId, requireCurrentLanguage(), option)
            .then( async (data) => {
                setReport(data);
                setCurrentOption(data.currentOption);
                setEditOptions(data.editOptions.map((option) => {
                    return {
                        translated: t.editOptions[option],
                        original: option
                    };
                }));
                await getPages(data.url);
            })
            .catch((e) => { if (e instanceof Error) setError(e.message) })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        fetchReport().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientId])

    useEffect( () => {
        if (report) {
            setLoading(true)
            getPages(report.url)
                .then(() => {
                    setLoading(false);
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenSpec]);

    const getPages = async (url: string) => {
        const canvases = await getDocumentPages({url, screenSpec});
        setPages(canvases);
    };

    const handleDownloadClick = () => {
        const linkElement = document.createElement("a") as HTMLAnchorElement;
        linkElement.href = report!!.url;
        linkElement.setAttribute("download", report!!.filename);
        document.body.appendChild(linkElement);
        linkElement.click()
        linkElement.parentNode?.removeChild(linkElement);
    }

    const handleEditOptionChange = async (e: ChangeEvent<HTMLSelectElement>) => {
        const { value} = e.target;

        if (value.length !== 0) {
            await fetchReport(value);
        }
    }

    return (
        <>
            <SpinLoader
                containerClassName="content invisible"
                loading={loading}
                loadMessage={`${t.loading} Report...`} />
            <ErrorMessage
                error={error}
                containerClassName="content invisible" />
            {!loading && !error && report && pages &&
                <div className="content invisible fold-out">
                    <div className="content-inner-frame-container">
                        <div className="content-inner-frame-panel">
                            {editOptions && currentOption && (
                                <div className="row toColumn">
                                    <label className="input-label no-margin fit-text">
                                        {t.edit}
                                    </label>
                                    <select
                                        className="input on-panel no-margin fit-context"
                                        defaultValue={currentOption}
                                        onChange={handleEditOptionChange}>
                                        {editOptions.map((option, index) => {
                                            return <option key={index} value={option.original}>{option.translated}</option>
                                        })}
                                    </select>
                                </div>
                            )}
                            <button className="outline" onClick={handleDownloadClick}>Download</button>
                        </div>
                        {pages.map((canvasUrl, index) => (
                            <img
                                className="content-inner-frame"
                                src={canvasUrl}
                                key={index}
                                alt="Report" />
                        ))}
                    </div>
                </div>
            }
        </>
    );
}
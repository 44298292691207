interface ArmatureModelInit {
    id: string | null;
    airflow: string;
    airflowType: number | null;
    model: string;
    clientId: string | null;
}

export class ArmatureModel implements ArmatureModelInit {
    id: string | null = null;
    airflow: string = "";
    airflowType: number | null = null;
    model: string = "";
    clientId: string | null = null;

    constructor(initializer?: ArmatureModelInit) {
        if (!initializer) return;
        if (initializer) {
            this.id = initializer.id;
            this.airflow = initializer.airflow;
            this.airflowType = initializer.airflowType;
            this.model = initializer.model;
            this.clientId = initializer.clientId;
        }
    }
}
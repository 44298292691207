import ClientCaseCard from "./ClientCaseCard";
import React, {ChangeEvent, useEffect, useState} from "react";
import {SimplifiedClientCase} from "../../models/ClientCase";
import {IntegratedSpinLoader, SpinLoader} from "../common/SpinLoader";
import requireTranslation from "../../lang/locales/config";
import ErrorMessage from "../common/ErrorMessage";
import {useClientApi} from "../../API/useClientCaseApi";
import {useStandardSetup} from "../../utils/customHooks";
import {DatePickerWithLabel} from "../common/FormCommons";
import DropdownContent, {useDropdownContent} from "../utils/OverlayOptions";
import {timestampFromDateString} from "../../utils/timestamp";
import {CASE_INITIAL_OFFSET, CASE_LIMIT} from "../../constants/requirement";


enum CaseListState {
    SEARCH,
    FILTER
}

interface ListLoadingCredentials {
    offset: number;
    limit: number;
    listState: CaseListState | undefined;
    isMore: boolean;
}


function ClientCaseList() {
    const t = requireTranslation();
    const [clientCases, setClientCases] = useState<SimplifiedClientCase[] | []>([]);
    const [
        loading, setLoading,
        error, setError,
        searchInput, setSearchInput
    ] = useStandardSetup<string>('');
    const [ loadingCredentials, setLoadingCredentials ] = useState<ListLoadingCredentials>({
        offset: CASE_INITIAL_OFFSET,
        limit: CASE_LIMIT,
        listState: undefined,
        isMore: false
    });
    const [ showMoreButton, setShowMoreButton ] = useState(false);
    const [ noCasesMessage, setNoCasesMessage ] = useState(t.messages.noCases);
    const [ loadingMore, setLoadingMore ] = useState(false);
    const [ showDropdown, toggleDropdown ] = useDropdownContent();
    const [ filterInputs, setFilterInputs ] = useState({
        dateFrom: "",
        dateTo: ""
    });
    const { getClientsByLimit, getClientsBySearchSimple, getClientsByFilterDate } = useClientApi();

    const handleSetClientCases = (newData: SimplifiedClientCase[]) => {
        setClientCases((currentData) => {
            if (currentData.length !== 0) {
                return [...currentData, ...newData];
            } else {
                return newData;
            }
        });
    };

    useEffect(() => {
        if (loadingCredentials.listState === CaseListState.SEARCH) {
            handleSearch();
        } else if (loadingCredentials.listState === CaseListState.FILTER) {
            handleApplyFilter(!loadingCredentials.isMore);
        } else {
            handleFetchList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingCredentials]);

    const handleFetchList = () => {
        loadingCredentials.isMore ? setLoadingMore(true) : setLoading(true);
        setNoCasesMessage(t.messages.noCases);
        getClientsByLimit(loadingCredentials.offset, loadingCredentials.limit, true)
            .then((data) => {
                loadingCredentials.isMore ? handleSetClientCases(data) : setClientCases(data)
                setShowMoreButton(data.length === loadingCredentials.limit);
            })
            .catch((e) => {
                if (e instanceof Error) setError(e.message);
            })
            .finally(() => {
                loadingCredentials.isMore ? setLoadingMore(false) : setLoading(false);
            });
    }

    const handleSearchFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        setSearchInput(value);
    }

    const handleSearch = () => {
        if (searchInput !== undefined) {
            loadingCredentials.isMore ? setLoadingMore(true) : setLoading(true);
            setNoCasesMessage(t.messages.noCasesMatchSearch);
            getClientsBySearchSimple(searchInput, loadingCredentials.limit, loadingCredentials.offset)
                .then((data) => {
                    loadingCredentials.isMore ? handleSetClientCases(data) : setClientCases(data)
                    setShowMoreButton(data.length === loadingCredentials.limit);
                })
                .catch((e) => {
                    if (e instanceof Error) setError(e.message);
                })
                .finally(() => {
                    loadingCredentials.isMore ? setLoadingMore(false) : setLoading(false);
                });
        }
    }

    const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const changedValue = { [name]: value };

        // console.log(name, value)

        setFilterInputs((current) => {
            return {...current, ...changedValue }
        })
    };

    const handleApplyFilter = (toggleOn: boolean = true) => {
        if (toggleOn) {
            toggleDropdown();
        }
        if (filterInputs.dateFrom && filterInputs.dateTo) {
            loadingCredentials.isMore ? setLoadingMore(true) : setLoading(true);
            setNoCasesMessage(t.messages.noCasesMatchSearch);
            getClientsByFilterDate(
                timestampFromDateString(filterInputs.dateFrom),
                timestampFromDateString(filterInputs.dateTo),
                loadingCredentials.limit,
                loadingCredentials.offset
            )
                .then((data) => {
                    loadingCredentials.isMore ? handleSetClientCases(data) : setClientCases(data);
                    setShowMoreButton(data.length === loadingCredentials.limit);
                })
                .catch((e) => {
                    if (e instanceof Error) setError(e.message);
                })
                .finally(() => {
                    loadingCredentials.isMore ? setLoadingMore(false) : setLoading(false);
                });
        }
    };

    const handleResetLimitAndOffset = (caseListState?: CaseListState) => {
        setLoadingCredentials({
            offset: CASE_INITIAL_OFFSET,
            limit: CASE_LIMIT,
            listState: caseListState,
            isMore: false
        });
    }

    const handleMoreClick = () => {
        setLoadingCredentials((prevState) => {
            return {
                offset: prevState.offset + CASE_LIMIT,
                limit: prevState.limit,
                listState: prevState.listState,
                isMore: true
            }
        })
    }

    // insert translation...
    return (
        <>
            <ErrorMessage
                error={error}
                containerClassName="content color-main-background" />
            <SpinLoader
                loading={loading}
                loadMessage={`${t.loading} ${t.cases.toLowerCase()}...`}
                containerClassName="content color-main-background" />

            {!loading && !error && clientCases && (
                <div className="content fold-out color-main-background">
                    <div className="row toColumn-on-phones">
                        <h3 className="content-headline available">{t.cases}</h3>
                        <DropdownContent
                            buttonText={t.filter}
                            showDropdown={showDropdown}
                            toggleDropdown={toggleDropdown}>
                            <div className="content-inner-form">
                                <form
                                    aria-label="formFilterFields"
                                    onSubmit={(e) => e.preventDefault()}>
                                    <DatePickerWithLabel
                                        labelText={t.from}
                                        name="dateFrom"
                                        ariaLabel="inputFilterDateFrom"
                                        initialValue={filterInputs.dateFrom}
                                        onChange={handleFilterChange}/>
                                    <DatePickerWithLabel
                                        labelText={t.to}
                                        name="dateTo"
                                        ariaLabel="inputFilterDateTo"
                                        minDate={filterInputs.dateFrom}
                                        initialValue={filterInputs.dateTo}
                                        onChange={handleFilterChange}/>
                                    <div className="row margin reverse">
                                        <button
                                            className="filled"
                                            onClick={() => handleResetLimitAndOffset(CaseListState.FILTER)}
                                        >{t.find}</button>
                                    </div>
                                </form>
                            </div>
                        </DropdownContent>
                        <form
                            aria-label="formSearchField"
                            className="form-search-field"
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleResetLimitAndOffset(CaseListState.SEARCH);
                            }}>
                            <input
                                aria-label="inputSearchField"
                                className="input search-field"
                                type="search"
                                placeholder={t.search}
                                onChange={handleSearchFieldChange}
                                value={searchInput} />
                        </form>
                    </div>

                    <div className="content-inner-list">
                        {clientCases.length > 0 ? clientCases.map((clientCase, index) => (
                            <ClientCaseCard key={clientCase.id + index} clientCase={clientCase}/>
                        )) : (
                            <p>{noCasesMessage}</p>
                        )}
                    </div>
                    {showMoreButton && (
                        <div className="row margin reverse">
                            <button
                                className="filled"
                                onClick={handleMoreClick}>{t.more}</button>
                        </div>
                    )}
                    <IntegratedSpinLoader loading={loadingMore} loadMessage={`${t.loading} ${t.more}`} />
                </div>
            )}
        </>
    );
}

export default ClientCaseList;
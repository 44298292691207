import React, {useEffect, useState} from "react";
import EmployeeCard from "./EmployeeCard";
import {EmployeeModel} from "../../models/Employee";
import requireTranslation from "../../lang/locales/config";
import {SpinLoader} from "../common/SpinLoader";
import ErrorMessage from "../common/ErrorMessage";
import {useEmployeeApi} from "../../API/useEmployeeApi";
import {Content} from "../common/ContentStandards";
import {useAppSelector} from "../../state/hooks";
import {selectUser} from "../../state/userSlice";


interface EmployeeListProps {
    onCardClick: (employee: EmployeeModel) => void;
    currentEmployeeCount: (count: number) => void;
}


function EmployeeList({ onCardClick, currentEmployeeCount }: EmployeeListProps) {
    const t = requireTranslation();
    const user = useAppSelector(selectUser);
    const { getEmployees } = useEmployeeApi();
    const [employees, setEmployees] = useState<EmployeeModel[] | []>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        setLoading(true);
        getEmployees()
            .then((data) => {
                setEmployees(data);
                currentEmployeeCount(data.length);
            })
            .catch((e) => {
                if (e instanceof Error) setError(e.message);
            })
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <ErrorMessage
                error={error}
                containerClassName="content color-main-background" />
            <SpinLoader loading={loading}
                        loadMessage={`${t.loading} ${t.employees.toLowerCase()}...`}
                        containerClassName="content color-main-background" />
            {!error && !loading && (
                <Content
                    headline={t.employees}
                    shownInfo={`${employees.length}/${user.maxEmployees}`}>
                    <div className="content-inner-list">
                            {employees.length > 0 ? employees.map(employee => (
                                <EmployeeCard
                                    key={employee.id}
                                    employee={employee}
                                    onClick={onCardClick}/>
                            )) : (
                                <p className="center-page">{t.messages.noEmployees}</p>
                            )}
                    </div>
                </Content>
            )}
        </>
    );
}

export default EmployeeList;
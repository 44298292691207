import {SuccessNoContent, useFetch} from "./useFetch";
import {SubscriptionChangeOutcome, SubscriptionStatus} from "../models/user/subscription";
import {SubscriptionUpdateOption} from "../components/utils/Enums";

export function useSubscriptionApi() {
    const subscriptionUrl = process.env.REACT_APP_API_SUBSCRIPTION_URL as string;
    const requester = useFetch();

    const getSubscriptionStatus = () => {
        return requester.get(SubscriptionStatus, subscriptionUrl) as Promise<SubscriptionStatus>;
    }

    const getSubscriptionChangeOutcome = (subscriptionChange: SubscriptionUpdateOption) => {
        const params = `?updateOption=${subscriptionChange}`;
        return requester.get(
            SubscriptionChangeOutcome,
            subscriptionUrl + params,
            undefined,
            undefined,
            false,
            false,
            true
        ) as Promise<SubscriptionChangeOutcome>;
    }

    const updateSubscription = (updateOption: SubscriptionUpdateOption) => {
        const param = `?updateOption=${updateOption}`;
        return requester.put(
            SuccessNoContent,
            subscriptionUrl + param,
            undefined,
            undefined,
            false,
            false,
            true
        ) as Promise<SuccessNoContent>;
    }

    return {
        getSubscriptionStatus,
        getSubscriptionChangeOutcome,
        updateSubscription
    }
}
export default class FirmHeaderFileModel {
    mimeType: string | null;
    filename: string | null;
    url: string;

    constructor(blob: Blob, header: Headers | undefined) {
        if (!header) {
            throw Error("FirmHeaderFileModel -- Setup wrong. This model should get the header!");
        }

        this.mimeType = header.get("content-type");
        this.filename = header.get("x-filename");
        this.url = URL.createObjectURL(blob);
    }
}
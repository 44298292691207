import {ArmatureModel} from "../../models/case/armature";
import requireTranslation from "../../lang/locales/config";
import {InformationRow} from "../common/TextCommons";
import ArmatureForm from "./ArmatureForm";
import {InactiveCheck} from "../common/ErrorMessage";

interface ArmatureCardProps {
    armature: ArmatureModel;
    onEdit: (formElement: JSX.Element, statusMessage?: string) => void;
}


export default function ArmatureCard({ armature, onEdit }: ArmatureCardProps) {
    const t = requireTranslation();
    
    return (
        <div
            className="card color-sec-background clickable"
            onClick={() => onEdit(
                <InactiveCheck>
                    <ArmatureForm
                        initialArmature={armature}
                        onSave={onEdit}
                        haveBackButton={true} />
                </InactiveCheck>
            )}>
            <h3 className="card-header">{t.airflowDirectionList[armature.airflowType!!]}</h3>
            <InformationRow title={`${t.airflow}:`} text={t.airflowDirectionList[armature.airflowType!!]} />
            <InformationRow title={`${t.model}:`} text={armature.model} />
        </div>
    )
}
import {SuccessNoContent, useFetch} from "./useFetch";
import {ResetPasswordModel, UserModel, UserUpdateModel} from "../models/user/user";
import FirmHeaderFileModel from "../models/user/firmHeader";
import {useDispatch} from "react-redux";
import {setUser} from "../state/userSlice";
import {BalancingFrontpageModel, FileModel} from "../models/user/balancingFrontpage";

export enum FrontpageOptions {
    LOGO,
    HEADER
}

export function useUserApi() {
    const baseUrl = process.env.REACT_APP_API_URL as string;
    const url = `${baseUrl}/user`;
    const firmHeaderUrl = `${url}/firm-header`;
    const balancingFrontpageUrl = `${url}/balancing/frontpage`;
    const resetUrl = `${baseUrl}/reset-password`;
    const dispatch = useDispatch();
    const requester = useFetch();

    const getUser = () =>
        requester.get(UserModel, url) as Promise<UserModel>;

    const putUser = (userUpdate: any) =>
        requester.put(SuccessNoContent, url, userUpdate) as Promise<SuccessNoContent>;

    const updateUserModel = async (): Promise<void> => {
        getUser()
            .then((user) => {
                dispatch(setUser({ ...user }));
            })
            .catch((e) => {
                if (e instanceof Error) {
                    // console.log(e.message);
                }
            })
    }

    const resetPassword = (email: string, language?: string) => {
        const param = `?email=${email}`;
        return requester.get(
            SuccessNoContent,
            resetUrl + param,
            undefined,
            undefined,
            false,
            true,
            language ? language : true) as Promise<SuccessNoContent>;
    };

    const verifyResetPasswordToken = (token: string) => {
        const param = `?resetId=${token}`;
        return requester.get(SuccessNoContent, resetUrl + param) as Promise<SuccessNoContent>;
    };

    const putResetPassword = (passwordModel: ResetPasswordModel, token: string) => {
        const param = `?resetId=${token}`;
        return requester.post(
            SuccessNoContent,
            resetUrl + param,
            passwordModel) as Promise<SuccessNoContent>;
    };

    const getFirmHeader = () =>
        requester.get(
            FirmHeaderFileModel,
            firmHeaderUrl,
            undefined,
            undefined,
            true
        ) as Promise<FirmHeaderFileModel>;

    const postFirmHeader = (firmHeaderFile: FormData) =>
        requester.post(
            SuccessNoContent,
            firmHeaderUrl,
            firmHeaderFile
        ) as Promise<SuccessNoContent>;

    const putFirmHeader = (firmHeaderFile: FormData) =>
        requester.put(
            SuccessNoContent,
            firmHeaderUrl,
            firmHeaderFile
        ) as Promise<SuccessNoContent>;

    const deleteFirmHeader = () =>
        requester.delete(
            SuccessNoContent,
            firmHeaderUrl
        ) as Promise<SuccessNoContent>;

    const getBalancingFrontpage = () => {
        return requester.get(
            // @ts-ignore
            BalancingFrontpageModel,
            balancingFrontpageUrl
        ) as Promise<BalancingFrontpageModel>;
    }

    const getBalancingFrontpageLogo = () => {
        const params = `?option=${FrontpageOptions.LOGO}`;
        return requester.get(
            // @ts-ignore
            FileModel,
            balancingFrontpageUrl + params,
            undefined,
            undefined,
            true
        ) as Promise<FileModel>;
    }

    const getBalancingFrontpageHeader = () => {
        const params = `?option=${FrontpageOptions.HEADER}`;
        return requester.get(
            // @ts-ignore
            FileModel,
            balancingFrontpageUrl + params,
            undefined,
            undefined,
            true
        ) as Promise<FileModel>;
    }

    const postBalancingFrontpage = (frontpage: FormData) =>
        requester.post(
            SuccessNoContent,
            balancingFrontpageUrl,
            frontpage
        ) as Promise<SuccessNoContent>;

    const putBalancingFrontpage = (frontpage: FormData) =>
        requester.put(
            SuccessNoContent,
            balancingFrontpageUrl,
            frontpage
        ) as Promise<SuccessNoContent>;

    const deleteBalancingFrontpage = () =>
        requester.delete(
            SuccessNoContent,
            balancingFrontpageUrl
        ) as Promise<SuccessNoContent>;

    return {
        getUser,
        putUser,
        updateUserModel,
        resetPassword,
        verifyResetPasswordToken,
        putResetPassword,
        getFirmHeader,
        postFirmHeader,
        putFirmHeader,
        deleteFirmHeader,
        getBalancingFrontpage,
        getBalancingFrontpageLogo,
        getBalancingFrontpageHeader,
        postBalancingFrontpage,
        putBalancingFrontpage,
        deleteBalancingFrontpage,
    }
}
import {useEffect, useState} from "react";
import {SpinLoader} from "../common/SpinLoader";
import MeasurementCard from "./MeasurementCard";
import requireTranslation from "../../lang/locales/config";
import ErrorMessage, {InactiveCheck} from "../common/ErrorMessage";
import {MeasurementModel} from "../../models/case/measurement";
import {useMeasurementApi} from "../../API/useClientCaseApi";
import {MeasurementForm} from "./MeasurementForm";

interface MeasurementListProps {
    clientId: string;
    onEdit: (formElement: JSX.Element,
             statusMessage?: string,
             newClientName?: string,
             shouldRefreshStatus?: boolean) => void;
}


export default function MeasurementList({ clientId, onEdit }: MeasurementListProps) {
    const t = requireTranslation();
    const [measurements, setMeasurements ] = useState<MeasurementModel[] | []>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const { getMeasurements } = useMeasurementApi();

    useEffect(() => {
        setLoading(true);
        getMeasurements(clientId)
            .then((data) => {
                if (data.length === 0) {
                    onEdit(
                        <InactiveCheck>
                            <MeasurementForm onSave={onEdit} clientId={clientId} />
                        </InactiveCheck>
                    )
                }
                setMeasurements(data)
            })
            .catch((e) => {
                if (e instanceof Error) setError(e.message);
            })
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientId])

    /**
     * Handles when the new button is clicked.
     * Changes content the MeasurementForm in add mode.
     */
    const handleNewClick = () =>
        onEdit(
            <InactiveCheck>
                <MeasurementForm
                    onSave={onEdit}
                    clientId={clientId}
                    haveBackButton={true} />
            </InactiveCheck>
        );

    return (
        <>
            <ErrorMessage
                error={error}
                containerClassName="content color-main-background" />
            <SpinLoader
                loading={loading}
                loadMessage={`${t.loading} ${t.measurements.toLowerCase()}...`}
                containerClassName="content color-main-background" />

            {!loading && !error && measurements && (
                <div className="content fold-out color-main-background">
                    <div className="row toColumn">
                        <h3 className="content-headline available">{`${t.measurements}`}</h3>
                        <button
                            className="filled"
                            onClick={handleNewClick}>{t.new}</button>
                    </div>
                    <div className="content-inner-list">
                        {measurements.length > 0 ? measurements.map((measurement) => (
                            <MeasurementCard
                                key={measurement.id}
                                measurement={measurement}
                                onEdit={onEdit} />
                        )) : (
                            <p className="center-page">{t.messages.noMeasurements}</p>
                        )}
                    </div>
                </div>
            )}
        </>
    )
}
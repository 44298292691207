import React from "react";
import {EmployeeModel} from "../../models/Employee";
import requireTranslation from "../../lang/locales/config";


interface EmployeeCardProps {
    employee: EmployeeModel;
    onClick: (employee: EmployeeModel) => void;
}

function EmployeeCard({ employee, onClick }: EmployeeCardProps) {
    const t = requireTranslation();
    return (
        <div
            className="card clickable color-sec-background"
            onClick={() => {
                onClick(employee)
            }}>
            <h3 className="card-header">{employee.name}</h3>
            <p className="card-body">{employee.description ? employee.description : t.messages.noDescription}</p>
        </div>
    );
}

export default EmployeeCard;
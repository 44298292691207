import {Content} from "../common/ContentStandards";
import {IntegratedSpinLoader, useSpinLoader} from "../common/SpinLoader";
import {useEffect, useState} from "react";
import {UnsupportedLoggerFileDetails} from "../../models/case/loggerNoteFile";
import useAdminApi from "../../API/useAdminApi";
import AdminUnsupportedLoggerFilesList from "./AdminUnsupportedLoggerFilesList";
import ErrorMessage from "../common/ErrorMessage";
import AdminUnsupportedLoggerFileDetails from "./AdminUnsupportedLoggerFileDetails";
import AdminSupportedLoggerEditConstruct from "./AdminSupportedLoggerEditConstruct";

export default function AdminUnsupportedLoggerFilesPage() {
    const [
        loading, setLoading,
        loadingMessage, setLoadingMessage
    ] = useSpinLoader();
    const [ error, setError ] = useState("");
    const [ loggerList, setLoggerList ] = useState<UnsupportedLoggerFileDetails[]>();
    const [ updateLoggerList, setUpdateLoggerList ] = useState(true);
    const [ selectedLogger, setSelectedLogger ] = useState<string>();
    const [ viewSupportedList, setViewSupportedList ] = useState(false);
    const { getUnsupportedLoggerFiles } = useAdminApi();

    useEffect(() => {
        if (!loggerList || updateLoggerList) {
            setLoading(true);
            setLoadingMessage("Getting unsupported logger files...");
            getUnsupportedLoggerFiles()
                .then(setLoggerList)
                .catch((e) => {
                    if (e instanceof Error) {
                        setError(e.message);
                    }
                })
                .finally(() => {
                    setLoading(false);
                    setUpdateLoggerList(false);
                });
        }
    }, [loggerList, updateLoggerList]);

    const handleLoggerClick = (loggerId: string) => {
        setSelectedLogger(loggerId);
    };

    const handleBackClick = (updateList: boolean = false) => {
        if (updateList) {
            setUpdateLoggerList(true);
        }
        setSelectedLogger(undefined);
    }




    return (
        <Content
            invisible={true}
            flexGrow={true}
            headline={viewSupportedList ? "Supported" : "Unupported"}
            centerHeadline={true}>
            <div className="row reverse">
                <button
                    className="filled"
                    onClick={() => setViewSupportedList(
                        (prevState) => !prevState
                    )}>
                    Change
                </button>
                {!viewSupportedList &&
                    <button
                        className="outline"
                        onClick={() => setUpdateLoggerList(true)}>
                        Refresh
                    </button>
                }
            </div>
            <>
                {loggerList && !selectedLogger && !viewSupportedList && (
                    <AdminUnsupportedLoggerFilesList
                        loggerList={loggerList}
                        onLoggerClick={handleLoggerClick} />
                )}
                {selectedLogger && (
                    <AdminUnsupportedLoggerFileDetails
                        loggerId={selectedLogger}
                        onBack={handleBackClick} />
                )}
                {viewSupportedList && (
                    <AdminSupportedLoggerEditConstruct />
                )}
            </>

            <IntegratedSpinLoader
                loading={loading}
                loadMessage={loadingMessage} />

            <ErrorMessage error={error} />
        </Content>
    );
}
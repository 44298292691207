import {useAppSelector} from "../../state/hooks";
import {selectAuth} from "../../state/authSlice";
import {Navigate, Outlet} from "react-router-dom";
import {selectUser} from "../../state/userSlice";
import {isAdmin} from "./Enums";

interface PrivateRouteProps {
    redirectPath?: string;
}

export function PrivateRoute({ redirectPath = "/login" }: PrivateRouteProps) {
    const auth = useAppSelector(selectAuth);
    const user = useAppSelector(selectUser);

    if (!auth.token || isAdmin(user.accessLevel)) {
        return <Navigate to={redirectPath} replace={true} />;
    }
    return <Outlet />
}

export function AdminRoute({ redirectPath = "/login" }) {
    const auth = useAppSelector(selectAuth);
    const user = useAppSelector(selectUser);

    if (!auth.token || !isAdmin(user.accessLevel)) {
        return <Navigate to={redirectPath} replace={true} />;
    }

    return <Outlet />
}
import {useStandardSetup} from "../../utils/customHooks";
import {SupportedLoggersAdminModel} from "../../models/case/supportedLoggers";
import React, {ChangeEvent, useEffect, useState} from "react";
import useAdminApi from "../../API/useAdminApi";
import {SupportedDeviceTemplate} from "../../models/case/loggerNoteFile";
import {FileInputWithLabel, SelectFieldWithLabel} from "../common/FormCommons";
import {Content, ContentForm} from "../common/ContentStandards";
import {InformationRow} from "../common/TextCommons";
import {IntegratedSpinLoader} from "../common/SpinLoader";
import LoggerNoteModel from "../../models/case/loggerNote";
import ErrorMessage from "../common/ErrorMessage";
import AdminLoggerConstructTestResults from "./AdminLoggerConstructTestResults";

export default function AdminSupportedLoggerEditConstruct() {
    const [
        loading, setLoading,
        error, setError,
        supportedDevices, setSupportedDevices
    ] = useStandardSetup<SupportedLoggersAdminModel>();
    const [ testMessage, setTestMessage ] = useState("");
    const [ uploadTestFile, setUploadTestFile ] = useState<File>();
    const [ template, setTemplate ] = useState<SupportedDeviceTemplate>();
    const [ initialTemplate, setInitialTemplate ] = useState<SupportedDeviceTemplate>();
    const [ currentFilename, setCurrentFilename ] = useState("");
    const [ currentDeviceName, setCurrentDeviceName ] = useState("");
    const [ getTemplate, setGetTemplate ] = useState("");
    const [ refreshExtractorList, setRefreshExtractorList ] = useState(false);
    const [ testResults, setTestResults ] = useState<LoggerNoteModel[]>();
    const {
        getSupportedDevices,
        getSupportedDeviceTemplate,
        putSupportedDeviceTest,
        putSupportedDevice
    } = useAdminApi();

    useEffect(() => {
        if (!supportedDevices || refreshExtractorList) {
            setLoading(true);
            getSupportedDevices()
                .then(setSupportedDevices)
                .catch((e) => {
                    if (e instanceof Error) {
                        setError(e.message);
                    }
                })
                .finally(() => {
                    setLoading(false);
                    setRefreshExtractorList(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supportedDevices, refreshExtractorList]);

    useEffect(() => {
        if (getTemplate) {
            setLoading(true);
            getSupportedDeviceTemplate(getTemplate)
                .then((loadedTemplate) => {
                    setTemplate(loadedTemplate);
                    setInitialTemplate(loadedTemplate);
                })
                .catch((e) => {
                    if (e instanceof Error) {
                        setError(e.message);
                    }
                })
                .finally(() => {
                    setGetTemplate("");
                    setLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getTemplate]);

    const handleSupportedDeviceChange = (event: any) => {
        const { value } = event.target;

        // eslint-disable-next-line array-callback-return
        const object = supportedDevices!!.supportedLoggers.find((model) => {
            if (model.name === value) {
                return model;
            }
        });

        const filename = object!!.filename;
        const deviceName = object!!.name;

        setGetTemplate(filename);
        setCurrentFilename(filename);
        setCurrentDeviceName(deviceName);
    }

    const handleChangeTemplate = (event: ChangeEvent<HTMLTextAreaElement>) => {
        const { value, name } = event.target;

        setTemplate((prevState) => {
            return new SupportedDeviceTemplate({ ...prevState!!, ...{[name]: value} })
        })
    };

    const handleChooseFile = (e: ChangeEvent<HTMLInputElement>) => {
        setUploadTestFile(e.target.files!![0])
    };

    const handleRunTest = () => {
        const formData = new FormData();

        formData.append("testFile", uploadTestFile!!, uploadTestFile!!.name);
        formData.append("jsonData", template!!.jsonString)

        setLoading(true);
        putSupportedDeviceTest(currentDeviceName, formData)
            .then(setTestResults)
            .catch((e) => {
                if (e instanceof Error) {
                    setTestMessage(e.message);
                }
            })
            .finally(() => setLoading(false))
    };

    const handleEditTemplate = () => {
        setLoading(true);
        putSupportedDevice(template!!.jsonString, currentFilename)
            .then(() => {
                setGetTemplate(currentFilename);
                setRefreshExtractorList(true);
            })
            .catch((e) => {
                if (e instanceof Error) {
                    setError(e.message)
                }
            })
            .finally(() => {
                setTestResults(undefined);
                setLoading(false);
            })
    };

    return (
        <Content invisible={true} flexGrow={true}>
            <ContentForm>
                <div>
                    {supportedDevices && (
                        <SelectFieldWithLabel
                            labelText={"Browse Existing Extractor Files"}
                            selection={supportedDevices.supportedLoggers.map((model) => {
                                return model.name;
                            })}
                            initialValue={""}
                            onChange={handleSupportedDeviceChange}/>
                    )}
                    {template && (
                        <textarea
                            className="input text-area adjust-to-content"
                            name="jsonString"
                            value={template.jsonString}
                            onChange={handleChangeTemplate}/>
                    )}
                    <FileInputWithLabel
                        labelText="Test File"
                        buttonText="Choose"
                        uploadFileText={uploadTestFile ? uploadTestFile.name : ""}
                        accepted=".txt"
                        onChange={handleChooseFile}
                        onRemove={uploadTestFile ? () => {setUploadTestFile(undefined)} : undefined}/>
                    <InformationRow title={"Current File"} text={currentFilename} />
                    <InformationRow title={"Current Device Name"} text={currentDeviceName} />
                    <div className="row reverse margin">
                        {template && template.jsonString !== initialTemplate?.jsonString &&
                            currentDeviceName && uploadTestFile &&
                            <button className="filled" onClick={handleRunTest}>
                                Test Run
                            </button>
                        }
                        {testResults && template?.jsonString !== initialTemplate?.jsonString && currentFilename &&
                            <button className="outline" onClick={handleEditTemplate}>
                                Edit
                            </button>
                        }
                    </div>
                    <AdminLoggerConstructTestResults
                        results={testResults}
                        testMessage={testMessage} />
                </div>

                <IntegratedSpinLoader loading={loading} loadMessage={""} />
                <ErrorMessage error={error} />
            </ContentForm>
        </Content>
    );
}
import {MeasurementModel} from "../../models/case/measurement";
import requireTranslation from "../../lang/locales/config";
import {InformationRow} from "../common/TextCommons";
import {MeasurementForm} from "./MeasurementForm";
import {InactiveCheck} from "../common/ErrorMessage";

interface MeasurementCardProps {
    measurement: MeasurementModel;
    onEdit: (formElement: JSX.Element, statusMessage?: string) => void;
}


export default function MeasurementCard({ measurement, onEdit }: MeasurementCardProps) {
    const t = requireTranslation();

    return (
        <div
            className="card color-sec-background clickable"
            onClick={() => onEdit(
                <InactiveCheck>
                    <MeasurementForm
                        initialMeasurement={measurement}
                        onSave={onEdit}
                        haveBackButton={true} />
                </InactiveCheck>
            )}>
            <div className="row">
                <h3 className="card-header available">{`${measurement.room}
                    ${measurement.roomNumber ? measurement.roomNumber : ""}`}
                </h3>
                {!measurement.measured &&
                    <img
                        className="icon-button right-align color-needed filter"
                        src={"edit.svg"}
                        alt={t.edit} />
                }
            </div>
            <InformationRow title={`${t.airflow}:`} text={t.airflowDirectionList[measurement.armatureType!!]}/>
            <InformationRow title={`${t.projected}:`} text={`${measurement.projected} ${t.m3h}`}/>
            <InformationRow title={`${t.measured}:`} text={`${measurement.measured ? measurement.measured : '-'} ${t.m3h}`} />
        </div>
    )
}
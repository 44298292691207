import LoggerNoteModel from "../../models/case/loggerNote";
import {InformationRow} from "../common/TextCommons";
import React from "react";
import {Content} from "../common/ContentStandards";
import {LoggerDeviceAddResults} from "../../models/case/LoggerDevice";
import {boolString} from "../../utils/stringModify";

interface LoggerConstructTestResultsProps {
    results?: LoggerNoteModel[];
    testMessage: string;
}

/**
 *
 * @param results The results of a test.
 * @param testMessage A message received on failed extraction.
 */
export default function AdminLoggerConstructTestResults(
    { results, testMessage }: LoggerConstructTestResultsProps
) {
    return (
        <>
        {results && !testMessage &&
            <Content centerHeadline={true} headline={"Test Results"}>
                <div className="content-inner-list">
                    {results.map((result, index) => (
                            <div className="card color-page-background" key={result.id ? result.id : index}>
                                <div>
                                    <InformationRow title={"note"} text={result.note}/>
                                    <InformationRow title={"date"} text={result.date}/>
                                    <InformationRow title={"deviceName"}
                                                    text={result.deviceName ? result.deviceName : "'null'"}/>
                                    <InformationRow title={"deviceNumber"}
                                                    text={result.deviceNumber ? result.deviceNumber : "'null'"}/>
                                    <InformationRow title={"serialName"}
                                                    text={result.serialName ? result.serialName : "'null'"}/>
                                    <InformationRow title={"serialNumber"}
                                                    text={result.serialNumber ? result.serialNumber : "'null'"}/>
                                    <InformationRow title={"apf"} text={result.apf ? result.apf : "'null'"}/>
                                    <InformationRow title={"timeConstant"}
                                                    text={result.timeConstant ? result.timeConstant : "'null'"}/>
                                    <InformationRow title={"density"}
                                                    text={result.density ? result.density : "'null'"}/>
                                    <InformationRow title={"barometer"}
                                                    text={result.barometer ? result.barometer : "'null'"}/>
                                    <InformationRow title={"area"} text={result.area ? result.area : "'null'"}/>
                                    <InformationRow title={"flow"} text={result.flow ? result.flow : "'null'"}/>
                                    <InformationRow title={"speed"} text={result.speed ? result.speed : "'null'"}/>
                                    <InformationRow title={"temperature"}
                                                    text={result.temperature ? result.temperature : "'null'"}/>
                                </div>
                                <hr/>
                            </div>
                        )
                    )}
                </div>
            </Content>
        }
            {testMessage &&
                <div className="row margin">
                <p className="error message">{testMessage}</p>
            </div>
        }
        </>
    )
}

interface LoggerConstructUploadResultsProps {
    uploadResults: LoggerDeviceAddResults,
    onBack: (updateList?: boolean) => void;
}

export function AdminLoggerConstructUploadResults(
    { uploadResults, onBack }: LoggerConstructUploadResultsProps
) {
    return (
        <Content headline={"Upload Results"} centerHeadline={true}>
            <div className="content-inner-form">
                <InformationRow title={"extractSuccess"} text={uploadResults.extractSuccess.toString()} />
                <InformationRow title={"extractFailed"} text={uploadResults.extractFailed.toString()} />
                <InformationRow title={"runtimeSuccess"} text={boolString(uploadResults.runtimeSuccess)} />
                <div className="row reverse">
                    <button
                        className="filled"
                        onClick={() => onBack(true)}>
                        Finished
                    </button>

                </div>
            </div>
        </Content>
    )
}
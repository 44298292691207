import requireTranslation from "../../lang/locales/config";
import "../../styles/homepage.css"
import {InformationRow} from "../common/TextCommons";

export default function SupportPage() {
    const t = requireTranslation();

    return (
        <div id="home">
            <div className="home-content no-bottom-padding">
                <div className="home-text-container">
                    <h1>{t.support}</h1>
                    <p className="center">{t.informationTexts.supportPart1}</p>
                </div>
                <div className="watermark-container color-sec-background">
                    <div className="watermark-content">
                        <h3 className="content-headline">{t.supportInfo.title}</h3>
                        <InformationRow title={t.email} text={t.supportInfo.email}/>
                        <InformationRow title={t.phone} text={t.supportInfo.phone}/>
                        <InformationRow title={t.phoneHours} text={t.supportInfo.phoneHours}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
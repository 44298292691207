import requireTranslation from "../../lang/locales/config";
import {useStandardSetup} from "../../utils/customHooks";
import {useLoggerNoteApi, useLoggerNoteFileApi} from "../../API/useClientCaseApi";
import {useEffect, useState} from "react";
import LoggerNoteFileModel from "../../models/case/loggerNoteFile";
import {SpinLoader} from "../common/SpinLoader";
import ErrorMessage from "../common/ErrorMessage";
import {ConfirmationDialog, useDialog} from "../common/ConfirmationDialog";

interface LoggerNoteExistingFileProps {
    clientId: string;
    afterDeleteSuccess: () => void;
}

export default function LoggerNoteExistingFile({ clientId, afterDeleteSuccess }: LoggerNoteExistingFileProps) {
    const t = requireTranslation();
    const [
        loading, setLoading,
        error, setError,
        loggerNoteFile, setLoggerNoteFile,
    ] = useStandardSetup<LoggerNoteFileModel>();
    const [ loadingMessage, setLoadingMessage ] = useState(`${t.loading} ${t.loggerNoteFile}...`);
    const { isOpen, message: dialogMessage, setDialogMessage, toggle: toggleDialog } = useDialog();
    const { getLoggerNoteFile, deleteLoggerNoteFile } = useLoggerNoteFileApi();
    const [ performDelete, setPerformDelete ] = useState(false);
    const { deleteLoggerNotes } = useLoggerNoteApi();

    useEffect(() => {
        setLoading(true);
        getLoggerNoteFile(clientId)
            .then((data) => {
                setLoggerNoteFile(data);
            })
            .catch((e) => {
                if (e instanceof Error) setError(e.message)
            })
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientId]);

    useEffect(() => {
        if (performDelete) {
            setLoading(true);
            setLoadingMessage(`${t.delete} ${t.loggerNotes}...`);
            deleteLoggerNotes(clientId)
                .then(() => {
                    afterDeleteSuccess();
                })
                .catch((e) => {
                    if (e instanceof Error) {
                        // console.log(e.message)
                    }
                })
                .finally(() => {
                    setLoading(false)
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [performDelete])

    const handleDeleteClick = () => {
        setDialogMessage(t.messages.confirmDelete);
        toggleDialog();
    }

    const handleDeleteFile = async () => {
        setLoading(true);
        setLoadingMessage(`${t.delete} ${t.loggerNoteFile}...`)
        await deleteLoggerNoteFile(clientId)
            .then(() => {
                setPerformDelete(true);
            })
            .catch((e) => { if (e instanceof Error) {
                // console.log(e.message)
            } })
            .finally(() => { setLoading(false) });
    };

    return (
        <>
            {isOpen && (
                <ConfirmationDialog
                    toggle={toggleDialog}
                    message={dialogMessage}
                    onConfirm={handleDeleteFile} />
            )}
            <SpinLoader
                containerClassName="content color-main-background"
                loading={loading}
                loadMessage={loadingMessage} />

            <ErrorMessage
                error={error}
                containerClassName="content color-main-background"/>

            {!loading && !error && loggerNoteFile && (
                <div className="content color-main-background fold-out">
                    <h2 className="content-headline center-page">{t.loggerNoteFile}</h2>
                    <h3 className="content-headline center-page">{loggerNoteFile.filename}</h3>
                    <div className="content-box">
                        <div className="row space-between">
                            <a
                                href={loggerNoteFile?.url}
                                download={`${loggerNoteFile.filename}`}>{t.download}</a>
                            <button
                                className="outline"
                                onClick={handleDeleteClick}>{t.delete}</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
import {WattAndCommentsModel} from "../../models/case/wattAndComments";
import requireTranslation from "../../lang/locales/config";
import {useWattAndCommentsApi} from "../../API/useClientCaseApi";
import {OverlaySpinLoader} from "../common/SpinLoader";
import {useFormSetup} from "../../utils/customHooks";
import {SyntheticEvent} from "react";
import {NumberInputWithLabel, TextAreaWithLabel} from "../common/FormCommons";
import {isInputErrorsEmpty, validateRequired, validateTextArea} from "../../utils/inputValidation";
import WattAndCommentsDetails from "./WattAndCommentDetails";

interface WattAndCommentsFormProps {
    initialWattAndComment?: WattAndCommentsModel;
    onSave: (returnElement: JSX.Element,
             statusMessage?: string,
             newClientName?: string,
             shouldRefreshStatus?: boolean) => void;
    clientId?: string;
    haveBackButton?: boolean;
}

interface InputErrorInter {
    watts: string;
    comments: string;
}


export default function WattAndCommentsForm(
    { initialWattAndComment, onSave, clientId, haveBackButton = false }: WattAndCommentsFormProps
) {
    if (!initialWattAndComment && !clientId) {
        throw Error("If initialWattAndComment is undefined, then clientId must be defined!");
    }
    let newWattAndComment = null;
    if (!initialWattAndComment) {
        newWattAndComment = new WattAndCommentsModel();
        newWattAndComment.clientId = clientId!!;
    }

    // console.log("Render watt and comments form!");
    const t = requireTranslation();
    const { postWattAndComments, putWattAndComments } = useWattAndCommentsApi();
    const initialInputErrors: InputErrorInter = {
        "watts": "",
        "comments": ""
    }
    const [
        awaiting, setAwaiting,
        inputErrors, setInputErrors,
        error, setError,
        wattAndComments, setWattAndComments,
    ] = useFormSetup(initialWattAndComment ? initialWattAndComment : newWattAndComment!!, initialInputErrors);

    /**
     * Handles when the back button (if any) is clicked.
     */
    const handleBackClick = () => {
        onSave(
            <WattAndCommentsDetails clientId={wattAndComments.clientId} onEdit={onSave} />
        )
    }

    /**
     * Handles a change in an input field.
     * Updates the WattAndCommentsModel to match the change.
     */
    const handleChange = (event: any, alt_value: boolean = false) => {
        const { name, value } = event.target;
        const change = { [name]: alt_value ? null : value };

        setWattAndComments((wattAndComments) => {
            return new WattAndCommentsModel({...wattAndComments, ...change});
        });
    };

    /**
     * Handles when the save button is clicked.
     * First validates input, if valid - a PUT or POST request is sent to the server,
     * depending on the 'isNew' flag.
     */
    const handleSave = async () => {
        if (validateInput()) {
            if (!initialWattAndComment) {
                setAwaiting(true);
                // console.log(wattAndComments);
                await postWattAndComments(wattAndComments)
                    .then(() => {
                        onSave(
                            <WattAndCommentsDetails
                                clientId={wattAndComments.clientId}
                                onEdit={onSave}/>,
                            t.messages.successAdd,
                            undefined,
                            true
                        );
                    })
                    .catch((e) => {
                        if (e instanceof Error) setError(e.message);
                    })
                    .finally(() => setAwaiting(false));

            } else {
                setAwaiting(true);
                // console.log(wattAndComments);
                await putWattAndComments(wattAndComments)
                    .then(() => {
                        onSave(
                            <WattAndCommentsDetails
                                clientId={wattAndComments.clientId}
                                onEdit={onSave}/>,
                            t.messages.successEdit,
                        );
                    })
                    .catch((e) => {
                        if (e instanceof Error) setError(e.message);
                    })
                    .finally(() => setAwaiting(false));
            }
        }
    };

    /**
     * Validates the input fields. Sets error text (inputErrors) if any is invalid.
     * Returns True if all tests passed.
     */
    const validateInput = (): boolean => {
        const err = { ...inputErrors };

        err.watts = validateRequired(wattAndComments.watts);
        err.comments = validateTextArea(wattAndComments.comments, true);

        setInputErrors(err);
        return isInputErrorsEmpty<InputErrorInter>(err);
    }

    return (
        <>
            <OverlaySpinLoader
                loading={awaiting}
                loadMessage={`${t.awaiting}...`}/>

            <div className="content color-main-background fold-out">
                <div className="content-inner-form">
                    <div className="row">
                        {haveBackButton &&
                            <img
                                className="icon-button"
                                src={"back_arrow.svg"}
                                alt={t.back}
                                onClick={handleBackClick}/>
                        }
                        {initialWattAndComment && (
                            <h3 className="form-header">{t.edit} {t.wattAndComments}</h3>
                        )}
                        {!initialWattAndComment && (
                            <h3 className="form-header">{t.add} {t.wattAndComments}</h3>
                        )}
                    </div>
                    <form
                        aria-label="wattAndComments form"
                        name="wattAndComments form"
                        onSubmit={(e: SyntheticEvent) => e.preventDefault()}>
                        <NumberInputWithLabel
                            labelText={t.watts}
                            name="watts"
                            ariaLabel="wattAndComments watts"
                            placeholder={`${t.enter} ${t.watts.toLowerCase()}`}
                            initialValue={wattAndComments.watts}
                            error={inputErrors.watts}
                            onChange={handleChange}/>
                        <TextAreaWithLabel
                            labelText={t.comments}
                            name="comments"
                            ariaLabel="wattAndComments comments"
                            placeholder={`${t.enter} ${t.comments.toLowerCase()}`}
                            initialValue={wattAndComments.comments}
                            error={inputErrors.comments}
                            onChange={handleChange}/>
                        <div className="row margin reverse">
                            <button
                                className="filled"
                                onClick={handleSave}>{t.save}</button>
                        </div>
                        {error && (
                            <div className="row margin">
                                <p className="error message">{error}</p>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </>
    );
}
import {MenuButton, StandardMenu, useMenu} from "../common/Menu_V2";
import CaseStatusModel from "../../models/case/caseStatus";
import ClientDetails from "./ClientDetails";
import InstallationDetails from "./InstallationDetails";
import WattAndCommentsDetails from "./WattAndCommentDetails";
import React, {useEffect, useState} from "react";
import requireTranslation from "../../lang/locales/config";
import WattAndCommentsForm from "./WattAndCommentsForm";
import ArmatureList from "./ArmatureList";
import MeasurementList from "./MeasurementList";
import StepReadingsList from "./StepReadingsList";
import TotalsDetails from "./TotalsDetails";
import LoggerNoteSection from "./LoggerNoteSection";
import ReportViewer from "./ReportViewer";
import ArmatureForm from "./ArmatureForm";
import InstallationForm from "./InstallationForm";
import StepReadingForm from "./StepReadingForm";
import {REQUIRED_STEP_READINGS} from "../../constants/requirement";
import {ConfirmationDialog, InformationDialog, useDialog} from "../common/ConfirmationDialog";
import DuplicationForm from "./DuplicationForm";
import {useNavigate} from "react-router-dom";
import {OverlaySpinLoader} from "../common/SpinLoader";
import {useClientApi} from "../../API/useClientCaseApi";
import {InactiveCheck} from "../common/ErrorMessage";


interface CaseMenuProps {
    currentCaseStatus: CaseStatusModel;
    clientId: string;
    handleMenuButtonClick: (content: JSX.Element) => void;
    handleChangeShownContent: (formElement: JSX.Element,
                               statusMessage?: string,
                               newClientName?: string,
                               shouldRefreshStatus?: boolean ) => void;
}

export default function CaseMenu({
     currentCaseStatus,
     clientId,
     handleMenuButtonClick,
     handleChangeShownContent }: CaseMenuProps) {
    const t = requireTranslation();
    const [ highlightList, setHighLightIndex ] = useMenu(11);
    const [ isComplete, setIsComplete ] = useState(false);
    const { isOpen, message: dialogMessage, setDialogMessage, toggle } = useDialog();
    const [ awaiting, setAwaiting ] = useState(false);
    const { deleteClient } = useClientApi();
    const navigate = useNavigate();
    const {
        isOpen: isConOpen,
        message: dialogConMessage,
        setDialogMessage: setConMessage,
        toggle: toggleConfirmationDialog
    } = useDialog();

    useEffect(() => {
        // console.log("Checking if case is complete!")
        // console.log("logger note file status:", currentCaseStatus.loggerNoteFile, "logger note extracted:", currentCaseStatus.loggerNotes)
        if (
            currentCaseStatus.installation && currentCaseStatus.armatures && currentCaseStatus.measurements &&
            currentCaseStatus.wattAndComments && currentCaseStatus.stepReadings
        ) {
            setIsComplete(true);
        }
    }, [currentCaseStatus]);

    const handleHighlight = (index: number, content: JSX.Element) => {
        setHighLightIndex(index);
        handleMenuButtonClick(content);
    }

    /**
     * Handles when logger note or view pdf is clicked before the case is complete.
     * Shows a dialog telling that the case should be finished before these are available.
     */
    const handleInaccessibleClick = () => {
        setDialogMessage(t.messages.notAccessibleCase);
        toggle();
    };

    const handleDeleteConfirmationDialog = () => {
        setConMessage(t.messages.confirmDelete);
        toggleConfirmationDialog();
    };

    const handleDeleteCase = () => {
        // console.log("Delete case!!");
        toggleConfirmationDialog();
        setAwaiting(true);
        deleteClient(clientId)
            .then(() => {
                navigate("/cases", {state: {
                    statusMessage: t.messages.successDelete
                }});
            })
            .catch((e) => {
                if (e instanceof Error) {
                    // console.log(e.message)
                    setDialogMessage(e.message);
                    toggle();
                }
            })
            .finally(() => setAwaiting(false))
    };

    return (
        <>
            {isOpen && <InformationDialog
                toggle={toggle}
                message={dialogMessage} />
            }

            {isConOpen &&
                <ConfirmationDialog
                    toggle={toggleConfirmationDialog}
                    message={dialogConMessage}
                    onConfirm={handleDeleteCase} />
            }

            <OverlaySpinLoader
                loading={awaiting}
                loadMessage={t.loadingMessages.deletingCQM.replace("?", t.client)} />

            <StandardMenu>
                <MenuButton
                    text={t.caseInformation}
                    onClick={() => handleHighlight(0, <ClientDetails
                        clientId={clientId}
                        onEdit={handleChangeShownContent}/>)}
                    isHighlight={highlightList[0]}
                />
                <MenuButton
                    text={t.installationInformation}
                    onClick={() => handleHighlight(1, currentCaseStatus.installation ?
                        <InstallationDetails
                            clientId={clientId}
                            onEdit={handleChangeShownContent}/> :
                        <InactiveCheck>
                            <InstallationForm onSave={handleChangeShownContent} clientId={clientId} />
                        </InactiveCheck>
                    )}
                    isHighlight={highlightList[1]}
                    initialClassName={currentCaseStatus.installation ?
                        undefined :
                        "content-control-menu-button fixed-height color-needed"}
                />
                <MenuButton
                    text={t.armatures}
                    onClick={() => handleHighlight(2, currentCaseStatus.armatures ?
                        <ArmatureList
                            clientId={clientId}
                            onEdit={handleChangeShownContent}/> :
                        <InactiveCheck>
                            <ArmatureForm onSave={handleChangeShownContent} clientId={clientId} />
                        </InactiveCheck>
                    )}
                    isHighlight={highlightList[2]}
                    initialClassName={currentCaseStatus.armatures ?
                        undefined :
                        "content-control-menu-button fixed-height color-needed"}
                />
                <MenuButton
                    text={t.measurements}
                    onClick={() => handleHighlight(3,
                        <MeasurementList
                            clientId={clientId}
                            onEdit={handleChangeShownContent}/>
                    )}
                    isHighlight={highlightList[3]}
                    initialClassName={currentCaseStatus.measurements ?
                        undefined :
                        "content-control-menu-button fixed-height color-needed"}
                />
                <MenuButton
                    text={t.stepReadings}
                    onClick={() => handleHighlight(4, currentCaseStatus.stepReadings !== 0 ?
                        <StepReadingsList
                            clientId={clientId}
                            onEdit={handleChangeShownContent} /> :
                        <InactiveCheck>
                            <StepReadingForm
                                onSave={handleChangeShownContent}
                                clientId={clientId}
                                stepReadingCount={currentCaseStatus.stepReadings} />
                        </InactiveCheck>
                    )}
                    isHighlight={highlightList[4]}
                    initialClassName={currentCaseStatus.stepReadings >= REQUIRED_STEP_READINGS ?
                        undefined :
                        "content-control-menu-button fixed-height color-needed"}
                />
                <MenuButton
                    text={t.wattAndComments}
                    onClick={() => handleHighlight(5, currentCaseStatus.wattAndComments ?
                        <WattAndCommentsDetails
                            clientId={clientId}
                            onEdit={handleChangeShownContent}/> :
                        <InactiveCheck>
                            <WattAndCommentsForm onSave={handleChangeShownContent} clientId={clientId} />
                        </InactiveCheck>
                    )}
                    isHighlight={highlightList[5]}
                    initialClassName={currentCaseStatus.wattAndComments ?
                        undefined :
                        "content-control-menu-button fixed-height color-needed"}
                />
                <MenuButton
                    text={t.totals}
                    onClick={() => handleHighlight(6, <TotalsDetails
                        clientId={clientId}/>)}
                    isHighlight={highlightList[6]}
                />
                <MenuButton
                    text={t.loggerNote}
                    onClick={isComplete ?
                        () => handleHighlight(7, <LoggerNoteSection
                            clientId={clientId}
                            changeContent={handleChangeShownContent}/>) :
                        () => handleInaccessibleClick()
                }
                    isHighlight={highlightList[7]}
                    initialClassName={isComplete ?
                        (currentCaseStatus.loggerNoteFile ?
                            undefined :
                            "content-control-menu-button fixed-height color-needed") :
                        "content-control-menu-button fixed-height color-low-emphasis"
                    }/>
                <MenuButton
                    text={t.viewPdf}
                    onClick={isComplete ?
                        () => handleHighlight(8, <ReportViewer
                            clientId={clientId}/>) :
                        () => handleInaccessibleClick()
                    }
                    isHighlight={highlightList[8]}
                    initialClassName={isComplete ?
                        undefined :
                        "content-control-menu-button fixed-height color-low-emphasis"
                    }/>
                <MenuButton
                    text={t.duplicate}
                    onClick={() => handleHighlight(
                        9,
                        <InactiveCheck>
                            <DuplicationForm
                                clientId={clientId}/>
                        </InactiveCheck>
                    )}
                    isHighlight={highlightList[9]} />
                <MenuButton
                    text={t.delete}
                    onClick={handleDeleteConfirmationDialog}
                    isHighlight={highlightList[10]}
                    initialClassName="content-control-menu-button fixed-height color-mild-warn"/>
            </StandardMenu>
        </>
    )
}
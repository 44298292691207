import React, {useEffect, useState} from "react";
import Overview from "./Overview";
import {PageWithContent} from "../common/ContentStandards";
import {useLocation} from "react-router-dom";
import ToastMessage from "../common/ToastMessage";

interface stateValueObject {
    statusMessage?: string;
}

/**
 * Component.
 * The first page the employee meets after the login.
 *
 */
export function OverviewPage() {
    const state: stateValueObject | null = useLocation().state;
    const [ toastMessage, setToastMessage ] = useState<string>();

    useEffect(() => {
        if (state)
            setToastMessage(state.statusMessage)
    }, [state])

    return (
        <PageWithContent>
            <ToastMessage
                toastMessage={toastMessage}
                setToastMessage={setToastMessage} />
            <Overview />
        </PageWithContent>
    );
}
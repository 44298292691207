import React from 'react';
import './styles/App.css';
import "./styles/nav.css";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import HomePage from "./components/home/HomePage";
import LoginPage from "./components/login/LoginPage";
import RegisterPage from "./components/login/RegisterPage";
import NotFoundPage from "./NotFoundPage";
import {useAppSelector} from "./state/hooks";
import {selectAuth} from "./state/authSlice";
import {useLoginApi} from "./API/useLoginApi";
import {AppHeaderAuthUser, AppHeaderNoAuth} from "./components/utils/AppHeaders";
import {AdminRoute, PrivateRoute} from "./components/utils/PrivateRoute";
import {OverviewPage} from "./components/overview/OverviewPage";
import ClientCaseDetailsPage from "./components/client-cases/ClientCaseDetailsPage";
import EmployeePage from "./components/employee/EmployeePage";
import EmployeesPage from "./components/employee/EmployeesPage";
import CasesPage from "./components/client-cases/CasesPage";
import RegisterEmployeePage from "./components/employee/RegisterEmployeePage";
import {selectUser} from "./state/userSlice";
import {isUser} from "./components/utils/Enums";
import ManageProfilePage from "./components/user/ManageProfilePage";
import ResetPasswordPage from "./components/login/ResetPasswordPage";
import UploadFirmHeaderPage from "./components/user/UploadFirmHeaderPage";
import UploadFrontPage from "./components/user/UploadFrontPage";
import AdminPage from "./components/admin/AdminPage";
import ForgotLoginPage from "./components/login/ForgotLoginPage";
import AboutPage from "./components/home/AboutPage";
import SupportPage from "./components/home/SupportPage";
import CheckoutPage from "./components/login/CheckoutPage";
import CheckoutReturnPage from "./components/login/CheckoutReturnPage";

function App() {
    const auth = useAppSelector(selectAuth);
    const user = useAppSelector(selectUser);
    const { logout } = useLoginApi();

    return (
        <Router>
            {!auth.token ? (<AppHeaderNoAuth/>) : (<AppHeaderAuthUser logout={logout}/>)}
            <div className="App-page-container">
                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/about" element={<AboutPage/>}/>
                    <Route path="/login" element={<LoginPage />}/>
                    <Route path="/forgot-login" element={<ForgotLoginPage />}/>
                    <Route path="/register" element={<RegisterPage/>}/>
                    <Route path="/checkout/:subscription/:email/:customer?" element={<CheckoutPage />} />
                    <Route path="/checkout/return/:session_id/:subscription_choice/:existing?" element={<CheckoutReturnPage />} />
                    <Route path="/employee/register/:token" element={<RegisterEmployeePage/>} />
                    <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
                    <Route element={<PrivateRoute/>}>
                        <Route
                            path="/"
                            element={<HomePage/>}/>
                        <Route
                            path="/overview"
                            element={<OverviewPage/>}/>
                        <Route
                            path="/detail"
                            element={<ClientCaseDetailsPage/>}/>
                        {isUser(user.accessLevel) &&
                            <Route
                                path="/employee"
                                element={<EmployeePage/>}/>
                        }
                        {isUser(user.accessLevel) &&
                            <Route
                                path="/employees"
                                element={<EmployeesPage/>}/>
                        }
                        {isUser(user.accessLevel) &&
                            <Route
                                path="/firm-header"
                                element={<UploadFirmHeaderPage />}/>
                        }
                        {isUser(user.accessLevel) &&
                            <Route
                                path="/firm-front-page"
                                element={<UploadFrontPage />}/>
                        }
                        <Route
                            path="/profile"
                            element={<ManageProfilePage/>}/>
                        <Route
                            path="/cases"
                            element={<CasesPage />}/>
                        <Route
                            path="/support"
                            element={<SupportPage />}/>
                        <Route
                            path="*"
                            element={<NotFoundPage/>}/>
                    </Route>
                    <Route element={<AdminRoute />}>
                        <Route
                            path="/admin"
                            element={<AdminPage />}/>
                    </Route>
                    <Route path="*" element={<NotFoundPage/>}/>
                </Routes>
            </div>
        </Router>
    )
}

export default App;

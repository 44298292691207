import {useStandardSetup} from "../../utils/customHooks";
import {ArmatureModel} from "../../models/case/armature";
import {SpinLoader} from "../common/SpinLoader";
import requireTranslation from "../../lang/locales/config";
import ErrorMessage, {InactiveCheck} from "../common/ErrorMessage";
import {useEffect} from "react";
import {useArmatureApi} from "../../API/useClientCaseApi";
import ArmatureCard from "./ArmatureCard";
import ArmatureForm from "./ArmatureForm";


interface ArmatureListProps {
    clientId: string;
    onEdit: (formElement: JSX.Element,
             statusMessage?: string,
             newClientName?: string,
             shouldRefreshStatus?: boolean) => void;
}

export default function ArmatureList({ clientId, onEdit }: ArmatureListProps) {
    const t = requireTranslation();
    const [
        loading, setLoading,
        error, setError,
        armatures, setArmatures
    ] = useStandardSetup<ArmatureModel[]>();
    const { getArmatures } = useArmatureApi();

    useEffect(() => {
        setLoading(true);
        getArmatures(clientId)
            .then((data) => {
                if (data.length === 0) {
                    onEdit(
                        <InactiveCheck>
                            <ArmatureForm onSave={onEdit} clientId={clientId} />
                        </InactiveCheck>
                    )
                }
                setArmatures(data)
            })
            .catch((e) => {
                if (e instanceof Error) setError(e.message);
            })
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientId]);

    /**
     * Handles when the new button is clicked.
     * Changes content the ArmatureForm in add mode.
     */
    const handleNewClick = () =>
        onEdit(
            <InactiveCheck>
                <ArmatureForm
                    onSave={onEdit}
                    clientId={clientId}
                    haveBackButton={true} />
            </InactiveCheck>
        );


    return (
        <>
            <SpinLoader
                containerClassName="content color-main-background"
                loading={loading}
                loadMessage={`${t.loading} ${t.armatures.toLowerCase()}...`} />
            <ErrorMessage
                error={error}
                containerClassName="content color-main-background" />
            {!loading && !error && armatures && (
                <div className="content fold-out color-main-background">
                    <div className="row toColumn">
                        <h3 className="content-headline available">{`${t.armatures}`}</h3>
                        <button
                            className="filled"
                            onClick={handleNewClick}>{t.new}</button>
                    </div>
                    <div className="content-inner-list">
                        {armatures.length > 0 ? armatures.map((armature) => (
                            <ArmatureCard
                                key={armature.id}
                                armature={armature}
                                onEdit={onEdit} />
                        )) : (
                            <p className="center-page">{t.messages.noArmatures}</p>
                        )}

                    </div>
                </div>
            )}
        </>
    )
}
import {IntegratedSpinLoader, useSpinLoader} from "../common/SpinLoader";
import {useEffect, useState} from "react";
import {NewsCollectionGet} from "../../models/News";
import {Content} from "../common/ContentStandards";
import ErrorMessage from "../common/ErrorMessage";
import AdminNewsList from "./AdminNewsList";
import useAdminApi from "../../API/useAdminApi";
import AdminNewsCollectionForm from "./AdminNewsCollectionForm";
import {useLanguageApi} from "../../API/useLanguageApi";

export default function AdminNewsPage() {
    const [
        loading, setLoading,
        loadingMessage, setLoadingMessage
    ] = useSpinLoader();
    const [ error, setError ] = useState("");
    const [ getNewsList, setGetNewsList ] = useState(true);
    const [ newsList, setNewsList ] = useState<NewsCollectionGet[]>();
    const [ getSupportedLanguages, setGetSupportedLanguages ] = useState(true);
    const [ supportedLanguages, setSupportedLanguages ] = useState<string[]>();
    const [ selectedNews, setSelectedNews ] = useState<NewsCollectionGet>();
    const [ addNews, setAddNews ] = useState(false);
    const { getNewsList: getNews } = useAdminApi();
    const { getSupportedLanguages: getLanguages } = useLanguageApi();


    useEffect(() => {
        if (getNewsList) {
            setLoadingMessage("Getting News List...")
            setLoading(true);
            getNews()
                .then(setNewsList)
                .catch((e) => {
                    if (e instanceof Error) {
                        setError(e.message);
                    }
                })
                .finally(() => {
                    setLoading(false);
                    setGetNewsList(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getNewsList]);

    useEffect(() => {
        if (getSupportedLanguages) {
            setLoadingMessage("Getting Supported Languages...");
            setLoading(true);
            getLanguages()
                .then((value) => {
                    setSupportedLanguages(value.supportedLanguages);
                })
                .catch((e) => {
                    if (e instanceof Error) {
                        setError(e.message);
                    }
                })
                .finally(() => {
                    setLoading(false);
                    setGetSupportedLanguages(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getSupportedLanguages]);

    const handleNewsClick = (news: NewsCollectionGet) => {
        setSelectedNews(news);
    };

    const handleOnBack = (reload: boolean) => {
        if (reload) {
            setNewsList(undefined);
            setGetNewsList(true);
        }
        setAddNews(false);
        setSelectedNews(undefined);
    };

    const handleRefresh = () => {
        setError("");
        setNewsList(undefined);
        setGetNewsList(true);
    };

    return (
        <Content
            invisible={true}>
            <div className="row">
                {(selectedNews || addNews) &&
                    <img
                        className="icon-button"
                        src={"back_arrow.svg"}
                        alt={"Back"}
                        onClick={() => handleOnBack(false)}/>
                }
                <h3 className="content-container-headline available center-with-button-on-right">{"News"}</h3>
                {supportedLanguages && !addNews &&
                    <button
                        className={"filled"}
                        onClick={() => {
                            setSelectedNews(undefined);
                            setAddNews(true);
                        }}>
                        {"Add News"}
                    </button>
                }
                {!selectedNews && !addNews &&
                    <button
                        className="outline"
                        onClick={handleRefresh}>
                        Refresh
                    </button>
                }
            </div>


            <>
                {!error && newsList && !selectedNews && !addNews &&
                    <AdminNewsList
                        newsList={newsList}
                        onNewsClick={handleNewsClick}/>
                }
                {!error && !selectedNews && addNews && supportedLanguages &&
                    <AdminNewsCollectionForm
                        supportedLanguages={supportedLanguages}
                        onBack={handleOnBack}/>
                }
                {!loading && !error && selectedNews && supportedLanguages &&
                    <AdminNewsCollectionForm
                        newsCollectionInfo={selectedNews}
                        supportedLanguages={supportedLanguages}
                        onBack={handleOnBack}/>
                }
                <IntegratedSpinLoader
                    loading={loading}
                    loadMessage={loadingMessage}/>

                <ErrorMessage error={error}/>
            </>
        </Content>
    )
}
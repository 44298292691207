import React, {SyntheticEvent, useEffect, useState} from "react";
import "../../styles/login.css"
import "../../styles/styles.css"
import {Link, useNavigate} from "react-router-dom";
import {Login} from "../../models/Login";
import requireTranslation from "../../lang/locales/config";
import {useAppDispatch, useAppSelector} from "../../state/hooks";
import {selectAuth} from "../../state/authSlice";
import {TextInputWithLabel} from "../common/FormCommons";
import {selectUser, setUser} from "../../state/userSlice";
import {useUserApi} from "../../API/useUserApi";
import {useLoginApi} from "../../API/useLoginApi";
import {OverlaySpinLoader} from "../common/SpinLoader";
import {getSubscriptionToCreate, isAdmin, isNotCreated} from "../utils/Enums";
import {Content, PageWithContent} from "../common/ContentStandards";
import useCountdown from "../../utils/timer";
import {ToManyLoginTries} from "../../API/useFetch";



const LoginPage = () => {
    const t = requireTranslation();
    const auth = useAppSelector(selectAuth);
    const user = useAppSelector(selectUser);
    const navigate = useNavigate();
    const { getUser } = useUserApi();
    const { login, logout } = useLoginApi();
    const dispatch = useAppDispatch();
    const [loginInput, setLoginInput] = useState(new Login({
        username: "",
        password: "",
    }));
    const [loadingMessage, setLoadingMessage] = useState("");
    const [awaitLogin, setAwaitLogin] = useState(false);
    const [loginError, setLoginError] = useState<string | null>(null)
    const [errors, setErrors] = useState({
        username: "",
        password: "",
    });
    const [ showCountdown, setShowCountdown ] = useState(false);
    const { count, setCount, startTimer, endTimer } = useCountdown();


    useEffect(() => {
        if (count === 0) {
            endTimer();
            setShowCountdown(false);
        }
    }, [endTimer, count]);

    useEffect(() => {
        if (auth.token && user.username && !isAdmin(user.accessLevel))
            navigate("/overview", {replace: true});

        if (auth.token && user.username && isAdmin(user.accessLevel))
            navigate("/admin", {replace: true});

        if (auth.token && user.username && isNotCreated(user.subscription)) {
            const subscription = user.subscription;
            const email = user.firmEmail;
            logout();
            navigate(`/checkout/${getSubscriptionToCreate(subscription)}/${email}`);
        }
        if (auth.token && !user.username) {
            handleGetUser().then();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, auth, navigate])

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        const change = {
            [name]: value,
        };

        let updatedLogin: Login;
        setLoginInput((login) => {
            updatedLogin = new Login({...login, ...change});
            return updatedLogin;
        })
    }

    const handleLogin = async (event: SyntheticEvent) => {
        event.preventDefault();
        if (isValid()) {
            try {
                setLoadingMessage(t.confirmLogin);
                setAwaitLogin(true);
                await login(loginInput);
            }
            catch(e) {
                if (e instanceof ToManyLoginTries) {
                    setCount(e.timeLeft);
                    setLoginError("");
                    setShowCountdown(true);
                    startTimer();
                }

                else if (e instanceof Error) {
                    setLoginError(t.errors.invalidLogin);
                }
            }
            finally {
                setAwaitLogin(false);
            }
        }
        setErrors(() => validateInput());
    }

    const handleGetUser = async () => {
        setLoadingMessage(t.loadingCredentials);
        getUser()
            .then((user) => {
                dispatch(setUser({...user}))
            })
            .catch((e) => {
                if (e instanceof Error)
                    setLoginError(e.message)
            })
            .finally(() => setAwaitLogin(false))
    }

    const validateInput = () => {
        let errors: any = { username: "", password: "", }

        if (loginInput.username.length < 3) {
            errors.username = t.loginPage.errors.username;
        }
        if (loginInput.password.length === 0) {
            errors.password = t.loginPage.errors.password;
        }
        return errors;
    }
    const isValid = () => {
        return (
            loginInput.username.length > 3 &&
            loginInput.password.length > 0
        );
    }

    return (
        <PageWithContent smaller={true}>
            <>
                {awaitLogin && (
                    <OverlaySpinLoader
                        loading={awaitLogin}
                        loadMessage={loadingMessage} />
                )}
                <Content
                    headline={t.login}
                    centerHeadline={true}>
                    <div className="content-inner-form">
                        <form
                            aria-label="loginForm"
                            onSubmit={handleLogin}>
                            <TextInputWithLabel
                                labelText={t.username}
                                name="username"
                                ariaLabel="loginUsername"
                                initialValue={loginInput.username}
                                placeholder={`${t.enter} ${t.username.toLowerCase()}...`}
                                error={errors.username}
                                isReadonly={showCountdown}
                                onChange={handleChange}/>
                            <TextInputWithLabel
                                labelText={t.password}
                                name="password"
                                ariaLabel="loginPassword"
                                initialValue={loginInput.password}
                                placeholder={`${t.enter} ${t.password.toLowerCase()}...`}
                                error={errors.password}
                                isPassword={true}
                                isReadonly={showCountdown}
                                onChange={handleChange}/>
                            <div className="row reverse toColumn">
                                <Link
                                    className="input-redirect-link"
                                    to="/register">
                                    <p>{t.loginPage.noLogin}</p>
                                </Link>
                                <Link
                                    className="input-redirect-link"
                                    to="/forgot-login">
                                    <p>{t.forgotLogin}</p>
                                </Link>
                            </div>
                            <div className="row margin reverse">
                                <input
                                    className="input-submit filled"
                                    type="submit"
                                    disabled={showCountdown}
                                    value={t.login}
                                />
                            </div>

                            {loginError && (
                                <div className="row">
                                    <p className="error message">{loginError}</p>
                                </div>
                            )}
                            {showCountdown && (
                                <div className="row">
                                    <p className="error message">
                                        {t.loginPage.triesCountdownCQM.replace("?", count.toString())}
                                    </p>
                                </div>
                            )}
                        </form>
                    </div>
                </Content>
            </>
        </PageWithContent>
    );
}

export default LoginPage;
interface TotalsModelInit {
    readonly id: string;
    outgoingProjected: number;
    outgoingMeasured: number;
    outgoingDiff: number;
    outgoingPercentDiff: number;
    ingoingProjected: number;
    ingoingMeasured: number;
    ingoingDiff: number;
    ingoingPercentDiff: number;
    selValue: number;
    readonly clientId: string;
}

export class TotalsModel implements TotalsModelInit {
    readonly id: string;
    outgoingProjected: number;
    outgoingMeasured: number;
    outgoingDiff: number;
    outgoingPercentDiff: number;
    ingoingProjected: number;
    ingoingMeasured: number;
    ingoingDiff: number;
    ingoingPercentDiff: number;
    selValue: number;
    readonly clientId: string;

    constructor(init: TotalsModelInit) {
        this.id = init.id;
        this.outgoingProjected = init.outgoingProjected;
        this.outgoingMeasured = init.outgoingMeasured;
        this.outgoingDiff = init.outgoingDiff;
        this.outgoingPercentDiff = init.outgoingPercentDiff;
        this.ingoingProjected = init.ingoingProjected;
        this.ingoingMeasured = init.ingoingMeasured;
        this.ingoingDiff = init.ingoingDiff;
        this.ingoingPercentDiff = init.ingoingPercentDiff;
        this.selValue = init.selValue;
        this.clientId = init.clientId;
    }

}
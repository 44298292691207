import {StepReadingModel} from "../../models/case/stepReading";
import requireTranslation from "../../lang/locales/config";
import {InformationRow} from "../common/TextCommons";
import StepReadingForm from "./StepReadingForm";
import {InactiveCheck} from "../common/ErrorMessage";

interface StepReadingCardProps {
    stepReading: StepReadingModel;
    onEdit: (formElement: JSX.Element, statusMessage?: string) => void;
    stepReadingCount: number
}

export function StepReadingCard({ stepReading, onEdit, stepReadingCount }: StepReadingCardProps) {
    const t = requireTranslation();

    return (
        <div
            className="card color-sec-background clickable"
            onClick={() => onEdit(
                <InactiveCheck>
                    <StepReadingForm
                        initialStepReading={stepReading}
                        onSave={onEdit}
                        stepReadingCount={stepReadingCount}
                        haveBackButton={true} />
                </InactiveCheck>
            )}>
            <h3 className="card-header">{`${t.step} ${t.reading} ${stepReading.step}`}</h3>
            <InformationRow title={`${t.in}:`} text={`${stepReading.inPercent} %`} />
            <InformationRow title={`${t.in}:`} text={`${stepReading.inM3h} ${t.m3h}`} />
            <InformationRow title={`${t.out}:`} text={`${stepReading.outPercent} %`} />
            <InformationRow title={`${t.out}:`} text={`${stepReading.outM3h} ${t.m3h}`} />
        </div>
    )
}
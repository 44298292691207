import requireTranslation from "../../lang/locales/config";
import {useStandardSetup} from "../../utils/customHooks";
import {CheckVATResult, VatInformation} from "../../models/registration/checkVAT";
import React, {useState} from "react";
import {IntegratedSpinLoader} from "../common/SpinLoader";
import useUserVATApi from "../../API/useUserVATApi";
import ErrorMessage, {SuccessMessage} from "../common/ErrorMessage";
import {InformationRow} from "../common/TextCommons";
import CheckVAT from "../login/CheckVAT";
import {ExpandableContent} from "../common/ContentStandards";


interface DisplayInformationProps {
    children: JSX.Element | JSX.Element[] | undefined
}

function DisplayInformation({ children }: DisplayInformationProps) {
    return (
        <div className="content-inner-form extra-space">
            {children}
        </div>
    )
}


interface AdministrateVATProps {
    updateUser: () => void;
}


export default function AdministrateVAT({ updateUser }: AdministrateVATProps) {
    const t = requireTranslation();
    const [
        loading, setLoading,
        error, setError,
        userVAT, setUserVAT
    ] = useStandardSetup<VatInformation>();
    const [ statusMessage, setStatusMessage ] = useState("");
    const [ successMessage, setSuccessMessage ] = useState("");
    const [ successfulOperation, setSuccessfulOperation ] = useState(false);
    const [ checkVATResult, setCheckVATResult ] = useState<CheckVATResult>();
    const [ confirmsVAT, setConfirmsVAT ] = useState(false);
    const {
        getUserVatInformation,
        putUserVatInformation,
        postUserVatInformation
    } = useUserVATApi();

    const vatInformation = (): VatInformation => {
        return {
            vatNumber: checkVATResult!!.vatNumber,
            countryCode: checkVATResult!!.countryCode,
            name: checkVATResult!!.name!!,
            address: checkVATResult!!.address!!,
            zipCode: checkVATResult!!.zipCode,
            city: checkVATResult!!.city
        }
    }

    const handleSuccessfulOperationContent = (successMessage: string) => {
        handleGetVatInformation(true);
        setSuccessfulOperation(true);
        setSuccessMessage(successMessage);
    };

    const handleGetVatInformation = (forceUpdate: boolean = false) => {
        if (!userVAT || forceUpdate) {
            setLoading(true);
            setError("");
            setStatusMessage("");
            setUserVAT(undefined);
            getUserVatInformation()
                .then(setUserVAT)
                .catch((e) => {
                    if (e instanceof Error) {
                        if (e.message === t.responseMessage.errorNoVAT) {
                            setStatusMessage(e.message);
                        } else {
                            setError(e.message);
                        }
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const handlePostVatInformation = () => {
        if (confirmsVAT && checkVATResult) {
            setError("");
            setLoading(true);
            postUserVatInformation(vatInformation())
                .then(() => {
                    handleSuccessfulOperationContent(t.messages.successAdd);
                    updateUser();
                })
                .catch((e) => {
                    if (e instanceof Error) {
                        setError(e.message);
                    }
                })
                .finally(() => setLoading(false));
        }
    }

    const handlePutVatInformation = () => {
        if (confirmsVAT && checkVATResult) {
            setError("");
            setLoading(true);
            putUserVatInformation(vatInformation())
                .then(() => {
                    handleSuccessfulOperationContent(t.messages.successEdit);
                    updateUser();
                })
                .catch((e) => {
                    if (e instanceof Error) {
                        setError(e.message);
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    return (
        <ExpandableContent title={t.VAT} onExpand={handleGetVatInformation}>
            <div className="content invisible color-sec-background fold-out">

                {!loading && !error && statusMessage &&
                    <div className="content invisible">
                        <div className="center-page">
                            <p className="support-linebreak centered">{statusMessage}</p>
                        </div>
                    </div>
                }

                {!loading && !error &&
                    <SuccessMessage containerClassName={"content invisible"} message={successMessage} />
                }


                {userVAT &&
                    <DisplayInformation>
                        <InformationRow title={t.vatNumber} text={userVAT.countryCode + userVAT.vatNumber}/>
                        <InformationRow title={t.name} text={userVAT.name} />
                        <InformationRow title={t.address} text={userVAT.address} />
                        <InformationRow title={t.zipCode} text={userVAT.zipCode ? userVAT.zipCode : "-"} />
                        <InformationRow title={t.city} text={userVAT.city ? userVAT.city : "-"} />
                    </DisplayInformation>
                }
                {!error && !successfulOperation &&
                <>
                    <hr/>
                    <CheckVAT
                        checkVATResult={checkVATResult}
                        setCheckVATResult={setCheckVATResult}
                        setConfirmsVAT={setConfirmsVAT}
                        invisible={true}
                        altInfoText={t.informationTexts.firmInformationEdit}
                        optional={false}
                        update={true}/>
                    <hr/>
                    <div className="content-inner-form">
                        <div className="row reverse margin">
                            {confirmsVAT && checkVATResult &&
                                <p
                                    className="clickable text-button"
                                    onClick={userVAT ? handlePutVatInformation : handlePostVatInformation}>
                                    {userVAT ? t.update : t.add}
                                </p>}
                        </div>
                    </div>
                </>
                }
                <IntegratedSpinLoader loading={loading} loadMessage={""} />
                <ErrorMessage containerClassName={"content invisible"} error={error} noBorder={true} />
            </div>
        </ExpandableContent>
    )
}
import React, {useState} from "react";

interface OverlaySpinLoaderProps {
    // Should be a useState variable.
    loading: boolean;

    // Message to display under the spin loader.
    // Note: Keep is short.
    loadMessage: string;
}


interface SpinLoaderProps extends OverlaySpinLoaderProps{
    // className of the outer <div> container.
    containerClassName: string;
}


/**
 * For use with spin loaders. Use as an easy way to use spin loaders that needs to have
 * changeable loading as well as loadingMessage. It is basically two in one.
 */
export function useSpinLoader(): [
    boolean, React.Dispatch<React.SetStateAction<boolean>>,
    string, React.Dispatch<React.SetStateAction<string>>
] {
    const [ loading, setLoading ] = useState(false);
    const [ loadingMessage, setLoadingMessage ] = useState("");

    return [
        loading,
        setLoading,
        loadingMessage,
        setLoadingMessage
    ]
}


export function SpinLoader({ loading, loadMessage, containerClassName }: SpinLoaderProps) {
    return (
        <>
            {loading && (
                <div className={containerClassName}>
                    <div className="center-page">
                        <div className="loader" />
                        <p className="single-line">{loadMessage}</p>
                    </div>
                </div>
            )}
        </>
    );
}

export function OverlaySpinLoader({ loading, loadMessage }: OverlaySpinLoaderProps) {
    return (
        <>
            {loading && (
                <div className="dialog-overlay">
                    <div className="content-box color-main-background">
                        <div className="loader" />
                        <p className="single-line">{loadMessage}</p>
                    </div>
                </div>
            )}
        </>
    )
}

/**
 * Used when you want a spin loader as an integrated part of an existing context. Fx.
 * when a request to load more elements and the loader is needed at the bottom of the listed
 * elements or something alike.
 *
 * @param loading boolean. Should be controlled by a stateAction.
 * @param loadMessage The message (if any) displayed below the spinner.
 */
export function IntegratedSpinLoader({ loading, loadMessage }: OverlaySpinLoaderProps) {
    return (
        <>
            {loading && (
                <div className="content-box">
                    <div className="loader"/>
                    <p className="single-line">{loadMessage}</p>
                </div>
            )}
        </>
    )
}
import {NewsModel} from "../../models/News";
import {ChangeEvent, useEffect, useState} from "react";
import {useStandardSetup} from "../../utils/customHooks";
import {Content, ContentForm} from "../common/ContentStandards";
import {TextAreaWithLabel, TextInputWithLabel} from "../common/FormCommons";
import {IntegratedSpinLoader} from "../common/SpinLoader";
import ErrorMessage from "../common/ErrorMessage";
import useAdminApi from "../../API/useAdminApi";
import {getDateNowTimestamp, localeTimeFromTimestamp} from "../../utils/timestamp";
import {InformationRow} from "../common/TextCommons";
import {useAppSelector} from "../../state/hooks";
import {selectUser} from "../../state/userSlice";
import useNewsApi from "../../API/useNewsApi";

interface AdminNewsFormProps {
    newsModel: NewsModel;
    index: number;
    handleNewsChange: (index: number, newsModel: NewsModel) => void;
}

export default function AdminNewsForm(
    { newsModel, index, handleNewsChange }: AdminNewsFormProps
) {
    const [ news, setNews ] = useState<NewsModel>(newsModel);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const change = {[name]: value};

        const newNews = new NewsModel({ ...news!!, ...change })

        setNews(newNews);
        handleNewsChange(index, newNews);
    };

    useEffect(() => {
        setNews(newsModel);
    }, [newsModel]);

    return (
        <Content
            invisible={true}>
            <>
                {news &&
                    <ContentForm name="AdminNewsForm">
                        <InformationRow
                            title={"Created"}
                            text={localeTimeFromTimestamp(news.created)}/>
                        <InformationRow
                            title={"Last Edit"}
                            text={localeTimeFromTimestamp(news.lastEdit)}/>
                        <InformationRow
                            title={"Created By"}
                            text={news.createdBy}/>

                        <TextInputWithLabel
                            labelText="Title"
                            initialValue={news.title}
                            name="title"
                            onChange={handleChange}/>
                        <TextAreaWithLabel
                            labelText="Text"
                            initialValue={news.text}
                            name="text"
                            onChange={handleChange}/>
                    </ContentForm>
                }
            </>
        </Content>
    );
}
import {Content, ContentForm, PageWithContent} from "../common/ContentStandards";
import requireTranslation from "../../lang/locales/config";
import {PasswordWithConfirmPassword, usePasswordWithConfirmPassword} from "../common/FormCommons";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useUserApi} from "../../API/useUserApi";
import {ResetPasswordModel} from "../../models/user/user";
import {OverlaySpinLoader, useSpinLoader} from "../common/SpinLoader";
import {FullPageError} from "../common/ErrorMessage";


interface RouteParam {
    [index: string]: string;
    token: string;
}

export default function ResetPasswordPage() {
    const t = requireTranslation();
    const {
        password, confirmPassword,
        isPasswordConfirmed, passwordConfirmIcon,
        inputErrors,
        handleChangePassword, validatePasswordInput
    } = usePasswordWithConfirmPassword();
    const [ errorMessage, setErrorMessage ] = useState("");
    const [ errorHeadline, setErrorHeadline ] = useState("");
    const [
        awaiting, setAwaiting,
        awaitingMessage, setAwaitingMessage
    ] = useSpinLoader();
    const params = useParams<RouteParam>();
    const token: string | undefined = params.token;
    const navigate = useNavigate();
    const { putResetPassword, verifyResetPasswordToken } = useUserApi();

    useEffect(() => {
        if (!token) {
            // console.log("NO TOKEN!")
            navigate("/login");
        } else {
            setAwaiting(true);
            setAwaitingMessage(t.loadingMessages.verifyLink)
            verifyResetPasswordToken(token)
                .then()
                .catch((e) => {
                    if (e instanceof Error) {
                        if (e.message === t.responseMessage.errorTokenExp) {
                            setErrorHeadline(e.message);
                            setErrorMessage(t.informationTexts.resetLinkExpired);
                        } else {
                            setErrorHeadline(e.message);
                        }
                    }})
                .finally(() => {
                    setAwaiting(false);
                    setAwaitingMessage("");
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    const handleSaveClick = () => {
        if (validatePasswordInput()) {
            setAwaiting(true);
            setAwaitingMessage(t.loadingMessages.savingChanges)
            putResetPassword(new ResetPasswordModel(password), token!!)
                .then(() => navigate("/login"))
                .catch((e) => {
                    if (e instanceof Error) {
                        if (e.message === t.responseMessage.errorTokenExp) {
                            setErrorHeadline(e.message);
                            setErrorMessage(t.informationTexts.resetLinkExpired);
                        } else {
                            setErrorHeadline(e.message);
                        }
                    }})
                .finally(() => setAwaiting(false));
        }
    }

    return (
        <PageWithContent
            smaller={true}>

            <OverlaySpinLoader
                loading={awaiting}
                loadMessage={awaitingMessage} />

            <>
                {!errorMessage && !errorHeadline && awaitingMessage !== t.loadingMessages.verifyLink &&
                    <Content
                        headline={t.resetPassword}
                        centerHeadline={true}>
                        <ContentForm
                            ariaLabel="ResetPasswordForm">
                            <PasswordWithConfirmPassword
                                password={password}
                                confirmPassword={confirmPassword}
                                isPasswordConfirmed={isPasswordConfirmed}
                                passwordConfirmIcon={passwordConfirmIcon}
                                inputErrors={inputErrors}
                                handleChangePassword={handleChangePassword} />
                            <div className="row reverse">
                                <button
                                    className="filled"
                                    onClick={handleSaveClick}>
                                    {t.save}
                                </button>
                            </div>
                        </ContentForm>
                    </Content>
                }

                <FullPageError
                    errorMessage={errorMessage}
                    errorHeadline={errorHeadline} />
            </>
        </PageWithContent>
    )
}
import {OrgUserModel} from "../../models/user/user";


interface UserListProps {
    users: OrgUserModel[];
    onUserClick: (user: OrgUserModel) => void;
}

export default function UserList({ users, onUserClick }: UserListProps) {
    return (
        <>
            {users &&
                <div className="table-container margin-padding">
                    <table className="table-with-content">
                        <thead>
                            <tr>
                                <th className="table-header-row">{"name"}</th>
                                <th className="table-header-row">{"username"}</th>
                                <th className="table-header-row">{"private email"}</th>
                                <th className="table-header-row">{"firm name"}</th>
                                <th className="table-header-row">{"firm email"}</th>
                                <th className="table-header-row">{"subscription"}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user) => (
                                <tr
                                    className="table-row clickable"
                                    key={user.username}
                                    onClick={() => onUserClick(user)}>
                                    <td className="table-value">{user.name}</td>
                                    <td className="table-value">{user.username}</td>
                                    <td className="table-value">{user.privateEmail}</td>
                                    <td className="table-value">{user.firmName}</td>
                                    <td className="table-value">{user.firmEmail}</td>
                                    <td className="table-value">{user.subscription}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            }
        </>
    );
}
import React, {useState} from "react";
import requireTranslation from "../../lang/locales/config";
import {useAppSelector} from "../../state/hooks";
import {selectUser} from "../../state/userSlice";
import {NavLink} from "react-router-dom";
import {AccessLevels} from "./Enums";


interface ProfileAvatarIconProps {
    logout: () => void;
}

export default function ProfileAvatarIcon({ logout }: ProfileAvatarIconProps) {
    const t = requireTranslation();
    const user = useAppSelector(selectUser);
    const [ isClicked, setIsClicked ] = useState(false);

    window.onclick = function(e: any) {
        if (e.target.id !== "profileIcon") {
            if (isClicked) {
                setIsClicked(false);
            }
        }
    }

    const handleAvatarIconClick = () => {
        setIsClicked((prevState) => !prevState);
    }

    return (
        <div className="dropdown">
            <img
                id="profileIcon"
                className="nav-menu-icon color-page-background filter"
                src={window.location.origin + "/avatar.svg"}
                alt="Profile"
                onClick={handleAvatarIconClick}
            />

            {isClicked && (
                <div
                    className="dropdown-content smaller"
                    onClick={(e) => e.stopPropagation()}>
                    <div className="content color-sec-background">
                        <div className="row space-between">
                            <img
                                id="profileIcon"
                                className="icon left-padding"
                                src={"avatar.svg"}
                                alt="Profile Image" />
                            <p className="support-linebreak bold available center-page">{`${user.name}\n @${user.username}`}</p>
                        </div>
                        <hr/>
                        <div className="column align-end">
                            <NavLink
                                to={"/profile"}
                                className="nav-button dropdown-button"
                                onClick={handleAvatarIconClick}>
                                {t.manageProfile}
                            </NavLink>
                            <NavLink
                                to="/"
                                className="nav-button dropdown-button negative"
                                onClick={logout}
                                replace={true}>
                                {t.logout}
                            </NavLink>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
import {ArmatureModel} from "../../models/case/armature";
import requireTranslation from "../../lang/locales/config";
import {useArmatureApi} from "../../API/useClientCaseApi";
import {useFormSetup} from "../../utils/customHooks";
import ArmatureList from "./ArmatureList";
import {isInputErrorsEmpty, validateRequired} from "../../utils/inputValidation";
import {OverlaySpinLoader} from "../common/SpinLoader";
import {SyntheticEvent} from "react";
import {SelectFieldWithLabel, TextInputWithLabel} from "../common/FormCommons";
import {ConfirmationDialog, useDialog} from "../common/ConfirmationDialog";
import {AirflowTypes} from "../utils/Enums";

interface ArmatureFormProps {
    initialArmature?: ArmatureModel;
    onSave: (formElement: JSX.Element,
             statusMessage?: string,
             newClientName?: string,
             shouldRefreshStatus?: boolean) => void;
    clientId?: string;
    haveBackButton?: boolean;
}

interface InputErrorInter {
    airflow: string;
    model: string;
}

export default function ArmatureForm(
    {initialArmature, onSave, clientId, haveBackButton = false }: ArmatureFormProps
) {
    if (!initialArmature && !clientId) {
        throw Error("If initialArmature is undefined, then clientId must be defined!");
    }

    let newArmature = null;
    if (!initialArmature) {
        newArmature = new ArmatureModel();
        newArmature.clientId = clientId!!;
    }

    const t = requireTranslation();
    const { postArmature, putArmature, deleteArmature } = useArmatureApi();
    const initialInputErrors: InputErrorInter = {
        airflow: "",
        model: ""
    };
    const { isOpen, message: dialogMessage, setDialogMessage, toggle: toggleDialog } = useDialog();
    const [
        awaiting, setAwaiting,
        inputErrors, setInputErrors,
        error, setError,
        armature, setArmature
    ] = useFormSetup(initialArmature ? initialArmature : newArmature!!, initialInputErrors);

    /**
     * Handles when the back button (if any) is clicked.
     */
    const handleBackClick = () => {
        onSave(
            <ArmatureList clientId={armature.clientId!!} onEdit={onSave}/>
        )
    }


    /**
     * Handles a change in an input field.
     * Updates the ArmatureModel to match the change.
     */
    const handleChange = (event: any) => {
        const { name, value } = event.target;
        const change = { [name]: value };

        setArmature((armature) =>
            new ArmatureModel({ ...armature, ...change }));
    };

    const handleAirflowChange = (event: any) => {
        const { name, value } = event.target;
        const change = { [name]: value };

        setArmature((armature) => {
            const newArmature = new ArmatureModel({...armature, ...change});
            // console.log("currentAirflow: ", newArmature.airflowType);
            if (newArmature.airflow === t.airflowDirectionList[0])
                newArmature.airflowType = AirflowTypes.OUTGOING
            else
                newArmature.airflowType = AirflowTypes.INGOING

            // console.log("AirflowType is now: ", newArmature.airflowType);
            return newArmature;
        });
    }

    /**
     * Handles when the save button is clicked.
     * First validates input, if valid, a PUT request is sent to the server.
     */
    const handleSave = async () => {
        if (validateInput()) {
            if (!initialArmature) {
                setAwaiting(true);
                await postArmature(armature)
                    .then(() => {
                        onSave(
                            <ArmatureList clientId={armature.clientId!!} onEdit={onSave}/>,
                            t.messages.successEdit,
                            undefined,
                            true
                        );
                    })
                    .catch((e) => {
                        if (e instanceof Error) setError(e.message);
                    })
                    .finally(() => setAwaiting(false));
            } else {
                setAwaiting(true);
                await putArmature(armature)
                    .then(() => {
                        onSave(
                            <ArmatureList clientId={armature.clientId!!} onEdit={onSave}/>,
                            t.messages.successEdit,
                        );
                    })
                    .catch((e) => {
                        if (e instanceof Error) setError(e.message);
                    })
                    .finally(() => setAwaiting(false));
            }
        }
    };

    /**
     * Handles when the delete button is clicked.
     * Toggles a confirmation dialog.
     */
    const handleDelete = async () => {
        setDialogMessage(t.messages.confirmDelete +
            initialArmature!!.airflow + " " + initialArmature!!.model + "?");
        toggleDialog();
    };

    /**
     * When confirmation dialog delete button is clicked, a DELETE request is send to the
     * server, if successful, switch content view to MeasurementList.
     */
    const handleConfirmDelete = async () => {
        toggleDialog();
        setAwaiting(true);
        deleteArmature(initialArmature!!) // The delete button is only visible in edit mode.
            // initial armature in case of field changes before deletion (has to be equal the database entry).
            .then(() => {
                onSave(
                    <ArmatureList clientId={armature.clientId!!} onEdit={onSave}/>,
                    t.messages.successDelete,
                    undefined,
                    true
                );
            })
            .catch((e) => {
                if (e instanceof Error) setError(e.message);
            })
            .finally(() => setAwaiting(false));
    }

    /**
     * Validates the input field 'model'. Set error text if invalid.
     * Returns True if field is filled out.
     */
    const validateInput = () => {
        const err = { ...inputErrors };

        err.airflow = validateRequired(armature.airflow);
        err.model = validateRequired(armature.model);
        setInputErrors(err);

        return isInputErrorsEmpty(err);
    }

    return (
        <>
            <OverlaySpinLoader
                loading={awaiting}
                loadMessage={`${t.awaiting}...`} />

            {isOpen && (
                <ConfirmationDialog
                    toggle={toggleDialog}
                    message={dialogMessage}
                    onConfirm={handleConfirmDelete} />
            )}
            <div className="content color-main-background fold-out">
                <div className="content-inner-form">
                    <div className="row">
                        {haveBackButton &&
                            <img
                                className="icon-button"
                                src={"back_arrow.svg"}
                                alt={t.back}
                                onClick={handleBackClick}/>
                        }
                        {initialArmature && (
                            <h3 className="form-header">{t.edit} - {initialArmature.airflow} {initialArmature.model}</h3>
                        )}
                        {!initialArmature && (
                            <h3 className="form-header">{t.add} {t.armature}</h3>
                        )}
                    </div>
                    <form
                        aria-label="armature form"
                        name="armature form"
                        onSubmit={(e: SyntheticEvent) => e.preventDefault()}>
                        <SelectFieldWithLabel
                            labelText={t.airflow}
                            selection={t.airflowDirectionList}
                            name="airflow"
                            ariaLabel="armature airflow"
                            initialValue={armature.airflowType ? t.airflowDirectionList[armature.airflowType] : armature.airflow}
                            error={inputErrors.airflow}
                            onChange={handleAirflowChange} />
                        <TextInputWithLabel
                            labelText={t.model}
                            name="model"
                            ariaLabel="armature model"
                            placeholder={`${t.enter} ${t.model.toLowerCase()}`}
                            initialValue={armature.model}
                            error={inputErrors.model}
                            onChange={handleChange} />
                        <div className="row margin reverse">
                            <button
                                className="filled"
                                onClick={handleSave}>{initialArmature ? t.edit : t.add}</button>
                            {initialArmature && (
                                <button
                                    className="outline"
                                    onClick={handleDelete}>{t.delete}</button>
                            )}
                        </div>
                        {error && (
                            <div className="row margin">
                                <p className="error message">{error}</p>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </>
    )
}
import {SuccessNoContent, useFetch} from "./useFetch";
import {EmployeeModel, EmployeeUpdateModel, FormerEmployeeModel} from "../models/Employee";
import {ResponseModel} from "./StatusError";

export function useEmployeeApi() {
    const baseUrl = process.env.REACT_APP_API_URL as string;
    const pluralUrl = `${baseUrl}/employees`;
    const url = `${baseUrl}/employee`;
    const former_url = `${baseUrl}/former-employee`;
    const requester = useFetch();

    const getEmployees = (simple: boolean = false) => {
        const param = simple ? `?simple=${simple}` : "";
        return requester.get(EmployeeModel, pluralUrl + param) as Promise<EmployeeModel[]>;
    }

    const getEmployee = (employeeId: string) => {
        const param = `?employeeId=${employeeId}`;
        return requester.get(EmployeeModel, url + param) as Promise<EmployeeModel>;
    }

    const getFormerEmployee = (formerEmployeeId: string) => {
        const param = `?formerEmployeeId=${formerEmployeeId}`;
        return requester.get(FormerEmployeeModel, former_url + param) as Promise<FormerEmployeeModel>;
    }

    const postEmployee = (employee: EmployeeModel) => requester
        .post(
            ResponseModel,
            url,
            employee,
            undefined,
            false,
            false,
            true
        ) as Promise<ResponseModel>;

    const putEmployee = (employee: EmployeeModel | EmployeeUpdateModel) =>
        requester.put(SuccessNoContent, url, employee) as Promise<SuccessNoContent>;


    const deleteEmployee = (employee: EmployeeModel) => requester
        .delete(ResponseModel, url, employee) as Promise<ResponseModel>;

    return {
        getEmployees,
        getEmployee,
        getFormerEmployee,
        postEmployee,
        putEmployee,
        deleteEmployee,
    };
}

export function useRegisterEmployeeApi() {
    const baseUrl = process.env.REACT_APP_API_URL as string;
    const url = `${baseUrl}/employee/register`;
    const requester = useFetch();

    const getEmployee = (token: string) => {
        const param = `?registerId=${token}`;
        return requester.get(
            EmployeeModel,
            url + param,
            undefined,
            undefined,
            false,
            true
        ) as Promise<EmployeeModel>;
    }

    const registerEmployee = (employee: EmployeeModel, token: string) => {
        const param = `?registerId=${token}`;
        return requester.post(
            SuccessNoContent,
            url + param,
            employee,
            undefined,
            false,
            false,
            true
        ) as Promise<SuccessNoContent>;
    }

    return {
        getEmployee,
        registerEmployee
    }
}

export function useEmployeeNewRegisterLinkApi() {
    const baseUrl = process.env.REACT_APP_API_URL as string;
    const url = `${baseUrl}/employee/register/resend`;
    const requester = useFetch();

    const getNewLink = (token: string) => {
        const param = `?registerId=${token}`;
        return requester.get(
            SuccessNoContent,
            url + param,
            undefined,
            undefined,
            false,
            false,
            true
        ) as Promise<SuccessNoContent>;
    };

    return {
        getNewLink,
    }
}
import {RootState} from "./store";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";


interface AuthState {
    token: string;
}

const initialState: AuthState = {
    token: sessionStorage.getItem("token") ?
        sessionStorage.getItem("token")!! : ""
};

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAuth: (state, action: PayloadAction<AuthState>) => {
            state.token = action.payload.token;
            sessionStorage.setItem('token', action.payload.token);
        },
        setNoAuth: (state) => {
            state.token = "";
            sessionStorage.removeItem('token');
        },
    }
});

export const { setAuth, setNoAuth } = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
interface MeasurementModelInit {
    id: string | null;
    room: string;
    roomNumber: number | null;
    loggerNote: number | null;
    projected: number | null;
    measured: number | null;
    diff: number | null;
    percentDiff: number | null;
    armatureType: number | null;
    armatureModel: string;
    armatureId: string | null;
    clientId: string;
}

export class MeasurementModel implements MeasurementModelInit{
    id: string | null = null;
    room: string = "";
    roomNumber: number | null = null;
    loggerNote: number | null = null;
    projected: number | null = null;
    measured: number | null = null;
    diff: number | null = null;
    percentDiff: number | null = null;
    armatureType: number | null = null;
    armatureModel: string = "";
    armatureId: string | null = null;
    clientId: string = "";


    constructor(initializer?: MeasurementModelInit) {
        if (!initializer) return;

        this.id = initializer.id;
        this.room = initializer.room;
        this.roomNumber = initializer.roomNumber;
        this.loggerNote = initializer.loggerNote;
        this.projected = initializer.projected;
        this.measured = initializer.measured;
        this.diff = initializer.diff;
        this.percentDiff = initializer.percentDiff;
        this.armatureType = initializer.armatureType;
        this.armatureModel = initializer.armatureModel;
        this.armatureId = initializer.armatureId;
        this.clientId = initializer.clientId;
    }
}
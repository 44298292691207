import React, {useEffect} from "react";


interface ToastMessageProps {
    toastMessage: string | undefined;
    setToastMessage: React.Dispatch<React.SetStateAction<string>> |
        React.Dispatch<React.SetStateAction<string | undefined>>;
}

export default function ToastMessage({ toastMessage, setToastMessage }: ToastMessageProps) {
    useEffect(() => {
        if (toastMessage)
            setTimeout(() => { setToastMessage(""); }, 3000)
    }, [toastMessage]);

    return (
        <>
            {toastMessage && (
                <div className="snackbar">{toastMessage}</div>
            )}
        </>
    );
}
import {ReactElement, useState} from "react";


export function useDropdownContent(): [boolean, () => void] {
    const [ showDropdown, setShowDropdown ] = useState(false);

    const toggleDropdown = () => setShowDropdown((prevState) => !prevState);

    return [
        showDropdown,
        toggleDropdown
    ]
}


interface DropdownContentProps {
    buttonText: string;
    children: ReactElement;
    showDropdown: boolean;
    toggleDropdown: () => void;
}

export default function DropdownContent({ buttonText, children, showDropdown, toggleDropdown }: DropdownContentProps) {
    window.onclick = function(e: any) {
        if (!e.target.matches(".drop-button")) {
            if (showDropdown) {
                toggleDropdown();
            }
        }
    }

    return (
        <div className="dropdown">
            <button
                className="drop-button"
                onClick={toggleDropdown}>{buttonText}</button>
            {showDropdown && (
                    <div
                        id="dropdownContent"
                        className="dropdown-content"
                        onClick={(e) => e.stopPropagation()}>
                        <div className="content color-sec-background">
                            {children}
                        </div>
                    </div>

            )}
        </div>
    )
}
import {Content} from "../common/ContentStandards";
import {useStandardSetup} from "../../utils/customHooks";
import {TermsAndConditions} from "../../models/registration/termsAndConditions";
import useAdminApi from "../../API/useAdminApi";
import {IntegratedSpinLoader} from "../common/SpinLoader";
import ErrorMessage from "../common/ErrorMessage";
import {useState} from "react";

export default function AdminBackupPage() {
    const [
        loading, setLoading,
        error, setError,
        backup, setBackup
    ] = useStandardSetup<TermsAndConditions>();
    const [ filename, setFilename ] = useState("");
    const { getBackup } = useAdminApi();

    const handleGetBackup = () => {
        setLoading(true);
        setError("");
        getBackup()
            .then((zipFile) => {
                setBackup(zipFile);
                setFilename(`air_backup_${new Date().toLocaleString()
                    .replace(" ", "_")}.zip`)
            })
            .catch((e) => {
                if (e instanceof Error) {
                    setError(e.message);
                }
            })
            .finally(() => setLoading(false));
    }

    return (
        <Content
            invisible={true}
            flexGrow={true}
            headline={"Backup"}
            centerHeadline={true}>
            <p className="support-linebreak">
                Press the backup button to retrieve a zip of the database and all supported loggers extraction json
                files
            </p>
            <div className="row center-page">
                <button
                    className="filled center-page"
                    onClick={handleGetBackup}>
                    Backup
                </button>
            </div>
            <div className="row center-page margin">
                {backup &&
                    <a
                        href={backup.url}
                        rel="noreferrer"
                        target="_blank"
                        download={filename}>
                        {filename}
                    </a>
                }

            </div>

            <IntegratedSpinLoader loading={loading} loadMessage={""} />
            <ErrorMessage error={error} />
        </Content>
    )
}
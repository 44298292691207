interface LoggerNoteFileUploadResponseInit {
    fileEncodingAccepted: boolean;
    loggerNoteExtraction: boolean;
    loggerTypeAccepted: boolean;
}


export class LoggerNoteFileUploadResponse implements LoggerNoteFileUploadResponseInit {
    fileEncodingAccepted: boolean;
    loggerNoteExtraction: boolean;
    loggerTypeAccepted: boolean;

    constructor(init: LoggerNoteFileUploadResponseInit) {
        this.fileEncodingAccepted = init.fileEncodingAccepted;
        this.loggerNoteExtraction = init.loggerNoteExtraction;
        this.loggerTypeAccepted = init.loggerTypeAccepted;
    }
}

export default class LoggerNoteFileModel {
    mimeType: string | null;
    encoding: string | null;
    filename: string | null;
    url: string;

    constructor(blob: Blob, header: Headers | undefined) {
        if (!header) {
            throw Error("LoggerNoteFileModel -- Setup wrong. This model should get the header! ");
        }
        this.mimeType = header.get("content-type")
        this.encoding = header.get("content-encoding")
        this.filename = header.get("x-filename")

        if (!this.mimeType || !this.encoding || !this.filename) {
            throw Error("LoggerNoteFileModel -- header does not include one of the following: " +
                "content-type, content-encoding, x-filename!");
        }
        // let byteArray: number[] = [];
        // init.replace(/([0-9a-f]{2})/gi, (d) => {
        //     byteArray.push(parseInt(d, 16));
        //     return ''
        // })
        // console.log(byteArray);

        // const fileBlob = new Blob([init], { type: this.mimeType });
        // fileBlob.text().then((result) => {
        //     console.log("blob text result!", result);
        // })
        // fileBlob.arrayBuffer().then((result) => {
        //     console.log("blob array buffer", result);
        // })
        // this.fileReader.readAsText(fileBlob, encoding);
        // this.fileReader.onload = () => {
        //     console.log(this.fileReader.result);
        // }
        this.url = URL.createObjectURL(blob);
    }
}

interface UnsupportedLoggerFileDetailsInit {
    id: string;
    deviceName: string;
    deviceCompany: string;
    filename: string;
}

export class UnsupportedLoggerFileDetails {
    id: string;
    deviceName: string;
    deviceCompany: string;
    filename: string;

    constructor(init: UnsupportedLoggerFileDetailsInit) {
        this.id = init.id;
        this.deviceName = init.deviceName;
        this.deviceCompany = init.deviceCompany;
        this.filename = init.filename;
    }
}

export class UnsupportedLoggerFile {
    mimeType: string;
    encoding: string;
    filename: string;
    deviceName: string;
    deviceCompany: string;
    id: string;
    url: string;

    constructor(blob: Blob, header: Headers | undefined) {
        if (!header) {
            throw Error("UnsupportedLoggerFile -- Setup wrong. This model should get the header! ");
        }

        this.mimeType = header.get("content-type")!!
        this.encoding = header.get("content-encoding")!!
        this.filename = header.get("x-filename")!!
        this.deviceName = header.get("x-device-name")!!
        this.deviceCompany = header.get("x-device-company")!!
        this.id = header.get("x-id")!!
        this.url = URL.createObjectURL(blob);
    }
}

interface SupportedDeviceTemplateInit {
    jsonString: string;
    filename?: string
}

export class SupportedDeviceTemplate {
    jsonString: string;
    filename?: string

    constructor(init: SupportedDeviceTemplateInit) {
        this.jsonString = init.jsonString;
        this.filename = init.filename;
    }
}
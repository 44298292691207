import {UnsupportedLoggerFileDetails} from "../../models/case/loggerNoteFile";

interface LoggerFilesListProps {
    loggerList: UnsupportedLoggerFileDetails[];
    onLoggerClick: (loggerId: string) => void;
}

export default function AdminUnsupportedLoggerFilesList(
    { loggerList, onLoggerClick }: LoggerFilesListProps
) {
    return (
        <>
            {loggerList.length === 0 &&
                <h2 className="content-headline centered success message">
                    There is no unsupported loggers to list atm!
                </h2>
            }
            {loggerList.length !== 0 &&
                <div className="table-container margin-padding">
                    <table className="table-with-content">
                        <thead>
                        <tr>
                            <th className="table-header-row">{"device name"}</th>
                            <th className="table-header-row">{"device company"}</th>
                            <th className="table-header-row">{"filename"}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {loggerList.map((logger) => (
                            <tr
                                className="table-row clickable"
                                key={logger.id}
                                onClick={() => onLoggerClick(logger.id)}>
                                <td className="table-value">{logger.deviceName}</td>
                                <td className="table-value">{logger.deviceCompany}</td>
                                <td className="table-value">{logger.filename}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            }
        </>
    )
}
import React, {useState} from "react";
import requireTranslation from "../../lang/locales/config";
import {SubscriptionEnum} from "./Enums";

interface SubscriptionProps {
    isSelected: boolean,
    onClick?: () => void | undefined;
}

export function BasicSubscription({ isSelected, onClick }: SubscriptionProps) {
    const t = requireTranslation();

    return (
        <div
            className={isSelected ? "card color-positive" : "card color-sec-background"}
            onClick={onClick}>
            <h2 className="card-header">{t.subscriptionBasic.name}</h2>
            <p className="card-body">{t.subscriptionBasic.included}</p>
            <div className="row">
                <h3 className="card-body red-line">{t.subscriptionBasic.price} {t.subscriptionBasic.currency}</h3>
                <h2 className="card-body highlight">{t.subscriptionBasic.campaignPrice} {t.subscriptionBasic.currency}</h2>
            </div>
            <h4 className="card-body center-page highlight">{t.subscriptionBasic.campaignText}</h4>
            <p className="card-body note">{t.subscriptionBasic.campaignNote}</p>
        </div>
    )
}

export function ExtendedSubscription({isSelected, onClick}: SubscriptionProps) {
    const t = requireTranslation();

    return (
        <div
            className={isSelected ? "card color-positive" : "card color-sec-background"}
            onClick={onClick}>
            <h2 className="card-header">{t.subscriptionExtended.name}</h2>
            <p className="card-body">{t.subscriptionExtended.included}</p>
            <div className="row">
                <h3 className="card-body red-line">{t.subscriptionExtended.price} {t.subscriptionExtended.currency}</h3>
                <h2 className="card-body highlight">{t.subscriptionExtended.campaignPrice} {t.subscriptionExtended.currency}</h2>
            </div>
            <h4 className="card-body center-page highlight">{t.subscriptionExtended.campaignText}</h4>
            <p className="card-body note">{t.subscriptionExtended.campaignNote}</p>
        </div>
    )
}

interface SelectSubscriptionProps {
    selectedSubscription: SubscriptionEnum,
    setSelectedSubscription: React.Dispatch<React.SetStateAction<SubscriptionEnum>>,
    handleChange: (selectedSubscription: SubscriptionEnum) => void
}

export function SelectSubscription(
    {selectedSubscription, setSelectedSubscription, handleChange}: SelectSubscriptionProps
) {
    const t = requireTranslation();

    return (
        <div className="column">
            <label
                className="input-label stay-top">
                {t.subscription}
            </label>
            <div className="content-inner-list">
                <BasicSubscription
                    isSelected={selectedSubscription === SubscriptionEnum.Basic}
                    onClick={() => {
                        setSelectedSubscription(SubscriptionEnum.Basic)
                        handleChange(SubscriptionEnum.Basic)
                    }} />
                <ExtendedSubscription
                    isSelected={selectedSubscription === SubscriptionEnum.Extended}
                    onClick={() => {
                        setSelectedSubscription(SubscriptionEnum.Extended)
                        handleChange(SubscriptionEnum.Extended)
                    }}/>
            </div>
        </div>
    )
}

export function useSelectSubscription<T>(
    setModel: new (data: any) => T,
    setModelInto: React.Dispatch<React.SetStateAction<T>>,
    nameInModel: string = "subscription"
): [
    SubscriptionEnum,
    React.Dispatch<React.SetStateAction<SubscriptionEnum>>,
    (selectedSubscription: SubscriptionEnum) => void
] {
    const [ selectedSubscription, setSelectedSubscription ] = useState(SubscriptionEnum.Basic);

    const handleChange = (selectedSubscription: SubscriptionEnum) => {
        setModelInto((prevState) => {
            return new setModel({ ...prevState, ...{[nameInModel]: selectedSubscription}})
        })
    }

    return [
        selectedSubscription,
        setSelectedSubscription,
        handleChange
    ]
}
import requireTranslation from "../../lang/locales/config";
import {useStandardSetup} from "../../utils/customHooks";
import NewsCard from "./NewsCard";
import {NewsModel} from "../../models/News";
import {Content} from "../common/ContentStandards";
import {useEffect, useState} from "react";
import useNewsApi from "../../API/useNewsApi";
import {IntegratedSpinLoader} from "../common/SpinLoader";
import ErrorMessage from "../common/ErrorMessage";


export default function NewsList() {
    const t = requireTranslation();
    const limit = 5;
    const [ offset, setOffset ] = useState(0);
    const [ fetchNews, setFetchNews ] = useState(true);
    const [
        loading, setLoading,
        error, setError,
        newsList, setNewsList
    ] = useStandardSetup<NewsModel[]>();
    const { getNews } = useNewsApi();

    useEffect(() => {
        if (fetchNews) {
            setFetchNews(false);
            setLoading(true);
            getNews(limit, offset)
                .then((data) => {
                    setNewsList((prevState) => {
                        if (prevState && offset !== 0) {
                            return prevState.concat(data);
                        } else {
                            return data;
                        }
                    })
                })
                .catch((e) => {
                    if (e instanceof Error) {
                        setError(e.message);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchNews]);

    const handleFetchMoreNews = () => {
        setOffset((prevState) => {
            return prevState + limit;
        })
        setFetchNews(true);
    };

    return (
        <Content
            headline={t.news}
            flexGrow={true}>
            <>
                {!loading && !error && newsList &&
                    <div className="content-inner-list-column gap">
                        {newsList.length > 0 ? newsList.map((news, index) => (
                            <NewsCard key={news.title + index} news={news}/>
                        )) :
                            <p className="support-linebreak center-text">
                                {t.informationTexts.noNews}
                            </p>
                        }
                    </div>
                }

                <IntegratedSpinLoader
                    loading={loading}
                    loadMessage={""} />

                <ErrorMessage error={error} />

                {!loading && !error && newsList && (offset + limit) === newsList.length &&
                    <div className="row reverse">
                        <button
                            className="filled"
                            onClick={handleFetchMoreNews}>
                            {t.more}
                        </button>
                    </div>
                }
            </>
        </Content>
    );
};
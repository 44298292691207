import React from "react";
import requireTranslation from "./lang/locales/config";

export default function NotFoundPage() {
    const t = requireTranslation();

    return (
        <div className="not-found">
            <h2>{t.notFoundPage.headline}</h2>
            <p>{t.notFoundPage.text}</p>
        </div>
    )
}
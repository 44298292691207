interface LoggerDeviceModelInit {
    deviceName: string;
    deviceCompany: string;
}

export default class LoggerDeviceModel implements LoggerDeviceModelInit{
    deviceName: string = "";
    deviceCompany: string = "";

    constructor(init?: LoggerDeviceModelInit) {
        if (init) {
            this.deviceName = init.deviceName;
            this.deviceCompany = init.deviceCompany;
        }
    }
}

interface LoggerDeviceAddResultsInit {
    extractSuccess: number;
    extractFailed: number;
    runtimeSuccess: boolean;
}

export class LoggerDeviceAddResults {
    extractSuccess: number;
    extractFailed: number;
    runtimeSuccess: boolean;

    constructor(init: LoggerDeviceAddResultsInit) {
        this.extractSuccess = init.extractSuccess;
        this.extractFailed = init.extractFailed;
        this.runtimeSuccess = init.runtimeSuccess;
    }
}
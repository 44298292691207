import React, {SyntheticEvent, useState} from "react";
import {InformationDialog, useDialog} from "./ConfirmationDialog";


interface PageWithContentProps {
    children: JSX.Element | JSX.Element[];
    smaller?: boolean;
}

/**
 * This should be used as an outer layer to Content if the content has a full page.
 *
 * @param children the children jsx.elements.
 * @param smaller Makes the container smaller.
 */
export function PageWithContent({ children, smaller = false}: PageWithContentProps) {
    return (
        <div className="page-container center-content">
            <div className={smaller ? "content-container for-page smaller" : "content-container for-page"}>
                {children}
            </div>
        </div>
    )
}


interface ContentProps {
    children: JSX.Element | JSX.Element[];
    headline?: string;
    subtitle?: string;
    backgroundColor?: string;
    centerHeadline?: boolean;
    invisible?: boolean;
    flexGrow?: boolean;
    shownInfo?: string;
    infoIconText?: string;
}

export function Content(
    {
        children,
        headline,
        subtitle,
        backgroundColor = "color-main-background",
        centerHeadline = false,
        invisible = false,
        flexGrow = false,
        shownInfo,
        infoIconText
}: ContentProps) {
    const {isOpen, message, toggle, setDialogMessage} = useDialog();

    const handleToggleInformation = () => {
        setDialogMessage(infoIconText!!);
        toggle();
    };

    return (
        <>
            {isOpen && (
                <InformationDialog
                    toggle={toggle}
                    message={message} />
            )}

            <div className={invisible ?
                flexGrow ? "content auto-fit invisible" : "content invisible" :
                flexGrow ? `content auto-fit fold-out ${backgroundColor}` : `content fold-out ${backgroundColor}`}>
                {headline && !infoIconText && !shownInfo && (
                    <h3 className={centerHeadline ?
                        "content-headline center-page" :
                        "content-headline"}>{headline}</h3>
                )}
                {headline && infoIconText && !shownInfo && (
                    <div className="row">
                        <h3 className={centerHeadline ?
                            "content-headline available center-page" :
                            "content-headline available"}>{headline}</h3>
                        <img
                            className="icon-button right-align pulse"
                            src={"icon_info_outlined.svg"}
                            alt="Info"
                            onClick={handleToggleInformation} />
                    </div>
                )}
                {headline && shownInfo && !infoIconText && (
                    <div className="row">
                        <h3 className={centerHeadline ?
                            "content-headline available center-page" :
                            "content-headline available"}>{headline}</h3>
                        <h4 className="content-headline right-align">{shownInfo}</h4>
                    </div>
                )}
                {headline && shownInfo && infoIconText && (
                    <div className="row">
                        <h3 className={centerHeadline ?
                            "content-headline available center-page" :
                            "content-headline available"}>{headline}</h3>
                        <h4 className="content-headline right-align">{shownInfo}</h4>
                        <img
                            className="icon-button right-align"
                            src={"icon_info_outlined.svg"}
                            alt="Info"
                            onClick={handleToggleInformation}/>
                    </div>
                )}
                {subtitle && (
                    <h4 className={centerHeadline ?
                        "support-linebreak center-page" :
                        "support-linebreak"}>{subtitle}</h4>
                )}
                {children}
            </div>
        </>
    )
}


interface ContentFormProps {
    children: JSX.Element | JSX.Element[] | boolean
    ariaLabel?: string;
    name?: string;
}

export function ContentForm({children, ariaLabel, name}: ContentFormProps) {
    return (
        <div className="content-inner-form">
            <form
                aria-label={ariaLabel}
                name={name}
                onSubmit={(e: SyntheticEvent) => e.preventDefault()}>
                {children}
            </form>
        </div>
    )
}


interface ContentTableProps {
    children: JSX.Element | JSX.Element[];
}

/**
 * Simply put the children elements into a list. Suited to fit into a Content element.
 *
 * @param children Any html object(s).
 * @param direction 0 = row, 1 = column.
 */
export function ContentTable({ children }: ContentTableProps) {
    return (
        <div className="content-list">
            {children}
        </div>
    )
}

export interface ExpandableContentProps {
    children: JSX.Element | JSX.Element[] | undefined
    title: string;
    onExpand: () => void;
}


export function ExpandableContent({children, title, onExpand}: ExpandableContentProps) {
    const [expanded, setExpanded] = useState(false);
    return (
        <>
            <div
                className="input-container box-radius clickable"
                onClick={() => {
                    onExpand();
                    setExpanded((prevState) => !prevState);
                }}>
                <div className="row">
                    <div className="row">
                        <p className="title-line">{title}</p>
                    </div>
                    <img
                        className="icon in-context-smaller no-margin"
                        src={expanded ? "expand_less_arrow.svg" : "expand_arrow.svg"}
                        alt="icon"/>
                </div>
            </div>
            {expanded && children &&
                children
            }
        </>
    )
}
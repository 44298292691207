import {SubscriptionEnum} from "../../components/utils/Enums";
import {localeDateFromTimestamp} from "../../utils/timestamp";

interface SubscriptionStatusInit {
    status: string;
    nextPayment: number | null;
    endPayment: number | null;
    subscriptionChoice: SubscriptionEnum;
    customerEmail: string;
    customerId: string;
}

export class SubscriptionStatus {
    status: string;
    nextPayment: string | null = null;
    endPayment: string | null = null;
    subscriptionChoice: SubscriptionEnum;
    customerEmail: string;
    customerId: string;

    constructor(init: SubscriptionStatusInit) {
        // console.log("init Subscription Status")
        this.status = init.status;

        if (init.nextPayment !== null) {
            this.nextPayment = localeDateFromTimestamp(init.nextPayment);
        }

        if (init.endPayment !== null) {
            this.endPayment = localeDateFromTimestamp(init.endPayment);
        }

        this.subscriptionChoice = init.subscriptionChoice;
        this.customerEmail = init.customerEmail;
        this.customerId = init.customerId;
    }
}


interface CalculationItem {
    type: string;
    amount: number;
}


interface SubscriptionChangeOutcomeInit {
    currency: string;
    calculation: Array<CalculationItem>;
    owed: number;
    subscriptionPrice: number;
    proration_date: number;
    subtotal: number;
    total: number;
}


export class SubscriptionChangeOutcome {
    currency: string;
    calculation: Array<CalculationItem>;
    owed: number;
    subscriptionPrice: number;
    proration_date: number;
    subtotal: number;
    total: number;

    constructor(init: SubscriptionChangeOutcomeInit) {
        this.currency = init.currency;
        this.calculation = init.calculation;
        this.owed = init.owed;
        this.subscriptionPrice = init.subscriptionPrice;
        this.proration_date = init.proration_date;
        this.subtotal = init.subtotal;
        this.total = init.total;
    }
}
import React, {ChangeEvent, DependencyList, useCallback, useEffect, useState} from "react";
import {
    AsYouType, parsePhoneNumber,
} from "libphonenumber-js";

/**
 * Standard setup - a loading, setLoading, error, setError, model, setModel
 * This is used in many components that communicates with the server. Get
 * data, display loading while awaiting, set error if something went wrong,
 * set data into model, display the model.
 *
 * Types:
 *
 *        loading: Boolean,
 *
 *        error: String,
 *
 *        model: T.
 *
 */
export function useStandardSetup<T>(initialValue?: T): [
    boolean, React.Dispatch<React.SetStateAction<boolean>>,
    string, React.Dispatch<React.SetStateAction<string>>,
    T | undefined, React.Dispatch<React.SetStateAction<T | undefined>>
] {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [model, setModel] = useState<T | undefined>(initialValue);

    return [
        loading, setLoading,
        error, setError,
        model, setModel
    ]
}

export function useFormSetup<T, EO>(initialModel: T, errorsObject: EO): [
    boolean, React.Dispatch<React.SetStateAction<boolean>>,
    EO, React.Dispatch<React.SetStateAction<EO>>,
    string, React.Dispatch<React.SetStateAction<string>>,
    T, React.Dispatch<React.SetStateAction<T>>
] {
    const [awaiting, setAwaiting] = useState(false);
    const [errors, setErrors] = useState(errorsObject);
    const [error, setError] = useState("");
    const [model, setModel] = useState<T>(initialModel);

    return [
        awaiting, setAwaiting,
        errors, setErrors,
        error, setError,
        model, setModel
    ]
}


/**
 * Normal handleChange. When a change happens event.target.name and event.target.value is used
 * to create the change ({[name]: value}) and merges the change with the previous model state.
 *
 * @param model The model to use in setModel.
 * @param setModel The model dispatcher.
 * @param setError Optional. The dispatcher for error message. If set the error will be set empty when a change occurs.
 */
export function useStandardHandleChange<T>(
    model: new (data: any) => T,
    setModel: React.Dispatch<React.SetStateAction<T>>,
    setError?: React.Dispatch<React.SetStateAction<string>>
): [(event: any) => void] {
    const handleChange = (event: any) => {
        if (setError !== undefined) {
            setError("");
        }

        const { name, value } = event.target;
        const change = {
            [name]: value,
        };

        setModel((prevModel) => {
            return new model({ ...prevModel, ...change });
        });
    }

    return [
        handleChange
    ]
}

/**
 * Used to handle phone number input.
 *
 * Uses libphonenumber AsYouType object in the handlePhoneNumberChange to format the input (inputFieldValue).
 *
 * @param model model to insert the phone number on change.
 * @param setModel The dispatcher related to the model.
 * @param initialValue The model value from a previous validated phone input (fx. +45 24 24 24 24), if any.
 *                     Do not set unless sure that the phoneNumber has been validated properly.
 * @param alternateInputFieldName Set if the 'name' attribute of PhoneInput is not 'phone'!
 * @param setError Optional. The dispatcher for error message. If set the error will be set empty when a change occurs.
 */
export function usePhoneNumberInput<T>(
    model: new (data: any) => T,
    setModel: React.Dispatch<React.SetStateAction<T>>,
    initialValue?: string,
    alternateInputFieldName?: string,
    setError?: React.Dispatch<React.SetStateAction<string>>
) {
    const [ phoneNumber, setPhoneNumber ] = useState(
        initialValue ?
            parsePhoneNumber(initialValue) :
            undefined);
    const [ inputFieldValue, setInputFieldValue ] = useState(
        phoneNumber ?
            phoneNumber.formatNational() :
            ""
    );
    const inputFieldName = alternateInputFieldName ? alternateInputFieldName : "phone";
    const [ countryCode, setCountryCode ] = useState(
        phoneNumber ?
            phoneNumber.country :
            undefined
    );

    useEffect(() => {
        if (countryCode) {
            if (phoneNumber && phoneNumber.country !== countryCode) {
                try {
                    const newPhoneNumber = parsePhoneNumber(phoneNumber.formatNational(), countryCode);
                    setPhoneNumber(newPhoneNumber);
                    setInputFieldValue(new AsYouType({
                        defaultCountry: newPhoneNumber.country,
                        defaultCallingCode: newPhoneNumber.countryCallingCode
                    }).input(newPhoneNumber.nationalNumber));
                    setModel((prevState) => {
                        return { ...prevState, ...{[inputFieldName]: newPhoneNumber.formatInternational()}}
                    });
                } catch (e) {

                }
            } else if (!phoneNumber) {
                try {
                    const newPhoneNumber = parsePhoneNumber(inputFieldValue, countryCode);
                    setPhoneNumber(newPhoneNumber);
                    setInputFieldValue(new AsYouType({
                        defaultCountry: newPhoneNumber.country,
                        defaultCallingCode: newPhoneNumber.countryCallingCode
                    }).input(newPhoneNumber.nationalNumber));
                    setModel((prevState) => {
                        return { ...prevState, ...{[inputFieldName]: newPhoneNumber.formatInternational()}}
                    });
                } catch (e) {

                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryCode]);

    const handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        let change = {};

        if (setError) {
            setError("");
        }

        try {
            const phoneObject = parsePhoneNumber(value, countryCode);
            setPhoneNumber(phoneObject);
            change = {[name]: phoneObject.formatInternational()};
        } catch (e) {
            change = {[name]: ""};
        }

        setInputFieldValue(new AsYouType({
            defaultCountry: countryCode,
            defaultCallingCode: phoneNumber?.countryCallingCode
        }).input(value));

        setModel((prevState) => {
            return new model({ ...prevState, ...change })
        });
    };

    return {
        handlePhoneNumberChange,
        inputFieldValue,
        countryCode,
        setCountryCode,
    }
}

export function useDebouncedEffect(effect: Function, delay: number, deps: DependencyList) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const callback = useCallback(effect, deps);

    useEffect(() => {

        const handler = setTimeout(() => {
            callback();
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [callback, delay]);
}
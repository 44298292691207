import {PageWithContent} from "../common/ContentStandards";
import {useState} from "react";
import AdminUserPage from "./AdminUserPage";
import AdminNewsPage from "./AdminNewsPage";
import AdminUnsupportedLoggerFilesPage from "./AdminUnsupportedLoggerFilesPage";
import AdminTermsPage from "./AdminTermsPage";
import AdminBackupPage from "./AdminBackupPage";

/**
 * AdminPage - Only available to the admin. From here all entries can be modified etc. This is the main
 * tool to use when offering support to the customers. Control Center.
 * This is also where news can be uploaded.
 */
export default function AdminPage() {
    const [ shownContent, setShownContent ] = useState(<AdminUserPage />);
    const [ highlight, setHighlight ] = useState(0);

    const handleChangeContent = (newContent: JSX.Element) => {
        setShownContent(newContent);
    };

    return (
        <PageWithContent>
            <div className="tabs-container">
                <div className="tabs-bar">
                    <button
                        className={highlight === 0 ?
                            "tabs-bar-button top-left-radius selected" :
                            "tabs-bar-button top-left-radius"}
                        onClick={() => {
                            setHighlight(0);
                            handleChangeContent(
                                <AdminUserPage/>
                            );
                        }}>
                        {"Users"}
                    </button>
                    <button
                        className={highlight === 1 ?
                            "tabs-bar-button selected" :
                            "tabs-bar-button"}
                        onClick={() => {
                            setHighlight(1);
                            handleChangeContent(
                                <AdminNewsPage/>
                            );
                        }}>
                        {"News"}
                    </button>
                    <button
                        className={highlight === 2 ?
                            "tabs-bar-button selected" :
                            "tabs-bar-button"}
                        onClick={() => {
                            setHighlight(2);
                            handleChangeContent(
                                <AdminUnsupportedLoggerFilesPage/>
                            );
                        }}>
                        {"Logger"}
                    </button>
                    <button
                        className={highlight === 3 ?
                            "tabs-bar-button selected" :
                            "tabs-bar-button"}
                        onClick={() => {
                            setHighlight(3);
                            handleChangeContent(
                                <AdminTermsPage />
                            );
                        }}>
                        {"Terms"}
                    </button>
                    <button
                        className={highlight === 4 ?
                            "tabs-bar-button selected" :
                            "tabs-bar-button"}
                        onClick={() => {
                            setHighlight(4);
                            handleChangeContent(
                                <AdminBackupPage />
                            );
                        }}>
                        {"Backup"}
                    </button>
                </div>
                {shownContent}
            </div>
        </PageWithContent>
    );

}
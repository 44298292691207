import requireTranslation from "../../lang/locales/config";
import {SpinLoader} from "../common/SpinLoader";
import ErrorMessage, {InactiveCheck} from "../common/ErrorMessage";
import {useEffect, useState} from "react";
import {CaseStatusParams, useCaseStatusApi} from "../../API/useClientCaseApi";
import {InformationDialog, useDialog} from "../common/ConfirmationDialog";
import LoggerNoteExtracted from "./LoggerNoteExtracted";
import LoggerNoteExistingFile from "./LoggerNoteExistingFile";
import LoggerNoteUploadFile from "./LoggerNoteUploadFile";
import {useStandardSetup} from "../../utils/customHooks";
import LoggerNoteCheck from "./LoggerNoteCheck";


interface LoggerNoteSectionProps {
    clientId: string;
    changeContent: (formElement: JSX.Element,
                    statusMessage?: string,
                    newClientName?: string,
                    shouldRefreshStatus?: boolean) => void;
}

export default function LoggerNoteSection({ clientId, changeContent }: LoggerNoteSectionProps) {
    const t = requireTranslation();
    const [
        loading, setLoading,
        error, setError,
        updateStatus, setUpdateStatus
    ] = useStandardSetup<boolean>(true);
    const [ extractedLoggerNotes, setExtractedLoggerNotes ] = useState(false);
    const [ loggerFileStatus, setLoggerFileStatus ] = useState<boolean>();
    const { isOpen, message: dialogMessage, setDialogMessage, toggle: toggleDialog } = useDialog();
    const { getCaseStatus } = useCaseStatusApi();

    useEffect(() => {
        if (updateStatus) {
        setLoading(true);
        getCaseStatus(clientId, [CaseStatusParams.LOGGER_NOTE_FILE, CaseStatusParams.LOGGER_NOTES])
            .then((caseStatus) => {
                    // console.log("status updated!")
                    setLoggerFileStatus(caseStatus.loggerNoteFile);
                    setExtractedLoggerNotes(caseStatus.loggerNotes);
                setUpdateStatus(false);
            })
            .catch((e) => { if (e instanceof Error) setError(e.message) })
            .finally(() => setLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateStatus])

    const handleInformation = () => {
        setDialogMessage(t.loggerNoteExperimentalInformationText);
        toggleDialog();
    }

    const handleChildChange = () => {
        changeContent(
            <LoggerNoteSection
                clientId={clientId}
                changeContent={changeContent} />,
            t.messages.successUpload,
            undefined,
            true
        )
        setUpdateStatus(true);
    }

    return (
        <>
            <div className="row center-page">
                <h2 className="color-warn text">{t.loggerNoteExperimentalHeader}</h2>
                <img className="icon-button" src={"icon_info_outlined.svg"} alt="Info" onClick={handleInformation}/>
            </div>
            {isOpen && (
                <InformationDialog
                    toggle={toggleDialog}
                    message={dialogMessage} />
            )}
            <SpinLoader
                containerClassName="content color-main-background"
                loading={loading}
                loadMessage={`${t.loading} ${t.loggerNote}...`} />
            <ErrorMessage
                error={error}
                containerClassName="content color-main-background" />
            {!loading && !error && (
                <div className="content-multiple-content">
                    {loggerFileStatus === false && (
                        <InactiveCheck>
                            <LoggerNoteUploadFile
                                clientId={clientId}
                                afterUploadSuccess={handleChildChange} />
                        </InactiveCheck>
                    )}
                    {loggerFileStatus && (
                        <LoggerNoteExistingFile
                            clientId={clientId}
                            afterDeleteSuccess={handleChildChange} />
                    )}
                    {loggerFileStatus && extractedLoggerNotes && (
                        <LoggerNoteCheck clientId={clientId} />
                    )}
                    {extractedLoggerNotes && (
                        <LoggerNoteExtracted
                            clientId={clientId} />
                    )}
                    {loggerFileStatus === undefined && (
                        <div className="content color-main-background fade-in-below">
                            <div className="content-box">
                                <h2>No current status on logger note file. Either server is down
                                    or status could not be acquired.

                                    Try to refresh page...
                                </h2>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}
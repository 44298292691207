import {Content, ContentForm} from "../common/ContentStandards";
import {ChangeEvent, useEffect, useState} from "react";
import {useStandardSetup} from "../../utils/customHooks";
import {SupportedLanguages} from "../../models/Languages";
import {useLanguageApi} from "../../API/useLanguageApi";
import {FileInputWithLabel, SelectFieldWithLabel, TextInputWithLabel} from "../common/FormCommons";
import {IntegratedSpinLoader} from "../common/SpinLoader";
import ErrorMessage from "../common/ErrorMessage";
import useAdminApi from "../../API/useAdminApi";
import {ConfirmationDialog, useDialog} from "../common/ConfirmationDialog";

export default function AdminTermsPage() {
    const [
        loading, setLoading,
        error, setError,
        supportedLanguages, setSupportedLanguages
    ] = useStandardSetup<SupportedLanguages>();
    const [ uploadFile, setUploadFile ] = useState<File>();
    const [ chosenLang, setChosenLang ] = useState("");
    const [ adminPassword, setAdminPassword ] = useState("");
    const [ successMessage, setSuccessMessage ] = useState("");
    const {
        isOpen,
        message: dialogMessage,
        setDialogMessage,
        toggle: toggleDialog
    } = useDialog();
    const { getSupportedLanguages } = useLanguageApi();
    const { postTermsAndConditions } = useAdminApi();

    useEffect(() => {
        if (!supportedLanguages) {
            setLoading(true);
            getSupportedLanguages()
                .then(setSupportedLanguages)
                .catch((e) => {
                    if (e instanceof Error) {
                        setError(e.message);
                    }
                })
                .finally(() => setLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [supportedLanguages]);


    const handleInputDialog = () => {
        setDialogMessage("Verify your admin status! Enter your unique pass:");
        toggleDialog();
    };

    const handleUploadTerms = () => {
        if (uploadFile && adminPassword) {
            // console.log("sending", uploadFile.name, "to language", chosenLang);
            setLoading(true);
            setSuccessMessage("");
            setError("");

            const formData = new FormData();
            formData.append("termsAndConditions", uploadFile, uploadFile.name);
            formData.append("passphrase", adminPassword);

            postTermsAndConditions(chosenLang, formData)
                .then(() => {
                    setSuccessMessage(`${uploadFile.name} has been successfully uploaded for language ${chosenLang.toUpperCase()}`)
                })
                .catch((e) => {
                    if (e instanceof Error) {
                        setError(e.message);
                    }
                })
                .finally(() => setLoading(false));
        }
    }


    return (
        <Content
            invisible={true}
            flexGrow={true}
            headline={"Terms And Conditions"}
            centerHeadline={true}>
            <>
                {isOpen &&
                    <ConfirmationDialog
                        toggle={toggleDialog}
                        message={dialogMessage}
                        onConfirm={handleUploadTerms}>
                        <TextInputWithLabel
                            labelText={"Pass"}
                            initialValue={adminPassword}
                            onChange={(e) => setAdminPassword(e.target.value)} />
                    </ConfirmationDialog>
                }

                {supportedLanguages &&
                    <ContentForm>
                        <FileInputWithLabel
                            labelText={"Upload Terms"}
                            buttonText={"Choose"}
                            uploadFileText={uploadFile ? uploadFile.name : ""}
                            accepted={".pdf"}
                            onChange={(e) => {
                                setError("");
                                if (e.target.files) {
                                    setUploadFile(e.target.files[0])
                                }
                            }}/>
                        <SelectFieldWithLabel
                            labelText={"Language"}
                            selection={supportedLanguages.supportedLanguages}
                            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                setChosenLang(e.target.value);
                            }}/>
                        <div className="row reverse margin">
                            <button
                                className="filled"
                                onClick={handleInputDialog}
                                disabled={!uploadFile || !chosenLang}>
                                Upload
                            </button>
                        </div>
                    </ContentForm>

                }
                {successMessage &&
                    <div className="row">
                        <p className="success message">{successMessage}</p>
                    </div>
                }
            </>
            <IntegratedSpinLoader loading={loading} loadMessage={""} />
            <ErrorMessage error={error} />
        </Content>
    )
}
import {SpinLoader} from "../common/SpinLoader";
import {useStandardSetup} from "../../utils/customHooks";
import {StepReadingModel} from "../../models/case/stepReading";
import requireTranslation from "../../lang/locales/config";
import ErrorMessage, {InactiveCheck} from "../common/ErrorMessage";
import {StepReadingCard} from "./StepReadingCard";
import {useStepReadingApi} from "../../API/useClientCaseApi";
import {useEffect} from "react";
import StepReadingForm from "./StepReadingForm";
import {MAX_STEP_READINGS} from "../../constants/requirement";


interface StepReadingsListProps {
    clientId: string;
    onEdit: (formElement: JSX.Element, statusMessage?: string) => void;
}

/**
 * Displays list of step readings.
 * Fetches list of step readings related to clientId from server.
 * Displays a spin loader while fetching.
 * Error message will be displayed on any error retrieving the data.
 *
 * Each item on the list is a StepReadingCard element.
 */
export default function StepReadingsList({ clientId, onEdit }: StepReadingsListProps) {
    const t = requireTranslation();
    const [
        loading, setLoading,
        error, setError,
        stepReadings, setStepReadings
    ] = useStandardSetup<StepReadingModel[]>([]);
    const { getStepReadings } = useStepReadingApi();

    useEffect(() => {
        setLoading(true);
        getStepReadings(clientId)
            .then((data) => {
                if (data.length === 0) {
                    onEdit(
                        <InactiveCheck>
                            <StepReadingForm
                                onSave={onEdit}
                                clientId={clientId}
                                stepReadingCount={data.length} />
                        </InactiveCheck>
                    )
                }
                setStepReadings(data)
            })
            .catch((e) => {
                if (e instanceof Error) setError(e.message);
            })
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientId])

    const handleNewClick = () => {
        onEdit(
            <InactiveCheck>
                <StepReadingForm
                    onSave={onEdit}
                    clientId={clientId}
                    stepReadingCount={stepReadings!!.length}
                    haveBackButton={true} />
            </InactiveCheck>
        )
    }


    return (
        <>
            <SpinLoader
                containerClassName="content color-main-background"
                loading={loading}
                loadMessage={`${t.loading} ${t.step.toLowerCase()} ${t.reading.toLowerCase()}...`} />
            <ErrorMessage
                error={error}
                containerClassName="content color-main-background" />
            {!loading && !error && stepReadings && (
                <div className="content color-main-background fold-out">
                    <div className="row toColumn">
                        <h3 className="content-headline available">{`${t.step} ${t.readings}`}</h3>
                        {stepReadings.length < MAX_STEP_READINGS && (
                            <button
                                className="filled"
                                onClick={handleNewClick}>{t.new}</button>
                        )}
                    </div>
                    <div className="content-inner-list">
                        {stepReadings.length > 0 ? stepReadings.map((stepReading) => (
                            <StepReadingCard
                                key={stepReading.id}
                                stepReading={stepReading}
                                onEdit={onEdit}
                                stepReadingCount={stepReadings.length} />
                        )) : (
                            <p className="center-page">{t.messages.noStepReadings}</p>
                        )}
                    </div>
                </div>
            )}
        </>
    )
}
import React from "react";
import requireTranslation, {requireCurrentLanguage} from "../../lang/locales/config";
import "../../styles/homepage.css"
import {NavLink} from "react-router-dom";
import {BasicSubscription, ExtendedSubscription} from "../utils/SelectSubscription";
import {InformationRow} from "../common/TextCommons";

function HomePage() {
    const t = requireTranslation();
    const lang = requireCurrentLanguage();

    return (
        <div id="home">
            <div className="home-content no-bottom-padding">
                <div className="home-text-container">
                    <h1>{t.informationTexts.siteFrontHeadline}</h1>
                    <p className="center">{t.informationTexts.siteFrontPart1}</p>
                    <img className="preview-pdf" src={`${lang}/salesExample-2.png`} alt={t.report}/>
                    <p className="center">{t.informationTexts.siteFrontPart2}</p>
                    <p className="note">{t.informationTexts.siteFrontPart2Notes}</p>
                    <img className="preview-pdf" src={`${lang}/salesExample-3.png`} alt={t.loggerNoteFile}/>
                    <p className="center bold">{t.informationTexts.siteFrontPart3Title}</p>
                    <p className="center">{t.informationTexts.siteFrontPart3}</p>
                    <p className="note">{t.informationTexts.siteFrontPart3Notes}</p>
                    <NavLink to="/register">{t.signUpHere}</NavLink>
                    <h1>{t.informationTexts.siteFrontPart4Title}</h1>
                    <p className="center">{t.informationTexts.siteFrontPart4}</p>
                    <div className="subscription-container">
                        <BasicSubscription isSelected={false}/>
                        <ExtendedSubscription isSelected={false}/>
                    </div>
                    <p className="note">{t.informationTexts.siteFrontPart4PreNote}</p>
                    <p className="center">{t.informationTexts.siteFrontPart4Continued}</p>
                    <h3 className="center bold">{t.informationTexts.siteFrontPart4ImportantTitle}</h3>
                    <p className="center">{t.informationTexts.siteFrontPart4ImportantBody}</p>
                    <p className="note">{t.informationTexts.siteFrontPart4Notes}</p>
                    <NavLink to="/register">{t.signUpHere}</NavLink>
                    <h1>{t.informationTexts.siteFrontPart5Title}</h1>
                    <p className="center">{t.informationTexts.siteFrontPart5}</p>
                    <NavLink to="/register">{t.signUpHere}</NavLink>
                </div>
                <div className="watermark-container color-sec-background">
                    <div className="watermark-content">
                        <h3 className="content-headline">{t.watermark.title}</h3>
                        <InformationRow title={t.VAT} text={t.watermark.companyVAT}/>
                        <InformationRow title={t.company} text={t.watermark.companyName}/>
                        <InformationRow title={t.location} text={t.watermark.companyLocation}/>
                        <InformationRow title={t.email} text={t.watermark.companyEmail}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomePage;
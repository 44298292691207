export class Subscription {
    indicator: number;
    textRepresentation?: string;
    possibleSubs = [
        {value: 0, text: "Basic"},
        {value: 1, text: "Extended"},
        {value: 2, text: "Free"},
        {value: 3, text: "Not Active"}
    ]

    constructor(indicator: number) {
        this.indicator = indicator;

        this.possibleSubs.forEach((sub) => {
            if (indicator === sub.value)
                this.textRepresentation = sub.text
        })

        if (this.textRepresentation === undefined) {
            this.textRepresentation = "Not Valid!"
        }

    };

    getSelection = () => {
        return ["Basic", "Extended", "Free", "Not Active"]
    };

    getIndicator = (text: string) => {
        let indicator = -1;
        this.possibleSubs.forEach((sub) => {
            if (text === sub.text)
                indicator = sub.value;
        })
        return indicator
    };
}
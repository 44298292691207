import requireTranslation from "../../lang/locales/config";
import {useStandardSetup} from "../../utils/customHooks";
import LoggerNoteModel from "../../models/case/loggerNote";
import {useEffect, useState} from "react";
import {useLoggerNoteApi} from "../../API/useClientCaseApi";
import {InformationRow} from "../common/TextCommons";
import {SpinLoader} from "../common/SpinLoader";
import ErrorMessage from "../common/ErrorMessage";
import LoggerNoteExtractedDetails from "./LoggerNoteExtractedDetails";

interface LoggerNoteExtractedProps {
    clientId: string;
}

/**
 * Part section of LoggerNoteSection.
 * If logger note extraction succeeded this part should be shown.
 * @param clientId Id of the related client.
 */
export default function LoggerNoteExtracted({ clientId }: LoggerNoteExtractedProps) {
    const t = requireTranslation();
    const [
        loading, setLoading,
        error, setError,
        loggerNotes, setLoggerNotes,
    ] = useStandardSetup<LoggerNoteModel[]>();
    const [ selectedLoggerNote, setSelectedLoggerNote ] = useState<LoggerNoteModel>();
    const { getLoggerNotes } = useLoggerNoteApi();
    
    useEffect(() => {
        setLoading(true);
        getLoggerNotes(clientId)
            .then(setLoggerNotes)
            .catch((e) => { if (e instanceof Error ) setError(e.message) })
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientId])

    const handleLoggerNoteClick = (loggerNote: LoggerNoteModel) => {
        setSelectedLoggerNote(loggerNote);
    }

    const handleLoggerNoteBackClick = () => {
        setSelectedLoggerNote(undefined);
    }
    
    return (
        <>
            <SpinLoader
                containerClassName="content color-main-background"
                loading={loading}
                loadMessage={`${t.loading} ${t.loggerNotes}...`} />

            <ErrorMessage
                error={error}
                containerClassName="content color-main-background" />

            {!loading && !error && loggerNotes && !selectedLoggerNote && (
                <div className="content color-main-background fold-out">
                    <h2 className="content-headline center-page">{t.loggerNoteExtracted}</h2>
                    <div className="content-inner-list">
                        {loggerNotes.map((loggerNote) => (
                            <div
                                className="card color-sec-background clickable"
                                key={loggerNote.id} onClick={() => handleLoggerNoteClick(loggerNote)}>
                                <h3 className="card-header">{`${t.loggerNote} ${loggerNote.note}`}</h3>
                                <InformationRow title={t.date} text={loggerNote.date} />
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {!loading && !error && selectedLoggerNote && (
                <LoggerNoteExtractedDetails
                    loggerNote={selectedLoggerNote}
                    onBackClick={handleLoggerNoteBackClick} />
            )}
        </>
    );
}
interface InformationRowProps {
    title: string;
    text?: string | number;
    rightIcon?: string;
    onClickRightIcon?: () => void;
    titleItalic?: boolean;
    textRightAlign?: boolean;
    miniLoader?: boolean;
    miniLoadMessage?: string;
}

export function InformationRow(
    {
        title,
        text,
        rightIcon,
        onClickRightIcon,
        titleItalic = true,
        textRightAlign = true,
        miniLoader,
        miniLoadMessage
    }: InformationRowProps) {
    return (
        <div className="input-container">
            <div className="row">
                {!text && rightIcon ?
                    <p className={titleItalic ?
                        "title-line fill-half italic" :
                        "title-line fill-half"}>
                        {title}
                    </p> :
                    <p className={titleItalic ?
                        "title-line fill-half italic" :
                        "title-line fill-half"}>
                        {title}
                    </p>
                }
                {text && !miniLoader && (
                    <p className={textRightAlign ?
                        "single-line text-right-align" :
                        "single-line"}>
                        {text}
                    </p>
                )}
                {miniLoader && (
                    <div className="row reverse">
                        <div className="loader mini"/>
                        <p className="single-line no-grow">{miniLoadMessage}</p>
                    </div>
                )}
                {rightIcon && (
                    <div className="row reverse">
                        <img
                            className={onClickRightIcon ? "icon-button clickable" : "icon-button"}
                            onClick={onClickRightIcon}
                            src={rightIcon}
                            alt="icon" />
                    </div>
                )}
            </div>
        </div>
    )
}

interface HeadlineWithTextProps {
    headline: string;
    text: string;
    centerContent?: boolean;
    italicHeadline?: boolean;
    italicText?: boolean;
}

export function HeadlineWithText(
    {
        headline,
        text,
        centerContent = false,
        italicHeadline = false,
        italicText = false
    }: HeadlineWithTextProps
) {
    return (
        <div className={centerContent ? "column" : "column align-start"}>
            <h3 className={italicHeadline ?
                "title-line no-margin top-padding start-padding italic" :
                "title-line no-margin top-padding start-padding"}>
                {headline}
            </h3>
            <h4 className={italicText ?
                "support-linebreak no-margin top-padding start-padding italic" :
                "support-linebreak no-margin top-padding start-padding"}>
                {text}
            </h4>
        </div>
    )
}


import {NewsCollectionGet, NewsCollectionModel, NewsDeleteModel, NewsModel} from "../../models/News";
import {useStandardSetup} from "../../utils/customHooks";
import {Content} from "../common/ContentStandards";
import {useEffect, useState} from "react";
import AdminNewsForm from "./AdminNewsForm";
import useNewsApi from "../../API/useNewsApi";
import {useAppSelector} from "../../state/hooks";
import {selectUser} from "../../state/userSlice";
import {validateRequired} from "../../utils/inputValidation";
import useAdminApi from "../../API/useAdminApi";
import {ConfirmationDialog, useDialog} from "../common/ConfirmationDialog";
import {TextInputWithLabel} from "../common/FormCommons";
import {OverlaySpinLoader} from "../common/SpinLoader";
import ErrorMessage from "../common/ErrorMessage";

interface AdminNewsCollectionFormProps {
    newsCollectionInfo?: NewsCollectionGet;
    supportedLanguages: string[];
    onBack: (reload: boolean) => void;
}

export default function AdminNewsCollectionForm(
    { newsCollectionInfo, supportedLanguages, onBack }: AdminNewsCollectionFormProps
) {
    const user = useAppSelector(selectUser);
    const [
        loading, setLoading,
        error, setError,
        newsCollectionModel, setNewsCollectionModel
    ] = useStandardSetup<NewsCollectionModel>(
        newsCollectionInfo ?
            new NewsCollectionModel(
                {
                    id: newsCollectionInfo.id,
                    newsCollection: Array(supportedLanguages.length)
                }
            ) :
            new NewsCollectionModel(
                {
                    id: null,
                    newsCollection: supportedLanguages.map((value) => {
                        const newsModel = new NewsModel();
                        newsModel.language = value;
                        newsModel.createdBy = user.name;
                        return newsModel;
                    })
                }
            )
    );
    const [ highlightError, setHighlightError ] = useState<number[]>([]);
    const [ highlight, setHighlight ] = useState(0);
    const [ adminPassword, setAdminPassword ] = useState("");
    const {
        isOpen,
        message: dialogMessage,
        setDialogMessage,
        toggle: toggleDialog
    } = useDialog();
    const { getNewsById } = useNewsApi();
    const { postNews, putNews, deleteNews } = useAdminApi();

    useEffect(() => {
        if (newsCollectionInfo) {
            supportedLanguages.forEach((language, index) => {
                if (newsCollectionInfo.newsIds.length >= index + 1) {
                    setLoading(true);
                    getNewsById(newsCollectionInfo.newsIds[index])
                        .then((newsModel) => {
                            newsCollectionModel!!.newsCollection[index] = newsModel;
                        })
                        .catch((e) => {
                            if (e instanceof Error) {
                                setError(e.message);
                            }
                        })
                        .finally(() => setLoading(false));
                } else {
                    newsCollectionModel!!.newsCollection[index] = new NewsModel();
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newsCollectionInfo]);

    const handleChangeNewsModel = (index: number, newsModel: NewsModel) => {
        if (index in highlightError) {
            setHighlightError((prevState) => {
                delete prevState[index]
                return prevState
            })
        }

        setNewsCollectionModel((prevState) => {
            prevState!!.newsCollection[index] = newsModel;
            return new NewsCollectionModel({ ...prevState!!})
        });
    };

    const handleInputDialog = () => {
        setDialogMessage("Verify your admin status! Enter your unique pass:");
        toggleDialog();
    };

    const handleAddNewsCollection = () => {
        setHighlightError([]);
        if (validateContent()) {
            setLoading(true);
            postNews(newsCollectionModel!!)
                .then(() => onBack(true))
                .catch((e) => {
                    if (e instanceof Error) {
                        setError(e.message);
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    const handleEditNewsCollection = () => {
        setHighlightError([]);
        if (validateContent()) {
            setLoading(true);
            putNews(newsCollectionModel!!)
                .then(() => onBack(true))
                .catch((e) => {
                    if (e instanceof Error) {
                        setError(e.message);
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    const handleDeleteNewsCollection = () => {
        const deleteModel: NewsDeleteModel = {
            collectionId: newsCollectionModel!!.id!!,
            passphrase: adminPassword
        }
        setLoading(true);
        deleteNews(deleteModel)
            .then(() => onBack(true))
            .catch((e) => {
                if (e instanceof Error) {
                    setError(e.message);
                }
            })
            .finally(() => setLoading(false));
    };

    const validateContent = (): boolean => {
        let validation = true;
        newsCollectionModel!!.newsCollection.forEach((news, index) => {
            if (validateRequired(news.title) !== "" ||
                validateRequired(news.text) !== "") {
                // console.log("index", index, "is not validated!")
                validation = false;
                setHighlightError((prevState) => {
                    prevState.push(index);
                    return prevState;
                });
            }
        });
        return validation;
    };

    return (
        <Content invisible={true} flexGrow={true}>
            <>
                {isOpen &&
                    <ConfirmationDialog
                        toggle={toggleDialog}
                        message={dialogMessage}
                        onConfirm={handleDeleteNewsCollection}>
                        <TextInputWithLabel
                            labelText={"Pass"}
                            initialValue={adminPassword}
                            onChange={(e) => setAdminPassword(e.target.value)} />
                    </ConfirmationDialog>
                }

                <OverlaySpinLoader loading={loading} loadMessage={""} />

                {supportedLanguages && newsCollectionModel &&
                    <div className="tabs-container no-box-shadow">
                        <div className="tabs-bar">
                            {supportedLanguages.map((language, index) => (
                                <button
                                    key={language}
                                    className={highlight === index ?
                                        highlightError.includes(index) ?
                                            "tabs-bar-button selected error" :
                                            "tabs-bar-button selected" :
                                        highlightError.includes(index) ?
                                            "tabs-bar-button error" :
                                            "tabs-bar-button"}
                                    onClick={() => {
                                        setHighlight(index);
                                    }}
                                >
                                    {language}
                                </button>
                            ))}
                        </div>
                        <AdminNewsForm
                            newsModel={newsCollectionModel.newsCollection[highlight]}
                            index={highlight}
                            handleNewsChange={handleChangeNewsModel} />
                        <div className="row reverse margin">
                            {newsCollectionModel.id &&
                                <>
                                    <button className="filled" onClick={handleEditNewsCollection}>
                                        Edit
                                    </button>
                                    <button className="outline" onClick={handleInputDialog}>
                                        Delete
                                    </button>
                                </>
                            }
                            {!newsCollectionModel.id &&
                                <button className="filled" onClick={handleAddNewsCollection}>
                                    Add
                                </button>
                            }
                        </div>
                    </div>
                }

                <ErrorMessage error={error} />
            </>
        </Content>
    )

}
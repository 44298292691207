import {getDateNowTimestamp} from "../../utils/timestamp";

interface ClientModelInit {
    readonly id: string,
    name: string,
    address: string,
    serviceArea: string,
    instrumentType: string,
    instrumentNumber: string,
    date: number,
    employeeId: string | null,
    formerEmployeeId: string | null
}

export class ClientModel implements ClientModelInit{
    readonly id: string = "";
    name: string = "";
    address: string = "";
    serviceArea: string = "";
    instrumentType: string = "";
    instrumentNumber: string = "";
    date: number = getDateNowTimestamp();
    employeeId: string | null = null;
    formerEmployeeId: string | null = null;

    constructor(init?: ClientModelInit) {
        if (!init) return;

        this.id = init.id;
        this.name = init.name;
        this.address = init.address;
        this.serviceArea = init.serviceArea;
        this.instrumentType = init.instrumentType;
        this.instrumentNumber = init.instrumentNumber;
        this.date = init.date;
        this.employeeId = init.employeeId;
        this.formerEmployeeId = init.formerEmployeeId;
    }
}
import React, {ChangeEvent, useState} from "react";
import {CheckVATModel, CheckVATResult} from "../../models/registration/checkVAT";
import {useRegistrationApi} from "../../API/useRegistrationApi";
import {isInputErrorsEmpty, validateRequired} from "../../utils/inputValidation";
import {IntegratedSpinLoader} from "../common/SpinLoader";
import ErrorMessage from "../common/ErrorMessage";
import {InformationRow} from "../common/TextCommons";
import requireTranslation from "../../lang/locales/config";
import {Content} from "../common/ContentStandards";

const EU = [
    "BE", "BG", "CZ", "DK", "DE", "EE", "IE", "EL",
    "ES", "FR", "HR", "IT", "CY", "LV", "LT", "LU",
    "HU", "MT", "NL", "AT", "PL", "PT", "RO", "SI",
    "SK", "FI", "SE", "NO"
]

interface InputErrors {
    countryCode: string;
    vatNumber: string;
}

const initialInputErrors: InputErrors = {
    countryCode: "",
    vatNumber: ""
}

interface CheckVatProps {
    checkVATResult: CheckVATResult | undefined,
    setCheckVATResult: React.Dispatch<React.SetStateAction<CheckVATResult | undefined>>,
    setConfirmsVAT: React.Dispatch<React.SetStateAction<boolean>>,
    invisible?: boolean,
    altInfoText?: string,
    optional?: boolean,
    update?: boolean
}

export default function CheckVAT(
    {
        checkVATResult,
        setCheckVATResult,
        setConfirmsVAT,
        invisible = false,
        altInfoText = undefined,
        optional = true,
        update = false
    }: CheckVatProps
) {
    const t = requireTranslation();
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState("");
    const [ checkVATInput, setCheckVATInput ] = useState<CheckVATModel>({
        vatNumber: "",
        countryCode: ""
    });
    const [ inputErrors, setInputErrors ] = useState(initialInputErrors);
    const { postCheckVAT } = useRegistrationApi();

    const handleChange = (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
        const { value, name } = event.target;
        const change = {[name]: value};

        setCheckVATInput((prevState) => {
            return { ...prevState, ...change };
        });
    };

    const handleCheckVAT = () => {
        setCheckVATResult(undefined);
        setError("");
        if (validateInput()) {
            setLoading(true);
            postCheckVAT(checkVATInput, update)
                .then((result) => {
                    setCheckVATResult(result)
                })
                .catch((e) => {
                    if (e instanceof Error) {
                        setError(e.message);
                    }
                })
                .finally(() => setLoading(false));
        }
    };

    const validateInput = () => {
        const err = initialInputErrors;

        err.vatNumber = validateRequired(checkVATInput.vatNumber);
        err.countryCode = validateRequired(checkVATInput.countryCode);

        setInputErrors(err);

        return isInputErrorsEmpty(err);
    };

    return (
        <Content
            headline={t.firmInformation.title}
            infoIconText={altInfoText ? altInfoText : t.informationTexts.firmInformation}
            subtitle={optional ? t.firmInformation.subtitle : undefined}
            invisible={invisible}
            flexGrow={true}>
            <div className="content-inner-form">
                {!checkVATResult && <>
                    <div className="row toColumn">
                        <label className="input-label">
                            {t.vatNumber}
                        </label>
                        <select
                            className={inputErrors.countryCode ?
                                "input error select fit-context" :
                                "input select fit-context"}
                            aria-label="VATCountryCode"
                            name="countryCode"
                            defaultValue={checkVATInput.countryCode}
                            onChange={handleChange}>
                            {EU.map((option) => (
                                <option
                                    key={option}
                                    value={option}>
                                    {option}
                                </option>
                            ))}
                            <option value=""></option>
                        </select>
                        <input
                            className={inputErrors.vatNumber ?
                                "input error text-align-center" :
                                "input text-align-center"}
                            name="vatNumber"
                            value={checkVATInput.vatNumber}
                            type="search"
                            maxLength={13}
                            onChange={(e) => {
                                e.target.value = e.target.value.toUpperCase();
                                handleChange(e);
                            }}/>
                        <img
                            className="icon avatar"
                            src={"search.svg"}
                            alt={t.search}/>
                    </div>
                    <div className="row reverse margin">
                        <button
                            className="filled"
                            onClick={handleCheckVAT}>
                            Check
                        </button>
                    </div>
                </>}

                <IntegratedSpinLoader loading={loading} loadMessage={""} />

                <ErrorMessage containerClassName={"content invisible"} error={error} noBorder={true} />

                {checkVATResult &&
                    <div className="row margin">
                        <img
                            className="icon-button"
                            src={"back_arrow.svg"}
                            alt={t.back}
                            onClick={() => {
                                setCheckVATResult(undefined);
                                setConfirmsVAT(false);
                            }}/>
                    </div>
                }

                {checkVATResult && (
                    <div className="card color-sec-background">
                        <div className="row center-page">
                            <h3>{checkVATResult.countryCode + checkVATResult.vatNumber}</h3>
                        </div>
                        <div className="row center-page">
                            <img
                                className={checkVATResult.valid ?
                                    "icon bigger-round color-positive filter pulse" :
                                    "icon bigger-round color-warn text-filter pulse"}
                                src={checkVATResult.valid ? "done.svg" : "error.svg"}
                                alt={checkVATResult.valid ? "Positive" : "Negative"}/>
                        </div>
                        {!checkVATResult.valid && checkVATResult.reason &&
                            <div className="row margin">
                                <p className="error message no-border">{t.firmInformation[checkVATResult.reason]}</p>
                            </div>
                        }
                        {checkVATResult.name && (
                            <InformationRow title={t.company} text={checkVATResult.name}/>
                        )}
                        {checkVATResult.address && (
                            <InformationRow title={t.address} text={checkVATResult.address}/>
                        )}
                        {checkVATResult.zipCode && (
                            <InformationRow title={t.zipCode} text={checkVATResult.zipCode}/>
                        )}
                        {checkVATResult.city && (
                            <InformationRow title={t.city} text={checkVATResult.city}/>
                        )}
                    </div>
                )}

                {checkVATResult && checkVATResult.valid && (
                    <div className="row margin">
                        <p className="multiline">{t.firmInformation.confirm}</p>
                        <label className="input-checkbox-container">
                            <input
                                name="terms"
                                type="checkbox"
                                onClick={(event) => setConfirmsVAT(event.currentTarget.checked)}/>
                            <span className="input-checkmark"></span>
                        </label>
                    </div>
                )}
            </div>
        </Content>
    )
}
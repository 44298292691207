import {useEffect, useState} from "react";
import {ClientModel} from "../../models/case/client";
import {useClientApi} from "../../API/useClientCaseApi";
import requireTranslation from "../../lang/locales/config";
import {SpinLoader} from "../common/SpinLoader";
import ErrorMessage, {InactiveCheck} from "../common/ErrorMessage";
import {localeDateFromTimestamp} from "../../utils/timestamp";
import {InformationRow} from "../common/TextCommons";
import {useStandardSetup} from "../../utils/customHooks";
import ClientForm from "./ClientForm";
import ClientCaseEmployee from "./ClientCaseEmployee";
import ClientCaseEmployeeForm from "./ClientCaseEmployeeForm";
import {useAppSelector} from "../../state/hooks";
import {selectUser} from "../../state/userSlice";
import {isUser} from "../utils/Enums";


interface ClientDetailsProps {
    clientId: string;
    onEdit: (formElement: JSX.Element, statusMessage?: string, newClientName?: string) => void;
}


export default function ClientDetails({ clientId, onEdit }: ClientDetailsProps) {
    const t = requireTranslation();
    const user = useAppSelector(selectUser);
    const [
        loading, setLoading,
        error, setError,
        client, setClient
    ] = useStandardSetup<ClientModel>();
    const [ employeeEdit, setEmployeeEdit ] = useState(false);
    const { getClient } = useClientApi();

    useEffect(() => {
        if (client === undefined) {
            setLoading(true);
            getClient(clientId)
                .then(setClient)
                .catch((e) => {
                    if (e instanceof Error) setError(e.message)
                })
                .finally(() => setLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientId, client])

    const handleEmployeeEdit = () => {
        setEmployeeEdit(true);
    }

    const handleEmployeeSave = () => {
        setEmployeeEdit(false);
        setClient(undefined);
    }

    return (
        <>
            <SpinLoader
                containerClassName="content color-main-background"
                loading={loading}
                loadMessage={`${t.loading} ${t.case.toLowerCase()} ${t.information.toLowerCase()}...`} />
            <ErrorMessage
                error={error}
                containerClassName="content color-main-background" />

            {!loading && !error && client && (
                <>
                    <div className="content fold-out color-main-background">
                        <h3 className="card-header">{`${t.case} ${t.information}`}</h3>
                        <InformationRow title={`${t.name}:`} text={client.name} />
                        <InformationRow title={`${t.address}:`} text={client.address} />
                        <InformationRow title={`${t.serviceArea}:`} text={client.serviceArea} />
                        <InformationRow title={`${t.instrumentType}:`} text={client.instrumentType} />
                        <InformationRow title={`${t.instrument} ${t.number}:`} text={client.instrumentNumber!!} />
                        <InformationRow title={`${t.date}:`} text={localeDateFromTimestamp(client.date!!)} />
                        <div className="row margin reverse">
                            <button
                                className="filled"
                                onClick={() => onEdit(
                                    <InactiveCheck>
                                        <ClientForm
                                            initialClient={client}
                                            onSave={onEdit}
                                            haveBackButton={true} />
                                    </InactiveCheck>
                                )}>{t.edit}</button>
                        </div>
                    </div>
                    {!employeeEdit && isUser(user.accessLevel) && (
                        <ClientCaseEmployee
                            employeeId={client.employeeId}
                            formerEmployeeId={client.formerEmployeeId}
                            onEdit={handleEmployeeEdit} />
                    )}
                    {employeeEdit && isUser(user.accessLevel) && (
                        <InactiveCheck>
                            <ClientCaseEmployeeForm
                                clientId={client.id}
                                currentEmployeeId={client.employeeId}
                                onSave={handleEmployeeSave} />
                        </InactiveCheck>
                    )}
                </>
            )}
        </>
    )
}
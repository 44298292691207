import requireTranslation from "../../lang/locales/config";
import {Content, ContentForm, PageWithContent} from "../common/ContentStandards";
import {DetailsAndTextInputCombo, PhoneInput} from "../common/FormCommons";
import {useAppDispatch, useAppSelector} from "../../state/hooks";
import {selectUser, setUser} from "../../state/userSlice";
import {useFormSetup, usePhoneNumberInput, useStandardHandleChange} from "../../utils/customHooks";
import {UserUpdateModel} from "../../models/user/user";
import React, {useState} from "react";
import {isInputErrorsEmpty, validateEmail, validateName, validatePhoneNumber} from "../../utils/inputValidation";
import ToastMessage from "../common/ToastMessage";
import {InformationRow} from "../common/TextCommons";
import {useUserApi} from "../../API/useUserApi";
import {OverlaySpinLoader} from "../common/SpinLoader";
import {isEmployee, isUser, SubscriptionEnum} from "../utils/Enums";
import {useEmployeeApi} from "../../API/useEmployeeApi";
import {EmployeeUpdateModel} from "../../models/Employee";
import ErrorMessage from "../common/ErrorMessage";
import {AdministrateSubscription} from "../utils/AdministrateSubscription";
import AdministrateVAT from "../utils/AdministrateVAT";
import {useVerificationDialog, VerificationDialog} from "../common/ConfirmationDialog";
import {useRegistrationApi} from "../../API/useRegistrationApi";


export default function ManageProfilePage() {
    const t = requireTranslation();
    const user = useAppSelector(selectUser);
    const dispatch = useAppDispatch();
    const initialInputErrors = {
        name: "",
        firmName: "",
        firmEmail: "",
        privateEmail: "",
        phone: ""
    }
    const [
        awaiting, setAwaiting,
        inputErrors, setInputErrors,
        errorMessage, setErrorMessage,
        userModel, setUserModel
    ] = useFormSetup(new UserUpdateModel(user), initialInputErrors);
    const [ handleChange ] = useStandardHandleChange(UserUpdateModel, setUserModel, setErrorMessage);
    const [ toastMessage, setToastMessage ] = useState("");
    const [ miniLoader, setMiniLoader ] = useState(false);
    const [ currentFieldName, setCurrentFieldName ] = useState("");
    const [ verificationCode, setVerificationCode ] = useState("");
    const [ emailIsSend, setEmailIsSend ] = useState(false);
    const {
        handlePhoneNumberChange,
        setCountryCode,
        countryCode,
        inputFieldValue
    } = usePhoneNumberInput(
        UserUpdateModel,
        setUserModel,
        user.phone,
        undefined,
        setErrorMessage
    );
    const {
        isOpen,
        toggle,
        dialogError,
        dialogAwaitingResponse,
        dialogAwaitingSendEmail
    } = useVerificationDialog();
    const { resetPassword, putUser, getUser } = useUserApi();
    const { postVerifyEmail } = useRegistrationApi();
    const { putEmployee } = useEmployeeApi();


    const getInputFromField = (fieldName: string): string => {
        switch (fieldName) {
            case "name":
                return userModel.name;
            case "firmName":
                return userModel.firmName;
            case "firmEmail":
                return userModel.firmEmail;
            case "privateEmail":
                return userModel.privateEmail;
            case "phone":
                return userModel.phone;
            default:
                return ""
        }
    };

    const validateInput = (fieldName: string): boolean => {
        const err = initialInputErrors;

        switch (fieldName) {
            case "name":
                err.name = validateName(userModel.name);
                break;
            case "firmName":
                err.firmName = validateName(userModel.firmName);
                break;
            case "firmEmail":
                err.firmEmail = validateEmail(userModel.firmEmail);
                break;
            case "privateEmail":
                err.privateEmail = validateEmail(userModel.privateEmail);
                break;
            case "phone":
                err.phone = validatePhoneNumber(userModel.phone);
                break;
            default:
                break;
        }
        setInputErrors(err);
        return isInputErrorsEmpty(err);
    };

    const handleUpdateUser = () => {
        getUser()
            .then((user) => {
                dispatch(setUser({...user}));
                setToastMessage(t.messages.successEdit);
                setUserModel(user);
            })
            .catch((e) => {
                if (e instanceof Error) {
                    setErrorMessage(e.message);
                }
            })
    }

    const handleResetPassword = () => {
        setMiniLoader(true);
        setEmailIsSend(true);
        resetPassword(user.privateEmail)
            .then()
            .catch((e) => {if (e instanceof Error) {
                setErrorMessage(e.message);
                setEmailIsSend(false);
            }})
            .finally(() => setMiniLoader(false));
    };

    /**
     * Only use on emails. This will validate the email parameter with
     * validateEmail, and if it passes it will toggle the validateEmail dialog and
     * send a validation code to the new email. The value will only be saved if the
     * validation code passes.
     */
    const handleSendValidationCode = (fieldName: string) => {
        if (validateInput(fieldName)) {
            toggle();
            setCurrentFieldName(fieldName);
            dialogAwaitingSendEmail(true);
            dialogError("");
            setErrorMessage("");
            postVerifyEmail(
                getInputFromField(fieldName),
                isEmployee(user.accessLevel) ? "employee" : "user"
            )
                .then()
                .catch((e) => {
                    if (e instanceof Error) {
                        if (e.message !== t.responseMessage.errorVerifyEmail) {
                            setErrorMessage(e.message);
                            setCurrentFieldName("");
                            toggle();
                        }
                    }
                })
                .finally(() => dialogAwaitingSendEmail(false));
        }
    }

    const handleSaveChange = (fieldName: string, toggleVerificationDialog: boolean = false) => {
        if (validateInput(fieldName)) {
            setAwaiting(true);
            isUser(user.accessLevel) ?
                putUser({
                    [fieldName]: getInputFromField(fieldName),
                    "verificationCode": verificationCode
                })
                    .then(() => {
                        if (toggleVerificationDialog) {
                            toggle();
                        }
                        handleUpdateUser();
                    })
                    .catch((e) => {if (e instanceof Error) {
                        setErrorMessage(e.message)
                    }})
                    .finally(() => setAwaiting(false)) :
                putEmployee(new EmployeeUpdateModel({
                    [fieldName]: getInputFromField(fieldName),
                    verificationCode: verificationCode
                }))
                    .then(() => {
                        if (toggleVerificationDialog) {
                            toggle();
                        }
                        handleUpdateUser();
                    })
                    .catch((e) => {if (e instanceof Error) {
                        setErrorMessage(e.message)
                    }})
                    .finally(() => setAwaiting(false));
        }
    };

    return (
        <PageWithContent smaller={true}>

            <>
                {isOpen &&
                    <VerificationDialog
                        setCode={setVerificationCode}
                        toggle={toggle}
                        onVerify={() => handleSaveChange(currentFieldName, true)}
                        error={dialogError}
                        awaitingResponse={dialogAwaitingResponse}
                        awaitingEmailSend={dialogAwaitingSendEmail} />
                }
            </>

            <ToastMessage
                toastMessage={toastMessage}
                setToastMessage={setToastMessage} />

            <OverlaySpinLoader
                loading={awaiting}
                loadMessage={t.loadingMessages.savingChanges} />

            <Content
                headline={t.basicInformation}
                centerHeadline={true}>
                <ContentForm
                    ariaLabel="ManageProfileFormBasic">
                    <DetailsAndTextInputCombo
                        labelText={t.name}
                        ariaLabel="ManageProfileFormBasicName"
                        name="name"
                        initialValue={userModel.name}
                        onChange={handleChange}
                        onSaveClick={() => handleSaveChange("name")}
                        error={inputErrors.name}
                    />
                    <hr/>
                    {isUser(user.accessLevel) ?
                        <>
                            <DetailsAndTextInputCombo
                                labelText={t.firmName}
                                ariaLabel="ManageProfileFormBasicFirmName"
                                name="firmName"
                                initialValue={userModel.firmName}
                                onChange={handleChange}
                                onSaveClick={() => handleSaveChange("firmName")}
                                error={inputErrors.firmName}/>
                                <hr/>
                        </> : <></>
                    }
                    <InformationRow
                        title={t.resetPassword}
                        text={emailIsSend ? t.emailSend : undefined}
                        rightIcon={emailIsSend ? undefined : "send_mail.svg"}
                        onClickRightIcon={emailIsSend ? undefined : handleResetPassword}
                        titleItalic={false}
                        miniLoader={miniLoader}
                        miniLoadMessage={t.loadingMessages.sendingEmail}
                    />
                    <hr/>
                    <>
                        {isUser(user.accessLevel) && user.subscription !== SubscriptionEnum.Free &&
                            <>
                                <AdministrateSubscription handleUpdateUser={handleUpdateUser}/>
                                <hr/>
                                <AdministrateVAT updateUser={handleUpdateUser}/>
                            </>
                        }
                    </>
                </ContentForm>
            </Content>
            <Content
                headline={t.contactInformation}
                centerHeadline={true}>
                <ContentForm>
                    {isUser(user.accessLevel) ? (
                        <>
                            <DetailsAndTextInputCombo
                                labelText={t.firmEmail}
                                ariaLabel="ManageProfileFormContactFirmEmail"
                                name="firmEmail"
                                initialValue={userModel.firmEmail}
                                onChange={handleChange}
                                onSaveClick={() => handleSendValidationCode("firmEmail")}
                                error={inputErrors.firmEmail}
                            />
                            <hr/>
                            <DetailsAndTextInputCombo
                                labelText={t.privateEmail}
                                ariaLabel="ManageProfileFormContactPrivateEmail"
                                name="privateEmail"
                                initialValue={userModel.privateEmail}
                                onChange={handleChange}
                                onSaveClick={() => handleSaveChange("privateEmail")}
                                error={inputErrors.privateEmail}
                            />
                            <hr/>
                            <PhoneInput
                                ariaLabel="ManageProfileFormContactPhone"
                                initialValue={inputFieldValue}
                                countryCode={countryCode}
                                asCombo={true}
                                setCountryCode={setCountryCode}
                                onChange={handlePhoneNumberChange}
                                onSaveClick={() => handleSaveChange("phone")}
                                error={inputErrors.phone}
                            />
                        </>
                    ) : ( <></> )}
                    {isEmployee(user.accessLevel) ? (
                        <>
                            <DetailsAndTextInputCombo
                                labelText={t.email}
                                ariaLabel="ManageProfileFormContactPrivateEmail"
                                name="privateEmail"
                                initialValue={userModel.privateEmail}
                                onChange={handleChange}
                                onSaveClick={() => handleSendValidationCode("privateEmail")}
                                error={inputErrors.privateEmail}/>
                            <hr/>
                            <PhoneInput
                                ariaLabel="ManageProfileFormContactPhone"
                                initialValue={inputFieldValue}
                                countryCode={countryCode}
                                asCombo={true}
                                setCountryCode={setCountryCode}
                                onChange={handlePhoneNumberChange}
                                onSaveClick={() => handleSaveChange("phone")}
                                error={inputErrors.phone}/>
                        </>
                    ) : ( <></> )}
                </ContentForm>
            </Content>

            <ErrorMessage error={errorMessage} />
        </PageWithContent>
    );
}
import requireTranslation from "../../lang/locales/config";
import {SelectFieldWithLabel} from "../common/FormCommons";
import {useEffect, useState} from "react";
import {SpinLoader} from "../common/SpinLoader";
import ClientForm from "./ClientForm";
import {ClientModel} from "../../models/case/client";
import {EmployeeModel} from "../../models/Employee";
import {useEmployeeApi} from "../../API/useEmployeeApi";
import {useClientApi} from "../../API/useClientCaseApi";
import {useNavigate} from "react-router-dom";
import ErrorMessage from "../common/ErrorMessage";
import {useAppSelector} from "../../state/hooks";
import {selectUser} from "../../state/userSlice";
import {isUser} from "../utils/Enums";


export default function CaseForm() {
    const t = requireTranslation();
    const user = useAppSelector(selectUser);
    const [ employees, setEmployees ] = useState<EmployeeModel[] | null>(null);
    const [ employeeSelection, setEmployeeSelection ] = useState<string | null>(null);
    const [ caseSelection, setCaseSelection ] = useState(t.casesList[0]);
    const [ showBalancingCaseForm, setShowBalancingCaseForm ] = useState(true);
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState("");
    const { getEmployees } = useEmployeeApi();
    const { postClient, createClientTemplate } = useClientApi();
    const navigate = useNavigate();

    useEffect(() => {
        if (!employees && isUser(user.accessLevel)) {
            setLoading(true);
            getEmployees(true)
                .then((employees) => {
                    setEmployees(employees);
                })
                .catch((e) => {
                    if (e instanceof Error) setError(e.message);
                })
                .finally(() => setLoading(false))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employees])

    const onCaseSelectionChange = (event: any) => {
        const { value } = event.target;

        setCaseSelection(value);
    };

    const onEmployeeSelectionChange = (event: any) => {
        const { value } = event.target;

        setEmployeeSelection(value !== t.boss ? value : null);
    }

    /**
     * The option to create a new case from the suggestion has been chosen.
     * Api call to preform this will be made. On success navigate to new 'template case'.
     */
    const handleOptionCreate = (optionChoice: ClientModel, date: number) => {
        setLoading(true);
        if (employeeSelection !== null) {
            const employee = employees?.find((employee) => {
                if (employee.name === employeeSelection) {
                    return employee
                }
            })
            if (employee) {
                optionChoice.employeeId = employee.id;
            } else {
                optionChoice.employeeId = null;
            }
        } else {
            optionChoice.employeeId = null;
        }

        createClientTemplate(
            optionChoice.id,
            optionChoice.employeeId,
            date
        ).then((newClientCase) => {
            navigate("/detail", { state:{
                    clientId: newClientCase.id, clientName: newClientCase.name
                }})
        })
            .catch((e) => { if (e instanceof Error) setError(e.message); })
            .finally(() => setLoading(false));

    }

    const onAdd = async (client: ClientModel) => {
        if (employeeSelection !== null) {
            const employee = employees?.find((employee) => {
                if (employee.name === employeeSelection) {
                    return employee
                }
            })
            if (employee) {
                client.employeeId = employee.id;
            }
        }

        // Insert condition. When more case choices are available...

        setLoading(true);
        await postClient(client)
            .then((data) => {
                // console.log("success!");
                navigate("/detail", { state:{
                    clientId: data.id, clientName: data.name
                }})
            })
            .catch((e) => {
                // console.log(e.message)
            })
            .finally(() => setLoading(false))
    };

    return (
        <>
            <SpinLoader
                containerClassName={"content color-main-background"}
                loading={loading}
                loadMessage={`${t.awaiting}`} />

            <ErrorMessage error={error}/>

            {!loading && !error &&
                <div className="content fold-out color-main-background">
                    <form className="content-inner-form" name="caseSelectionForm">
                        <SelectFieldWithLabel
                            labelText={t.case}
                            selection={t.casesList}
                            initialValue={t.casesList[0]}
                            onChange={onCaseSelectionChange}
                            ariaLabel="selectCase"
                            name="selectCaseInput"/>
                        {employees &&
                            <SelectFieldWithLabel
                                labelText={t.employee}
                                selection={employees.map((employee) => employee.name).concat([t.boss])}
                                initialValue={t.boss}
                                onChange={onEmployeeSelectionChange}
                                ariaLabel="selectEmployee"
                                name="selectEmployeeInput"/>
                        }
                    </form>

                    {showBalancingCaseForm && (
                        <ClientForm
                            initialClient={new ClientModel()}
                            onAdd={onAdd}
                            onOptionCreate={handleOptionCreate}
                            borderless={true}/>
                    )}
                </div>
            }
        </>
    )
}
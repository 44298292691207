interface SimplifiedClientCaseInit {
    id: string,
    name: string,
    address: string,
    date: number,
}


class SimplifiedClientCase {
    id: string;
    name: string;
    address: string;
    date: number;

    constructor(initializer: SimplifiedClientCaseInit) {
        this.id = initializer.id;
        this.name = initializer.name;
        this.address = initializer.address;
        this.date = initializer.date;
    }
}

export { SimplifiedClientCase }
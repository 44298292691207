import vasLogo from "../../air2-website.svg";
import {NavLink} from "react-router-dom";
import React, {useEffect, useState} from "react";
import requireTranslation from "../../lang/locales/config";
import {OverlayMenu, useOverlayMenu} from "../common/OverlayMenu";
import {useAppSelector} from "../../state/hooks";
import {selectUser} from "../../state/userSlice";
import {AccessLevels, isAdmin, isEmployee, isUser} from "./Enums";
import ProfileAvatarIcon from "./ProfileAvatarIcon";


export function AppHeaderNoAuth() {
    const t = requireTranslation();
    const [ navigationsSmall, setNavigationSmall ] = useState(window.innerWidth < 1330);
    const [ isOverlayMenuVisible, toggleOverlayMenu ] = useOverlayMenu();

    useEffect(() => {
        const handleWindowResize = () => {
            setNavigationSmall(window.innerWidth < 1330);
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, )

    return (
        <>
            {!navigationsSmall &&
                <header className="App-header">
                    <img src={vasLogo} className="App-logo" alt="logo"/>
                    <NavLink to="/" className="nav-button">
                        {t.home}
                    </NavLink>
                    <NavLink to="/login" className="nav-button">
                        {t.login}
                    </NavLink>
                    <NavLink to="/register" className="nav-button">
                        {t.register}
                    </NavLink>
                    <NavLink to={"/about"} className="nav-button">
                        {t.about}
                    </NavLink>
                </header>
            }
            {navigationsSmall &&
                <header className="App-header">
                    <img src={vasLogo} className="App-logo" alt="logo"/>
                    <div className="row reverse">
                        <div className="nav-menu">
                            <img
                                className="nav-menu-icon color-page-background filter"
                                src={"menu.svg"}
                                alt="Menu"
                                onClick={() => toggleOverlayMenu()}
                            />
                        </div>
                        <OverlayMenu
                            isVisible={isOverlayMenuVisible}
                            toggle={toggleOverlayMenu}
                            ButtonList={() => (
                                <>
                                    <NavLink to="/" className="nav-button overlay-menu">
                                        {t.home}
                                    </NavLink>
                                    <NavLink to="/login" className="nav-button overlay-menu">
                                        {t.login}
                                    </NavLink>
                                    <NavLink to="/register" className="nav-button overlay-menu">
                                        {t.register}
                                    </NavLink>
                                    <NavLink to={"/about"} className="nav-button overlay-menu">
                                        {t.about}
                                    </NavLink>
                                </>
                            )}
                            visibleOnLargeDevices={true} />
                    </div>
                </header>
            }
        </>
    )
}


interface AppHeaderAuthProps {
    logout: () => void;
}

export function AppHeaderAuthUser({ logout }: AppHeaderAuthProps) {
    const t = requireTranslation();
    const [ navigationsSmall, setNavigationSmall ] = useState(window.innerWidth < 1330);
    const [ isOverlayMenuVisible, toggleOverlayMenu ] = useOverlayMenu();
    const user = useAppSelector(selectUser);

    useEffect(() => {
        const handleWindowResize = () => {
            setNavigationSmall(window.innerWidth < 1330);
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
    }, )

    return (
        <>
            {!navigationsSmall && (
                <header className="App-header">
                    <img src={vasLogo} className="App-logo" alt="logo"/>
                    {(isEmployee(user.accessLevel) || isUser(user.accessLevel)) &&
                        <>
                            <NavLink
                                to="/"
                                className="nav-button">
                                {t.home}
                            </NavLink>
                            <NavLink
                                to="/overview"
                                className="nav-button">
                                {t.overview}
                            </NavLink>
                            {isUser(user.accessLevel) &&
                                <NavLink
                                    to={"/employees"}
                                    className="nav-button">
                                    {t.employees}
                                </NavLink>
                            }
                            <NavLink
                                to={"/cases"}
                                className="nav-button">
                                {t.cases}
                            </NavLink>
                            <NavLink
                                to={"/support"}
                                className="nav-button">
                                {t.support}
                            </NavLink>
                            <NavLink
                                to={"/about"}
                                className="nav-button">
                                {t.about}
                            </NavLink>
                        </>
                    }
                    {isAdmin(user.accessLevel) &&
                        <>
                            <NavLink to={"/"} className="nav-button">
                                {t.home}
                            </NavLink>
                            <NavLink to={"/admin"} className="nav-button">
                                {t.administrate}
                            </NavLink>
                        </>
                    }
                    <div className="row reverse">
                        <ProfileAvatarIcon logout={logout}/>
                    </div>
                </header>
            )}
            {navigationsSmall && (
                <header className="App-header">
                    <img src={vasLogo} className="App-logo" alt="logo"/>
                    <div className="row reverse">
                        <img
                            className="nav-menu-icon color-page-background filter"
                            src={"menu.svg"}
                            alt="Menu"
                            onClick={() => toggleOverlayMenu()}
                            />
                        <ProfileAvatarIcon logout={logout} />
                        <OverlayMenu
                            isVisible={isOverlayMenuVisible}
                            toggle={toggleOverlayMenu}
                            ButtonList={() => (
                                <>
                                    <NavLink
                                        to="/"
                                        className="nav-button overlay-menu">
                                        {t.home}
                                    </NavLink>
                                    <NavLink
                                        to="/overview"
                                        className="nav-button overlay-menu">
                                        {t.overview}
                                    </NavLink>
                                    {user.accessLevel === AccessLevels.User &&
                                        <NavLink
                                            to={"/employees"}
                                            className="nav-button overlay-menu">
                                            {t.employees}
                                        </NavLink>
                                    }
                                    <NavLink
                                        to={"/cases"}
                                        className="nav-button overlay-menu">
                                        {t.cases}
                                    </NavLink>
                                    <NavLink
                                        to={"/support"}
                                        className="nav-button overlay-menu">
                                        {t.support}
                                    </NavLink>
                                    <NavLink to={"/about"} className="nav-button overlay-menu">
                                        {t.about}
                                    </NavLink>
                                </>
                            )}
                            visibleOnLargeDevices={true} />
                    </div>
                </header>
            )}
        </>
    )
}
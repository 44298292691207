import {useAppSelector} from "../../state/hooks";
import {selectUser} from "../../state/userSlice";
import {HeadlineWithText, InformationRow} from "../common/TextCommons";
import requireTranslation from "../../lang/locales/config";
import {useNavigate} from "react-router-dom";
import {Content} from "../common/ContentStandards";


export default function TasksOverview() {
    const t = requireTranslation();
    const user = useAppSelector(selectUser);
    const navigate = useNavigate();

    const handleGotoFirmHeader = () => {
        navigate("/firm-header");
    };

    const handleGotoFirmFrontPage = () => {
        navigate("/firm-front-page");
    };

    return (
        <Content headline={t.taskOverview}
                 backgroundColor="color-sec-background"
                 flexGrow={true}>
            <div className="content-inner-list-column no-padding">
                <hr/>
                <HeadlineWithText
                    headline={t.firmHeader}
                    text={user.hasFirmHeader ?
                        t.informationTexts.firmHeaderIsPresent :
                        t.informationTexts.firmHeaderNotPresent}
                    italicText={true}/>
                <div className="row margin reverse">
                    <button className="filled" onClick={handleGotoFirmHeader}>
                        {user.hasFirmHeader ? t.edit : t.add}
                    </button>
                </div>
                <hr/>
                <HeadlineWithText
                    headline={t.firmFrontPage}
                    text={user.hasBalancingFrontpage ?
                        t.informationTexts.firmFrontPageIsPresent :
                        t.informationTexts.firmFrontPageNotPresent}
                    italicText={true}/>
                <div className="row margin reverse">
                    <button className="filled" onClick={handleGotoFirmFrontPage}>
                        {user.hasBalancingFrontpage ? t.edit : t.add}
                    </button>
                </div>
            </div>
        </Content>
    );
}
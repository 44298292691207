/**
 * Adds a suffix to the string.
 *
 * @param toSuffix The string to add suffix too.
 * @param suffix The suffix to add.
 * @param tight True: fx. "50%" False: fx. "50 %", default - false
 */
function addSuffix(toSuffix: string | number, suffix: string, tight: boolean = false) {
    if (tight) {
        return `${toSuffix}${suffix}`
    } else {
        return `${toSuffix} ${suffix}`
    }
}

function boolString(toString: boolean) {
    return toString ? "True" : "False"
}

export { addSuffix, boolString }
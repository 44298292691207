import {SuccessNoContent, useFetch} from "./useFetch";
import {VatInformation, VatInformationModel} from "../models/registration/checkVAT";

export default function useUserVATApi() {
    const baseUrl = process.env.REACT_APP_API_URL as string;
    const url = `${baseUrl}/user/VAT`;
    const requester = useFetch();

    const getUserVatInformation = () => {
        return requester.get(VatInformationModel, url) as Promise<VatInformationModel>;
    };

    const postUserVatInformation = (vatInformation: VatInformation) => {
        return requester.post(SuccessNoContent, url, vatInformation) as Promise<SuccessNoContent>;
    };

    const putUserVatInformation = (vatInformation: VatInformation) => {
        return requester.put(SuccessNoContent, url, vatInformation) as Promise<SuccessNoContent>;
    };

    const deleteUserVatInformation = () => {
        return requester.delete(SuccessNoContent, url) as Promise<SuccessNoContent>;
    };

    return {
        getUserVatInformation,
        postUserVatInformation,
        putUserVatInformation,
        deleteUserVatInformation
    }
}
import requireTranslation from "../../lang/locales/config";
import LoggerNoteModel from "../../models/case/loggerNote";
import {InformationRow} from "../common/TextCommons";

interface LoggerNoteExtractedDetailsProps {
    loggerNote: LoggerNoteModel;
    onBackClick: () => void;
}

export default function LoggerNoteExtractedDetails({ loggerNote, onBackClick }: LoggerNoteExtractedDetailsProps) {
    const t = requireTranslation();
    
    return (
        <div className="content fold-out color-main-background">
            <div className="row">
                <img
                    className="icon-button"
                    src={"back_arrow.svg"}
                    alt={t.back}
                    onClick={onBackClick} />
                <h3 className="content-headline">{`${t.loggerNote} ${loggerNote.note}`}</h3>
            </div>
            <InformationRow title={t.date} text={loggerNote.date} />
            {loggerNote.deviceName && (
                <InformationRow title={t.deviceName} text={loggerNote.deviceName} />
            )}
            {loggerNote.deviceNumber && (
                <InformationRow title={t.deviceNumber} text={loggerNote.deviceNumber} />
            )}
            {loggerNote.serialName && (
                <InformationRow title={t.serialName} text={loggerNote.serialName} />
            )}
            {loggerNote.serialNumber && (
                <InformationRow title={t.serialNumber} text={loggerNote.serialNumber} />
            )}
            {loggerNote.apf && (
                <InformationRow title={t.apf} text={loggerNote.apf} />
            )}
            {loggerNote.timeConstant && (
                <InformationRow
                    title={t.timeConstant}
                    text={`${loggerNote.timeConstant} ${t.timeConstantSuffix}`} />
            )}
            {loggerNote.density && (
                <InformationRow title={t.density} text={loggerNote.density} />
            )}
            {loggerNote.barometer && (
                <InformationRow
                    title={t.barometer}
                    text={`${loggerNote.barometer} ${t.barometerSuffix}`} />
            )}
            {loggerNote.area && (
                <InformationRow
                    title={t.area}
                    text={`${loggerNote.area} ${t.areaSuffix}`} />
            )}
            {loggerNote.flow && (
                <InformationRow
                    title={t.flow}
                    text={`${loggerNote.flow} ${t.flowSuffix}`} />
            )}
            {loggerNote.speed && (
                <InformationRow
                    title={t.speed}
                    text={`${loggerNote.speed} ${t.speedSuffix}`} />
            )}
            {loggerNote.temperature && (
                <InformationRow
                    title={t.temperature}
                    text={`${loggerNote.temperature} ${t.temperatureSuffix}`} />
            )}
        </div>
    );
}
import {IntegratedSpinLoader, useSpinLoader} from "../common/SpinLoader";
import ErrorMessage from "../common/ErrorMessage";
import UserList from "./UserList";
import UserDetailsAndEdit from "./UserDetailsAndEdit";
import {Content} from "../common/ContentStandards";
import useAdminApi from "../../API/useAdminApi";
import {useState} from "react";
import {OrgUserModel} from "../../models/user/user";
import AdminAddUser from "./AdminAddUser";

export default function AdminUserPage() {
    const { getUsers } = useAdminApi();
    const [
        loading, setLoading,
        loadingMessage, setLoadingMessage
    ] = useSpinLoader();
    const [ error, setError ] = useState("");
    const [ search, setSearch ] = useState("");
    const [ showUserForm, setShowUserForm ] = useState(false);
    const [ users, setUsers ] = useState<OrgUserModel[]>();
    const [ selectedUser, setSelectedUser ] = useState<OrgUserModel>();

    const handleSearchUsers = (search: string) => {
        setError("");
        setLoading(true);
        setLoadingMessage("Searching for users...")
        getUsers(search)
            .then(setUsers)
            .catch((e) => { if (e instanceof Error) setError(e.message) })
            .finally(() => setLoading(false));
    };

    const handleUserClick = (user: OrgUserModel) => {
        // console.log("user have been selected")
        setSelectedUser(user);
        setUsers(undefined);
    };

    return (
        <Content
            invisible={true}>
            <div className="row">
                <h3 className="content-container-headline available center-with-button-on-right">
                    {"Search for the user in question"}
                </h3>
                <button
                    className={"filled"}
                    onClick={() => {
                        setUsers(undefined)
                        setSelectedUser(undefined);
                        setShowUserForm(true)
                    }}>
                    {"Add User"}
                </button>
            </div>

            <>
                <form
                    className="form-search-field center-page"
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSearchUsers(search);
                        setSelectedUser(undefined);
                        setShowUserForm(false);
                    }}>
                    <input
                        className="input search-field"
                        type="search"
                        placeholder={"search"}
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}/>
                </form>

                <hr/>

                <IntegratedSpinLoader
                    loading={loading}
                    loadMessage={loadingMessage} />

                <ErrorMessage error={error} />

                {!loading && !error && users &&
                    <UserList
                        users={users}
                        onUserClick={handleUserClick}/>
                }
                {!loading && !error && selectedUser &&
                    <UserDetailsAndEdit initialUser={selectedUser} />
                }
                {!loading && !error && showUserForm &&
                    <AdminAddUser onSuccessAdd={() => setShowUserForm(false)} />
                }
            </>
        </Content>
    )
}
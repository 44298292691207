interface LoggerNoteModelInit {
    readonly id: string;
    note: number;
    date: string;
    deviceName: string | null;
    deviceNumber: number | null;
    serialName: string | null;
    serialNumber: number | null;
    apf: string | null;
    timeConstant: number | null;
    density: string | null;
    barometer: number | null;
    area: number | null;
    flow: number | null;
    speed: number | null;
    temperature: number | null;
}

export default class LoggerNoteModel implements LoggerNoteModelInit {
    readonly id: string;
    note: number;
    date: string;
    deviceName: string | null;
    deviceNumber: number | null;
    serialName: string | null;
    serialNumber: number | null;
    apf: string | null;
    timeConstant: number | null;
    density: string | null;
    barometer: number | null;
    area: number | null;
    flow: number | null;
    speed: number | null;
    temperature: number | null;

    constructor(init: LoggerNoteModelInit) {
        this.id = init.id;
        this.note = init.note;
        this.date = init.date;
        this.deviceName = init.deviceName;
        this.deviceNumber = init.deviceNumber;
        this.serialName = init.serialName;
        this.serialNumber = init.serialNumber;
        this.apf = init.apf;
        this.timeConstant = init.timeConstant;
        this.density = init.density;
        this.barometer = init.barometer;
        this.area = init.area;
        this.flow = init.flow;
        this.speed = init.speed;
        this.temperature = init.temperature;
    }

}
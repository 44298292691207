interface StepReadingModelInit {
    id: string | null,
    step: number | null,
    inPercent: number | null,
    inM3h: number | null,
    outPercent: number | null,
    outM3h: number | null,
    clientId: string,
}

export class StepReadingModel implements StepReadingModelInit {
    id: string | null = null;
    step: number | null = null;
    inPercent: number | null = null;
    inM3h: number | null = null;
    outPercent: number | null = null;
    outM3h: number | null = null;
    clientId: string = "";

    constructor(init?: StepReadingModelInit) {
        if (!init) return;

        this.id = init.id;
        this.step = init.step;
        this.inPercent = init.inPercent;
        this.inM3h = init.inM3h;
        this.outPercent = init.outPercent;
        this.outM3h = init.outM3h;
        this.clientId = init.clientId;
    }

}
import {NewsModel} from "../../models/News";
import {localeDateFromTimestamp} from "../../utils/timestamp";

interface NewsCardProps {
    news: NewsModel;
}

export default function NewsCard({ news }: NewsCardProps) {

    return (
        <div className="card color-sec-background">
            <h3 className="card-header">{news.title}</h3>
            <p className="support-linebreak centered fit-card">{news.text}</p>
            <p className="single-line">{localeDateFromTimestamp(news.lastEdit)}</p>
        </div>
    );
}
import {SuccessNoContent, useFetch} from "./useFetch";
import {ExtendedRegistrationModel, OrgUserModel} from "../models/user/user";
import {NewsCollectionGet, NewsCollectionModel, NewsDeleteModel} from "../models/News";
import {EmployeeModel} from "../models/Employee";
import {
    SupportedDeviceTemplate,
    UnsupportedLoggerFile,
    UnsupportedLoggerFileDetails
} from "../models/case/loggerNoteFile";
import {SupportedLoggersAdminModel} from "../models/case/supportedLoggers";
import LoggerNoteModel from "../models/case/loggerNote";
import {LoggerDeviceAddResults} from "../models/case/LoggerDevice";
import {TermsAndConditions} from "../models/registration/termsAndConditions";

export default function useAdminApi() {
    const baseURL = process.env.REACT_APP_API_URL as string;
    const url = `${baseURL}/admin`;
    const backup_url = `${url}/backup`;
    const users_url = `${url}/users`;
    const user_url = `${url}/user`;
    const employees_url = `${user_url}/employees`;
    const employee_url = `${user_url}/employee`;
    const resend_url = `${url}/employee/resend`;
    const news_url = `${baseURL}/news`;
    const news_admin_url = `${baseURL}/admin/news`;
    const support_url = `${baseURL}/support/loggerNoteFile`;
    const supported_url = `${baseURL}/case/supportedDevices`;
    const terms_url = `${baseURL}/terms`;
    const requester = useFetch();

    const getUsers = (search: string) => {
        const params = `?search=${search}`
        return requester.get(OrgUserModel, users_url + params) as Promise<OrgUserModel[]>;
    };

    const postUser = (registration: ExtendedRegistrationModel) => {
        return requester.post(SuccessNoContent, user_url, registration) as Promise<SuccessNoContent>;
    };

    const putUser = (user: OrgUserModel) => {
        return requester.put(SuccessNoContent, user_url, user) as Promise<SuccessNoContent>;
    };

    const deleteUser = (user: {id: string, passphrase: string | undefined}) => {
        return requester.delete(SuccessNoContent, user_url, user) as Promise<SuccessNoContent>;
    };

    const getUserEmployees = (userId: string) => {
        const param = `?userId=${userId}`;
        return requester.get(EmployeeModel, employees_url + param) as Promise<EmployeeModel[]>;
    };

    const putUserEmployee = (employee: EmployeeModel) => {
        return requester.put(SuccessNoContent, employee_url, employee) as Promise<SuccessNoContent>;
    };

    const resendRegisterEmployee = (employee: EmployeeModel, lang?: string) => {
        return requester.post(
            SuccessNoContent,
            resend_url,
            employee,
            undefined,
            false,
            false,
            lang ? lang : true
        )
    }

    const getNewsList = () => {
        return requester.get(NewsCollectionGet, news_admin_url) as
            Promise<NewsCollectionGet[]>;
    };

    const postNews = (newsCollection: NewsCollectionModel) => {
        return requester.post(SuccessNoContent, news_url, newsCollection) as
            Promise<SuccessNoContent>;
    };

    const putNews = (newsCollection: NewsCollectionModel) => {
        return requester.put(SuccessNoContent, news_url, newsCollection) as
            Promise<SuccessNoContent>;
    };

    const deleteNews = (newsDeleteModel: NewsDeleteModel) => {
        return requester.delete(SuccessNoContent, news_url, newsDeleteModel) as
            Promise<SuccessNoContent>;
    };

    const getUnsupportedLoggerFiles = () => {
        return requester.get(UnsupportedLoggerFileDetails, support_url) as
            Promise<UnsupportedLoggerFileDetails[]>;
    };

    const getUnsupportedLoggerFile = (loggerId: string) => {
        const param = `?id=${loggerId}`;
        return requester.get(
            UnsupportedLoggerFile,
            support_url + param,
            undefined,
            undefined,
            true) as
            Promise<UnsupportedLoggerFile>;
    };

    const getSupportedDevices = () => {
        const param = `?withFilename=${true}`;
        return requester.get(SupportedLoggersAdminModel, supported_url + param) as
            Promise<SupportedLoggersAdminModel>;
    };

    const getSupportedDeviceTemplate = (filename?: string) => {
        let param: string
        if (filename) {
            param = `?template=${true}&filename=${filename}`
        } else {
            param = `?template=${true}`;
        }

        return requester.get(SupportedDeviceTemplate, supported_url + param) as
            Promise<SupportedDeviceTemplate>;
    }

    const postSupportedDeviceTest = (stringToJson: string, loggerId: string) => {
        const params = `?test=${true}&id=${loggerId}`;
        return requester.post(LoggerNoteModel, supported_url + params, stringToJson) as
            Promise<LoggerNoteModel[]>;
    };

    const postSupportedDevice = (stringToJson: string, filename: string) => {
        const param = `?filename=${filename}`;
        return requester.post(LoggerDeviceAddResults, supported_url + param, stringToJson) as
            Promise<LoggerDeviceAddResults>;
    };

    const postAddDeviceToExisting = (deviceName: string, filename: string) => {
        const params = `?deviceAdd=${deviceName}&filename=${filename}`;
        return requester.post(LoggerDeviceAddResults, supported_url + params) as
            Promise<LoggerDeviceAddResults>;
    };

    const putSupportedDeviceTest = (deviceName: string, formData: FormData) => {
        const params = `?test=${true}&deviceName=${deviceName}`;
        return requester.put(LoggerNoteModel, supported_url + params, formData) as
            Promise<LoggerNoteModel[]>;
    };

    const putSupportedDevice = (stringToJson: string, filename: string) => {
        const param = `?filename=${filename}`;
        return requester.put(SuccessNoContent, supported_url + param, stringToJson) as
            Promise<SuccessNoContent>;
    };

    const postTermsAndConditions = (language: string, formData: FormData) => {
        const param = `?lang=${language}`;
        return requester.post(SuccessNoContent, terms_url + param, formData) as
            Promise<SuccessNoContent>;
    };

    const getBackup = () => {
        return requester.get(
            TermsAndConditions,
            backup_url,
            undefined,
            undefined,
            true
        ) as Promise<TermsAndConditions>;
    }

    return {
        getUsers,
        postUser,
        putUser,
        deleteUser,
        getUserEmployees,
        putUserEmployee,
        resendRegisterEmployee,
        getNewsList,
        postNews,
        putNews,
        deleteNews,
        getUnsupportedLoggerFiles,
        getUnsupportedLoggerFile,
        getSupportedDevices,
        getSupportedDeviceTemplate,
        postSupportedDeviceTest,
        postSupportedDevice,
        postAddDeviceToExisting,
        putSupportedDeviceTest,
        putSupportedDevice,
        postTermsAndConditions,
        getBackup
    };
}
interface SessionInit {
    status: string,
    customerEmail: string,
    paymentStatus: string,
}

export class SessionModel {
    status: string;
    customerEmail: string;
    paymentStatus: string;

    constructor(init: SessionInit) {
        this.status = init.status;
        this.customerEmail = init.customerEmail;
        this.paymentStatus = init.paymentStatus;
    }
}
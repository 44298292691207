import React, {ChangeEvent, MouseEventHandler, ReactElement, useEffect, useRef, useState} from "react";
import requireTranslation from "../../lang/locales/config";
import "../../styles/dialog.css"
import {IntegratedSpinLoader} from "./SpinLoader";
import ErrorMessage from "./ErrorMessage";


export function useDialog() {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState("");

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const setDialogMessage = (message: string) => {
        setMessage(message);
    };

    return {
        isOpen,
        message,
        setDialogMessage,
        toggle
    };
}


export function useVerificationDialog() {
    const [ isOpen, setIsOpen ] = useState(false);
    const [ error, setError ] = useState("");
    const [ awaitingResponse, setAwaitingResponse ] = useState(false);
    const [ awaitingSendEmail, setAwaitingSendEmail ] = useState(false);

    const toggle = () => {
        setIsOpen((prevState) => !prevState);
    };

    const dialogError = (dialogError?: string): string => {
        if (dialogError !== undefined) {
            setError(dialogError);
            return dialogError;
        } else {
            return error;
        }
    }

    const dialogAwaitingResponse = (isLoading?: boolean): boolean => {
        if (isLoading !== undefined) {
            setAwaitingResponse(isLoading);
            return isLoading;
        } else {
            return awaitingResponse;
        }
    }

    const dialogAwaitingSendEmail = (isLoading?: boolean): boolean => {
        if (isLoading !== undefined) {
            setAwaitingSendEmail(isLoading);
            return isLoading;
        } else {
            return awaitingSendEmail;
        }
    }

    return {
        isOpen,
        toggle,
        dialogError,
        dialogAwaitingResponse,
        dialogAwaitingSendEmail
    }
}


interface VerificationDialogProps {
    setCode: React.Dispatch<React.SetStateAction<string>>;
    toggle: () => void;
    onVerify: () => void;
    error: () => string;
    awaitingResponse: () => boolean;
    awaitingEmailSend: () => boolean;
}


export function VerificationDialog(
    { setCode, toggle, onVerify, error, awaitingResponse, awaitingEmailSend }: VerificationDialogProps
) {
    const t = requireTranslation();
    const inputRefs = useRef<HTMLInputElement[]>([]);
    const [ inputs, setInputs ] = useState<JSX.Element[]>([]);
    const [ currentIndex, setCurrentIndex ] = useState<number | undefined>(undefined);
    const [ codeArray ] = useState(["", "", "", "", "", ""]);
    const [ disableVerify, setDisableVerify ] = useState(true);

    useEffect(() => {
        if (inputs.length === 0) {
            const arr = []
            for (let i = 0 ; i < 6 ; i++) {
                arr.push(
                    <input
                        key={i}
                        type="text"
                        ref={(ref) => inputRefs.current[i] = ref!!}
                        onKeyDown={(e) => handleBackspace(e, i)}
                        onChange={(e) => {
                            handleInput(e, i);
                        }}
                        maxLength={1}/>
                )
            }
            setInputs(arr);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputs]);

    useEffect(() => {
        if (currentIndex !== undefined) {
            inputRefs.current[currentIndex].focus()
        }
    }, [currentIndex]);

    const handleBackspace = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === "Backspace" && index !== 0) {
            if (!inputRefs.current[index].value) {
                inputRefs.current[index - 1].value = "";
                codeArray[index - 1] = "";
                setCurrentIndex(index - 1);
            } else {
                inputRefs.current[index].value = "";
                codeArray[index] = "";
            }

            setDisableVerify(true);
            setCode(codeArray.toString().replaceAll(",", ""));
        }
    };

    const handleSetFocus = () => {
        for (let i = 0 ; i < 6 ; i++) {
            if (!inputRefs.current[i].value || (inputRefs.current[i].value && i === 5)) {
                if (currentIndex !== i) {
                    setCurrentIndex(i);
                }
                break;
            }
        }
    };

    const handleInput = (event: ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = event.target;

        codeArray[index] = value.toUpperCase();
        setCode(codeArray.toString().replaceAll(",", ""));

        if (value) {
            if (index < 5) {
                setCurrentIndex(index + 1);
            }

            if (index === 5) {
                setDisableVerify(false);
            }
        }
        event.target.value = value.toUpperCase();
    };

    return (
        <div className="dialog-overlay">
            <div id="verification-dialog" className="color-main-background">
                <h4 className="text">
                    {!awaitingEmailSend() ?
                        t.verificationDialog.enter :
                        t.verificationDialog.sendingVerificationCode}
                </h4>
                {!awaitingEmailSend() ?
                    <div
                        className={"input-container"}
                        onBlur={() => setCurrentIndex(undefined)}
                        onClick={handleSetFocus}>
                        {inputs}
                    </div> :
                    <div className="loader"/>
                }
                <div className="row margin">
                    <p className="error message fixed no-border">{error()}</p>
                </div>

                <div className="button-container">
                    <button
                        className={"filled button"}
                        disabled={disableVerify}
                        onClick={onVerify}>
                        {awaitingResponse() ? "" : t.verificationDialog.verify}
                        {awaitingResponse() &&
                            <div className="loader small"/>
                        }
                    </button>
                    <button
                        className="outline"
                        onClick={() => toggle()}>
                        {t.cancel}
                    </button>
                </div>
            </div>
        </div>
    )
}


interface ConfirmationDialogProps {
    children?: JSX.Element | JSX.Element[];
    toggle: () => void;
    message: string;
    onConfirm: () => void;
}

export function ConfirmationDialog({ children, toggle, message, onConfirm }: ConfirmationDialogProps) {
    const t = requireTranslation();

    return (
        <div className="dialog-overlay" onClick={toggle}>
            <div className="dialog-box adaptable color-main-background" onClick={(e) => e.stopPropagation()}>
                <h4 className="support-linebreak">{message}</h4>
                {children}
                <div className="row reverse margin">
                    <button
                        className="filled"
                        onClick={() => {
                            toggle();
                            onConfirm();
                        }}>{t.confirm}</button>
                    <button
                        className="outline"
                        onClick={() => {toggle()}}>{t.cancel}</button>
                </div>
            </div>
        </div>
    );
}

interface InformationDialogProps {
    toggle: () => void;
    message: string;
}

export function InformationDialog({ toggle, message }: InformationDialogProps) {
    const t = requireTranslation();

    return (
        <div className="dialog-overlay" onClick={toggle}>
            <div className="dialog-box adaptable color-main-background" onClick={(e) => e.stopPropagation()}>
                <h4 className="support-linebreak">{message}</h4>
                <div className="row reverse margin">
                    <button
                        className="filled"
                        onClick={() => {toggle()}}>{t.ok}</button>
                </div>
            </div>
        </div>
    );
}


interface OptionButtonProps {
    text: string;
    onClick: () => void;
    toggle: () => void;
}

/**
 * For use with OptionsDialog.
 *
 * @param text button text.
 * @param onClick function to execute on click.
 * @param toggle the toggle function from useDialog.
 */
export function OptionButton({ text, onClick, toggle }: OptionButtonProps) {
    return (
        <button
            className="filled fit-small-screens"
            onClick={() => {
                toggle();
                onClick();
            }}>
            {text}
        </button>
    )
}

interface OptionsDialogProps {
    children: JSX.Element | JSX.Element[]
    toggle: () => void;
    message: string;
}

/**
 * Use when multiple options can be selected.
 *
 * @param toggle the toggle function from useDialog.
 * @param message message you want to display before the options.
 * @param children preferably OptionButton(s).
 */
export function OptionsDialog({ children, toggle, message }: OptionsDialogProps) {
    const t = requireTranslation();
    const options = children instanceof Array ?
        children.map((value) => {
            if (value.type === OptionButton) {
                return value
            }
        }) : children;
    const other = children instanceof Array ?
        children.map((value) => {
            if (value.type !== OptionButton) {
                return value
            }
        }) : undefined;

    return (
        <div className="dialog-overlay" onClick={toggle}>
            <div className="dialog-box adaptable color-main-background" onClick={(e) => e.stopPropagation()}>
                <h4 className="support-linebreak">{message}</h4>
                <div className="dialog-box-content">{other}</div>
                <div className="row reverse toColumn margin">
                    {options}
                    <button
                        className="outline fit-small-screens"
                        onClick={() => {
                            toggle();
                        }}>{t.cancel}</button>
                </div>
            </div>
        </div>
    );
}
import {EmployeeModel} from "../../models/Employee";
import requireTranslation from "../../lang/locales/config";
import {useStandardSetup} from "../../utils/customHooks";
import {OverlaySpinLoader, SpinLoader} from "../common/SpinLoader";
import ErrorMessage from "../common/ErrorMessage";
import {ChangeEvent, useEffect, useState} from "react";
import {useEmployeeApi} from "../../API/useEmployeeApi";
import {useClientApi} from "../../API/useClientCaseApi";

interface ClientCaseEmployeeFormProps {
    clientId: string;
    currentEmployeeId: string | null;
    onSave: () => void;
}

export default function ClientCaseEmployeeForm({ clientId, currentEmployeeId, onSave }: ClientCaseEmployeeFormProps) {
    const t = requireTranslation();
    const [
        loading, setLoading,
        error, setError,
        employeeList, setEmployeeList,
    ] = useStandardSetup<EmployeeModel[]>();
    const [ awaitingSubmit, setAwaitingSubmit ] = useState(false);
    const { getEmployees } = useEmployeeApi();
    const { putClientEmployee } = useClientApi();
    const [ selectedEmployee, setSelectedEmployee ] = useState<string | null>(null);

    useEffect(() => {
        if (!employeeList) {
            setLoading(true);
            getEmployees()
                .then(setEmployeeList)
                .catch((e) => { if (e instanceof Error) setError(e.message); })
                .finally(() => setLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employeeList])

    const handleSelectionChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const { value } = e.target;

        value === "user" ? setSelectedEmployee(null) : setSelectedEmployee(value);
    }

    const handleSaveClick = async () => {
        setAwaitingSubmit(true);
        await putClientEmployee(clientId, selectedEmployee)
            .then(() => onSave())
            .catch((e) => { if (e instanceof Error) setError(e.message) })
            .finally(() => setAwaitingSubmit(false));
    }

    return (
        <>
            <SpinLoader
                containerClassName="content color-main-background"
                loading={loading}
                loadMessage={`${t.loading} ${t.employees.toLowerCase()}`} />

            <ErrorMessage
                error={error}
                containerClassName="content color-main-background" />

            <OverlaySpinLoader
                loading={awaitingSubmit}
                loadMessage={`${t.awaiting}`} />

            {!loading && !error && employeeList && (
                <div className="content fold-out color-main-background">
                    <div className="content-inner-form">
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className="row margin toColumn">
                                <label
                                    className="input-label"
                                    aria-label="employeeSelectionLabel">
                                    {t.employee}
                                </label>
                                <select
                                    className="input select"
                                    name="employeeSelect"
                                    defaultValue={currentEmployeeId ? employeeList.find((employee) => {
                                        return employee.id === currentEmployeeId
                                    })?.id!! : "user"}
                                    onChange={handleSelectionChange}>
                                    {employeeList.map((employee) => (
                                        <option
                                            key={employee.id!!}
                                            value={employee.id!!}>{employee.name}</option>
                                    ))}
                                    <option
                                        key="user"
                                        value="user">{t.yourself}</option>
                                </select>
                            </div>
                            <div className="row margin reverse">
                                <button
                                    className="filled"
                                    onClick={handleSaveClick}>
                                    {t.save}
                                </button>

                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    )
}
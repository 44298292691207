import requireTranslation from "../../lang/locales/config";
import {useStandardSetup} from "../../utils/customHooks";
import {SimplifiedClientCase} from "../../models/ClientCase";
import {useClientApi} from "../../API/useClientCaseApi";
import {IntegratedSpinLoader} from "../common/SpinLoader";
import {useEffect} from "react";
import ClientCaseCard from "../client-cases/ClientCaseCard";
import {Content} from "../common/ContentStandards";
import ErrorMessage from "../common/ErrorMessage";
import {Link} from "react-router-dom";

export default function LatestClientCases() {
    const t = requireTranslation();
    const [
        loading, setLoading,
        error, setError,
        clientCases, setClientCases
    ] = useStandardSetup<SimplifiedClientCase[]>();
    const { getClientsByFilterDate } = useClientApi();
    const startDate = new Date(Date.now() - 1209000000).getTime() / 1000;
    const stopDate = new Date(Date.now() + 1209000000).getTime() / 1000;

    useEffect(() => {
        if (!clientCases) {
            handleGetClientCases();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientCases]);

    const handleGetClientCases = () => {
        setLoading(true);
        getClientsByFilterDate(startDate, stopDate, 10, 0)
            .then((setClientCases))
            .catch((e) => { if (e instanceof Error) setError(e.message) })
            .finally(() => setLoading(false));
    }


    return (
        <Content
            headline={t.recentCases}
            flexGrow={true}>

            <IntegratedSpinLoader
                loading={loading}
                loadMessage={t.loading} />

            <ErrorMessage error={error}/>

            <>
                {clientCases && !loading && !error && (
                    <div className="content-inner-list-column gap">
                        {clientCases.length > 0 ? clientCases.map((clientCase, index) => (
                            <ClientCaseCard key={clientCase.id + index} clientCase={clientCase} />
                        )) : (
                            <p className="support-linebreak center-text">
                                {t.informationTexts.noRecentCases}<Link to={"/cases"}>{t.cases.toLowerCase()}.</Link>
                            </p>
                        )}
                    </div>
                )}
            </>
        </Content>
    );
};
import requireTranslation from "../../lang/locales/config";
import "../../styles/homepage.css"
import {InformationRow} from "../common/TextCommons";

export default function AboutPage() {
    const t = requireTranslation();

    return (
        <div id="home">
            <div className="home-content no-bottom-padding">
                <div className="home-text-container">
                    <h1>{t.about}</h1>
                    <p className="center">{t.informationTexts.about}</p>
                    <h1>{t.informationTexts.privacyPolicyTitle}</h1>
                    <p className="center">{t.informationTexts.privacyPolicyIntroText}</p>
                    <h3>{t.informationTexts.privacyPolicyDataControllerTitle}</h3>
                    <p className="center">{t.informationTexts.privacyPolicyDataControllerText}</p>
                    <h3>{t.informationTexts.privacyPolicyDataProcessorTitle}</h3>
                    <p className="center">{t.informationTexts.privacyPolicyDataProcessorText}</p>
                    <h3>{t.informationTexts.privacyPolicyWhatDataIsCollectedTitle}</h3>
                    <p className="center">{t.informationTexts.privacyPolicyWhatDataIsCollectedText}</p>
                    <h3>{t.informationTexts.privacyPolicyHowWeProcessDataTitle}</h3>
                    <p className="center">{t.informationTexts.privacyPolicyHowWeProcessDataText}</p>
                    <h3>{t.informationTexts.privacyPolicyHandlingStorageAndDeletionTitle}</h3>
                    <p className="center">{t.informationTexts.privacyPolicyHandlingStorageAndDeletionText}</p>
                    <h1>{t.informationTexts.cookiePolicyTitle}</h1>
                    <p className="center">{t.informationTexts.cookiePolicyText}</p>
                </div>
                <div className="watermark-container color-sec-background">
                    <div className="watermark-content">
                        <h3 className="headline">{t.watermark.title}</h3>
                        <InformationRow title={t.VAT} text={t.watermark.companyVAT}/>
                        <InformationRow title={t.company} text={t.watermark.companyName}/>
                        <InformationRow title={t.location} text={t.watermark.companyLocation}/>
                        <InformationRow title={t.email} text={t.watermark.companyEmail}/>
                    </div>
                </div>
            </div>
        </div>
    );
};
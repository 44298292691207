import {useEffect, useState} from "react";
import {useRegistrationApi} from "../../API/useRegistrationApi";
import {Content, PageWithContent} from "../common/ContentStandards";
import {IntegratedSpinLoader} from "../common/SpinLoader";
import EmbeddedStripe from "./EmbeddedStripe";
import requireTranslation from "../../lang/locales/config";
import {useNavigate, useParams} from "react-router-dom";
import {SubscriptionEnum} from "../utils/Enums";
import ErrorMessage from "../common/ErrorMessage";
import {EmbeddedCheckout, EmbeddedCheckoutProvider} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";

interface RouteParams {
    [index: string]: string | undefined,
    subscription: string,
    email: string,
    customer: string | undefined
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);


export default function CheckoutPage() {
    const t = requireTranslation();
    const [ options, setOptions ] = useState<string | null>(null);
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState("");
    const { getCheckout } = useRegistrationApi();
    const params = useParams<RouteParams>()
    const navigate = useNavigate()

    useEffect(() => {
        if (
            params.subscription &&
            (params.subscription === SubscriptionEnum.Basic.toString() ||
                params.subscription === SubscriptionEnum.Extended.toString()) &&
            params.email
        ) {
            setLoading(true);
            getCheckout(params.subscription, params.email, params.customer)
                .then((checkout) => {
                    setOptions(checkout.clientSecret)
                })
                .catch((e) => {
                    if (e instanceof Error) {
                        setError(e.message);
                    }
                })
                .finally(() => {
                    setLoading(false)
                });
        } else {
            navigate('/register')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <PageWithContent>
            <Content
                headline={t.paymentDetails}
                invisible={true}
                centerHeadline={true}>
                <IntegratedSpinLoader loading={loading} loadMessage={""} />
                <ErrorMessage error={error} />

                <>
                    {options &&
                        <EmbeddedCheckoutProvider
                            stripe={stripePromise}
                            options={{
                                clientSecret: options
                            }} >
                            <EmbeddedCheckout />
                        </EmbeddedCheckoutProvider>
                    }
                </>
            </Content>
        </PageWithContent>
    )
}
import {EmployeeModel} from "../../models/Employee";
import {PhoneInput, TextAreaWithLabel, TextInputWithLabel} from "../common/FormCommons";
import {Content} from "../common/ContentStandards";
import {usePhoneNumberInput, useStandardHandleChange} from "../../utils/customHooks";
import {useState} from "react";

interface AdminEditEmployeeProps {
    initialEmployee: EmployeeModel;
    onEditClick: (employee: EmployeeModel) => void;
    onBack: () => void;
    onResendVALClick: () => void;
    onResendPWClick: () => void;
    emailLang: string;
    onChangeEmailLang: (value: string) => void;
}

export default function AdminEditEmployee(
    {
        initialEmployee,
        onEditClick,
        onBack,
        onResendVALClick,
        onResendPWClick,
        emailLang,
        onChangeEmailLang
    }: AdminEditEmployeeProps
) {
    const [ employee, setEmployee ] = useState(initialEmployee);
    const [ handleChange ] = useStandardHandleChange(EmployeeModel, setEmployee);
    const {
        handlePhoneNumberChange,
        inputFieldValue,
        countryCode,
        setCountryCode
    } = usePhoneNumberInput(EmployeeModel, setEmployee, employee.phone);


    return (
        <Content
            invisible={true}>
            <div className="content-inner-form">
                <div className="row">
                    <img
                        className="icon-button"
                        src={"back_arrow.svg"}
                        alt={"Back"}
                        onClick={onBack}/>
                    <h3 className="content-headline">
                        {`Edit - ${employee.name} (${employee.username})`}
                    </h3>
                </div>
                <TextInputWithLabel
                    labelText={"Name"}
                    name="name"
                    initialValue={employee.name}
                    onChange={handleChange}/>
                <TextAreaWithLabel
                    labelText={"Description"}
                    name="description"
                    initialValue={employee.description}
                    isReadonly={true}/>
                <TextInputWithLabel
                    labelText={"Username"}
                    name="username"
                    initialValue={employee.username}
                    onChange={handleChange}/>
                <TextInputWithLabel
                    labelText={"Email"}
                    name="email"
                    initialValue={employee.email}
                    onChange={handleChange}/>
                <PhoneInput
                    initialValue={inputFieldValue}
                    countryCode={countryCode}
                    setCountryCode={setCountryCode}
                    onChange={handlePhoneNumberChange} />

                <div className="row reverse">
                    <button
                        className="filled"
                        onClick={() => {
                            onEditClick(employee)
                        }}>
                        {"Edit"}
                    </button>
                    <button
                        className="outline"
                        onClick={onResendPWClick}>
                        {"Reset PW"}
                    </button>
                    {employee.username === null &&
                        <button
                            className="outline"
                            onClick={onResendVALClick}>
                            {"Resend VAL"}
                        </button>
                    }
                    <input
                        value={emailLang}
                        onChange={(event) => {
                            onChangeEmailLang(event.target.value);
                        }} />
                </div>
            </div>
        </Content>
    )
}
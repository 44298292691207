import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {UserModel} from "../models/user/user";
import {SubscriptionEnum} from "../components/utils/Enums";


export interface UserState {
    username: string;
    name: string;
    firmName: string;
    firmEmail: string;
    privateEmail: string;
    phone: string;
    hasFirmHeader: boolean;
    hasBalancingFrontpage: boolean;
    maxEmployees: number;
    subscription: number;
    accessLevel: number;
}

const initialUser = sessionStorage.getItem("user") ?
    (JSON.parse(sessionStorage.getItem("user")!!) as UserModel) : null;

const initialUserState: UserState = {
    username: initialUser ? initialUser.username : "",
    name: initialUser ? initialUser.name : "",
    firmName: initialUser ? initialUser.firmName : "",
    firmEmail: initialUser ? initialUser.firmEmail : "",
    privateEmail: initialUser ? initialUser.privateEmail : "",
    phone: initialUser ? initialUser.phone : "",
    hasFirmHeader: initialUser ? initialUser.hasFirmHeader : false,
    hasBalancingFrontpage: initialUser ? initialUser.hasBalancingFrontpage : false,
    maxEmployees: initialUser ? initialUser.maxEmployees : 0,
    subscription: initialUser ? initialUser.subscription : SubscriptionEnum.NotActive,
    accessLevel: initialUser ? initialUser.accessLevel : -1
};

export const userSlice = createSlice({
    name: "user",
    initialState: initialUserState,
    reducers: {
        setUser: (state, action: PayloadAction<UserState>) => {
            state.username = action.payload.username;
            state.name = action.payload.name;
            state.firmName = action.payload.firmName;
            state.firmEmail = action.payload.firmEmail;
            state.privateEmail = action.payload.privateEmail;
            state.phone = action.payload.phone;
            state.hasFirmHeader = action.payload.hasFirmHeader;
            state.hasBalancingFrontpage = action.payload.hasBalancingFrontpage;
            state.maxEmployees = action.payload.maxEmployees;
            state.subscription = action.payload.subscription;
            state.accessLevel = action.payload.accessLevel;
            sessionStorage.setItem('user', JSON.stringify(action.payload));
        },
        setNoUser: (state) => {
            state.username = "";
            state.name = "";
            state.firmName = "";
            state.firmEmail = "";
            state.privateEmail = "";
            state.phone = "";
            state.hasFirmHeader = false;
            state.maxEmployees = 0;
            state.subscription = SubscriptionEnum.NotActive;
            state.accessLevel = -1;
            sessionStorage.removeItem('user')
        }
    }
});

export const { setUser, setNoUser } = userSlice.actions;

// Other code such as selectors can use the imported 'RootState' type
export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;
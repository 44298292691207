export function localeDateFromTimestamp(timestamp: number): string {
    return new Date(timestamp * 1000).toLocaleDateString();
}

export function localeTimeFromTimestamp(timestamp: number): string {
    return new Date(timestamp * 1000).toLocaleString();
}

export function localeTimeFromUtcTimestamp(timestamp: number): string {
    const date = new Date(timestamp * 1000);
    const timezoneOffset = date.getTimezoneOffset();
    const local = new Date(date.getTime() - timezoneOffset * 60 * 1000);
    return local.toLocaleString();
}

export function toDateInputFromTimeStamp(timestamp: number): string {
    const dateObj = new Date(timestamp * 1000);
    let month: number | string = dateObj.getMonth() + 1;
    let day: number | string = dateObj.getDate();

    if (day < 10) day = `0${day}`;
    if (month < 10) month = `0${month}`;

    return `${dateObj.getFullYear()}-${month}-${day}`;
}

export function timestampFromDateString(date: string): number {
    return Date.parse(date) / 1000;
}

export function getDateNowTimestamp(): number {
    return Date.now() / 1000;
}

export function getDateNow(): string {
    return toDateInputFromTimeStamp(Date.now() / 1000)
}